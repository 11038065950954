import React, { ReactElement, useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import { orderBy } from "natural-orderby";

import SendEmailAttendee from "../SendEmailAttendee/SendEmailAttendee";
import { AppContext, AppContextType } from "../../../../../context/AppContext";
import {
  EmailContext,
  EmailContextType,
} from "../../../../../context/EmailContext";

import { SendEmailAttendeesStyles } from "./SendEmailAttendeesStyles";
import styles from "./SendEmailAttendees.module.css";
import useGetAllAttendeesOrPresenters from "../../../../../hooks/useGetAllAttendeesOrPresenters";

interface Props {
  currentTab: number;
}

export default function SendEmailAttendees(props: Props): ReactElement {
  const { selectedBookingID }: AppContextType = useContext(AppContext);

  const {
    isLoading,
    bookingAttendees,
    noAttendees,
    error,
    getAllAttendeesOrPresenters,
  } = useGetAllAttendeesOrPresenters(selectedBookingID, false);

  const [searchValue, setSearchValue] = useState("");
  const [selectAll, toggleSelectAll] = useState(false);
  const [selectAllBrand, toggleSelectAllBrand] = useState(false);
  const [selectAllSent, toggleSelectAllSent] = useState(false);
  const [selectAllUnsent, toggleSelectAllUnsent] = useState(false);

  const {
    selectedAttendees,
    setSelectedAttendees,
    selectedEmailTemplate,
  }: EmailContextType = useContext(EmailContext);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  //Get booking attendees
  useEffect(() => {
    setSelectedAttendees([]);
    getAllAttendeesOrPresenters();

    return function cleanup() {
      source.cancel("Component was unmounted");
    };
  }, []);

  /**/

  /* Search field change */
  const handleSearchFieldChange = (e) => {
    setSearchValue(e.target.value);
  };
  /**/

  /* SELECT ALL ATTENDEES*/
  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedAttendees([]);
    } else {
      setSelectedAttendees(bookingAttendees);
    }

    toggleSelectAll(!selectAll);
  };
  /**/

  /* Select all brand attendees */
  const handleSelectAllBrandChange = () => {
    if (selectAllBrand) {
      setSelectedAttendees(
        selectedAttendees.filter((attendee) => !attendee.brand)
      );
    } else {
      bookingAttendees.forEach((attendee) => {
        if (attendee.brand) {
          setSelectedAttendees((selectedAttendees) => [
            ...selectedAttendees,
            attendee,
          ]);
        }
      });
    }
    toggleSelectAllBrand(!selectAllBrand);
  };
  /**/

  /* Select all previously sent */
  const handleSelectAllPreviousChange = () => {
    let emailTemplateID = selectedEmailTemplate.emailTemplateID;

    /* TODO: TODO: TODO: TODO: */

    if (selectAllSent) {
      //remove all sent previous
      setSelectedAttendees(
        selectedAttendees.filter(
          (attendee) => !attendee.emails.includes(emailTemplateID)
        )
      );
    } else {
      //add all sent previous

      bookingAttendees.forEach((attendee) => {
        if (attendee.emails.includes(emailTemplateID)) {
          setSelectedAttendees((selectedAttendees) => [
            ...selectedAttendees,
            attendee,
          ]);
        }
      });
    }

    toggleSelectAllSent(!selectAllSent);
  };
  /**/

  /* Select all unsent */
  const handleSelectAllUnsentChange = () => {
    let emailTemplateID = selectedEmailTemplate.emailTemplateID;

    if (selectAllUnsent) {
      //remove all unsent previous
      setSelectedAttendees(
        selectedAttendees.filter((attendee) =>
          attendee.emails.includes(emailTemplateID)
        )
      );
    } else {
      //add all sent unsent previous

      bookingAttendees.forEach((attendee) => {
        if (!attendee.emails.includes(emailTemplateID)) {
          setSelectedAttendees((selectedAttendees) => [
            ...selectedAttendees,
            attendee,
          ]);
        }
      });
    }

    toggleSelectAllUnsent(!selectAllUnsent);
  };
  /**/

  //MaterialUI classes
  const classes = SendEmailAttendeesStyles();

  //Assemble booking attendees array and order by name
  let attendeesArray = [];
  for (var key in bookingAttendees) {
    if (
      `${bookingAttendees[key].registrationData.firstName} ${bookingAttendees[key].registrationData.lastName}`
        .toLocaleLowerCase()
        .includes(searchValue.toLowerCase())
    ) {
      attendeesArray.push(bookingAttendees[key]);
    }
  }

  let attendeesArrayOrdered = orderBy(
    attendeesArray,
    [(a) => a.registrationData.lastName, (a) => a.registrationData.firstName],
    ["asc", "asc"]
  );

  let attendeesComponentArray = [];

  for (let i = 0; i < attendeesArrayOrdered.length; i++) {
    attendeesComponentArray.push(
      <SendEmailAttendee
        registration={attendeesArrayOrdered[i]}
        presenters={false}
        key={attendeesArrayOrdered[i].registrationID}
        selectAll={selectAll}
        selectAllBrand={selectAllBrand}
        selectAllSentPrevious={selectAllSent}
      />
    );
  }

  return (
    <React.Fragment>
      <div
        className={styles.searchHolder}
        style={{ display: props.currentTab === 0 ? "inline-block" : "none" }}
      >
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              checked={selectAll}
              onChange={handleSelectAllChange}
              name="Select All"
              color="primary"
            />
          }
          label="Select All"
        />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              checked={selectAllBrand}
              onChange={handleSelectAllBrandChange}
              name="Select All Brand"
              color="primary"
            />
          }
          label="Select All Brand"
        />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              checked={selectAllSent}
              onChange={handleSelectAllPreviousChange}
              name="Select All Sent"
              color="primary"
            />
          }
          label="Select All Sent"
        />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              checked={selectAllUnsent}
              onChange={handleSelectAllUnsentChange}
              name="Select All Unsent"
              color="primary"
            />
          }
          label="Select All Unsent"
        />

        <TextField
          variant="outlined"
          label={"Attendee Search"}
          className={classes.attendeeSearch}
          value={searchValue}
          onChange={handleSearchFieldChange}
          InputProps={{
            className: classes.searchInput,
          }}
        />
      </div>
      <div
        className={styles.sendEmailAttendeesHolder}
        style={{ display: props.currentTab === 0 ? "inline-block" : "none" }}
      >
        <div className={styles.attendeeListHeader}>
          <Typography variant="body1" className={classes.nameHeader}>
            Name
          </Typography>
          <Typography variant="body1" className={classes.brandHeader}>
            Brand
          </Typography>
          <Typography variant="body1" className={classes.sentPreviousHeader}>
            Sent Previous
          </Typography>
        </div>

        <div className={styles.attendeeListHolder}>
          {error !== "" && (
            <Typography variant="body1" className={classes.error}>
              An error occurred getting attendees for this booking
            </Typography>
          )}
          {noAttendees && (
            <Typography variant="body1" className={classes.noAttendeesError}>
              No attendees found for this booking
            </Typography>
          )}

          {isLoading && <CircularProgress className={classes.loadingSpinner} />}
          {[attendeesComponentArray].map((item) => {
            return item;
          })}
        </div>
      </div>
    </React.Fragment>
  );
}
