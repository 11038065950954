import React, { useState } from "react";
import axios from "axios";

const useGetAllAttendeesOrPresenters = (
  selectedBookingID,
  gettingPresenters
) => {
  const [isLoading, toggleIsLoading] = useState(true);
  const [bookingAttendees, setBookingAttendees] = useState([]);
  const [bookingPresenters, setBookingPresenters] = useState([]);
  const [noAttendees, toggleNoAttendees] = useState(false);
  const [error, setError] = useState("");
  const [noPresenters, toggleNoPresenters] = useState(false);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const getAllAttendeesOrPresenters = async () => {
    try {
      let response = await axios.get(
        gettingPresenters ? "/presenters/all" : "/attendees/all",
        {
          params: {
            bookingID: selectedBookingID,
          },
          cancelToken: source.token,
        }
      );

      toggleIsLoading(false);
      if (gettingPresenters) {
        setBookingPresenters(response.data);
      } else {
        setBookingAttendees(response.data);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }

      toggleIsLoading(false);

      if (gettingPresenters) {
        setBookingPresenters([]);
      } else {
        setBookingAttendees([]);
      }

      if (error.response && error.response.status === 404) {
        if (gettingPresenters) {
          toggleNoPresenters(true);
        } else {
          toggleNoAttendees(true);
        }
      } else {
        setError("Error getting registrations for this booking");
      }
    }
  };

  return {
    isLoading,
    toggleIsLoading,
    bookingAttendees,
    setBookingAttendees,
    noAttendees,
    toggleNoAttendees,
    noPresenters,
    toggleNoPresenters,
    error,
    setError,
    getAllAttendeesOrPresenters,
    bookingPresenters,
    setBookingPresenters,
  };
};

export default useGetAllAttendeesOrPresenters;
