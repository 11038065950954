import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, CircularProgress, Paper, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import axios from "axios";

import EmailItem from "./EmailItem/EmailItem";
import AddEmailPopup from "./AddEmailPopup/AddEmailPopup";
import { AppContext, AppContextType } from "../../../context/AppContext";

import styles from "./BookingEmail.module.css";
import { BookingEmailStyles } from "./BookingEmailStyles";

interface Props {}

export default function BookingEmail(props: Props): ReactElement {
  const { selectedBooking, setSelectedBooking, producerID }: AppContextType =
    useContext(AppContext);

  const [addEmail, toggleAddEmail] = useState(false);

  const [loadingSpinner, toggleLoadingSpinner] = useState(false);

  /* Add email popup handlers */
  const handleAddEmailClick = () => {
    toggleAddEmail(true);
  };

  const handleAddEmailClose = () => {
    toggleAddEmail(false);
  };
  /**/

  /* Delete Email Template */
  const handleEmailTemplateDelete = async (emailTemplateID: string) => {
    //Show loading spinner
    toggleLoadingSpinner(true);

    //Re-get booking email templates
    try {
      let response = await axios.get("/booking", {
        params: {
          bookingID: selectedBooking.bookingID,
          producerID: producerID,
        },
      });

      toggleLoadingSpinner(false);
      setSelectedBooking(response.data.booking);
    } catch {
      toggleLoadingSpinner(false);
    }
  };
  /**/

  const classes = BookingEmailStyles();

  let emailTemplatesArray = [];
  for (var key in selectedBooking.emailTemplates) {
    emailTemplatesArray.push(selectedBooking.emailTemplates[key]);
  }

  //put system emails first
  const sortBySystem = (a, b) => {
    if (a.system && !b.system) {
      return -1;
    } else {
      return 1;
    }
  };

  emailTemplatesArray = emailTemplatesArray.sort(sortBySystem);

  let emailTemplatesComponentArray = [];

  emailTemplatesArray.map((template, i) => {
    emailTemplatesComponentArray.push(
      <EmailItem
        name={template.name}
        id={template.id}
        system={template.system}
        HTML={template.HTML}
        from={template.from}
        subject={template.subject}
        replyTo={template.replyTo}
        key={template.id}
        handleEmailTemplateDelete={handleEmailTemplateDelete}
        index={i}
      />
    );
  });

  return (
    <React.Fragment>
      <Button
        className={classes.newButton}
        onClick={handleAddEmailClick}
        variant="contained"
      >
        <AddIcon className={classes.addIcon}></AddIcon>
        NEW
      </Button>

      <Paper square elevation={3} className={classes.emailHeaderHolder}>
        <Typography variant="h1" className={classes.nameHeader}>
          Name
        </Typography>
        <Typography variant="h1" className={classes.systemHeader}>
          System
        </Typography>
        <Typography variant="h1" className={classes.htmlHeader}>
          HTML
        </Typography>
      </Paper>

      <div
        className={styles.emailHolder}
        style={{
          minHeight: loadingSpinner ? "123px" : "0px",
        }}
      >
        {loadingSpinner && (
          <CircularProgress className={classes.loadingSpinner} />
        )}

        {!loadingSpinner &&
          [emailTemplatesComponentArray].map((item) => {
            return item;
          })}
      </div>

      {addEmail && (
        <AddEmailPopup open={addEmail} handlePopupClose={handleAddEmailClose} />
      )}
    </React.Fragment>
  );
}
