import React, { ReactElement, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import CopyIcon from '@material-ui/icons/LinkOutlined';
import { Dialog, IconButton, Snackbar, Typography } from '@material-ui/core';

import styles from './AttendeeInformationPopup.module.css';
import { AttendeeInformationPopupStyles } from './AttendeeInformationPopupStyles';

interface Props {
  open: boolean,
  handlePopupClose: () => void,
  attendeeRecord: any,
}

export default function AttendeeInformationPopup(props: Props): ReactElement {

  const [ copyLink, toggleCopyLink ] = useState(false);

  const handleAttendeeLinkCopy = () => {
    let dummy = document.createElement('textarea');

    document.getElementById("attendeeDetailsPopup").appendChild(dummy);

    dummy.value = "https://go.showboat.live/" + props.attendeeRecord.loginCode;

    dummy.select();
    dummy.setSelectionRange(0, 99999);

    document.execCommand('copy');

    document.getElementById("attendeeDetailsPopup").removeChild(dummy);

    toggleCopyLink(true);
  }
  
  const handleCopyToClipboardClose = () => {
    toggleCopyLink(false);
  }
  
  const classes = AttendeeInformationPopupStyles();

  let intakeData=props.attendeeRecord.intakeData;
  
  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={props.open}
    >
      <div 
        className={classes.popup}
        id="attendeeDetailsPopup"
      >

        <div className={styles.popupHeader}>
          <IconButton
            className={classes.closeIconButton}
            onClick={props.handlePopupClose}  
          >
            <CloseIcon />
          </IconButton>
          
        </div>

        <Typography variant="h2" className={classes.attendeeInformationHeader}>
          Attendee Information
        </Typography>

        <Typography variant="h3" className={classes.attendeeURL}>
          URL: go.showboat.live/{props.attendeeRecord.loginCode}

          <IconButton
            className={classes.copyButton}
            onClick={handleAttendeeLinkCopy}
          >
            <CopyIcon />
          </IconButton>
        </Typography>

        <div className={styles.attendeeGridHolder}>
          <div className={styles.informationItemHolder}>
            <Typography variant="body1" className={classes.informationHeader}>
              First Name
            </Typography>

            <Typography variant="body1" className={classes.informationText}>
              {intakeData.firstName}
            </Typography>
          </div>
          <div className={styles.informationItemHolder}>
            <Typography variant="body1" className={classes.informationHeader}>
              Address 1
            </Typography>
            <Typography variant="body1" className={classes.informationText}>
              {intakeData.address.addressLine1}
            </Typography>
            
          </div>
          <div className={styles.informationItemHolder}>
            <Typography variant="body1" className={classes.informationHeader}>
              Last Name
            </Typography>
            <Typography variant="body1" className={classes.informationText}>
              {intakeData.lastName}
            </Typography>
            
          </div>
          <div className={styles.informationItemHolder}>
            <Typography variant="body1" className={classes.informationHeader}>
              Address 2
            </Typography>
            <Typography variant="body1" className={classes.informationText}>
              {intakeData.address.addressLine2}
            </Typography>
            
          </div>
          <div className={styles.informationItemHolder}>
            <Typography variant="body1" className={classes.informationHeader}>
              Name
            </Typography>
            <Typography variant="body1" className={classes.informationText}>
              {intakeData.company}
            </Typography>
            
          </div>

          <div className={styles.informationItemHolder}>
            <Typography variant="body1" className={classes.informationHeader}>
              City
            </Typography>
            <Typography variant="body1" className={classes.informationText}>
              {intakeData.address.city}
            </Typography>
          </div>
          <div className={styles.informationItemHolder}>
            <Typography variant="body1" className={classes.informationHeader}>
              Email
            </Typography>
            <Typography variant="body1" className={classes.informationText}>
              {intakeData.emailAddress}
            </Typography>
          </div>
          <div className={styles.informationItemHolder}>
            <Typography variant="body1" className={classes.informationHeader}>
              State
            </Typography>
            <Typography variant="body1" className={classes.informationText}>
              {intakeData.address.state}
            </Typography>
          </div>
          <div className={styles.informationItemHolder}>
            <Typography variant="body1" className={classes.informationHeader}>
              Phone
            </Typography>
            <Typography variant="body1" className={classes.informationText}>
              {intakeData.phone}
            </Typography>
          </div>
          <div className={styles.informationItemHolder}>
            <Typography variant="body1" className={classes.informationHeader}>
              Zip Code
            </Typography>
            <Typography variant="body1" className={classes.informationText}>
              {intakeData.address.zipCode}
            </Typography>
          </div>

        </div>

      </div>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        className={classes.copySnackbar}
        autoHideDuration={1500}
        open={copyLink}
        onClose={handleCopyToClipboardClose}
        message="Attendee Link Copied to Clipboard"
      />
    </Dialog>
  )
}
