import { IconButton, Typography } from '@material-ui/core';
import React, { ReactElement, useEffect, useState, useContext } from 'react';
import moment from 'moment-timezone';
import PeopleIcon from '@material-ui/icons/PersonOutlineOutlined';

import { AttendeeUsageHelper } from '../../../../utilities/AttendeeUsageHelper';
import AttendeeInformationPopup from '../AttendeeInformationPopup/AttendeeInformationPopup';
import { AppContext, AppContextType } from '../../../../context/AppContext';

import styles from './MatchingAttendeeRecord.module.css';
import { MatchingAttendeeRecordStyles } from './MatchingAttendeeRecordStyles';

interface Props {
  matchingRecord: any,
}

export default function MatchingAttendeeRecord(props: Props): ReactElement {

  const {
    selectedBooking
  } : AppContextType = useContext(AppContext);
  
  const [ timeMinutes, setTimeMinutes ] = useState("");
  const [ timeHours, setTimeHours ] = useState(0);
  const [ showAttendeeDetails, toggleShowAttendeeDetails ] = useState(false);

  useEffect(() => {

    let endTime;

    //If endtime is empty string, set endTime to current time. Otherwise, set endTime to record's end time
    if (props.matchingRecord.endTime !== "") {
      endTime = props.matchingRecord.endTime;
    } else {
      endTime = new Date().toUTCString();
    }

    let attendeeUsage = AttendeeUsageHelper.calculateUsage(props.matchingRecord.startTime, endTime);

    //Attendee usage returned in form [ hours, minutes ]
    setTimeHours(parseInt(attendeeUsage[0]));
    setTimeMinutes(attendeeUsage[1]);
    
  }, []);

  const handleAttendeeDetailsClick = () => {
    toggleShowAttendeeDetails(true);
  }

  const handleInformationPopupClose = () => {
    toggleShowAttendeeDetails(false);
  }

  const classes = MatchingAttendeeRecordStyles();
   
  return (
    <div className={styles.matchingRecordHolder}>

      <Typography variant="body1" className={classes.recordName}>
        {props.matchingRecord.intakeData.lastName.slice().length 
          ? `${props.matchingRecord.intakeData.lastName}, ${props.matchingRecord.intakeData.firstName}`
          : `${props.matchingRecord.intakeData.firstName}`
        }
      </Typography>

      <Typography variant="body1" className={classes.startTime}>
        {`${moment.tz(props.matchingRecord.startTime, selectedBooking.start.timezone).format("MM/DD/YYYY")} ${moment.tz(props.matchingRecord.startTime, selectedBooking.start.timezone).format("h:mm A z")}`}
      </Typography>

      {props.matchingRecord.endTime !== "" &&
        <Typography variant="body1" className={classes.endTime}>
          {`${moment.tz(props.matchingRecord.endTime, selectedBooking.start.timezone).format("MM/DD/YYYY")} ${moment.tz(props.matchingRecord.endTime, selectedBooking.start.timezone).format("h:mm A z")}`}
          
        </Typography>
      }

      <Typography variant="body1" className={classes.recordUsage}>
        {`${timeHours}h:${timeMinutes}m`}
      </Typography>

      <IconButton
        className={classes.detailsButton}
        onClick={handleAttendeeDetailsClick}
      >
        <PeopleIcon />
      </IconButton>

      {showAttendeeDetails &&
        <AttendeeInformationPopup
          attendeeRecord={props.matchingRecord}
          open={true}
          handlePopupClose={handleInformationPopupClose}
        ></AttendeeInformationPopup>
      }
    </div>
      
  )
}
