import { CircularProgress } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { SaveLoadingSpinnerStyles } from './SaveLoadingSpinnerStyles';

interface Props {
  
}

export default function SaveLoadingSpinner(props: Props): ReactElement {

  const classes = SaveLoadingSpinnerStyles();
  
  return (
    <CircularProgress className={classes.loadingSpinner} />
  )
}
