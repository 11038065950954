import { makeStyles, createStyles } from '@material-ui/core/styles';

const NameWarningPopupStyles = makeStyles((theme: any) => createStyles({
  dialogPaper: {
    width: 582,
    height: 250,
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "hidden",
  },
  popup: {
    width: "100%",
    height: 250,
    position: "absolute",
  },
  warningText: {
    fontSize: 24,
    display: "inline-block",
    width: 506,
    position: "absolute",
    top: 50,
    left: 40,
  },
  noButton: {
    width: 95,
    height: 44,
    padding: 0,
    position: "absolute",
    left: 325,
    bottom: 35,
  },
  yesButton: {
    width: 95,
    height: 44,
    padding: 0,
    position: "absolute",
    right: 49,
    bottom: 35,
  },
  loadingSpinner: {
    color: "grey",
    position: "absolute",
    right: 260,
    bottom: 42,
    width: "30px !important",
    height: "30px !important"
  },
  error: {
    color: "red",
    fontSize: 14,
    position: "relative",
    top: 160,
    left: 40,
  }
})
)

export { NameWarningPopupStyles };