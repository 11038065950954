import React, { ReactElement, useContext, useState } from 'react';
import { Typography, Button, TextField, Dialog, CircularProgress } from '@material-ui/core';
import axios from 'axios';

import { AppContext, AppContextType } from '../../../../context/AppContext';
import PopupHeader from '../../../PopupHeader/PopupHeader';

import { AddEmailPopupStyles } from './AddEmailPopupStyles';
import SaveButton from '../../../ui/SaveButton/SaveButton';
import CancelButton from '../../../ui/CancelButton/CancelButton';
import SaveLoadingSpinner from '../../../ui/SaveLoadingSpinner/SaveLoadingSpinner';

interface Props {
  open: boolean,
  handlePopupClose: () => any,
}

export default function AddEmailPopup(props: Props): ReactElement {

  const { 
    selectedBookingID, 
    selectedBooking, 
    setSelectedBooking 
  } : AppContextType = useContext(AppContext); 

  const [ templateName, setTemplateName] = useState("");
  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);
  const [ requiredError, toggleRequiredError ] = useState(false);
  const [ error, toggleError ] = useState(false);

  //Template name change handler
  const handleTemplateNameChange = (e) => {
    toggleRequiredError(false);

    setTemplateName(e.target.value);
  }

  const handleCancelButtonClick = () => {

    if (loadingSpinner) {
      return;
    }
    
    props.handlePopupClose();
  }

  //Submit form
  const handleSaveButtonClick = async () => {

    toggleError(false);

    toggleLoadingSpinner(true);
    
    if (!templateName.trim().length) {
      toggleRequiredError(true);
      return; 
    } 

    toggleRequiredError(false);

    try {
      let response = await axios.post("/email-template", {
        bookingID: selectedBookingID,
        emailTemplateName: templateName
      });

      setTemplateName("");

      toggleLoadingSpinner(false);

      let selectedBookingClone = selectedBooking;

      let newEmailTemplate = {
        id: response.data.emailTemplateID,
        name: response.data.emailTemplateName,
        system: response.data.system,
        HTML: response.data.HTML
      }

      selectedBookingClone.emailTemplates[response.data.emailTemplateID] = newEmailTemplate;

      setSelectedBooking(selectedBookingClone);

      props.handlePopupClose();
    }
    catch {
      toggleError(true);
      toggleLoadingSpinner(false);
    }

  }

  const classes = AddEmailPopupStyles();
  
  return (
    <Dialog
      open={props.open}
      classes={{ paper: classes.dialogPaper }}
    >
      <div className={classes.popup}>

        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={loadingSpinner}
        />
        <Typography className={classes.addEmailHeader}>
          Add Template
        </Typography>

        {error &&
          <Typography className={classes.error} variant="body1">
            An error occurred creating this email template
          </Typography>
        }
        
        <TextField
          variant="outlined"
          className={classes.templateNameInput}
          value={templateName}
          onChange={handleTemplateNameChange}
          helperText="Required"
          label="Template Name"
          error={requiredError}
          fullWidth
        />

        {loadingSpinner &&
          <SaveLoadingSpinner />
        }

        <CancelButton 
          onClick={handleCancelButtonClick}
          variant="contained"
        />

        <SaveButton
          disableBoolean={loadingSpinner}
          onClick={handleSaveButtonClick}
        />
      </div>
    </Dialog>
  )
}
