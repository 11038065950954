import { makeStyles, createStyles } from "@material-ui/core/styles";

const UploadLoadingSpinnerStyles = makeStyles((theme: any) =>
  createStyles({
    loadingSpinner: {
      color: "grey",
      position: "absolute",
      right: 254,
      bottom: 39,
      width: "25px !important",
      height: "25px !important",
    },
  })
);

export { UploadLoadingSpinnerStyles };
