import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const SpaceWorldsAdminStyles = makeStyles((theme: any) =>
  createStyles({
    spaceWorldsAdminWrapper: {
      position: "relative",
      display: "inline-block",
      width: 714,
      height: "auto",
      top: 50,
      left: 130,
      minHeight: 450,
      margin: "auto",
      border: "1px solid #dedede",
      borderRadius: 0,
      textAlign: "center",
    },
    spaceWorldsHeader: {
      fontSize: 30,
      position: "relative",
      display: "inline-block",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      top: 20,
    },
    adminMenuButton: {
      position: "absolute",
      left: 15,
      top: 15,
    },
    spaceWorldsDivider: {
      width: "90%",
      position: "relative",
      top: 25,
      display: "inline-block",
    },
    scriptButton: {
      position: "absolute",
      display: "inline-block",
      left: "50%",
      marginLeft: -57,
      top: 100,
    },
    invalidSpacesHeader: {
      fontSize: 22,
      fontWeight: 400,
      position: "absolute",
      left: 35,
      top: 30,
    },
    invalidWorldsHeader: {
      fontSize: 22,
      fontWeight: 400,
      position: "absolute",
      top: 30,
    },
    invalidName: {
      fontSize: 20,
      fontWeight: 400,
      color: "black",
      "&:hover": {
        cursor: "pointer",
      },
    },
    invalidNameSpace: {
      marginBottom: 3,
    },
    invalidName2: {
      fontSize: 14,
      fontWeight: 400,
      color: "grey",
      top: 10,
      position: "relative",
    },
    invalidName2Space: {
      marginBottom: 6,
    },
    invalidProperty: {
      fontSize: 14,
      fontWeight: 400,
      color: "grey",
      position: "relative",
      top: 10,
    },
    loadingSpinner: {
      width: 20,
      height: 20,
      position: "absolute",
      top: 148,
      left: 340,
    },
    error: {
      color: "red",
      position: "absolute",
      top: 158,
      fontSize: 14,
      left: 302,
    },
    invalidInfo: {
      color: "grey",
      fontSize: 14,
      fontWeight: 400,
      position: "absolute",
      top: 60,
      textAlign: "left",
      display: "inline-block",
      width: 250,
    },
    copyButton: {
      position: "relative",
      padding: 4,
      left: 5,
    },
    repairButton: {
      position: "relative",
      top: 30,
    },
    repairLoadingSpinner: {
      width: "30px !important",
      height: "30px !important",
      position: "absolute",
      bottom: 20,
      left: 110,
    }
  })
);

export { SpaceWorldsAdminStyles };
