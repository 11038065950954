import { Button, CircularProgress, IconButton, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { ReactElement, useContext, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { isSafari } from 'react-device-detect';

import { AppContext, AppContextType } from '../../../context/AppContext';

import styles from './CalendarItem.module.css';
import { CalendarItemStyles } from './CalendarItemStyles';

interface Props {
  calendarName: string,
  calendarID: string,
  index: number,
}

export default function CalendarItem(props: Props): ReactElement {

  const { 
    setSelectedCalendar 
  } : AppContextType = useContext(AppContext);
  
  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);

  //Edit click
  const handleEditClick = async () => {

    toggleLoadingSpinner(true); 

    try {
      let response = await axios.get("/calendar", {
        params: {
          calendarID: props.calendarID
        }
      });

      toggleLoadingSpinner(false);
      setSelectedCalendar(response.data);
    }
    catch {
      toggleLoadingSpinner(false);
      alert("An error occurred finding this calendar. Please try again.")
    }
  
  }
  
  const classes = CalendarItemStyles();

  return (
    <div 
      className={isSafari ? `${styles.calendarItemHolder} ${styles.calendarItemHolderSafari}` : styles.calendarItemHolder}
      style={{ 
        backgroundColor:
          (props.index + 1) % 2 === 0 ? "rgb(0, 0, 0, 0.02)" : "white"
      }}
    >
      <Typography variant="body1" className={classes.calendarName}>
        {props.calendarName}
      </Typography>

      {loadingSpinner &&
        <CircularProgress className={classes.loadingSpinner} />
      }

      <IconButton 
        disabled={loadingSpinner} 
        className={classes.editButton} 
        onClick={handleEditClick} 
        color="primary"
      >
        <EditIcon className={classes.editIcon}></EditIcon>
      </IconButton>
    </div>
  )
}
