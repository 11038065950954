import React, { ReactElement, useEffect, useContext, useState } from "react";
import {
  Paper,
  Typography,
  Divider,
  TextField,
  ListItem,
  ListItemIcon,
  Checkbox,
  List,
  ListItemText,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import axios from "axios";

import { AuthContext, AuthContextType } from "../../../context/AuthContext";

import styles from "./ProducerAdmin.module.css";
import { ProducerAdminStyles } from "./ProducerAdminStyles";
import AdminInterfaceWrapper from "../../../components/ui/AdminInterfaceWrapper/AdminInterfaceWrapper";
import { orderBy } from "natural-orderby";

interface Props {}

function not(a: any[], b: any[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: any[], b: any[]) {
  return a.filter((value) => b.indexOf(value.index) !== -1);
}

export default function ProducerAdminCreate(props: Props): ReactElement {
  const { idToken, setIdToken }: AuthContextType = useContext(AuthContext);

  const [isAuthenticated, toggleIsAuthenticated] = useState(false);

  const [postError, togglePostError] = useState(false);

  const [projects, setProjects] = useState([]);

  const [temporaryPassword, setTemporaryPassword] = useState("");
  const [temporaryPasswordError, toggleTemporaryPasswordError] =
    useState(false);

  const [producerEmail, setProducerEmail] = useState("");
  const [emailError, toggleEmailError] = useState(false);

  const [producerUsername, setProducerUsername] = useState("");
  const [usernameError, toggleUsernameError] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [firstNameError, toggleFirstNameError] = useState(false);

  const [lastName, setLastName] = useState("");
  const [lastNameError, toggleLastNameError] = useState(false);

  //Transfer list state
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);

  const [loadingSpinner, toggleLoadingSpinner] = useState(false);
  const [projectsLoadingSpinner, toggleProjectsLoadingSpinner] =
    useState(false);

  const [producerPostCompleted, toggleProducerPostCompleted] = useState(false);

  let leftIndexes = left.map((item) => {
    return item.index;
  });

  let rightIndexes = right.map((item) => {
    return item.index;
  });

  const leftChecked = intersection(checked, leftIndexes);
  const rightChecked = intersection(checked, rightIndexes);

  useEffect(() => {
    //Initial authentication check
    //First check if there is any producerID in sessionStorage
    if (localStorage.getItem("producerID") == undefined) {
      //Redirect to sign-in if no producerID in sessionStorage
      alert("You do not have privileges to access this page.");
      window.location.replace("/");
    } else {
      //Set up authentication to query producer object
      var poolData = {
        UserPoolId: "us-east-1_N15Q0NLkm",
        ClientId: "2332rbhi35f5016dglri2mojo",
      };

      const userPool = new CognitoUserPool(poolData);

      let currentUser = userPool.getCurrentUser();

      if (currentUser) {
        currentUser.getSession((err, session) => {
          if (err) {
            alert("Your session has expired. Please sign in again.");
            window.location.replace("/");
          } else {
            axios.defaults.headers.common["Authorization"] = session
              .getIdToken()
              .getJwtToken();

            setIdToken(session.getIdToken().getJwtToken());

            //Check producer privileges on load (check if SuperAdmin)
            //ProducerID is received from sessionStorage
            axios
              .get("/producer/role", {
                params: {
                  producerID: localStorage.getItem("producerID"),
                },
              })
              .then(function (response) {
                //Get the role on the producer object
                if (response.data.role === 0) {
                  toggleIsAuthenticated(true);

                  //Show projects loading spinner
                  toggleProjectsLoadingSpinner(true);

                  //Get projects
                  axios
                    .get("/projects/all/admin", {
                      headers: {
                        Authorization: session.getIdToken().getJwtToken(),
                      },
                    })
                    .then(function (response) {
                      toggleProjectsLoadingSpinner(false);

                      //Append an index to each project object
                      let projectsResponseArray = response.data;
                      let projectsWithIndex = [];

                      for (let i = 0; i < projectsResponseArray.length; i++) {
                        let project = projectsResponseArray[i];
                        project.index = i;
                        projectsWithIndex.push(project);
                      }

                      //Order projects by name
                      let projectsWithIndexOrdered = orderBy(
                        projectsWithIndex,
                        [p => (p as any).projectName],
                        ['asc']
                      )

                      setProjects(projectsWithIndexOrdered);

                      //Put all projects in left list
                      let leftStateArray = [];

                      for (
                        let i = 0;
                        i < projectsWithIndexOrdered.length;
                        i++
                      ) {
                        leftStateArray.push(projectsWithIndexOrdered[i]);
                      }

                      setLeft(leftStateArray);
                    })
                    .catch(function (error) {
                      toggleProjectsLoadingSpinner(false);
                    });
                } else {
                  alert("You do not have privileges to access this page.");
                  window.location.replace("/");
                }
              })
              .catch(function (error) {
                alert("You do not have privileges to access this page.");
                window.location.replace("/");
              });
          }
        });
      } else {
        alert("Your session has expired. Please sign in again.");
        window.location.replace("/");
      }
    }
  }, []);

  const handleTemporaryPasswordChange = (e) => {
    toggleTemporaryPasswordError(false);

    setTemporaryPassword(e.target.value);
  };

  //Email
  const handleEmailChange = (e) => {
    toggleEmailError(false);

    setProducerEmail(e.target.value);
  };

  //Username
  const handleUsernameChange = (e) => {
    toggleUsernameError(false);
    setProducerUsername(e.target.value);
  };

  //First name
  const handleFirstNameChange = (e) => {
    toggleFirstNameError(false);

    setFirstName(e.target.value);
  };

  //Last name
  const handleLastNameChange = (e) => {
    toggleLastNameError(false);

    setLastName(e.target.value);
  };

  /*Transfer list functions*/
  const handleToggle = (value: any) => {
    let checkedIndexes = [];
    for (let i = 0; i < checked.length; i++) {
      checkedIndexes.push(checked[i].index);
    }

    const currentIndex = checkedIndexes.indexOf(value.index);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (items: any[]) => (
    <Paper className={classes.listHolder}>
      <List dense component="div" role="list">
        {items.map((value: any) => {
          const labelId = `transfer-list-item-${value.index}-label`;

          return (
            <ListItem
              key={value.index}
              onClick={() => handleToggle(value)}
              role="listitem"
              button
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.projectName} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );
  /**/

  //Submit
  const handleSubmit = () => {
    let formHasError = false;

    togglePostError(false);

    //Check inputs

    if (!temporaryPassword.trim().length) {
      toggleTemporaryPasswordError(true);
      formHasError = true;
    } else {
      toggleTemporaryPasswordError(false);
    }

    if (!producerEmail.trim().length) {
      toggleEmailError(true);
      formHasError = true;
    } else {
      toggleEmailError(false);
    }

    if (!producerUsername.trim().length) {
      toggleUsernameError(true);
      formHasError = true;
    } else {
      toggleUsernameError(false);
    }

    if (!firstName.trim().length) {
      toggleFirstNameError(true);
      formHasError = true;
    } else {
      toggleFirstNameError(false);
    }

    if (!lastName.trim().length) {
      toggleLastNameError(true);
      formHasError = true;
    } else {
      toggleLastNameError(false);
    }

    //If we have an error, return
    if (formHasError) {
      return;
    }

    toggleLoadingSpinner(true);

    //Assemble projects (ones on right side of transfer list)
    let assignedProjectsArray = right;

    //Assemble assigned projects to correct format
    let assignedProjects = {};

    assignedProjectsArray.forEach((item) => {
      assignedProjects[`${item.projectID}`] = {
        projectName: item.projectName,
        projectID: item.projectID,
      };
    });

    let newProducerObj = {
      temporaryPassword: temporaryPassword,
      email: producerEmail,
      username: producerUsername,
      first: firstName,
      last: lastName,
      projects: assignedProjects,
      role: 1,
    };

    axios
      .post("/producer", JSON.stringify(newProducerObj), {
        headers: {
          Authorization: idToken,
        },
      })
      .then(function (response) {
        toggleLoadingSpinner(false);
        toggleProducerPostCompleted(true);
      })
      .catch(function (error) {
        toggleLoadingSpinner(false);

        //Show post error message
        togglePostError(true);
      });
  };

  const classes = ProducerAdminStyles();

  if (isAuthenticated) {
    return (
      <AdminInterfaceWrapper wrapperWidth={974} wrapperHeight={900}>
        <Paper className={classes.producerAdminWrapper}>
          <Typography variant="h1" className={classes.createProducerHeader}>
            Create a Producer
          </Typography>

          <Divider className={classes.producerDivider}></Divider>

          {producerPostCompleted ? (
            <Typography variant="body1" className={classes.success}>
              Success
            </Typography>
          ) : (
            <React.Fragment>
              <div className={styles.producerFormHolder}>
                <div className={styles.producerFormItem}>
                  {postError && (
                    <Typography
                      variant="body1"
                      className={classes.errorMessage}
                    >
                      An error occurred creating this producer.
                    </Typography>
                  )}

                  <Typography
                    variant="h2"
                    className={classes.producerFormHeader}
                  >
                    Username
                  </Typography>

                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.producerTextField}
                    value={producerUsername}
                    onChange={handleUsernameChange}
                    error={usernameError}
                  ></TextField>
                </div>

                <div className={styles.producerFormItem}>
                  <Typography
                    variant="h2"
                    className={classes.producerFormHeader}
                  >
                    Email
                  </Typography>

                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.producerTextField}
                    value={producerEmail}
                    onChange={handleEmailChange}
                    error={emailError}
                  ></TextField>
                </div>

                <div className={styles.producerFormItem}>
                  <Typography
                    variant="h2"
                    className={classes.producerFormHeader}
                  >
                    Temporary Password
                  </Typography>

                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.producerTextField}
                    value={temporaryPassword}
                    onChange={handleTemporaryPasswordChange}
                    error={temporaryPasswordError}
                  ></TextField>
                </div>

                <div className={styles.producerFormItemTransferList}>
                  <Typography
                    variant="h2"
                    className={classes.producerFormHeader}
                  >
                    Assign Projects
                  </Typography>

                  <div className={styles.leftListHolder}>
                    {projectsLoadingSpinner && (
                      <CircularProgress
                        className={classes.projectsLoadingSpinner}
                      />
                    )}
                    {customList(left)}
                  </div>

                  <div className={styles.rightListHolder}>
                    {customList(right)}
                  </div>

                  <div className={styles.listButtonsHolder}>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleAllRight}
                      disabled={left.length === 0}
                      aria-label="move all right"
                    >
                      ≫
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleCheckedRight}
                      disabled={leftChecked.length === 0}
                      aria-label="move selected right"
                    >
                      &gt;
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleCheckedLeft}
                      disabled={rightChecked.length === 0}
                      aria-label="move selected left"
                    >
                      &lt;
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleAllLeft}
                      disabled={right.length === 0}
                      aria-label="move all left"
                    >
                      ≪
                    </Button>
                  </div>
                </div>
              </div>

              <div className={styles.producerNameHolder}>
                <div className={styles.producerNameItem}>
                  <Typography
                    variant="h2"
                    className={classes.producerFormHeader}
                  >
                    First Name
                  </Typography>

                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.producerTextField}
                    value={firstName}
                    onChange={handleFirstNameChange}
                    error={firstNameError}
                  ></TextField>
                </div>

                <div className={styles.producerNameItem}>
                  <Typography
                    variant="h2"
                    className={classes.producerFormHeader}
                  >
                    Last Name
                  </Typography>

                  <TextField
                    variant="outlined"
                    size="small"
                    className={classes.producerTextField}
                    value={lastName}
                    onChange={handleLastNameChange}
                    error={lastNameError}
                  ></TextField>
                </div>
              </div>

              {loadingSpinner && (
                <CircularProgress className={classes.loadingSpinner} />
              )}

              <Button
                disabled={loadingSpinner}
                className={classes.submitButton}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                SUBMIT
              </Button>
            </React.Fragment>
          )}
        </Paper>
      </AdminInterfaceWrapper>
    );
  } else {
    return <div></div>;
  }
}
