import { makeStyles, createStyles } from '@material-ui/core/styles';

const BookingRegistrationStyles = makeStyles((theme: any) =>
  createStyles({
    registrationHeaderHolder: {
      position: "absolute",
      width: "100%",
      top: -25,
      height: 90,
      backgroundColor: "#E5E5E5",
      zIndex: 100,
    },
    fieldNameHeader: {
      fontSize: 14,
      fontWeight: 600,
      position: "absolute",
      left: 26,
      bottom: 8,
    },
    visibleHeader: {
      fontSize: 14,
      fontWeight: 600,
      position: "absolute",
      left: 300,
      bottom: 8,
    },
    fieldsHeader: {
      fontSize: 14,
      position: "absolute",
      left: 30,
      top: 64,
      fontWeight: "bold",
    },
    requiredHeader: {
      fontSize: 14,
      position: "absolute",
      left: 272,
      top: 64,
      fontWeight: "bold",
    },
    addressHeader: {
      fontSize: 14,
      position: "absolute",
      left: 30,
      bottom: 133,
      fontWeight: "bold",
    },
    fieldWarning: {
      position: "absolute",
      left: 26,
      top: 20,
      fontSize: ".8rem",
      color: "red",
      fontWeight: 600,
    },
    addressTypeFormHolder: {},
    registrationCapacityHeader: {
      fontSize: 16,
      fontWeight: 400,
      position: "relative",
      left: 26,
      bottom: 20,
    },
    editIcon: {
      position: "relative",
      marginLeft: 15,
      padding: 8,
      color: "grey",
      fontSize: 19,
      top: 11,
      "&:hover": {
        cursor: "pointer",
      },
    },
    registrationFieldsHeader: {
      fontSize: "1rem",
      fontWeight: 400,
      position: "absolute",
      left: 30,
      top: 19,
    },
    registrationFieldsHeaderCapacity: {
      left: 388,
    },
    divider: {
      width: 296,
      position: "absolute",
      left: 0,
    },
    divider1: {
      top: 44,
    },
    divider2: {
      top: 87,
    },
    divider3: {
      top: 132,
    },
    divider4: {
      top: 176,
    },
    divider5: {
      top: 219,
    },
    divider6: {
      top: 50,
    },
    divider7: {
      top: 93,
    },
    divider8: {
      top: 136,
    },
    specifyToggle: {
      fontSize: 14,
      position: "absolute",
      top: 0,
      left: -15,
    },
    specifyToggleLabel: {
      marginRight: 41,
      fontSize: 14,
    },
    capacityInfoText: {
      fontSize: 12,
      position: "absolute",
      left: 0,
      top: 44,
    },
    overviewLink: {
      color: "#00B6FF",
      marginLeft: 5,
      marginRight: 5,
      "&:hover": {
        cursor: "pointer",
      },
    },
    registrationCapText: {
      position: "absolute",
      bottom: 5,
      left: 0,
      fontSize: 14,
    },
    registrationCountInput: {
      width: 54,
      height: 33,
      position: "absolute",
      bottom: 0,
      left: 185,
    },
    registrationCountInputInner: {
      padding: "5.5px 6px",
    },
    signUpUrlHeader: {
      fontSize: 14,
      fontWeight: "bold",
      position: "absolute",
      left: 30,
    },
    signUpUrl: {
      fontSize: 14,
      position: "absolute",
      left: 130.5,
      width: 370,
      fontFamily: "Helvetica",
      wordWrap: "break-word",
    },
    dividerSignUp: {
      position: "relative",
      top: 18,
      left: 31,
      width: "92%",
    },
    copyButton: {
      position: "relative",
      bottom: 8,
      padding: 4,
    },
    regenerateButton: {
      marginLeft: 20,
      position: "relative",
      padding: 4,
      bottom: 8,
      right: 12,
    },
    snackbar: {
      width: 360,
    },
    loadingSpinner: {
      color: "grey",
      position: "relative",
      top: 124,
      left: 150,
    }
  })
);

export { BookingRegistrationStyles };