import React, { ReactElement, useState, useContext, useEffect } from 'react';
import { Typography, Button, Dialog, CircularProgress, ClickAwayListener } from '@material-ui/core';
import TimezoneSelect from 'react-timezone-select';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import axios from 'axios';
import TimeIcon from '@material-ui/icons/AccessTime';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { AppContext, AppContextType } from '../../../../context/AppContext';
import PopupHeader from '../../../PopupHeader/PopupHeader';
import SaveButton from "../../../ui/SaveButton/SaveButton";

import styles from './EditBookingEndTimePopup.module.css';
import { EditBookingEndTimePopupStyles } from './EditBookingEndTimePopupStyles';
import CancelButton from '../../../ui/CancelButton/CancelButton';
import SaveLoadingSpinner from '../../../ui/SaveLoadingSpinner/SaveLoadingSpinner';
import { JWTHelper } from '../../../../utilities/JWTHelper';

interface Props {
  open: boolean,
  handlePopupClose: () => any,
  duplicateBooking: boolean,
  selectedStartTime?: string,
}

export default function EditBookingEndTimePopup(props: Props): ReactElement {

  const { 
    selectedBooking, 
    setSelectedBooking, 
    selectedSpace,
    setSelectedBookingUsage,
    setSelectedBookingID,
    selectedSpaceID,
  } : AppContextType = useContext(AppContext);

  const [ selectedDate, setSelectedDate ] = useState(moment.tz(selectedBooking.end.time, selectedBooking.end.timezone).format());
  const [ selectedDateFormatted, setSelectedDateFormatted ] = useState(moment.tz(selectedBooking.end.time, selectedBooking.end.timezone).format());

  const [ selectedTimezone, setSelectedTimezone] = useState({});
  const [ selectedTimezoneValue, setSelectedTimezoneValue ] = useState(selectedBooking.end.timezone);

  const [ timezonePickerOpen, toggleTimezonePickerOpen ] = useState(false);

  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);
  
  const [ dateError, toggleDateError ] = useState(false);

  const [ spaceDateError, toggleSpaceDateError ] = useState(false);

  const [ duplicateBookingError, toggleDuplicateBookingError ] = useState(false);

  const [ timezoneError, toggleTimezoneError ] = useState(false);

  useEffect(() => {
    moment.tz.setDefault(selectedTimezoneValue);

    //set default timezone value
    setSelectedTimezone({
      value: 9999,
      label: selectedTimezoneValue
    });

    //Check if we are duplicating a booking
    if (props.duplicateBooking) {
      //Calculate end time based on booking that is being duplicated's length, and start time from previous screen
      let originalBookingStartTime = selectedBooking.start.time;
      let originalBookingEndTime = selectedBooking.end.time;

      let originalBookingLength = moment(originalBookingEndTime).diff(originalBookingStartTime);
      
      let selectedStartTime = moment(props.selectedStartTime).format();

      //Duplicate booking end date is then selected start date + the original booking length
      let duplicateBookingEndTime = moment(selectedStartTime).add(originalBookingLength, "ms").format();

      setSelectedDate(duplicateBookingEndTime);
      
    }

    
  }, [])

  const handleDateChange = (date) => {

    setSelectedDate(date.tz(selectedTimezoneValue, true).format());
    
  }

  const handleTimeChange = (time) => {

    setSelectedDate(time.tz(selectedTimezoneValue, true).format());

  }

  const handleTimezoneChange = (tz) => {

    toggleTimezonePickerOpen(false);

    moment.tz.setDefault(tz.value);
    
    toggleTimezoneError(false);

    let currentDate = moment(selectedDate);

    currentDate.tz(tz.value).format('ha z');
    
    setSelectedDate(currentDate.format());
    setSelectedTimezone(tz);
    setSelectedTimezoneValue(tz.value);
  }

  const handleSaveButtonClick = async () => {

    toggleLoadingSpinner(true);

    toggleDateError(false);
    toggleSpaceDateError(false);

    //check to see if date is before start date/time
    if (moment.utc(selectedDate).valueOf() < moment.utc(selectedBooking.start.time).valueOf()) {
      toggleLoadingSpinner(false);
      toggleDateError(true);
      return;
    };

    //Make sure selected time is not before/after space open/close
    if (moment.utc(selectedDate).valueOf() < moment.utc(selectedSpace.startDate.date).valueOf()) {
      toggleLoadingSpinner(false);
      toggleSpaceDateError(true);
      return;
    }

    if (moment.utc(selectedDate).valueOf() > moment.utc(selectedSpace.endDate.date).valueOf()) {
      toggleLoadingSpinner(false);
      toggleSpaceDateError(true);
      return;
    }

    toggleSpaceDateError(false);

    if (Object.keys(selectedTimezone).length == 0) {
      toggleTimezoneError(true);
      toggleLoadingSpinner(false);
      return;
    }

    //Check if booking has doorsOpen/doorsClosed
    if (selectedBooking.doorsOpen && selectedBooking.doorsClosed) {

      let returnArray = handleChangeDoorsClosedWithEndTime();

      (selectedBooking.doorsOpen as any) = returnArray[0];
      (selectedBooking.doorsClosed as any) = returnArray[1];
    }


    let selectedBookingClone = selectedBooking;

    let timeObj = {
      time: selectedDate,
      timezone: selectedTimezoneValue,
      timeValue: moment.utc(selectedDate).format()
    }

    selectedBookingClone.end = timeObj;

    //Change start time timezone

    let newStartTime = moment.tz(selectedBooking.start.time, selectedTimezoneValue).format();

    let timeObjStart = {
      time: newStartTime,
      timezone: selectedTimezoneValue,
      timeValue: moment.utc(newStartTime).valueOf()
    }

    selectedBookingClone.start = timeObjStart;

    try {
      let response = await axios.put("/booking", JSON.stringify(selectedBookingClone))

      toggleLoadingSpinner(false);
      setSelectedBooking(selectedBookingClone);
      props.handlePopupClose();
    }
    catch {
      toggleLoadingSpinner(false);
    }
   
  }

  const handleCancelButtonClick = () => {

    if (loadingSpinner) {
      return;
    }
    
    setSelectedDate(selectedBooking.end.time);
    setSelectedDateFormatted(selectedBooking.end.time);
    setSelectedTimezone({
      value: 9999,
      label: selectedBooking.end.timezone
    });
    props.handlePopupClose();
  };

  const handleTimezonePickerClick = () => {
    toggleTimezonePickerOpen(!timezonePickerOpen);
  }

  const handleTimezonePickerClose = () => {
    toggleTimezonePickerOpen(false);
  }

  //Duplicate booking
  const handleDuplicateBookingSaveClick = async () => {

    //Hide errors, show loading spinner
    toggleLoadingSpinner(true);
    toggleDuplicateBookingError(false);
    toggleDateError(false);
    toggleSpaceDateError(false);

    //Make sure selected end date is not before the start date
    if (moment.utc(selectedDate).valueOf() < moment.utc(props.selectedStartTime).valueOf()) {
      toggleLoadingSpinner(false);
      toggleDateError(true);
      return;
    }

    //Make sure selected start date is within space open/close
    if (moment.utc(selectedDate).valueOf() < moment.utc(selectedSpace.startDate.date).valueOf()) {
      toggleLoadingSpinner(false);
      toggleSpaceDateError(true);
      return;
    }

    if (moment.utc(selectedDate).valueOf() > moment.utc(selectedSpace.endDate.date).valueOf()) {
      toggleLoadingSpinner(false);
      toggleSpaceDateError(true);
      return;
    }

    let selectedBookingClone = selectedBooking;

    //Post duplicate booking with same info as currently-selected booking, except for start/end times
    let duplicateBookingStartTime = moment.tz(props.selectedStartTime, selectedTimezoneValue).format();
    let duplicateBookingEndTime = moment.tz(selectedDate, selectedTimezoneValue).format();

    selectedBookingClone.start = {
      time: duplicateBookingStartTime,
      timezone: selectedTimezoneValue,
      timeValue: moment.utc(duplicateBookingStartTime).valueOf()
    };

    selectedBookingClone.end = {
      time: duplicateBookingEndTime,
      timezone: selectedTimezoneValue,
      timeValue: moment.utc(duplicateBookingEndTime).valueOf()
    }
    
    let accessTime = moment().utc().startOf("hour").format();

    selectedBookingClone.accessTime = accessTime;

    //Make sure registration count is 0 for the clone
    let prop = "registrationCount"
    selectedBookingClone[prop] = 0;

    try {
      let response = await axios.post("/booking", JSON.stringify(selectedBookingClone));

      toggleLoadingSpinner(false);
      setSelectedBookingUsage(0);

      let responseBooking = JSON.parse(response.data);

      setSelectedBooking(responseBooking);
      setSelectedBookingID(responseBooking.bookingID);

      //Issue new JWT containing new bookingID
      JWTHelper.createJWT(selectedSpaceID, responseBooking.bookingID);

      //Navigate URL to new bookingID
      window.history.pushState(
        null,
        "Title",
        `/booking/${responseBooking.bookingID}`
      );

      props.handlePopupClose();

    }
    catch {
      toggleLoadingSpinner(false);

      toggleDuplicateBookingError(true);
    }
  };

  const handleChangeDoorsClosedWithEndTime = () => {

    //Change timezone of doorsOpen/doorsClosed
    let newDoorsOpen = moment.tz(selectedBooking.doorsOpen, selectedTimezoneValue).format();

    let oldEndTime = moment(selectedBooking.end.time);
    let oldDoorsClosed = moment(selectedBooking.doorsClosed);

    //Set new open time to be new selected date initially 
    let newDoorsClosedFromEndTime = moment(selectedDate);

    //Calculate duration string
    let durationString = moment.duration(moment(oldEndTime).diff(oldDoorsClosed));

    //Check if there is a days difference
    let days = durationString.days();
    if (days !== 0) {
      //There is a days difference, so change newDoorsOpen that amount
      let newDoorsClosed = moment(newDoorsClosedFromEndTime).subtract(days, "days");
      newDoorsClosedFromEndTime = newDoorsClosed;
    }

    //Check if there is an hours difference
    let hours = durationString.hours();
    if (hours !== 0) {
      //There is an hours difference, so change newDoorsOpen that amount
      let newDoorsClosed = moment(newDoorsClosedFromEndTime).subtract(hours, "hours");
      newDoorsClosedFromEndTime = newDoorsClosed;
    }

    //Check if there is a minutes difference
    let minutes = durationString.minutes();
    if (minutes !== 0) {
      let newDoorsClosed = moment(newDoorsClosedFromEndTime).subtract(minutes, "minutes");
      newDoorsClosedFromEndTime = newDoorsClosed;
    }

    return [moment(newDoorsOpen).format(), moment(newDoorsClosedFromEndTime).format()];
  }

  const classes = EditBookingEndTimePopupStyles();
  
  return (
    <Dialog
      open={props.open}
      classes={
        { paper: 
          timezonePickerOpen 
          ? `${classes.dialogPaper} ${classes.dialogPaperTimezoneOpen} `
          : classes.dialogPaper
        }
      }
    >
      <div className={timezonePickerOpen ? `${classes.popup} ${classes.popupTimezoneOpen}` : classes.popup}>

        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={loadingSpinner}
        />

        <div className={styles.contentWrapper}>

          <Typography variant="h1" className={classes.editTimeHeader}>
            Set Run End Date / Time
          </Typography>
          <Typography variant="body1" className={classes.editTimeText}>
            The space will end at this time
          </Typography>

          {timezoneError &&
          <Typography variant="body1" className={classes.errorText}>
            Please select a timezone
          </Typography>
          }

          {dateError &&
          <Typography variant="body1" className={classes.dateErrorText}>
            End date/time must be after the event's start date/time
          </Typography>
          }

          {spaceDateError &&
          <Typography variant="body1" className={classes.dateErrorText}>
            Start date/time must be within the open/close time of this space
          </Typography>
          }

          {duplicateBookingError &&
          <Typography variant="body1" className={classes.dateErrorText}>
            An error occurred creating your booking duplicate. Please try again.
          </Typography>
          }

          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>

            <KeyboardDatePicker
              clearable
              label="Enter Date"
              inputVariant="outlined"
              format="MM/DD/YYYY"
              value={selectedDate}
              onChange={date => handleDateChange(date)}
              /* minDate={new Date()}
              minDateMessage="Date must not be before current date" */
              className={classes.datePicker}
              okLabel={
                <span
                  style={{ color: "green" }}
                >
                  OK
                    </span>
              }
            />

          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardTimePicker
              label="Enter Time"
              keyboardIcon={<TimeIcon />}
              inputVariant="outlined"
              minDateMessage="Date should not be before selected start date"
              className={classes.timePicker}
              mask="__:__ _M"
              value={selectedDate}
              onChange={(date) => handleTimeChange(date)}
              okLabel={
                <span
                  style={{ color: "green" }}
                >
                  OK
                    </span>
              }
            />
          </MuiPickersUtilsProvider>

            <Typography variant="body1" className={classes.timezoneLabel}>
              Select Timezone
            </Typography>
            
            <ClickAwayListener onClickAway={handleTimezonePickerClose}>
              <div onClick={handleTimezonePickerClick} className={styles.timezonePicker}>
                <TimezoneSelect
                  value={selectedTimezone}
                  onChange={tz => handleTimezoneChange(tz)}
                  onBlur={handleTimezonePickerClose}
                  className={styles.timezonePicker}
                />
              </div>
            </ClickAwayListener>

        </div>

        {loadingSpinner &&
          <SaveLoadingSpinner />
        }
          
        <CancelButton 
          variant="contained"
          onClick={handleCancelButtonClick}
        />

        <SaveButton
          onClick={props.duplicateBooking ? handleDuplicateBookingSaveClick : handleSaveButtonClick}
          disableBoolean={loadingSpinner}
        />
        
      </div>
    </Dialog>
  )
}
