import React, { ReactElement, useState, useEffect, useContext } from 'react';
import { Typography, Button, CircularProgress } from '@material-ui/core';
import axios from 'axios';

import BookingEdit from './BookingEdit/BookingEdit';
import { AppContext, AppContextType } from '../../context/AppContext';
import BookingItemsDisplay from './BookingItemsDisplay/BookingItemsDisplay';
import AddBookingPopup from './AddBookingPopup/AddBookingPopup';
import AddIcon from '@material-ui/icons/Add';

import { BookingsWindowStyles } from './BookingsWindowStyles';

interface Props {
}

export default function BookingsWindow(props: Props): ReactElement {

  const {
    setSelectedBookingUsage,
    setSelectedBooking,
    setSelectedBookingID,
    selectedBooking,
  } : AppContextType = useContext(AppContext);
  
  const [ addAndEdit, toggleAddAndEdit ] = useState(false);
  const [ isLoading, toggleIsLoading ] = useState(false);
  const [ addBookingPopup, toggleAddBookingPopup ] = useState(false);

  //Open "Add and Edit" popup
  const handleAddAndEditClick = () => {
    
    toggleAddBookingPopup(true);
    
  }

  //Close add booking popup
  const handleAddBookingPopupClose = () => {
    toggleAddBookingPopup(false);
  }

  //Handle edit done button click
  const handleDoneEditClick = () => {

    setSelectedBookingUsage(null);
    setSelectedBooking(null);
    setSelectedBookingID(null);
  }

  //Material UI classes
  const classes = BookingsWindowStyles();

  return (
    <React.Fragment>
      <Typography variant="h1" classes={{ root: classes.bookingsHeader }}>
        Bookings
      </Typography>

      {selectedBooking === null &&
      <Button 
        color="primary" 
        variant="contained" 
        classes={{ root: classes.addAndEditButton }}
        onClick={handleAddAndEditClick}
        disabled={isLoading}
      >
        <AddIcon className={classes.addIcon}></AddIcon>
        NEW
      </Button>
      }

      {isLoading &&
        <CircularProgress className={classes.loadingSpinner} />
      }
      
      {selectedBooking === null
      ? (

        <BookingItemsDisplay />
        
      )
      : (
        <BookingEdit 
          addAndEdit={addAndEdit} 
          handleDoneButtonClick={handleDoneEditClick} 
          itemToEdit={selectedBooking} 
        />
      )}

      {addBookingPopup &&
        <AddBookingPopup 
          open={addBookingPopup} 
          handlePopupClose={handleAddBookingPopupClose} 
        />
      }
    </React.Fragment>
  )
}
