import React, { ReactElement, useState } from "react";
import axios from "axios";

interface Props {}

export default function useGetAllBookings(selectedSpaceID) {
  const [isLoading, toggleIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [spaceBookings, setSpaceBookings] = useState([]);
  const [noBookings, toggleNoBookings] = useState(false);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const getAllBookings = async () => {
    setSpaceBookings([]);
    setError("");
    toggleIsLoading(true);
    try {
      //Get bookings for a space
      let response = await axios.get("/space/bookings/all", {
        params: {
          spaceID: selectedSpaceID,
        },
        cancelToken: source.token,
      });

      //Ensure error is cleared after successful request
      setError("");

      toggleIsLoading(false);
      setSpaceBookings(response.data);
      toggleNoBookings(false);
    } catch (error) {
      console.log("ERROR", error);

      if (axios.isCancel(error)) {
        return;
      }

      toggleIsLoading(false);

      if (error.response && error.response.status === 404) {
        toggleNoBookings(true);
      } else {
        setError("Error getting bookings for this space");
      }
    }
  };

  return {
    isLoading,
    toggleIsLoading,
    error,
    spaceBookings,
    noBookings,
    getAllBookings,
  };
}
