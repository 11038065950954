import React, { ReactElement, useState, useContext, useEffect } from 'react';
import { CircularProgress, Divider, FormControlLabel, IconButton, Switch, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment-timezone';
import axios from 'axios';

import EditBookingEndTimePopup from '../../BookingEdit/EditBookingEndTimePopup/EditBookingEndTimePopup';
import { AppContext, AppContextType } from '../../../../context/AppContext';
import EditBookingStartTimePopup from '../../BookingEdit/EditBookingStartTimePopup/EditBookingStartTimePopup';
import EditDoorsOpenClosed from '../../BookingEdit/EditDoorsOpenClosedPopup/EditDoorsOpenClosedPopup';

import styles from './RunDisplay.module.css';
import { RunDisplayStyles } from './RunDisplayStyles';

interface Props {
  handleStartTimeChange: (startTime) => void,
}

export default function RunDisplay(props: Props): ReactElement {

  const  { 
    selectedBooking,
    setSelectedBooking,
  } : AppContextType = useContext(AppContext);

  const [ editStartTime, toggleEditStartTime ] = useState(false);
  const [ editEndTime, toggleEditEndTime ] = useState(false);

  const [ enableDoors, toggleEnableDoors ] = useState(selectedBooking.enableDoors);
  
  const [ editDoorsOpenTime, toggleEditDoorsOpenTime ] = useState(false);
  const [ editDoorsClosedTime, toggleEditDoorsClosedTime ] = useState(false);

  const [ doorsLoadingSpinner, toggleDoorsLoadingSpinner ] = useState(false);

  useEffect(() => {
    //Check if booking has doors open/closed. If not, set it to 30mins before/after event times
    if (!selectedBooking.doorsOpen) {
      let bookingStartTime = selectedBooking.start.time;
      let bookingEndTime = selectedBooking.end.time;

      let doorsOpen = moment(bookingStartTime).subtract("30", "minutes").format();
      let doorsClosed = moment(bookingEndTime).add("30", "minutes").format();

      let selectedBookingClone = selectedBooking;
      
      selectedBookingClone.doorsOpen = doorsOpen;
      selectedBookingClone.doorsClosed = doorsClosed;

      setSelectedBooking(selectedBookingClone);

    }
    
  }, [])
  
  /* Edit start time popup handlers */
  const handleEditStartTimePopupClose = () => {
    toggleEditStartTime(false);

    props.handleStartTimeChange(selectedBooking.start.time);
  };

  const handleEditStartTimeClick = () => {
    toggleEditStartTime(true);
  }
  /**/

  /* Edit end time popup handlers */
  const handleEditEndTimePopupClose = () => {
    toggleEditEndTime(false);
    
  };

  const handleEditEndTimeClick = () => {
    toggleEditEndTime(true);
  }
  /**/

  /* Doors logic */
  const handleDoorsToggle = async () => {
    toggleDoorsLoadingSpinner(true);

    try {
      let selectedBookingClone = selectedBooking;
      selectedBookingClone.enableDoors = !enableDoors;

      let response = await axios.put("/booking", JSON.stringify(selectedBookingClone));

      setSelectedBooking(selectedBookingClone);
      toggleEnableDoors(!enableDoors);
      toggleDoorsLoadingSpinner(false);
    }
    catch (error) {
      toggleDoorsLoadingSpinner(false);
      alert("Error editing this booking");
    }
  }

  const handleDoorsOpenPopupClose = () => {
    toggleEditDoorsOpenTime(false);
  }

  const handleDoorsClosedPopupClose = () => {
    toggleEditDoorsClosedTime(false);
  }
  /**/
  
  const classes = RunDisplayStyles();
  
  return (
    <React.Fragment>
      <Typography variant="h1" classes={{ root: classes.runHeader}}>
        Booking Dates & Times
      </Typography>

      {doorsLoadingSpinner &&
        <CircularProgress className={classes.doorsLoadingSpinner} />
      }

      <FormControlLabel
        value="doorsOpenEarly"
        control={<Switch color="secondary" checked={enableDoors} />}
        label="Doors Open Early"
        labelPlacement="start"
        classes={{
          root: classes.doorsOpenEarlyToggle,
          label: classes.doorsOpenEarlyLabel
        }}
        onChange={handleDoorsToggle}
        disabled={doorsLoadingSpinner}
      />

      <div className={styles.startHolder}>
        <Typography variant="h2" classes={{ root: classes.runStartHeader}}>
          Event Start
        </Typography>

        <div className={styles.startTimeHolder}>
          <Typography variant="body1" classes={{ root: classes.startTime}}>
            {moment.tz(selectedBooking.start.time, selectedBooking.start.timezone).format('L')}
          </Typography>
          <Typography variant="body1" classes={{ root: classes.startDate}}>
            {moment.tz(selectedBooking.start.time, selectedBooking.start.timezone).format('LT z')}
          </Typography>

          <IconButton className={classes.editIconButton} onClick={handleEditStartTimeClick}>
            <EditIcon  className={classes.editIcon} />
          </IconButton>

        </div>

        <Divider className={classes.runDivider}></Divider>
      </div>

      <div className={styles.doorsOpenHolder}>
        <Typography 
          variant="h2" 
          classes={{ root: classes.runStartHeader }}
          style={{
            color: enableDoors ? "black" : "#BABABA",
          }}
        >
          Doors Open
        </Typography>

        <div className={styles.startTimeHolder}>
          <Typography 
            variant="body1" 
            classes={{ root: classes.startTime }}
            style={{
              color: enableDoors ? "black" : "#BABABA",
            }}
          >
            {moment.tz(selectedBooking.doorsOpen, selectedBooking.start.timezone).format('L')}
          </Typography>
          <Typography 
            variant="body1" 
            classes={{ root: classes.startDate }}
            style={{
              color: enableDoors ? "black" : "#BABABA",
            }}
          >
            {moment.tz(selectedBooking.doorsOpen, selectedBooking.start.timezone).format('LT z')}
          </Typography>

          <IconButton 
            className={classes.editIconButton} 
            onClick={() => toggleEditDoorsOpenTime(true)}
            disabled={!enableDoors}
          >
            <EditIcon className={classes.editIcon} />
          </IconButton>

        </div>

        <Divider className={`${classes.runDivider} ${classes.runDividerRight}`}></Divider>
      </div>

      <div className={styles.endHolder}>
        <Typography variant="h2" classes={{ root: classes.runEndHeader}}>
          Event End
        </Typography>

        <div className={styles.endTimeHolder}>
          <Typography variant="body1" classes={{ root: classes.endTime}}>
            {moment.tz(selectedBooking.end.time, selectedBooking.end.timezone).format('L')}
          </Typography>
          <Typography variant="body1" classes={{ root: classes.endDate}}>
            {moment.tz(selectedBooking.end.time, selectedBooking.end.timezone).format('LT z')}
          </Typography>
          
          <IconButton onClick={handleEditEndTimeClick} className={classes.editIconButton}>
            <EditIcon className={classes.editIcon} />
          </IconButton>

        </div>

        <Divider className={classes.runDivider}></Divider>

        
      </div>

      <div className={styles.doorsClosedHolder}>
        <Typography 
          variant="h2" 
          classes={{ root: classes.runStartHeader }}
          style={{
            color: enableDoors ? "black" : "#BABABA",
          }}
        >
          Doors Close
        </Typography>

        <div className={styles.startTimeHolder}>
          <Typography 
            variant="body1" 
            classes={{ root: classes.startTime }}
            style={{
              color: enableDoors ? "black" : "#BABABA",
            }}
          >
            {moment.tz(selectedBooking.doorsClosed, selectedBooking.start.timezone).format('L')}
          </Typography>
          <Typography 
            variant="body1" 
            classes={{ root: classes.startDate }}
            style={{
              color: enableDoors ? "black" : "#BABABA",
            }}
          >
            {moment.tz(selectedBooking.doorsClosed, selectedBooking.start.timezone).format('LT z')}
          </Typography>

          <IconButton 
            className={classes.editIconButton} 
            onClick={() => toggleEditDoorsClosedTime(true)}
            disabled={!enableDoors}
          >
            <EditIcon className={classes.editIcon} />
          </IconButton>

        </div>

        <Divider className={`${classes.runDivider} ${classes.runDividerRight}`}></Divider>
      </div>

      {editStartTime &&
        <EditBookingStartTimePopup 
          handlePopupClose={handleEditStartTimePopupClose} 
          open={editStartTime} 
          duplicateBooking={false}
        />
      }

      {editEndTime &&
        <EditBookingEndTimePopup 
          handlePopupClose={handleEditEndTimePopupClose} 
          open={editEndTime} 
          duplicateBooking={false}
        />
      }

      {(editDoorsClosedTime || editDoorsOpenTime) &&
        <EditDoorsOpenClosed
          handlePopupClose={editDoorsClosedTime ? handleDoorsClosedPopupClose : handleDoorsOpenPopupClose}
          open={true}
          duplicateBooking={false}
          doorsClosedTime={editDoorsClosedTime}
        />
      }
    </React.Fragment>
  )
}
