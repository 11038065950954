import { Button } from "@material-ui/core";
import React, { ReactElement } from "react";

import { CancelButtonStyles } from "./CancelButtonStyles";

interface Props {
  onClick: () => any;
  disableBoolean?: boolean;
  variant: "text" | "outlined" | "contained";
}

export default function CancelButton(props: Props): ReactElement {
  const classes = CancelButtonStyles();

  return (
    <Button
      disabled={props.disableBoolean}
      onClick={props.onClick}
      variant={props.variant}
      color="primary"
      className={classes.cancelButton}
      style={{ color: props.variant === "text" ? "black" : "white" }}
    >
      CANCEL
    </Button>
  );
}
