import { makeStyles, createStyles } from '@material-ui/core/styles';

const EditBookingNameStyles = makeStyles((theme: any) => createStyles({
  dialogPaper: {
    width: 462,
    height: 298,
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "auto",
  },
  popup: {
    width: "100%",
    height: 298,
    position: "absolute",
  },
  editNameHeader: {
    fontSize: 24,
    position: "relative",
    left: 30,
    top: 58,
    fontWeight: 400,
  },
  eventNameTextField: {
    width: 403,
    position: "absolute",
    left: 30,
    top: 120
  },
  eventNameInput: {
    backgroundColor: "#F7F7F7"
  },
  loadingSpinner: {
    color: "grey",
    position: "absolute",
    right: 57,
    bottom: 77
  },
  error: {
    color: "red",
    position: "absolute",
    left: 30,
    top: 89,
  }
})
)

export { EditBookingNameStyles };