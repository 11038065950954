import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import axios from "axios";
import TimezoneSelect from "react-timezone-select";
import DateFnsUtils from "@date-io/date-fns";
import React, { ReactElement, useEffect, useState, useContext } from "react";
import { AuthContext, AuthContextType } from "../../context/AuthContext";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import styles from "./SpaceAdmin.module.css";
import { SpaceAdminStyles } from "./SpaceAdminStyles";
import moment from "moment";
import AdminInterfaceWrapper from "../../components/ui/AdminInterfaceWrapper/AdminInterfaceWrapper";
import { orderBy } from "natural-orderby";

interface Props {}

export default function SpaceAdmin(props: Props): ReactElement {
  const history = useHistory();

  const { idToken, setIdToken }: AuthContextType = useContext(AuthContext);

  const [authenticated, toggleAuthenticated] = useState(false);

  const [spaceName, setSpaceName] = useState("");

  const [videoServer, setVideoServer] = useState(
    "https://cloud.liveswitch.io/"
  );
  const [videoServerAppID, setVideoServerAppID] = useState(
    "dc68fa69-6578-4b42-a0ae-a567b850a1d7"
  );

  const [gameServer, setGameServer] = useState("engine.showboat.live");
  const [gameServerPort, setGameServerPort] = useState(443);

  const [projects, setProjects] = useState([]);
  const [selectedProjectID, setSelectedProjectID] = useState("");

  const [spaceNameError, toggleSpaceNameError] = useState(false);

  const [videoServerError, toggleVideoServerError] = useState(false);
  const [videoServerAppIDError, toggleVideoServerAppIDError] = useState(false);

  const [gameServerError, toggleGameServerError] = useState(false);
  const [gamePortError, toggleGamePortError] = useState(false);

  const [worlds, setWorlds] = useState([]);
  const [selectedWorldID, setSelectedWorldID] = useState("");

  const [selectedStartDate, setSelectedStartDate] = useState(moment().format());
  const [selectedEndDate, setSelectedEndDate] = useState(moment().format());

  const [selectedTimezone, setSelectedTimezone] = useState({});

  const [projectError, toggleProjectError] = useState(false);

  const [worldError, toggleWorldError] = useState(false);

  const [timezoneError, toggleTimezoneError] = useState(false);

  const [loadingSpinner, toggleLoadingSpinner] = useState(false);

  const [worldsLoadingSpinner, toggleWorldsLoadingSpinner] = useState(false);

  const [postError, togglePostError] = useState(false);

  const [spacePostCompleted, toggleSpacePostCompleted] = useState(false);

  useEffect(() => {
    setSelectedTimezone({
      value: moment.tz.guess(),
      label: moment.tz.guess(),
    });

    //First check if there is any producerID in sessionStorage
    if (localStorage.getItem("producerID") == undefined) {
      //Redirect to sign-in if no producerID in sessionStorage
      alert("You do not have privileges to access this page.");
      window.location.replace("/");
    } else {
      //Set up authentication to query producer object
      var poolData = {
        UserPoolId: "us-east-1_N15Q0NLkm",
        ClientId: "2332rbhi35f5016dglri2mojo",
      };

      const userPool = new CognitoUserPool(poolData);

      let currentUser = userPool.getCurrentUser();

      if (currentUser) {
        currentUser.getSession((err, session) => {
          if (err) {
            alert("Your session has expired. Please sign in again.");
            window.location.replace("/");
          } else {
            axios.defaults.headers.common["Authorization"] = session
              .getIdToken()
              .getJwtToken();

            setIdToken(session.getIdToken().getJwtToken());

            //Check producer privileges on load (check if SuperAdmin)
            //ProducerID is received from sessionStorage
            axios
              .get("/producer/role", {
                params: {
                  producerID: localStorage.getItem("producerID"),
                },
              })
              .then(function (response) {
                //Get the role on the producer object
                if (response.data.role === 0) {
                  toggleAuthenticated(true);

                  axios
                    .get("/projects/all/admin", {
                      headers: {
                        Authorization: session.getIdToken().getJwtToken(),
                      },
                    })
                    .then(async function (response) {

                      //Order project selections
                      let projectsOrdered = orderBy(
                        response.data,
                        [p => (p as any).projectName],
                        ['asc']
                      )

                      setProjects(projectsOrdered);

                      await getAllWorlds(session);
                    })
                    .catch(function (error) {});
                } else {
                  alert("You do not have privileges to access this page.");
                  window.location.replace("/");
                }
              })
              .catch(function (error) {
                alert("You do not have privileges to access this page.");
                window.location.replace("/");
              });
          }
        });
      } else {
        alert("Your session has expired. Please sign in again.");
        window.location.replace("/");
      }
    }
  }, []);

  const getAllWorlds = async (session) => {
    //Show loader
    toggleWorldsLoadingSpinner(true);

    try {
      let worldResponse = await axios.get("/worlds/all/admin", {
        headers: {
          Authorization: session.getIdToken().getJwtToken(),
        },
      });

      toggleWorldsLoadingSpinner(false);

      //Order world selections
      let worldsOrdered = orderBy(
        worldResponse.data,
        [w => (w as any).name],
        ['asc']
      )

      setWorlds(worldsOrdered);
    } catch (error) {
      //Hide loader
      toggleWorldsLoadingSpinner(false);
    }
  };

  //Space name
  const handleSpaceNameChange = (e) => {
    toggleSpaceNameError(false);

    setSpaceName(e.target.value);
  };

  //Video server
  const handleVideoServerChange = (e) => {
    toggleVideoServerError(false);

    setVideoServer(e.target.value);
  };

  //Video server app ID
  const handleVideoServerAppIDChange = (e) => {
    toggleVideoServerAppIDError(false);

    setVideoServerAppID(e.target.value);
  };

  //Game server
  const handleGameServerChange = (e) => {
    toggleGameServerError(false);

    setGameServer(e.target.value);
  };

  //Game port
  const handleGameServerPortChange = (e) => {
    toggleGamePortError(false);

    setGameServerPort(e.target.value);
  };

  //Project
  const handleProjectSelect = (e) => {
    toggleProjectError(false);

    setSelectedProjectID(e.target.value);
  };

  //Submit
  const handleSubmitClick = () => {
    togglePostError(false);

    let formHasError = false;

    //Make sure spaceName is not empty
    if (!spaceName.trim().length) {
      toggleSpaceNameError(true);
      formHasError = true;
    } else {
      toggleSpaceNameError(false);
    }

    if (!videoServer.trim().length) {
      toggleVideoServerError(true);
      formHasError = true;
    } else {
      toggleVideoServerError(false);
    }

    if (!videoServerAppID.trim().length) {
      toggleVideoServerAppIDError(true);
      formHasError = true;
    } else {
      toggleVideoServerAppIDError(false);
    }

    if (!gameServer.trim().length) {
      toggleGameServerError(true);
      formHasError = true;
    } else {
      toggleGameServerError(false);
    }

    if (!gameServerPort.toString().trim().length) {
      toggleGamePortError(true);
      formHasError = true;
    } else {
      toggleGamePortError(false);
    }

    //Make sure project selection is not empty
    if (selectedProjectID === "") {
      toggleProjectError(true);
      formHasError = true;
    } else {
      toggleProjectError(false);
    }

    //Make sure world selection is not empty
    if (selectedWorldID === "") {
      toggleWorldError(true);
      formHasError = true;
    } else {
      toggleWorldError(false);
    }

    //If we have an error, return
    if (formHasError) {
      return;
    }

    toggleLoadingSpinner(true);

    toggleProjectError(false);

    //Format startTime to be at 12:01am of day selected
    let startDateFormatted = moment
      .tz(selectedStartDate, (selectedTimezone as any).value)
      .set("hour", 0)
      .set("minutes", 1)
      .set("seconds", 0)
      .format();

    //Format endTime to be at 11:59pm of day selected
    let endDateFormatted = moment
      .tz(selectedEndDate, (selectedTimezone as any).value)
      .set("hour", 23)
      .set("minutes", 59)
      .set("seconds", 0)
      .format();

    let timezoneValue = (selectedTimezone as any).value;

    let spaceObj = {
      spaceName: spaceName,
      projectID: selectedProjectID,
      defaultVideoServer: videoServer,
      defaultVideoServerAppID: videoServerAppID,
      defaultGameServer: gameServer,
      defaultGamePort: gameServerPort,
      startDate: {
        date: startDateFormatted,
        timezone: timezoneValue,
      },
      endDate: {
        date: endDateFormatted,
        timezone: timezoneValue,
      },
      worldID: selectedWorldID,
    };

    axios
      .post("/space", JSON.stringify(spaceObj), {
        headers: {
          Authorization: idToken,
        },
      })
      .then(function (response) {
        toggleLoadingSpinner(false);
        toggleSpacePostCompleted(true);
      })
      .catch(function (error) {
        toggleLoadingSpinner(false);
        togglePostError(true);
      });
  };

  const handleBackClick = () => {
    window.location.reload();
  };

  //Start date
  const handleStartDateChange = (date) => {
    setSelectedStartDate(moment(date).format());
  };

  //End date
  const handleEndDateChange = (date) => {
    setSelectedEndDate(moment(date).format());
  };

  //Timezone
  const handleTimezoneChange = (tz) => {
    setSelectedTimezone(tz);
  };

  //World select
  const handleWorldSelect = (e) => {
    toggleWorldError(false);

    setSelectedWorldID(e.target.value);
  };

  const classes = SpaceAdminStyles();

  if (authenticated) {
    return (
      <React.Fragment>
        <Button
          className={classes.adminMenuButton}
          variant="contained"
          color="primary"
          onClick={() => {
            history.push("/admin-menu");
          }}
        >
          ADMIN MENU
        </Button>

        <AdminInterfaceWrapper
          wrapperHeight={900}
          wrapperWidth={974}
        >
          <Paper className={classes.spaceAdminWrapper}>
            <Typography variant="h1" className={classes.createSpaceHeader}>
              Create a Space
            </Typography>

            <Divider className={classes.spaceDivider}></Divider>

            {spacePostCompleted ? (
              <Typography variant="body1" className={classes.success}>
                Success
              </Typography>
            ) : (
              <div className={styles.spaceAdminGrid}>
                <div className={styles.spaceFormHolder}>
                  <div className={styles.spaceFormItem}>
                    {postError && (
                      <Typography
                        variant="body1"
                        className={classes.errorMessage}
                      >
                        An error occurred creating this space
                      </Typography>
                    )}
                    <Typography
                      variant="h2"
                      className={classes.spaceNameHeader}
                    >
                      Space Name
                    </Typography>

                    <TextField
                      variant="outlined"
                      size="small"
                      className={classes.spaceNameTextField}
                      value={spaceName}
                      onChange={handleSpaceNameChange}
                      error={spaceNameError}
                    ></TextField>
                  </div>

                  <div className={styles.spaceFormItem}>
                    <Typography
                      variant="h2"
                      className={classes.spaceNameHeader}
                    >
                      Default Video Server
                    </Typography>

                    <TextField
                      variant="outlined"
                      size="small"
                      className={classes.spaceNameTextField}
                      value={videoServer}
                      onChange={handleVideoServerChange}
                      error={videoServerError}
                    ></TextField>
                  </div>

                  <div className={styles.spaceFormItem}>
                    <Typography
                      variant="h2"
                      className={classes.spaceNameHeader}
                    >
                      Default Video Server App ID
                    </Typography>

                    <TextField
                      variant="outlined"
                      size="small"
                      className={classes.spaceNameTextField}
                      value={videoServerAppID}
                      onChange={handleVideoServerAppIDChange}
                      error={videoServerAppIDError}
                    ></TextField>
                  </div>

                  <div className={styles.spaceFormItem}>
                    <Typography
                      variant="h2"
                      className={classes.spaceNameHeader}
                    >
                      Default Game Server
                    </Typography>

                    <TextField
                      variant="outlined"
                      size="small"
                      className={classes.spaceNameTextField}
                      value={gameServer}
                      onChange={handleGameServerChange}
                      error={gameServerError}
                    ></TextField>
                  </div>

                  <div className={styles.spaceFormItem}>
                    <Typography
                      variant="h2"
                      className={classes.spaceNameHeader}
                    >
                      Default Game Port
                    </Typography>

                    <TextField
                      variant="outlined"
                      size="small"
                      className={classes.spaceNameTextField}
                      value={gameServerPort}
                      onChange={handleGameServerPortChange}
                      error={gamePortError}
                    ></TextField>
                  </div>

                  <div className={styles.spaceFormItem}>
                    <Typography
                      variant="h2"
                      className={classes.spaceNameHeader}
                    >
                      Choose Project
                    </Typography>

                    <FormControl
                      classes={{ root: classes.projectSelectHolder }}
                      variant="outlined"
                      error={projectError}
                    >
                      <InputLabel>Choose Project</InputLabel>
                      <Select
                        label="Choose Project"
                        classes={{ selectMenu: classes.projectMenu }}
                        value={selectedProjectID}
                        onChange={handleProjectSelect}
                        MenuProps={{
                          classes: { paper: classes.projectMenuPaper },
                        }}
                      >
                        {projects.map((project) => {
                          return (
                            <MenuItem
                              key={project.projectID}
                              value={project.projectID}
                              classes={{
                                root: classes.menuItem,
                                selected: classes.menuItemSelected,
                              }}
                            >
                              {project.projectName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>

                  <div
                    className={`${styles.spaceFormItem} ${styles.spaceFormItemWorld}`}
                  >
                    <Typography
                      variant="h2"
                      className={classes.spaceNameHeader}
                    >
                      Choose World
                    </Typography>

                    {worldsLoadingSpinner && (
                      <CircularProgress
                        className={classes.worldLoadingSpinner}
                      />
                    )}

                    <FormControl
                      classes={{ root: classes.projectSelectHolder }}
                      variant="outlined"
                      error={worldError}
                    >
                      <InputLabel>Choose World</InputLabel>
                      <Select
                        label="Choose World"
                        classes={{ selectMenu: classes.projectMenu }}
                        value={selectedWorldID}
                        onChange={handleWorldSelect}
                        MenuProps={{
                          classes: { paper: classes.projectMenuPaper },
                        }}
                      >
                        {worlds.map((world) => {
                          return (
                            <MenuItem
                              key={world.worldID}
                              value={world.worldID}
                              classes={{
                                root: classes.menuItem,
                                selected: classes.menuItemSelected,
                              }}
                            >
                              {world.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className={styles.spaceAdminDateHolder}>
                  <div className={styles.dateInputHolder}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        label="Select start date"
                        value={selectedStartDate}
                        onChange={handleStartDateChange}
                        className={classes.startDatePicker}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className={styles.dateInputHolder}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        label="Select end date"
                        value={selectedEndDate}
                        onChange={handleEndDateChange}
                        className={classes.endDatePicker}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>

                  <div className={styles.dateInputHolder}>
                    <Typography variant="h3" className={classes.timezoneHeader}>
                      Select Timezone
                    </Typography>

                    <TimezoneSelect
                      value={selectedTimezone}
                      onChange={(tz) => handleTimezoneChange(tz)}
                      className={styles.timezonePicker}
                      color="red"
                    />
                  </div>
                </div>
              </div>
            )}

            {loadingSpinner && (
              <CircularProgress
                className={classes.loadingSpinner}
              ></CircularProgress>
            )}

            {spacePostCompleted ? (
              <Button
                className={classes.submitButton}
                variant="contained"
                color="primary"
                onClick={handleBackClick}
              >
                BACK
              </Button>
            ) : (
              <Button
                className={classes.submitButton}
                variant="contained"
                color="primary"
                onClick={handleSubmitClick}
                disabled={loadingSpinner}
              >
                SUBMIT
              </Button>
            )}
          </Paper>
        </AdminInterfaceWrapper>
      </React.Fragment>
    );
  } else {
    return <div></div>;
  }
}
