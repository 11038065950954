import { makeStyles, createStyles } from '@material-ui/core/styles';

const UploadAttendeesPopupStyles = makeStyles((theme: any) => createStyles({
  dialogPaper: {
    width: 730,
    minHeight: 405,
    height: "auto",
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "auto",
  },
  popup: {
    height: "auto",
    width: "100%",
    minHeight: 405
  },
  uploadAttendeeHeader: {
    fontSize: 24,
    fontWeight: 400,
    position: "absolute",
    left: 30,
    top: 75
  },
  uploadAttendeeText: {
    fontSize: 16,
    position: "absolute",
    left: 30,
    top: 135,
    "&:hover": {
      cursor: "pointer"
    }
  },
  downloadAttendeeText: {
    fontSize: 16,
    position: "absolute",
    top: 171,
    left: 30,
    color: "#6200EE",
    "&:hover": {
      cursor: "pointer"
    }
  },
  dropzoneText: {
    fontSize: 16,
    color: "#AAA6A6",
    position: "relative",
    top: 46,
  },
  error: {
    fontSize: 14,
    color: "red",
    position: "relative",
    top: 8,
  },
  success: {
    color: "green",
    fontSize: 14,
    position: "relative",
    top: 8,
  },
  parsing: {
    fontSize: 14,
    position: "relative",
    top: 8,
  },
  loadingSpinner: {
    color: "grey",
    position: "absolute",
    bottom: 91,
    right: 54
  },
  axiosError: {
    color: "red",
    position: "relative",
    top: 107,
    left: 30
  },
  closeButton: {
    position: "absolute",
    top: 3,
    color: "white",
    padding: 4,
    right: 5,
  },
  fileName: {
    fontSize: 14,
    position: 'absolute',
    left: 20,
    top: 14,
  },
  fileError: {
    fontSize: 14,
    color: "red",
    left: 20,
    bottom: 12,
    position: 'absolute',
  }
})
)

export { UploadAttendeesPopupStyles };