import React, { ReactElement, useState, useContext, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  Typography,
} from "@material-ui/core";
import axios from "axios";

import { AppContext, AppContextType } from "../../../../context/AppContext";

import { ConfirmLinkRegeneratePopupStyles } from "./ConfirmLinkRegeneratePopupStyles";
import styles from "./ConfirmLinkRegeneratePopup.module.css";
import CancelButton from "../../../ui/CancelButton/CancelButton";

interface Props {
  open: boolean;
  regenerateType: string;
  handlePopupClose: () => any;
  handlePublicLinkRegenerated?: () => any;
  handleSignUpLinkRegenerated?: () => any;
  handleMobileHybridLinkRegenerated?: () => any;
}

export default function ConfirmLinkRegeneratePopup(props: Props): ReactElement {
  const {
    selectedBooking,
    selectedBookingID,
    setSelectedBooking,
  }: AppContextType = useContext(AppContext);

  const [loadingSpinner, toggleLoadingSpinner] = useState(false);

  const [error, toggleError] = useState(false);

  //Regenerate login link click
  const handleRegenerateLoginLink = async () => {
    let type = props.regenerateType;

    toggleLoadingSpinner(true);
    toggleError(false);

    let urlExtension;

    /* = type === "signup" ? "/booking/signup/regenerate" : "/booking/public/regenerate"; */

    switch (type) {
      case "signup":
        urlExtension = "/booking/signup/regenerate";
        break;
      case "public":
        urlExtension = "/booking/public/regenerate";
        break;
      case "mobile-hybrid":
        urlExtension = "/booking/mobile-hybrid/regenerate";
        break;
    }

    try {
      let response = await axios.put(urlExtension, {
        bookingID: selectedBookingID,
      });

      toggleLoadingSpinner(false);
      let selectedBookingClone = selectedBooking;

      if (type === "signup") {
        selectedBookingClone.signUpLoginCode = response.data;
      } else if (type === "public") {
        selectedBookingClone.publicAppLoginCode = response.data;
      } else if (type === "mobile-hybrid") {
        selectedBookingClone.mobileHybridLoginCode = response.data;
      }

      setSelectedBooking(selectedBookingClone);

      switch (type) {
        case "signup":
          props.handleSignUpLinkRegenerated();
          break;
        case "public":
          props.handlePublicLinkRegenerated();
          break;
        case "mobile-hybrid":
          props.handleMobileHybridLinkRegenerated();
          break;
      }

      props.handlePopupClose();
    } catch {
      toggleError(true);
      toggleLoadingSpinner(false);
    }
  };

  //Cancel click
  const handleCancelClick = () => {
    if (loadingSpinner) {
      return;
    }

    props.handlePopupClose();
  };

  const classes = ConfirmLinkRegeneratePopupStyles();

  return (
    <Dialog open={props.open} classes={{ paper: classes.dialogPaper }}>
      <div className={classes.popup}>
        <div className={styles.popupHeader}>
          <Typography className={classes.linkRegenerateHeader}>
            Confirm Link Regeneration
          </Typography>
        </div>

        {error && (
          <Typography variant="body1" className={classes.error}>
            An error occurred regenerating this link
          </Typography>
        )}

        <Typography variant="body1" className={classes.linkRegenerateSubtext}>
          Regenerating this link will cause the current link to stop working.
        </Typography>

        {loadingSpinner && (
          <CircularProgress className={classes.loadingSpinner} />
        )}

        <Button
          variant="contained"
          color="primary"
          className={classes.confirmButton}
          disabled={loadingSpinner}
          onClick={handleRegenerateLoginLink}
        >
          CONFIRM
        </Button>

        <CancelButton variant="contained" onClick={handleCancelClick} />
      </div>
    </Dialog>
  );
}
