import React, { ReactElement, useContext, useEffect, useState } from 'react'

//TODO: TODO: TODO:
//Convert selectedSpace and selectedProject to the correct types

export interface AppContextType {
  producerID : string,
  setProducerID : (producerID: string) => void,
  selectedProjectID : string,
  setSelectedProjectID : (projectID: string) => void,
  selectedSpaceID : string,
  setSelectedSpaceID : (spaceID: string) => void,
  selectedSpace : any,
  setSelectedSpace : (space: any) => void,
  selectedBookingID : string,
  setSelectedBookingID : (bookingID: string) => void,
  selectedBooking : SHOWBOAT.Booking,
  setSelectedBooking : (booking: SHOWBOAT.Booking) => void,
  selectedRegistration : SHOWBOAT.Registration,
  setSelectedRegistration : (registration: SHOWBOAT.Registration) => void,
  selectedPresentation : SHOWBOAT.Presentation,
  setSelectedPresentation : (presentation: SHOWBOAT.Presentation) => void,
  selectedPresenter : SHOWBOAT.Registration,
  setSelectedPresenter : (presenter: SHOWBOAT.Registration) => void,
  selectedProject : any,
  setSelectedProject : (project: SHOWBOAT.Project) => void,
  producerProjects: Array<any>,
  setProducerProjects : (projects : Array<any>) => void,
  selectedBookingUsage : number,
  setSelectedBookingUsage : (bookingUsage: number) => void,
  isRegistrationTabSelected : boolean,
  toggleIsRegistrationTabSelected: (isRegistrationTabSelected: boolean) => void,
  wasDoneClicked : boolean,
  toggleWasDoneClicked : (wasDoneClicked: boolean) => void,
  onMainBookingWindow : boolean,
  toggleOnMainBookingWindow : (onMainBookingWindow : boolean) => void,
  selectedCalendar : SHOWBOAT.Calendar,
  setSelectedCalendar : (calendar: SHOWBOAT.Calendar) => void,
  spaceCalendars : SHOWBOAT.Calendar[],
  setSpaceCalendars : (spaceCalendars: SHOWBOAT.Calendar[]) => void,
  bookingHasCalendar : boolean,
  toggleBookingHasCalendar : (bookingHasCalendar : boolean) => void,
  onMessagesWindow : boolean,
  toggleOnMessagesWindow : (onMessagesWindow: boolean) => void,
  isSuperAdmin: boolean,
  toggleIsSuperAdmin: (isSuperAdmin : boolean) => void,
  producerSpaces: any,
  setProducerSpaces: (spaces: any) => void,
  bookingLink: boolean,
  toggleBookingLink: (isBookingLink: boolean) => void,
  spaceLink: boolean,
  toggleSpaceLink: (isSpaceLink: boolean) => void,
}

export const AppContext = React.createContext(null);

export default function AppContextProvider(props): ReactElement {

  const [ selectedProject, setSelectedProject ] = useState({});
  const [ selectedProjectID, setSelectedProjectID, ] = useState("");

  const [ token, setToken ] = useState("");

  const [ selectedSpaceID, setSelectedSpaceID ] = useState(
    sessionStorage.getItem("selectedSpaceID") !== null
    ? sessionStorage.getItem("selectedSpaceID")
    : ""
  );

  const [ selectedSpace, setSelectedSpace ] = useState({});


  const [ selectedBookingID, setSelectedBookingID ] = useState("");
  const [ selectedBooking, setSelectedBooking ] = useState(null);

  const [ selectedRegistration, setSelectedRegistration ] = useState(null);

  const [ selectedPresentation, setSelectedPresentation ] = useState(null);

  const [ selectedPresenter, setSelectedPresenter ] = useState(null);

  const [ producerProjects, setProducerProjects ] = useState([]);

  const [ selectedBookingUsage, setSelectedBookingUsage ] = useState(null);

  const [ selectedCalendar, setSelectedCalendar ] = useState(null);

  const [ isRegistrationTabSelected, toggleIsRegistrationTabSelected ] = useState(false);

  const [ spaceCalendars, setSpaceCalendars ] = useState([]);
  
  const [ producerID, setProducerID ] = useState(
    localStorage.getItem("producerID") !== null
      ? localStorage.getItem("producerID")
    : ""
  );

  const [ producerSpaces, setProducerSpaces ] = useState(null)

  const [ wasDoneClicked, toggleWasDoneClicked ] = useState(false);

  const [ onMainBookingWindow, toggleOnMainBookingWindow ] = useState(false);

  const [ bookingHasCalendar, toggleBookingHasCalendar ] = useState(false);

  const [ onMessagesWindow, toggleOnMessagesWindow ] = useState(false);

  const [ isSuperAdmin, toggleIsSuperAdmin ] = useState(false);

  const [ bookingLink, toggleBookingLink ] = useState(false);
  const [ spaceLink, toggleSpaceLink ] = useState(false);

  /* useEffect(() => {
    if (isSuperAdmin === true) toggleIsSuperAdmin(false);
  }, [isSuperAdmin]) */

  useEffect(() => {
    console.log("Selected registration change:", selectedRegistration);
  }, [selectedRegistration])

  useEffect(() => {
    if (wasDoneClicked) {
      setSelectedBooking(null);
      setSelectedBookingID(null);
      setSelectedBookingUsage(null);
      toggleWasDoneClicked(false);
    }
  }, [wasDoneClicked])

  const AppContextValue : AppContextType = {
    producerID, 
    setProducerID,
    selectedProjectID,
    setSelectedProjectID,
    selectedSpaceID, 
    setSelectedSpaceID,
    selectedSpace,
    setSelectedSpace,
    selectedBookingID,
    setSelectedBookingID,
    selectedBooking,
    setSelectedBooking,
    selectedRegistration,
    setSelectedRegistration,
    selectedPresentation,
    setSelectedPresentation,
    selectedPresenter,
    setSelectedPresenter,
    selectedProject,
    setSelectedProject,
    selectedBookingUsage,
    setSelectedBookingUsage,
    isRegistrationTabSelected,
    toggleIsRegistrationTabSelected,
    wasDoneClicked,
    toggleWasDoneClicked,
    onMainBookingWindow,
    toggleOnMainBookingWindow,
    selectedCalendar,
    setSelectedCalendar,
    spaceCalendars,
    setSpaceCalendars,
    bookingHasCalendar,
    toggleBookingHasCalendar,
    onMessagesWindow,
    toggleOnMessagesWindow,
    isSuperAdmin,
    toggleIsSuperAdmin,
    producerProjects,
    setProducerProjects,
    producerSpaces,
    setProducerSpaces,
    bookingLink,
    toggleBookingLink,
    spaceLink,
    toggleSpaceLink
  }

  return (
    <AppContext.Provider
      value={AppContextValue}
    >
      {props.children}
    </AppContext.Provider>
  )
}
