import { makeStyles, createStyles } from '@material-ui/core/styles';

const ConfirmAttendeeLinkRegenerateStyles = makeStyles((theme: any) => createStyles({
  dialogPaper:{ 
    width: 361,
    height: 238,
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "auto",
  },
  popup: {
    width: "100%",
    height: 238,
    position: "absolute",
  },
  linkRegenerateHeader:{
    fontSize: 20,
    position: "absolute",
    color: "white",
    left: 30,
    top: 23,
  },
  linkRegenerateSubtext: {
    position: "absolute",
    fontSize: 16,
    top: 83,
    left: 30,
    display: "inline-block",
    width: 270,
  },
  errorMessageSubHeader: {
    fontSize: 16,
    position: "relative",
    left: 63,
    top: 40,
  },
  errorMessageTextField: {
    width: 608,
    position: "absolute",
    left: 60,
    top: 135
  },
  errorMessageInput: {
    backgroundColor: "#F7F7F7"
  },
  confirmButton: {
    height: 44,
    width: 115,
    position: "absolute",
    right: 30,
    bottom: 30,
    backgroundColor: "red",
    color: "white",
    "&:hover": {
      backgroundColor: "green"
    }
  },
  cancelButton: {
    height: 44,
    width: 95,
    position: "absolute",
    right: 163,
    bottom: 30,
  },
  loadingSpinner:{ 
    color: "grey",
    position: "absolute",
    right: 74,
    bottom: 82,
    height: "25px !important",
    width: "25px !important"
  },
  error: {
    color: "red",
    position: "absolute",
    left: 30,
    top: 128,
  }
})
)

export { ConfirmAttendeeLinkRegenerateStyles };