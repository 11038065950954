import { makeStyles, createStyles } from '@material-ui/core/styles';

const AttendeeDetailsPopupStyles = makeStyles((theme: any) =>
  createStyles({
    dialogPaper: {
      width: 857,
      height: 291,
      display: "inline-block",
      margin: 0,
      overflowX: "hidden",
      overflowY: "auto",
    },
    popup: {
      position: "absolute",
      width: "100%",
      height: 291,
    },
    attendeeNameHeader: {
      fontSize: 18,
      position: "relative",
      fontWeight: 400,
      top: 51,
      left: 29,
      width: 300,
    },
    closeButton: {
      position: "absolute",
      top: 3,
      color: "white",
      padding: 4,
      right: 5,
    },
    attendeeDetailsText: {
      position: "relative",
      fontSize: "1rem",
      marginBottom: 9,
    },
    arrivedHeader: {
      fontSize: "1rem",
      position: "relative",
    },
    arrivedDate: {
      fontSize: "1rem",
      position: "relative",
      bottom: 23,
      left: 80,
    },
    arrivedTime: {
      fontSize: "1rem",
      position: "relative",
      left: 80,
      bottom: 23,
    },
    leftHeader: {
      fontSize: "1rem",
      position: "relative",
      top: 15,
    },
    leftDate: {
      fontSize: "1rem",
      position: "relative",
      left: 80,
      bottom: 10,
    },
    leftTime: {
      fontSize: "1rem",
      position: "relative",
      left: 80,
      bottom: 10,
    },
    totalRecordTime: {
      fontSize: 24,
      position: "absolute",
      right: 44,
      bottom: 37,
      display: "inline-block",
    },
    attendeeSocketID: {
      fontSize: 12,
      position: "absolute",
      top: 46,
      right: 32,
    },
    attendeeAttendeesID: {
      fontSize: 12,
      position: "absolute",
      top: 66,
      right: 32,
    },
    tableHeader: {
      fontFamily: "Helvetica",
      fontSize: 14,
      fontWeight: "bold",
      position: "absolute",
      top: 9,
    },
    tableHeaderName: {
      left: 30,
    },
    tableHeaderIn: {
      left: 236,
    },
    tableHeaderOut: {
      left: 454,
    },
    tableHeaderTime: {
      left: 661,
    },
  })
);

export { AttendeeDetailsPopupStyles };