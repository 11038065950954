import { makeStyles, createStyles } from '@material-ui/core/styles';

const PopupHeaderStyles = makeStyles((theme: any) => createStyles({
  closeIconButton: {
    position: "absolute",
    top: 3,
    color: "white",
    padding: 4,
    right: 5,
    zIndex: 10,
  },
  headerTitle: {
    color: "white",
    fontSize: 20,
    fontWeight: 400,
    marginBottom: 42,
    position: "relative",
    top: 28, 
    left: 65
  }
})
)

export { PopupHeaderStyles };