import { CognitoUserPool } from "amazon-cognito-identity-js";
import { useHistory } from "react-router-dom";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import axios from "axios";

import { AuthContext, AuthContextType } from "../../context/AuthContext";

import { LoginCodeMenuStyles } from "./LoginCodeMenuStyles";
import styles from "./LoginCodeMenu.module.css";
import {
  Button,
  CircularProgress,
  Divider,
  Link,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import AdminInterfaceWrapper from "../../components/ui/AdminInterfaceWrapper/AdminInterfaceWrapper";

interface Props {}

export default function BookingsAdmin(props: Props): ReactElement {
  const history = useHistory();

  const { idToken, setIdToken }: AuthContextType = useContext(AuthContext);

  const [authenticated, setAuthenticated] = useState(false);

  const [loginCode, setLoginCode] = useState("");

  const [loginCodeError, setLoginCodeError] = useState(false);

  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const [ bookingLink, setBookingLink ] = useState("");

  useEffect(() => {
    //Initial authentication check
    //First check if there is any producerID in sessionStorage
    if (localStorage.getItem("producerID") == undefined) {
      //Redirect to sign-in if no producerID in sessionStorage
      alert("You do not have privileges to access this page.");
      window.location.replace("/");
    } else {
      //Set up authentication to query producer object
      var poolData = {
        UserPoolId: "us-east-1_N15Q0NLkm",
        ClientId: "2332rbhi35f5016dglri2mojo",
      };

      const userPool = new CognitoUserPool(poolData);

      let currentUser = userPool.getCurrentUser();

      if (currentUser) {
        currentUser.getSession(async (err, session) => {
          if (err) {
            alert("Your session has expired. Please sign in again.");
            window.location.replace("/");
          } else {
            axios.defaults.headers.common["Authorization"] = session
              .getIdToken()
              .getJwtToken();

            setIdToken(session.getIdToken().getJwtToken());

            //Check producer privileges on load (check if SuperAdmin)
            //ProducerID is received from sessionStorage

            try {
              let roleResponse = await axios.get("/producer/role", {
                params: {
                  producerID: localStorage.getItem("producerID"),
                },
              });

              //Get the role on the producer object
              if (roleResponse.data.role === 0) {
                setAuthenticated(true);
              } else {
                alert("You do not have privileges to access this page.");
                window.location.replace("/");
              }
            } catch {
              alert("You do not have privileges to access this page.");
              window.location.replace("/");
            }
          }
        });
      } else {
        alert("Your session has expired. Please sign in again.");
        window.location.replace("/");
      }
    }
  }, []);

  const handleLoginCodeChange = (e) => {
    setLoginCodeError(false);

    setLoginCode(e.target.value);
  };

  const handleLoginCodeSubmit = async () => {
    if (!loginCode.trim().length) {
      setLoginCodeError(true);
      return;
    }

    setLoadingSpinner(true);

    setLoginCodeError(false);
  
    try {
      const response = await axios.get("/login-admin", {
        params: {
          loginCode: loginCode,
        },
      });

      console.log("RESPONSE", response);
      
      setLoadingSpinner(false);
      
      let link = `https://producer.showboat.live/booking/${(response as any).data.bookingID}`;

      setBookingLink(link);
    } catch (error) {
      console.log("ERROR", error);

      setLoadingSpinner(false);
    }
    
  };

  const handleBookingLinkClick = () => {
    window.location.replace(bookingLink);
  }

  const classes = LoginCodeMenuStyles();

  if (authenticated) {
    return (
      <React.Fragment>
        <Button
          className={classes.adminMenuButton}
          variant="contained"
          color="primary"
          onClick={() => {
            history.push("/admin-menu");
          }}
        >
          ADMIN MENU
        </Button>

        <AdminInterfaceWrapper wrapperHeight={600} wrapperWidth={900}>
          <Paper className={classes.loginCodeAdminWrapper}>
            <Typography variant="h1" className={classes.loginCodeHeader}>
              Login Code Lookup
            </Typography>

            <Divider className={classes.loginCodeDivider}></Divider>

            <div className={styles.loginCodeHolder}>
              <Typography className={classes.loginCodeEntryHeader}>
                Login Code
              </Typography>
              <TextField
                value={loginCode}
                className={classes.loginTextField}
                onChange={handleLoginCodeChange}
                variant="outlined"
                error={loginCodeError}
              />

              <Button
                className={classes.submitButton}
                variant="contained"
                color="primary"
                disabled={loadingSpinner}
                onClick={handleLoginCodeSubmit}
              >
                SUBMIT
              </Button>

              {loadingSpinner && (
                <CircularProgress
                  className={classes.loadingSpinner}
                ></CircularProgress>
              )}

              {bookingLink !== "" &&
                <div className={styles.bookingLinkHolder}>
                  <Typography className={classes.loginCodeLinkHeader}>
                    Booking Link
                  </Typography>

                  <Link className={classes.bookingLink} onClick={handleBookingLinkClick}>
                    {bookingLink}
                  </Link>
                </div>
              }
            </div>
          </Paper>
        </AdminInterfaceWrapper>
      </React.Fragment>
    );
  } else {
    return <div></div>;
  }
}
