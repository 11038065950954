import React, { ReactElement, useContext, useState, useEffect } from "react";
import {
  Typography,
  Button,
  Switch,
  FormControlLabel,
  Snackbar,
  Dialog,
  CircularProgress,
  IconButton,
  Tooltip,
  Tabs,
  Tab,
  Divider,
} from "@material-ui/core";
import TrashIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import isEmail from "validator/lib/isEmail";
import LinkIcon from "@material-ui/icons/LinkOutlined";
import SendIcon from "@material-ui/icons/SendOutlined";
import RefreshIcon from "@material-ui/icons/Refresh";
import OpenIcon from "@material-ui/icons/OpenInNew";

import AttendeeResendInvitePopup from "../AttendeeResendInvitePopup/AttendeeResendInvitePopup";
import AttendeeForm from "../AttendeeForm/AttendeeForm";
import AttendeeDeletePopup from "../AttendeeDeletePopup/AttendeeDeletePopup";
import { AppContext, AppContextType } from "../../../../context/AppContext";
import ConfirmAttendeeLinkRegenerate from "../ConfirmAttendeeLinkRegenerate/ConfirmAttendeeLinkRegenerate";
import DeletePresenterPopup from "../../BookingPresenters/DeletePresenterPopup/DeletePresenterPopup";

import styles from "./AttendeeEditPopup.module.css";
import { AttendeeEditPopupStyles } from "./AttendeeEditPopupStyles";
import PresenterResendInvitePopup from "../../BookingPresenters/PresenterResendInvitePopup/PresenterResendInvitePopup";
import BookingPresenterPermissions from "../../BookingPresenters/BookingPresenterPermissions/BookingPresenterPermissions";

interface Props {
  open: boolean;
  handlePopupClose: (cancelled: boolean) => any;
  presenter: boolean;
}

export default function AttendeeEditPopup(props: Props): ReactElement {
  const {
    selectedRegistration,
    setSelectedRegistration,
    selectedBooking,
  }: AppContextType = useContext(AppContext);

  const [copyToClipboard, toggleCopyToClipboard] = useState(false);
  const [bridgeCopyToClipboard, setBridgeCopyToClipboard] = useState(false);
  const [linkRegenerated, toggleLinkRegenerated] = useState(false);
  const [resendInvite, toggleResendInvite] = useState(false);
  const [deleteAttendee, toggleDeleteAttendee] = useState(false);
  const [regenerateAttendeeLinkPopup, toggleRegenerateAttendeeLinkPopup] =
    useState(false);
  const [emailError, toggleEmailError] = useState(false);
  const [loadingSpinner, toggleLoadingSpinner] = useState(false);
  const [error, toggleError] = useState(false);
  const [brand, toggleBrand] = useState(false);
  const [allowEarlyAccess, toggleALlowEarlyAccess] = useState(
    selectedRegistration.allowEarlyAccess
  );
  const [tab, setTab] = useState(0);
  const [bridgeAccess, setBridgeAccess] = useState(
    selectedRegistration.bridgeAccess !== undefined
      ? selectedRegistration.bridgeAccess
      : true
  );

  useEffect(() => {
    if (selectedRegistration.brand) {
      toggleBrand(true);
    }

  }, []);

  //Copy to clipboard
  const handleCopyLinkClick = (e, removeHttps) => {
    let dummy = document.createElement("textarea");

    document.getElementById("attendeePopup").appendChild(dummy);

    dummy.value = removeHttps
      ? document.getElementById("toCopy").innerText
      : "https://" + document.getElementById("toCopy").innerText;

    dummy.select();
    dummy.setSelectionRange(0, 99999);

    document.execCommand("copy");
    document.getElementById("attendeePopup").removeChild(dummy);

    toggleCopyToClipboard(true);
  };

  const handleCopyBridgeLinkClick = () => {
    let dummy = document.createElement("textarea");

    document.getElementById("attendeePopup").appendChild(dummy);

    dummy.value = document.getElementById("toCopyBridge").innerText;

    dummy.select();
    dummy.setSelectionRange(0, 99999);

    document.execCommand("copy");
    document.getElementById("attendeePopup").removeChild(dummy);

    setBridgeCopyToClipboard(true);
  };

  const handleCopyToClipboardClose = () => {
    toggleCopyToClipboard(false);
  };

  //Regenerate link
  const handleRegenerateLinkClick = () => {
    toggleRegenerateAttendeeLinkPopup(true);
  };

  const handleRegenerateLinkPopupClose = (wasRegenerated: boolean) => {
    //Check if we need to show toast notification
    if (wasRegenerated) toggleLinkRegenerated(true);

    toggleRegenerateAttendeeLinkPopup(false);
  };

  const handleRegenerateLinkClose = () => {
    toggleLinkRegenerated(false);
  };

  //Resend invite
  const handleResendInviteClick = () => {
    //Show error if we do not have a valid email address to send an email to
    if (
      !selectedRegistration.registrationData.emailAddress.trim().length ||
      !isEmail(selectedRegistration.registrationData.emailAddress)
    ) {
      toggleEmailError(true);
      return;
    }

    toggleEmailError(false);

    toggleResendInvite(true);
  };

  const handleResendInvitePopupClose = () => {
    toggleResendInvite(false);
  };

  const handleBridgeCopyToClipboardClose = () => {
    setBridgeCopyToClipboard(false);
  };

  //Delete attendee
  const handleAttendeeDelete = () => {
    toggleDeleteAttendee(true);
  };

  const handleDeleteCancel = () => {
    toggleDeleteAttendee(false);
  };

  const handleDeleteAttendeePopupClose = () => {
    toggleDeleteAttendee(false);
    setSelectedRegistration(null);
    props.handlePopupClose(false);
  };

  //Handle attendee edit submit
  const handleAttendeeSubmit = async (
    newAttendeeObject: SHOWBOAT.Registration
  ) => {
    toggleLoadingSpinner(true);

    try {
      let body = {
        bookingID: selectedRegistration.bookingID,
        registrationID: selectedRegistration.registrationID,
        first: newAttendeeObject.registrationData.firstName,
        last: newAttendeeObject.registrationData.lastName,
        email: newAttendeeObject.registrationData.emailAddress,
        company: newAttendeeObject.registrationData.company,
        phone: newAttendeeObject.registrationData.phone,
        address: {
          addressLine1: newAttendeeObject.registrationData.address.addressLine1,
          addressLine2: newAttendeeObject.registrationData.address.addressLine2,
          city: newAttendeeObject.registrationData.address.city,
          state: newAttendeeObject.registrationData.address.state,
          zipCode: newAttendeeObject.registrationData.address.zipCode,
        },
        brand: brand,
        allowEarlyAccess: allowEarlyAccess,
      };

      //If we are editing a presenter, add permissions and bridgeAccess to the body
      if (props.presenter) {
        (body as any).permissions = selectedRegistration.permissions;
        (body as any).bridgeAccess = selectedRegistration.bridgeAccess;
      }

      await axios.put(props.presenter ? "/presenter" : "/registration", body);

      toggleLoadingSpinner(false);
      toggleEmailError(false);
      setSelectedRegistration(null);
      props.handlePopupClose(false);
    } catch (error) {
      toggleError(true);
      toggleLoadingSpinner(false);
    }
  };

  //Change brand
  const handleBrandChange = () => {
    if (brand) {
      toggleBrand(false);
    } else {
      toggleBrand(true);
    }
  };

  //Change allow early access
  const handleAllowEarlyAccessChange = () => {
    toggleALlowEarlyAccess(!allowEarlyAccess);
  };

  const handleCloseIconClick = () => {
    props.handlePopupClose(true);
  };

  const handleOpenLinkClick = (e, bridge?) => {
    //Open link in to-copy div in new tab
    window
      .open(
        document.getElementById(bridge ? "toCopyBridge" : "toCopy").innerText,
        "_blank"
      )
      .focus();
  };

  const handleBridgeAccessChange = (e) => {
    let currentPresenterClone = Object.assign({}, selectedRegistration);

    currentPresenterClone.bridgeAccess = e.target.checked;

    setSelectedRegistration(currentPresenterClone);

    setBridgeAccess(e.target.checked);
  };

  //Material ui classes
  const classes = AttendeeEditPopupStyles();

  return (
    <Dialog
      open={props.open}
      classes={{
        paper: props.presenter
          ? selectedBooking.intakeFields[5].type === "international"
            ? `${classes.dialogPaper} ${classes.dialogPaperInternationalPresenter}`
            : `${classes.dialogPaper} ${classes.dialogPaperPresenter}`
          : selectedBooking.intakeFields[5].type === "international"
          ? `${classes.dialogPaper} ${classes.dialogPaperInternational} `
          : classes.dialogPaper,
      }}
    >
      <div
        id="attendeePopup"
        className={
          selectedBooking.intakeFields[5].type === "international"
            ? `${classes.popup} ${classes.popupInternational}`
            : classes.popup
        }
      >
        <div className={styles.popupHeader}>
          <IconButton
            className={classes.closeIconButton}
            onClick={handleCloseIconClick}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <Typography className={classes.editAttendeeHeader} variant="h1">
          Manage {props.presenter ? "Presenter" : "Attendee"}
        </Typography>

        {props.presenter && (
          <div className={styles.tabBackdrop}>
            <Tabs className={classes.tabs} value={tab}>
              <Tab
                onClick={() => setTab(0)}
                label="GENERAL"
                className={classes.tab}
              ></Tab>
              <Tab
                onClick={() => setTab(1)}
                label="ACCESS"
                className={classes.tab}
              ></Tab>
              <Tab
                onClick={() => setTab(2)}
                label="CUSTOMIZE"
                className={classes.tab}
              ></Tab>
            </Tabs>
          </div>
        )}

        {!props.presenter && (
          <div
            className={
              props.presenter
                ? `${styles.buttonTopHolder} ${styles.buttonTopHolderPresenter}`
                : styles.buttonTopHolder
            }
          >
            <Typography
              className={classes.editAttendeeLink}
              id="attendeeLink"
              variant="h1"
            >
              Intake URL:{" "}
              <span id="toCopy">
                go.showboat.live/{selectedRegistration.loginCode}
              </span>
            </Typography>

            <div>
              <Tooltip
                classes={{
                  tooltip: classes.tooltip,
                  popper: classes.tooltipPopper,
                }}
                placement="bottom"
                title="Copy Intake Link"
              >
                <IconButton
                  color="primary"
                  className={classes.copyLinkButton}
                  onClick={(e) => handleCopyLinkClick(e, false)}
                >
                  <LinkIcon />
                </IconButton>
              </Tooltip>
            </div>

            <div>
              <Tooltip
                classes={{
                  tooltip: classes.tooltip,
                  popper: classes.tooltipPopper,
                }}
                placement="bottom"
                title="Resend Invite"
              >
                <IconButton
                  color="primary"
                  className={classes.resendInviteButton}
                  onClick={handleResendInviteClick}
                >
                  <SendIcon />
                </IconButton>
              </Tooltip>
            </div>

            <div>
              <Tooltip
                classes={{
                  tooltip: classes.tooltip,
                  popper: classes.tooltipPopper,
                }}
                placement="bottom"
                title="Regenerate Link"
              >
                <IconButton
                  color="primary"
                  className={classes.regenerateButton}
                  onClick={handleRegenerateLinkClick}
                  style={{
                    color: "red",
                  }}
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </div>

            <div>
              <Tooltip
                classes={{
                  tooltip: classes.tooltip,
                  popper: classes.tooltipPopper,
                }}
                placement="bottom"
                title="Delete Record"
              >
                <IconButton
                  onClick={handleAttendeeDelete}
                  className={classes.trashIconButton}
                  style={{
                    color: "red",
                  }}
                >
                  <TrashIcon className={classes.trashIcon} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        )}

        {props.presenter && (
          <Tooltip
            classes={{
              tooltip: classes.tooltip,
              popper: classes.tooltipPopper,
            }}
            placement="bottom"
            title="Delete Record"
          >
            <IconButton
              onClick={handleAttendeeDelete}
              className={`${classes.trashIconButton} ${classes.trashIconButtonPresenter}`}
              style={{
                color: "red",
              }}
            >
              <TrashIcon className={classes.trashIcon} />
            </IconButton>
          </Tooltip>
        )}

        <div
          className={
            props.presenter
              ? `${styles.allowEarlyAccessToggleHolder} ${styles.allowEarlyAccessToggleHolderPresenter}`
              : styles.allowEarlyAccessToggleHolder
          }
        >
          <FormControlLabel
            control={
              <Switch
                value={allowEarlyAccess}
                checked={allowEarlyAccess}
                onChange={handleAllowEarlyAccessChange}
                color="secondary"
                className={classes.allowEarlyAccessToggle}
              />
            }
            label="Allow Early Access"
            classes={{
              label: classes.toggleLabel,
            }}
          />
        </div>

        <React.Fragment>
          {error && (
            <Typography
              variant="body1"
              className={
                props.presenter
                  ? `${classes.error} ${classes.errorPresenter}`
                  : classes.error
              }
            >
              An error occurred editing this attendee
            </Typography>
          )}

          <div
            className={
              props.presenter
                ? `${styles.attendeeFormHolder} ${styles.attendeeFormHolderPresenter}`
                : styles.attendeeFormHolder
            }
          >
            <AttendeeForm
              handlePopupClose={props.handlePopupClose}
              handleAttendeeSubmit={handleAttendeeSubmit}
              isAddingAttendee={false}
              isLoading={loadingSpinner}
              emailError={emailError}
              tab={tab}
              presenter={props.presenter}
            />
          </div>

          {!props.presenter && (
            <div className={styles.brandToggleHolder}>
              <FormControlLabel
                control={
                  <Switch
                    checked={brand}
                    onChange={handleBrandChange}
                    color="secondary"
                    className={classes.brandToggle}
                  />
                }
                label="Brand"
                classes={{
                  label: classes.toggleLabel,
                }}
              />
            </div>
          )}
        </React.Fragment>

        {tab === 1 && (
          <React.Fragment>
            <div className={styles.linkHolder}>
              <Typography className={classes.linkHeader}>
                In-Room Access
              </Typography>

              {emailError && (
                <Typography className={classes.presenterEmailError}>
                  Please enter a valid email address for this presenter
                </Typography>
              )}

              <Typography
                className={classes.presenterLink}
                id="attendeeLink"
                variant="h1"
              >
                <span id="toCopy">
                  https://go.showboat.live/{selectedRegistration.loginCode}
                </span>
              </Typography>

              <div className={styles.presenterLinkButtonHolder}>
                <div>
                  <Tooltip
                    classes={{
                      tooltip: classes.tooltip,
                      popper: classes.tooltipPopper,
                    }}
                    placement="bottom"
                    title="Copy Intake Link"
                  >
                    <IconButton
                      color="primary"
                      className={classes.copyLinkButton}
                      onClick={(e) => handleCopyLinkClick(e, true)}
                    >
                      <LinkIcon />
                    </IconButton>
                  </Tooltip>
                </div>

                <div>
                  <Tooltip
                    classes={{
                      tooltip: classes.tooltip,
                      popper: classes.tooltipPopper,
                    }}
                    placement="bottom"
                    title="Open Link"
                  >
                    <IconButton
                      color="primary"
                      className={classes.copyLinkButton}
                      onClick={handleOpenLinkClick}
                    >
                      <OpenIcon />
                    </IconButton>
                  </Tooltip>
                </div>

                <div>
                  <Tooltip
                    classes={{
                      tooltip: classes.tooltip,
                      popper: classes.tooltipPopper,
                    }}
                    placement="bottom"
                    title="Resend Invite"
                  >
                    <IconButton
                      color="primary"
                      className={classes.resendInviteButton}
                      onClick={handleResendInviteClick}
                    >
                      <SendIcon />
                    </IconButton>
                  </Tooltip>
                </div>

                <div>
                  <Tooltip
                    classes={{
                      tooltip: classes.tooltip,
                      popper: classes.tooltipPopper,
                    }}
                    placement="bottom"
                    title="Regenerate Link"
                  >
                    <IconButton
                      color="primary"
                      className={classes.regenerateButton}
                      onClick={handleRegenerateLinkClick}
                      style={{
                        color: "red",
                      }}
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>

              <Divider className={classes.accessDivider} />
            </div>
            <div className={`${styles.linkHolder} ${styles.linkHolderBridge}`}>
              <Typography className={classes.linkHeader}>
                Bridge Access
              </Typography>

              <Switch
                className={classes.bridgeToggle}
                onChange={handleBridgeAccessChange}
                color="secondary"
                checked={bridgeAccess}
              />

              <Typography
                className={`${classes.presenterLink} ${classes.bridgeLink}`}
                id="bridgeLink"
                variant="h1"
              >
                <span id="toCopyBridge">
                  https://bridge.showboat.live/{selectedRegistration.loginCode}
                </span>
              </Typography>

              <div
                className={`${styles.presenterLinkButtonHolder} ${styles.presenterLinkButtonHolderBridge}`}
              >
                <div>
                  <Tooltip
                    classes={{
                      tooltip: classes.tooltip,
                      popper: classes.tooltipPopper,
                    }}
                    placement="bottom"
                    title="Copy Bridge Link"
                  >
                    <IconButton
                      color="primary"
                      className={classes.copyLinkButton}
                      onClick={handleCopyBridgeLinkClick}
                    >
                      <LinkIcon />
                    </IconButton>
                  </Tooltip>
                </div>

                <div>
                  <Tooltip
                    classes={{
                      tooltip: classes.tooltip,
                      popper: classes.tooltipPopper,
                    }}
                    placement="bottom"
                    title="Open Link"
                  >
                    <IconButton
                      color="primary"
                      className={classes.copyLinkButton}
                      onClick={(e) => handleOpenLinkClick(e, true)}
                    >
                      <OpenIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>

              <Divider className={classes.accessDivider} />
            </div>
          </React.Fragment>
        )}

        {tab === 2 && <BookingPresenterPermissions />}

        {regenerateAttendeeLinkPopup && (
          <ConfirmAttendeeLinkRegenerate
            isPresenter={props.presenter}
            handlePopupClose={handleRegenerateLinkPopupClose}
            open={regenerateAttendeeLinkPopup}
          />
        )}

        {/*  COPY TO CLIPBOARD SNACKBAR */}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          className={classes.copySnackbar}
          autoHideDuration={1500}
          open={copyToClipboard}
          onClose={handleCopyToClipboardClose}
          message={`${
            props.presenter ? "Presenter" : "Attendee"
          } Link Copied to Clipboard`}
        />

        {/*  BRIDGE COPY TO CLIPBOARD SNACKBAR */}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          className={classes.copySnackbar}
          autoHideDuration={1500}
          open={bridgeCopyToClipboard}
          onClose={handleBridgeCopyToClipboardClose}
          message={"Bridge link copied to clipboard"}
        />

        {/*  REGENERATE LINK SNACKBAR */}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          autoHideDuration={1500}
          className={classes.regenerateSnackbar}
          open={linkRegenerated}
          onClose={handleRegenerateLinkClose}
          message={`${
            props.presenter ? "Presenter" : "Attendee"
          } Event URL Regenerated`}
        />

        {/*  Resend invite POPUP */}
        {props.presenter ? (
          <PresenterResendInvitePopup
            handlePopupClose={handleResendInvitePopupClose}
            open={resendInvite}
          />
        ) : (
          <AttendeeResendInvitePopup
            handlePopupClose={handleResendInvitePopupClose}
            open={resendInvite}
          />
        )}

        {deleteAttendee && (
          <React.Fragment>
            {props.presenter ? (
              <DeletePresenterPopup
                handlePopupClose={handleDeleteAttendeePopupClose}
                handleDeleteCancel={handleDeleteCancel}
              />
            ) : (
              <AttendeeDeletePopup
                handlePopupClose={handleDeleteAttendeePopupClose}
                handleDeleteCancel={handleDeleteCancel}
                open={deleteAttendee}
              />
            )}
          </React.Fragment>
        )}

        {loadingSpinner && (
          <CircularProgress
            className={
              selectedBooking.intakeFields[5].type === "international"
                ? props.presenter
                  ? `${classes.loadingSpinner} ${classes.loadingSpinnerInternationalPresenter}`
                  : `${classes.loadingSpinner} ${classes.loadingSpinnerInternational}`
                : props.presenter
                ? `${classes.loadingSpinner} ${classes.loadingSpinnerPresenter}`
                : classes.loadingSpinner
            }
          />
        )}
      </div>
    </Dialog>
  );
}
