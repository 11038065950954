import React, { ReactElement } from 'react';
import { Typography, Button, Dialog, CircularProgress } from '@material-ui/core';

import PopupHeaderDelete from '../../../PopupHeaderDelete/PopupHeaderDelete';

import { DeleteBookingPopupStyles } from './DeleteBookingPopupStyles';

interface Props {
  open: boolean,
  handleBookingDelete: (bookingID: string) => any,
  handlePopupClose: () => any,
  bookingName: string,
  bookingID: string,
  isLoading: boolean,
  bookingDeleteError: boolean
}

export default function DeleteBookingPopup(props: Props): ReactElement {

  const handleYesButtonClick = () => {
    props.handleBookingDelete(props.bookingID);
  };

  const handleNoButtonClick = () => {
    if (props.isLoading) {
      return;
    }
    
    props.handlePopupClose();
  }
  
  //Material ui classes
  const classes = DeleteBookingPopupStyles();
  
  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={props.open}
    >
      <div className={classes.popup}>

        <PopupHeaderDelete
          closeFunction={props.handlePopupClose}
          headerText="Delete This Booking?"
          disableBoolean={props.isLoading}
        />

        <Typography variant="h1" className={classes.deleteBookingHeader}>
          There is no undo for booking delete.
        </Typography>

        {props.bookingDeleteError &&
          <Typography variant="body1" className={classes.error}>
            An error occurred deleting this booking
          </Typography>
        }

        {props.isLoading &&
          <CircularProgress className={classes.loadingSpinner } />
        }

        <Button 
          className={classes.noButton} 
          variant="contained" 
          color="primary"
          onClick={handleNoButtonClick}
        >
          CANCEL
        </Button>

        <Button 
          className={classes.yesButton} 
          variant="contained"
          onClick={handleYesButtonClick}
          disabled={props.isLoading}
        >
          CONFIRM
        </Button>
      </div>
    </Dialog>
  )
}
