import React, { ReactElement, useContext, useState } from 'react';
import { Button, CircularProgress, Dialog, TextField, Typography } from '@material-ui/core';
import axios from 'axios';

import { AppContext, AppContextType } from '../../../../context/AppContext';

import { RegistrationEmailEditStyles } from './RegistrationEmailEditStyles';
import PopupHeader from '../../../PopupHeader/PopupHeader';
import SaveButton from '../../../ui/SaveButton/SaveButton';
import CancelButton from '../../../ui/CancelButton/CancelButton';
import SaveLoadingSpinner from '../../../ui/SaveLoadingSpinner/SaveLoadingSpinner';

interface Props {
  open: boolean,
  handlePopupClose: () => any,
}

export default function RegistrationEmailEdit(props: Props): ReactElement {

  const { 
    selectedBooking, 
    setSelectedBooking 
  } : AppContextType = useContext(AppContext);

  const [ registrationNotificationEmailAddress, setRegistrationNotificationEmailAddress ] = useState(selectedBooking.registrationNotificationEmailAddress !== undefined 
    ? selectedBooking.registrationNotificationEmailAddress
    : ""
  );

  const [ formHasBeenSubmitted, toggleFormHasBeenSubmitted ] = useState(false);
  const [ error, toggleError ] = useState(false);
  const [ requiredError, toggleRequiredError ] = useState(false);

  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);

  const handleRegistrationNotificationChange = (e) => {
    
    toggleRequiredError(false);
    
    setRegistrationNotificationEmailAddress(e.target.value);
  };

  const handleSaveButtonClick = async () => {

    toggleFormHasBeenSubmitted(true);

    toggleRequiredError(false);

    toggleLoadingSpinner(true);
    
    let selectedBookingClone = selectedBooking;

    let emailAddressLocal = registrationNotificationEmailAddress;

    //Check for empty
    if (!registrationNotificationEmailAddress.trim().length) {
      emailAddressLocal = "";
    }

    selectedBookingClone.registrationNotificationEmailAddress = emailAddressLocal;

    try {
      await axios.put("/booking", JSON.stringify(selectedBookingClone));

      toggleLoadingSpinner(false);
      setSelectedBooking(selectedBookingClone);
      props.handlePopupClose();
    }
    catch {
      toggleLoadingSpinner(false);
      toggleError(true);
    }
    
  }

  const handleCancelClick = () => {

    if (loadingSpinner) {
      return;
    }
    
    props.handlePopupClose();
  }

  const classes = RegistrationEmailEditStyles();

  return (
    <Dialog
      open={props.open}
      classes={{ paper: classes.dialogPaper }}
    >
      <div className={classes.popup}>

        <PopupHeader 
          closeFunction={props.handlePopupClose}        
          disableBoolean={loadingSpinner}
        />

        <Typography className={classes.editErrorMessageHeader}>
          Registration Notification Email Address
        </Typography>

        <Typography className={`${classes.editErrorMessageHeader} ${classes.editErrorMessageSubheader}`}>
          This email address will be notified whenever someone registers for this event.
        </Typography>

        {error &&
          <Typography variant="body1" className={classes.error}>
            An error occurred updating the notification email address for this event.
          </Typography>
        }

        <TextField
          className={classes.errorMessageTextField}
          label=""
          variant="outlined"
          error={formHasBeenSubmitted && requiredError}
          value={registrationNotificationEmailAddress}
          onChange={handleRegistrationNotificationChange}
          InputProps={{
            className: classes.errorMessageInput
          }}
        />

        {loadingSpinner &&
          <SaveLoadingSpinner />
        }
        
        <CancelButton
          onClick={handleCancelClick}
          variant="contained"
        />

        <SaveButton 
          disableBoolean={loadingSpinner}
          onClick={handleSaveButtonClick}
        />
      </div>
    </Dialog>
  )
}
