import { IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React, { ReactElement } from 'react';


import styles from './EmailSkinnableItem.module.css';
import { EmailSkinnableItemStyles } from './EmailSkinnableItemStyles';

interface Props {
  type: string,
  value: string,
  label: string,
  property: string,
  division: string,
  assetFamily: any,
  dimensions: {},
  handleShowUploadPopup: (label: string, type: string, property: string, assetFamily: any, division: string, dimensions: {}) => any,
  handleShowPreviewPopup: (previewObj : PreviewObject) => any
}

interface PreviewObject {
  title: string,
  property: string,
  value: string,
  type: string,
  division: string
}

export default function EmailSkinnableItem(props: Props): ReactElement {

  /* Upload */
  const handleUploadClick = () => {
    props.handleShowUploadPopup(props.label, props.type, props.property, props.assetFamily, props.division, props.dimensions);
  }
  /**/
  
  /* Preview */
  const handlePreviewClick = () => {

    let previewObj = {
      title: props.label,
      type: props.type,
      property: props.property,
      value: props.value,
      division: props.division
    };

    props.handleShowPreviewPopup(previewObj as PreviewObject);
  }
  /**/
  
  const classes = EmailSkinnableItemStyles();
  
  if (props.type === "Image") {
    return (
      <div className={styles.itemHolder}>
        <Typography variant="body1" className={classes.itemHeader}>
          {props.label}
        </Typography>

        <IconButton 
          onClick={handlePreviewClick} 
          className={classes.webAssetIconButton}
        >
          <img 
            src="assets/images/PreviewIcon.svg" 
            alt="Preview icon" 
            className={styles.previewIcon}          
          />
        </IconButton>

        <IconButton 
          onClick={handleUploadClick} 
          className={classes.uploadButton} 
        >
          <img 
            src="assets/images/UploadIcon.svg" 
            alt="Upload icon" 
            className={styles.uploadIcon}
          />
        </IconButton>
      </div>
    )
  }
}
