import { makeStyles, createStyles } from '@material-ui/core/styles';

const CancelButtonStyles = makeStyles((theme: any) =>
  createStyles({
    cancelButton: {
      height: 44,
      width: 95,
      position: "absolute",
      right: 141,
      bottom: 30,
    },
  })
);

export { CancelButtonStyles };