import {
  Button,
  CircularProgress,
  Dialog,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { ReactElement, useState, useContext } from "react";
import axios from "axios";
import moment from "moment-timezone";

import { AppContext, AppContextType } from "../../../context/AppContext";
import PopupHeader from "../../PopupHeader/PopupHeader";
import { JWTHelper } from "../../../utilities/JWTHelper";

import { AddBookingPopupStyles } from "./AddBookingPopupStyles";
import SaveButton from "../../ui/SaveButton/SaveButton";
import CancelButton from "../../ui/CancelButton/CancelButton";
import SaveLoadingSpinner from "../../ui/SaveLoadingSpinner/SaveLoadingSpinner";

interface Props {
  open: boolean;
  handlePopupClose: () => any;
}

export default function AddBookingPopup(props: Props): ReactElement {
  const {
    setSelectedBookingUsage,
    setSelectedBooking,
    setSelectedBookingID,
    selectedSpaceID,
  }: AppContextType = useContext(AppContext);

  const [bookingName, setBookingName] = useState("");
  const [nameRequiredError, toggleNameRequiredError] = useState(false);
  const [error, toggleError] = useState(false);
  const [loadingSpinner, toggleLoadingSpinner] = useState(false);
  const [formHasBeenSubmitted, toggleFormHasBeenSubmitted] = useState(false);

  //Booking name change handler
  const handleBookingNameChange = (e: any) => {
    toggleNameRequiredError(false);

    setBookingName(e.target.value);
  };

  //Booking submit handler
  const handleSaveButtonClick = async () => {
    toggleError(false);

    toggleFormHasBeenSubmitted(true);

    if (!bookingName.trim().length) {
      toggleNameRequiredError(true);
      return;
    }

    toggleLoadingSpinner(true);

    toggleNameRequiredError(false);

    //Round current time up/down to nearest hour for start and end times
    let startTime = moment().startOf("hour").format();
    let endTime =
      moment().minute() || moment().second() || moment().millisecond()
        ? moment().add(1, "hour").startOf("hour").format()
        : moment().startOf("hour").format();

    try {
      //Post new booking
      let response = await axios.post("/booking", {
        spaceID: selectedSpaceID,
        name: bookingName,
        start: {
          time: startTime,
          timezone: moment.tz.guess(),
          timeValue: moment.utc(startTime).valueOf(),
        },
        end: {
          time: endTime,
          timezone: moment.tz.guess(),
          timeValue: moment.utc(endTime).valueOf(),
        },
        capacity: 20,
        gracePeriodMinutes: 0,
        accessTime: moment().utc().startOf("hour").format(),
        doorsOpen: moment(startTime).subtract("30", "minutes").format(),
        doorsClosed: moment(endTime).add("30", "minutes").format(),
      });

      toggleLoadingSpinner(false);
      setSelectedBookingUsage(null);
      setSelectedBooking(JSON.parse(response.data));
      setSelectedBookingID(JSON.parse(response.data).bookingID);

      //Create JWT with new bookingID
      JWTHelper.createJWT(selectedSpaceID, JSON.parse(response.data).bookingID);

      window.history.pushState(
        null,
        "Title",
        `/booking/${JSON.parse(response.data).bookingID}`
      );

      props.handlePopupClose();
    } catch (error) {
      toggleLoadingSpinner(false);

      toggleError(true);
    }
  };

  //Cancel add booking handler
  const handleCancelButtonClick = () => {
    //If we are already loading a request, do nothing
    if (loadingSpinner) return;

    props.handlePopupClose();
  };

  const classes = AddBookingPopupStyles();

  return (
    <Dialog open={props.open} classes={{ paper: classes.dialogPaper }}>
      <div className={classes.popup}>
        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={loadingSpinner}
        />

        <Typography variant="h1" className={classes.createBookingHeader}>
          Name Booking
        </Typography>

        {error && (
          <Typography variant="body1" className={classes.error}>
            An error occurred creating this booking
          </Typography>
        )}

        <TextField
          variant="outlined"
          value={bookingName}
          onChange={handleBookingNameChange}
          inputProps={{
            maxLength: 70,
          }}
          helperText="Required"
          className={classes.bookingNameInput}
          placeholder="Booking Name"
          error={formHasBeenSubmitted && nameRequiredError}
        />

        {loadingSpinner && (
          <SaveLoadingSpinner />
        )}

        <CancelButton 
          onClick={handleCancelButtonClick}
          variant="text"
        />

        <SaveButton
          disableBoolean={loadingSpinner}
          onClick={handleSaveButtonClick}
        />
      </div>
    </Dialog>
  );
}
