import { makeStyles, createStyles } from '@material-ui/core/styles';

const MiscellaneousDisplayStyles = makeStyles((theme: any) =>
  createStyles({
    miscellaneousHeader: {
      left: 30,
      position: "absolute",
      top: 0,
      fontWeight: 400,
      fontSize: 16,
    },
    capacityHeader: {
      position: "absolute",
      fontSize: 14,
      left: 30,
      fontWeight: "bold",
    },
    capacityHeaderPrivateCloud: {
      top: 5,
    },
    capacityHeaderGameServer: {
      left: 395,
    },
    graceTimeHeader: {
      fontSize: 16,
      left: 30,
      fontWeight: 400,
      position: "absolute",
    },
    userHoursHeader: {
      fontSize: 14,
      left: 30,
      fontWeight: "bold",
      position: "relative",
      top: 17,
    },
    userHoursHeaderQADisplay: {
      top: 15,
    },
    capacity: {
      fontSize: 14,
      position: "absolute",
      left: 191,
    },
    graceTime: {
      fontSize: 14,
      position: "absolute",
      left: 191,
    },
    userHours: {
      fontSize: 14,
      position: "relative",
      left: 191,
      bottom: 9,
      display: "inline-block",
    },
    editButton: {
      position: "relative",
      left: 28,
      top: -2,
      padding: 4,
      color: "black",
      "&:hover": {
        cursor: "pointer",
      },
    },
    editIcon: {
      fontSize: 20,
    },
    editButtonGraceTime: {
      position: "absolute",
      left: 290,
      top: -9,
      padding: 4,
      color: "black",
    },
    editButtonCapacity: {
      left: "92.4%",
      padding: 4,
      position: "relative",
      width: 28,
    },
    editIconGraceTime: {
      fontSize: 20,
    },
    usageLoadingSpinner: {
      color: "grey",
      width: "20px !important",
      height: "20px !important",
      position: "absolute",
      bottom: 15,
      left: 190,
    },
    refreshIcon: {
      color: "grey",
      fontSize: 22,
      padding: 4,
      position: "relative",
      "&:hover": {
        cursor: "pointer",
      },
      top: -1,
      left: 15,
    },
    refreshButtonIcon: {
      fontSize: 20,
      padding: 4,
    },
    refreshIconLoading: {
      left: 30,
    },
    addToCalendarSwitch: {
      right: 17,
      position: "absolute",
      bottom: 5,
    },
    divider: {
      position: "absolute",
      left: 31,
      width: "92%",
      bottom: 0,
    },
    dividerPrivateCloud: {
      bottom: -80,
    },
    privateCloudLoadingSpinner: {
      width: "30px !important",
      height: "30px !important",
      position: "absolute",
      right: 384,
      top: 44,
    },
    privateCloudSwitch: {
      color: "#FF0092",
      bottom: -15,
    },
    calendarEntryLoadingSpinner: {
      width: "20px !important",
      height: "20px !important",
      position: "absolute",
      right: 90,
      top: 12,
    },
    calendarName: {
      fontSize: 14,
      position: "relative",
      left: 191,
      bottom: -1,
    },
    liveswitchServerSelect: {
      minWidth: 200,
      left: 30,
      top: 40,
      fontSize: 14,
    },
    gameServerSelect: {
      position: "absolute",
      minWidth: 200,
      left: 195,
      top: 40,
      fontSize: 14,
    },
    menuItem: {
      fontSize: 14,
    },
    qaSwitch: {
      left: 182,
      bottom: 8,
    },
    qaLoadingSpinner: {
      color: "grey",
      width: "20px !important",
      height: "20px !important",
      position: "absolute",
      bottom: 12,
      left: 260,
    },
    downloadIcon: {
      color: "black",
      position: "relative",
      top: -9,
      "&:hover": {
        cursor: "pointer",
      },
    },
    qaDisplayLink: {
      fontSize: 14,
      position: "absolute",
      left: 191,
      color: "black !important",
    },
    qaDisplayCopy: {
      position: "relative",
      padding: 4,
      width: 28,
      left: "92.4%",
      bottom: 9,
    },
    snackbar: {
      width: 360,
    },
  })
);

export { MiscellaneousDisplayStyles };