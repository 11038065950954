import { Button, Dialog, Typography } from "@material-ui/core";
import React, { ReactElement } from "react";
import PopupHeader from "../../../../PopupHeader/PopupHeader";
import { PermissionsHelpPopupStyles } from "./PermissionsHelpPopupStyles";
import HelpIcon from "@material-ui/icons/InfoOutlined";
import { StringHelper } from "../../../../../utilities/StringHelper";

interface Props {
  title: string;
  value: string;
  handlePopupClose: () => void;
}

export default function PermissionsHelpPopup(props: Props): ReactElement {
  const handlePopupClose = () => {
    props.handlePopupClose();
  };

  const classes = PermissionsHelpPopupStyles();

  return (
    <Dialog
      open={true}
      classes={{
        paper:
          props.value === StringHelper.PresenterPermissions.canShareWebcam
            ? `${classes.dialogPaper} ${classes.dialogPaperWebcam}`
            : classes.dialogPaper,
      }}
    >
      <div
        className={
          props.value === StringHelper.PresenterPermissions.canShareWebcam
            ? `${classes.popup} ${classes.popupWebcam}`
            : classes.popup
        }
      >
        <PopupHeader
          closeFunction={handlePopupClose}
          disableBoolean={false}
          text={props.title}
          icon={<HelpIcon style={{ fill: "white" }} />}
          hideClose={true}
        />

        {StringHelper.PermissionsHelpText[props.value].map((line) => {
          return (
            <Typography
              variant="body1"
              key={line}
              className={classes.permissionsSubtext}
            >
              {line}
            </Typography>
          );
        })}

        <Button
          className={classes.closeButton}
          onClick={handlePopupClose}
          color="primary"
          variant="contained"
        >
          CLOSE
        </Button>
      </div>
    </Dialog>
  );
}
