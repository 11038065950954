import React, { ReactElement, useState, useContext } from 'react';
import axios from 'axios';
import CloseIcon from "@material-ui/icons/Close";
import {
  Typography,
  Button,
  Dialog,
  CircularProgress,
  IconButton,
} from "@material-ui/core";

import { AppContext, AppContextType } from '../../../../context/AppContext';
import styles from "./PresenterResendInvitePopup.module.css";

import { PresenterResendInvitePopupStyles } from './PresenterResendInviteStyles';

interface Props {
  open: boolean,
  handlePopupClose: () => any,
}

export default function PresenterResendInvitePopup(props: Props): ReactElement {

  const { 
    selectedPresenter, 
    selectedBooking, 
    selectedSpace 
  } : AppContextType = useContext(AppContext);

  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);

  //Resend invite handler
  const handleResendInvite = async () => {

    toggleLoadingSpinner(true);

    //Get matching invite email template
    let inviteEmailTemplate;

    for (var key in selectedBooking.emailTemplates) {
      if (selectedBooking.emailTemplates[key].name === "Presenter Invite Link") {
        inviteEmailTemplate = selectedBooking.emailTemplates[key];
      };
    };
    
    let toSendArr = [];
    toSendArr.push(selectedPresenter);

    try {
      await axios.post("/email-parse-request", {
        bookingID: selectedBooking.bookingID,
        spaceID: selectedSpace.spaceID,
        emailTemplateID: inviteEmailTemplate.id,
        toSend: toSendArr
      });

      toggleLoadingSpinner(false);
      props.handlePopupClose();
    }
    catch (error) {
      toggleLoadingSpinner(false);
      console.log(error);
    }

  }
  //Cancel handler
  const handleCancelResendInvite = () => {

    if (loadingSpinner) {
      return;
    }
    
    props.handlePopupClose();
  }

  //Material UI classes
  const classes = PresenterResendInvitePopupStyles();

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper}}
      open={props.open}
    >
      <div className={classes.popup}>

        <div className={styles.popupHeader}>
          <IconButton 
            onClick={handleCancelResendInvite}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <Typography variant="body1" className={classes.resendInviteHeader}>
          Send Invite to:
        </Typography>
        <Typography variant="body1" className={classes.resendInviteName}>
          {selectedPresenter.registrationData.firstName} {selectedPresenter.registrationData.lastName}
        </Typography>

        {loadingSpinner &&
          <CircularProgress className={classes.loadingSpinner} />
        }

        <Button 
          className={classes.sendButton} 
          variant="contained" 
          color="primary"
          onClick={handleResendInvite}
          disabled={loadingSpinner}
        >
          SEND
        </Button>
      </div>
    </Dialog>
    
  )
}
