import { CircularProgress } from '@material-ui/core'
import React, { ReactElement } from 'react'
import { UploadLoadingSpinnerStyles } from './UploadLoadingSpinnerStyles'

export default function UploadLoadingSpinner(): ReactElement {

  const classes = UploadLoadingSpinnerStyles();
  
  return (
    <CircularProgress className={classes.loadingSpinner} />
  )
}
