import React, { useState, useContext, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  Redirect,
} from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

import { AppContext } from "./context/AppContext";
import { AuthContext } from "./context/AuthContext";
import ProjectsAdmin from "./Pages/ProjectsAdmin/ProjectsAdmin";
import AdminMenu from "./Pages/AdminMenu/AdminMenu";
import BookingsAdmin from "./Pages/BookingsAdmin/BookingsAdmin";
import UsageAdmin from "./Pages/UsageAdmin/UsageAdmin";
import SystemCheckAdmin from "./Pages/SystemCheckAdmin/SystemCheckAdmin";
import LoginCodeMenu from "./Pages/LoginCodeMenu/LoginCodeMenu";
import CacheAdmin from "./Pages/CacheAdmin/CacheAdmin";
import ErrorPage from "./Pages/ErrorPage/ErrorPage";
import LoadingSpinner from "./Pages/LoadingSpinner/LoadingSpinner";
import { JWTHelper } from "./utilities/JWTHelper";
import Login from "./Pages/Login/Login";
import Main from "./Pages/Main/Main";
import SpaceAdmin from "./Pages/SpaceAdmin/SpaceAdmin";
import ProducerAdminPage from "./Pages/ProducerAdminPage/ProducerAdminPage";
import { AuthHelper } from "./utilities/AuthHelper";

import "./App.css";
import SpaceWorldsAdmin from "./Pages/SpaceWorldsAdmin/SpaceWorldsAdmin";
import SpaceUpdateAdmin from "./Pages/SpaceUpdateAdmin/SpaceUpdateAdmin";

export default function App(props) {
  const {
    producerID,
    setProducerID,
    setSelectedSpace,
    setSelectedSpaceID,
    setSelectedProject,
    setSelectedBooking,
    setSelectedBookingID,
  } = useContext(AppContext);

  const { setIdToken } = useContext(AuthContext);

  //Assemble URL Path
  let urlPath = window.location.pathname;

  const [spaceLink, toggleSpaceLink] = useState(
    urlPath.split("/").includes("space")
  );
  const [bookingLink, toggleBookingLink] = useState(
    urlPath.split("/").includes("booking")
  );
  const [render, toggleRender] = useState(false);
  const [isLoadingContent, toggleIsLoadingContent] = useState(false);
  const [authenticationError, toggleAuthenticationError] = useState(false);
  const [error, toggleError] = useState(false);
  const [isAuthenticated, toggleIsAuthenticated] = useState(false);

  const queryCount = useRef(0);

  useEffect(() => {
    //Add listener for back button clicks
    window.addEventListener("popstate", handleBackButtonClick);

    let urlPathArray = urlPath.split("/");

    //Check for "/space" in the URL
    if (urlPathArray.includes("space")) {
      sessionStorage.setItem("spaceLink", true);
      sessionStorage.setItem("linkSpaceID", urlPathArray[2]);
      toggleSpaceLink(true);
    }

    //Check for "/booking" in the URL
    if (urlPathArray.includes("booking")) {
      sessionStorage.setItem("bookingLink", true);
      sessionStorage.setItem("linkBookingID", urlPathArray[2]);
      toggleBookingLink(true);
    }

    //Check authentication
    let isProducerAuthenticated = checkAuthentication();

    toggleIsAuthenticated(isProducerAuthenticated);

    if (urlPath.split("/").includes("error")) {
      toggleError(true);
      toggleRender(true);
      return;
    }

    //If we are authenticated and coming from space or booking link,
    //show general loader and fetch content
    if (
      isProducerAuthenticated &&
      spaceOrBookingLinkCheck() &&
      !urlPathArray.includes("error")
    ) {
      JWTHelper.createJWT(urlPathArray[2], "");

      toggleIsLoadingContent(true);

      //Ensure sessionStorage variables are set
      setSessionStorageVariables(spaceLink);

      const getBookingOrSpaceInUseEffect = async () => {
        await getBookingOrSpaceObject(
          urlPathArray.includes("booking"),
          localStorage.getItem("producerID")
        );
      };
      getBookingOrSpaceInUseEffect();
    }

    //If we are authenticated, add axios interceptor to refresh token on every request
    if (isAuthenticated) {
      JWTHelper.addAxiosInterceptor();
    }

    //Show app
    toggleRender(true);

    return function cleanup() {
      window.removeEventListener("popstate", handleBackButtonClick);
    };
  }, [isAuthenticated]);

  const getBookingOrSpaceObject = async (
    isBooking,
    producerIDParam,
    spaceOrBookingID
  ) => {
    //Ensure query only runs once
    if (queryCount.current === 1) return;
    queryCount.current = 1;

    let params;

    //Assemble parameters object
    if (isBooking) {
      params = {
        bookingID: spaceOrBookingID ? spaceOrBookingID : urlPath.split("/")[2],
        producerID: producerIDParam,
      };
    } else {
      params = {
        spaceID: spaceOrBookingID ? spaceOrBookingID : urlPath.split("/")[2],
        producerID: producerIDParam,
      };
    }

    try {
      let bookingOrSpaceResponse = await axios.get(
        isBooking ? "/booking" : "/space",
        {
          params: params,
        }
      );

      //If a JWT was sent back, store it
      if (bookingOrSpaceResponse.data.jwt) {
        JWTHelper.storeJWT(bookingOrSpaceResponse.data.jwt);
      }

      //If space request was made, set the space as selected
      if (!isBooking) {
        setSelectedSpace(bookingOrSpaceResponse.data.space);
        setSelectedSpaceID(bookingOrSpaceResponse.data.space.spaceID);

        //Legacy check for project
        if (bookingOrSpaceResponse.data.space.project) {
          setSelectedProject(bookingOrSpaceResponse.data.space.project);
        } else {
          await getProject(bookingOrSpaceResponse.data.space.projectID);
        }
      } else {
        //A booking was gotten, so get space associated with booking
        let spaceID = bookingOrSpaceResponse.data.booking.spaceID;

        await getSpace(spaceID);

        setSelectedBooking(bookingOrSpaceResponse.data.booking);
        setSelectedBookingID(bookingOrSpaceResponse.data.booking.bookingID);
      }

      //Navigate to booking/space URL, but only if not arrived through the back button
      if (sessionStorage.getItem("backPressed") === null) {
        window.history.pushState(
          null,
          "Title",
          isBooking
            ? `/booking/${bookingOrSpaceResponse.data.booking.bookingID}`
            : `/space/${bookingOrSpaceResponse.data.space.spaceID}`
        );
      } else {
        sessionStorage.removeItem("backPressed");
      }

      toggleIsLoadingContent(false);
    } catch (err) {
      console.log("Error", err);

      toggleError(true);
      //If 401 is returned, we are unauthorized. So, show sign in form
      //Otherwise, show invalid link page
      if (err.response && err.response.status !== 401) {
        window.history.pushState(null, "Title", "/error");
      } else {
        //Means we are not actually authenticated. So, sign out user and redirect
        AuthHelper.signOutUser();
        toggleAuthenticationError(true);
        //Show sign-in form
        window.history.pushState(null, "Title", "/error");
      }

      toggleIsLoadingContent(false);
    }
  };

  const spaceOrBookingLinkCheck = () => {
    return (
      urlPath.split("/").includes("booking") ||
      urlPath.split("/").includes("space")
    );
  };

  //Check for authentication
  const checkAuthentication = () => {
    if (localStorage.getItem("producerID") !== null) {
      setProducerID(localStorage.getItem("producerID"));
    } else {
      return false;
    }

    if (localStorage.getItem("idToken") !== null) {
      //Ensure idToken is refreshed
      var poolData = {
        UserPoolId: "us-east-1_N15Q0NLkm",
        ClientId: "2332rbhi35f5016dglri2mojo",
      };

      const userPool = new CognitoUserPool(poolData);

      let currentUser = userPool.getCurrentUser();

      //Refresh token
      if (currentUser) {
        currentUser.getSession(async (err, session) => {
          if (err) {
            return false;
          } else {
            let idToken = session.getIdToken().getJwtToken();
            setIdToken(idToken);
            axios.defaults.headers.common["Authorization"] = idToken;

            //Set refresh token interval (10 minutes)
            setInterval(function () {
              var poolData = {
                UserPoolId: "us-east-1_N15Q0NLkm",
                ClientId: "2332rbhi35f5016dglri2mojo",
              };

              const userPool = new CognitoUserPool(poolData);

              let currentUser = userPool.getCurrentUser();

              if (currentUser) {
                currentUser.getSession((err, session) => {
                  if (err) {
                    alert("Your session has expired. Please sign in again.");
                    window.history.pushState(null, "Title", "/");
                  } else {
                    setIdToken(session.getIdToken().getJwtToken());
                  }
                });
              } else {
                alert("Your session has expired. Please sign in again.");
                window.history.pushState(null, "Title", "/");
              }
            }, 600000);

            return true;
          }
        });
      } else {
        return false;
      }
    } else {
      return false;
    }

    return true;
  };

  const setSessionStorageVariables = (fromSpaceLink) => {
    if (fromSpaceLink) {
      sessionStorage.setItem("spaceLink", "true");
    } else {
      sessionStorage.setItem("bookingLink", "true");
    }
  };

  const handleAuthenticate = () => {
    toggleIsAuthenticated(true);
  };

  const getProject = async (projectID) => {
    try {
      let projectResponse = await axios.get("/project", {
        params: {
          projectID: projectID,
        },
      });

      setSelectedProject(projectResponse.data);
    } catch (err) {
      //TODO: TODO: TODO:
      console.log("ERR");
    }
  };

  const getSpace = async (spaceID) => {
    try {
      let response = await axios.get("/space", {
        params: {
          spaceID: spaceID,
          producerID: producerID,
        },
      });

      let responseObj = response.data;

      //Legacy check for project
      if (responseObj.space.project) {
        setSelectedProject(responseObj.space.project);
      } else {
        await getProject(responseObj.space.projectID);
      }

      setSelectedSpace(responseObj.space);
      setSelectedSpaceID(responseObj.space.spaceID);
    } catch (err) {
      console.log("err");
    }
  };

  const handleErrorLinkClick = () => {
    toggleError(false);
  };

  const handleBackButtonClick = async (e) => {
    sessionStorage.setItem("backPressed", "true");

    //Check if back location contains booking or space
    let newPathName = e.currentTarget.location.pathname;
    if (
      newPathName.split("/").includes("booking") ||
      newPathName.split("/").includes("space")
    ) {
      let bookingLinkBack = newPathName.split("/").includes("booking");
      let spaceLinkBack = newPathName.split("/").includes("space");

      //Ensure API call count is at 0
      queryCount.current = 0;

      if (bookingLinkBack) {
        sessionStorage.setItem("bookingLink", "true");
      } else {
        sessionStorage.removeItem("bookingLink");
      }

      if (spaceLinkBack) {
        setSelectedBooking(null);
        sessionStorage.setItem("spaceLink", "true");
      } else {
        sessionStorage.removeItem("spaceLink");
      }

      //Check authentication first
      let isAuthenticated = checkAuthentication();

      if (!isAuthenticated) {
        //Send to login
        alert("Please log in to see this page");
        window.location.replace("/");
        return;
      }

      //We are authenticated, so go ahead and query for the booking
      let backButtonContentID = newPathName.split("/")[2];

      //Show loader and get content
      toggleIsLoadingContent(true);
      await getBookingOrSpaceObject(
        bookingLinkBack,
        localStorage.getItem("producerID"),
        backButtonContentID
      );
    } else if (newPathName.split("/").includes("home")) {
      //Going back to home, so reload page
      window.location.replace("/home");
    }
  };

  /* Material UI Theme */
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#868686",
      },
      secondary: {
        main: "#FF0092",
      },
    },
    breakpoints: {
      values: {
        lg: 1281,
        md: 960,
      },
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "*": {
            "scrollbar-width": "thin",
          },
          "*::-webkit-scrollbar": {
            width: "4px",
            height: "4px",
          },
        },
      },
    },
  });
  /**/

  if (render) {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={theme}>
          <Router>
            <div className="app" id="app">
              {isAuthenticated ? (
                /* Show authenticated set of routes */
                <React.Fragment>
                  {isLoadingContent ? (
                    <LoadingSpinner />
                  ) : (
                    <React.Fragment>
                      {error ? (
                        <ErrorPage
                          errorText="Unable to find a page using the link provided."
                          handleLinkClick={handleErrorLinkClick}
                        />
                      ) : (
                        <Switch>
                          <Route
                            exact
                            path={["/space/:spaceId", "/booking/:bookingId"]}
                          >
                            <Main
                              spacePicker={false}
                              spaceLink={urlPath.split("/").includes("space")}
                              bookingLink={urlPath
                                .split("/")
                                .includes("booking")}
                            />
                          </Route>
                          <Route exact path="/home">
                            <Main spacePicker={true} />
                          </Route>
                          <Route exact path="/space-admin">
                            <SpaceAdmin />
                          </Route>
                          <Route exact path="/producer-admin">
                            <ProducerAdminPage />
                          </Route>
                          <Route exact path="/bookings-admin">
                            <BookingsAdmin />
                          </Route>
                          <Route exact path="/usage-admin">
                            <UsageAdmin />
                          </Route>
                          <Route exact path="/projects-admin">
                            <ProjectsAdmin />
                          </Route>
                          <Route exact path="/admin-menu">
                            <AdminMenu />
                          </Route>
                          <Route exact path="/login-code-admin">
                            <LoginCodeMenu />
                          </Route>
                          <Route exact path="/cache-admin">
                            <CacheAdmin />
                          </Route>
                          <Route exact path="/space-worlds">
                            <SpaceWorldsAdmin />
                          </Route>
                          <Route exact path="/space-update">
                            <SpaceUpdateAdmin />
                          </Route>
                          <Route exact path="/system-check-admin">
                            <SystemCheckAdmin />
                          </Route>
                          <Route exact path="/">
                            {authenticationError ? (
                              <Login
                                fromSpaceLink={false}
                                fromBookingLink={false}
                                handleAuthenticate={handleAuthenticate}
                              />
                            ) : (
                              <Redirect to="/home" />
                            )}
                          </Route>
                          <Route path="*">
                            <ErrorPage errorText="Unable to find a page using the link provided." />
                          </Route>
                          <Route exact path="/error">
                            <ErrorPage errorText="Unable to find a page using the link provided." />
                          </Route>
                        </Switch>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                /*  We are unauthenticated, so show login form */
                <Switch>
                  <Route
                    exact
                    path={["/", "/space/:spaceId", "/booking/:bookingId"]}
                  >
                    <Login
                      fromSpaceLink={spaceLink}
                      fromBookingLink={bookingLink}
                      handleAuthenticate={handleAuthenticate}
                    />
                  </Route>
                  <Route path="*">
                    <ErrorPage errorText="Unable to find a page using the link provided." />
                  </Route>
                  <Route exact path="/error">
                    <ErrorPage errorText="Unable to find a page using the link provided." />
                  </Route>
                </Switch>
              )}
            </div>
          </Router>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    );
  } else {
    return null;
  }
}
