import { makeStyles, createStyles } from '@material-ui/core/styles';

const PermissionsHelpPopupStyles = makeStyles((theme: any) =>
  createStyles({
    dialogPaper: {
      width: 361,
      height: 268,
      display: "inline-block",
      margin: 0,
      overflowX: "hidden",
      overflowY: "auto",
    },
    dialogPaperWebcam: {
      height: 315,
    },  
    popup: {
      width: "100%",
      height: 268,
      position: "absolute",
    },
    popupWebcam: {
      height: 315,
    },
    permissionsHelpHeader: {
      fontSize: 20,
    },
    permissionsSubtext: {
      fontSize: 16,
      fontWeight: 400,
      width: 300,
      top: 88,
      left: 30,
      position: "relative",
      marginBottom: 10,
    },
    closeButton: {
      position: "absolute",
      padding: 0,
      width: 114, 
      height: 44,
      bottom: 24,
      right: 29
    }
  })
);

export { PermissionsHelpPopupStyles };