import { Dialog, IconButton, Typography } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { orderBy } from 'natural-orderby';

import { AttendeeUsageHelper } from '../../../../utilities/AttendeeUsageHelper';
import { AttendeeDetailsPopupStyles } from './AttendeeDetailsPopupStyles';

import styles from './AttendeeDetailsPopup.module.css';
import MatchingAttendeeRecord from '../MatchingAttendeeRecord/MatchingAttendeeRecord';

interface Props {
  open: boolean,
  attendeeRecord: any,
  handlePopupClose: () => any,
  matchingRecords: any,
  isSuperAdmin: boolean
}

export default function AttendeeDetailsPopup(props: Props): ReactElement {

  const handleCloseButtonClick = () => {
    props.handlePopupClose();
  }

  const classes = AttendeeDetailsPopupStyles();

  let matchingAttendeeRecords = props.matchingRecords;

  //Order matching attendee records by first/last name
  let matchingRecordsOrdered = orderBy(
    matchingAttendeeRecords,
    [r => (r as any).intakeData.lastName, r => (r as any).intakeData.firstName],
    ['asc', 'asc']
  );

  let matchingRecordsComponentArray = [];

  for (let i = 0; i < matchingRecordsOrdered.length; i++) {
    matchingRecordsComponentArray.push(
      <MatchingAttendeeRecord 
        key={(matchingRecordsOrdered[i] as any).attendeesID} 
        matchingRecord={matchingRecordsOrdered[i]}
      />
    )
  }

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} open={props.open}>
      <div className={classes.popup}>
        <div className={styles.popupHeader}>
          <IconButton
            onClick={handleCloseButtonClick}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </div>

        {/* If we are a superAdmin, display the socketID */}
        {props.isSuperAdmin && (
          <React.Fragment>
            <Typography variant="body1" className={classes.attendeeSocketID}>
              {props.attendeeRecord.socketID}
            </Typography>

            <Typography variant="body1" className={classes.attendeeAttendeesID}>
              {props.attendeeRecord.attendeesID}
            </Typography>
          </React.Fragment>
        )}

        <Typography variant="h1" className={classes.attendeeNameHeader}>
          Login Activity
        </Typography>

        <div className={styles.tableHeaderHolder}>
          <Typography
            className={`${classes.tableHeader} ${classes.tableHeaderName}`}
          >
            Name
          </Typography>
          <Typography
            className={`${classes.tableHeader} ${classes.tableHeaderIn}`}
          >
            In
          </Typography>
          <Typography
            className={`${classes.tableHeader} ${classes.tableHeaderOut}`}
          >
            Out
          </Typography>
          <Typography
            className={`${classes.tableHeader} ${classes.tableHeaderTime}`}
          >
            Time
          </Typography>
        </div>

        <div className={styles.tableHolder}>
          {matchingRecordsComponentArray.map((item) => {
            return item;
          })}
        </div>
      </div>
    </Dialog>
  );
}
