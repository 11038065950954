import { Divider, IconButton, Typography, Snackbar, CircularProgress } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import React, { ReactElement, useContext, useState } from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '@material-ui/icons/Edit';
import TrashIcon from '@material-ui/icons/Delete';
import axios from 'axios';

import { AppContext, AppContextType } from '../../../context/AppContext';
import EditCalendarNamePopup from '../EditCalendarNamePopup/EditCalendarNamePopup';
import DeleteCalendarPopup from '../DeleteCalendarPopup/DeleteCalendarPopup';

import styles from './CalendarEdit.module.css';
import { CalendarEditStyles } from './CalendarEditStyles';

interface Props {
  handleBackButtonClick: () => any,
}

export default function CalendarEdit(props: Props): ReactElement {

  const { 
    selectedCalendar, 
    setSelectedCalendar 
  } : AppContextType = useContext(AppContext);

  const [ editCalendarName, toggleEditCalendarName ] = useState(false);
  const [ deleteCalendar, toggleDeleteCalendar ] = useState(false);

  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);
  
  const [ copyLink, toggleCopyLink ] = useState(false);
  const [ linkRegenerated, toggleLinkRegenerated ] = useState(false);

  //Back
  const handleBackButtonClick = () => {
    setSelectedCalendar(null);
  }

  //Edit name
  const handleEditCalendarNameClick = () => {
    toggleEditCalendarName(true);
  }

  //Delete calendar
  const handleDeleteCalendarClick = () => {
    toggleDeleteCalendar(true);
  }

  //Copy click
  const handleCopyClick = () => {
    let dummy = document.createElement('textarea');
    
    dummy.style.right = "9999px";

    document.getElementById("calendarEditHolder").appendChild(dummy);

    dummy.value = "https://calendar.showboat.live/" + selectedCalendar.loginCode;

    dummy.select();
    dummy.setSelectionRange(0, 99999);

    document.execCommand('copy');

    document.getElementById("calendarEditHolder").removeChild(dummy);

    toggleCopyLink(true);
  }

  const handleCopyLinkClose = () => {
    toggleCopyLink(false);
  }

  //Regenerate click
  const handleRegenerateClick = async () => {

    toggleLoadingSpinner(true);
    
    try {
      let response = await axios.put("/calendar/login/regenerate", {
        calendarID: selectedCalendar.calendarID,
      });

      toggleLoadingSpinner(false);
      
      let selectedCalendarClone = selectedCalendar;

      selectedCalendarClone.loginCode = response.data;

      setSelectedCalendar(selectedCalendarClone);

      toggleLinkRegenerated(true);

    }
    catch {
      toggleLoadingSpinner(false);
    }
  }

  const handleRegenerateLinkClose = () => {
    toggleLinkRegenerated(false);
  }

  //Delete calendar close
  const handleDeleteCalendarClose = () => {
    toggleDeleteCalendar(false);
  }

  //Edit name close
  const handleEditCalendarPopupClose = () => {
    toggleEditCalendarName(false);
  }
  
  const classes = CalendarEditStyles();
  
  return (

    <React.Fragment>

      <div className={styles.calendarEditHolder} id="calendarEditHolder" >
        <Typography variant="h1" classes={{ root: classes.calendarEditHeader }}>
          Calendar
        </Typography>

        <IconButton className={classes.backButton} onClick={handleBackButtonClick}>
          <BackIcon />
        </IconButton>

        <Typography variant="h2" className={classes.calendarNameHeader}>
          Calendar Name
        </Typography>

        <Typography variant="h2" className={classes.calendarName}>
          {selectedCalendar.calendarName}
        </Typography>

        <div className={styles.inputIconHolder}>
          <EditIcon 
            className={classes.editIcon} 
            onClick={handleEditCalendarNameClick}
          />

          <TrashIcon 
            className={classes.trashIcon} 
            onClick={handleDeleteCalendarClick}
          />
        </div>

        <Divider className={classes.divider}></Divider>

        <div className={styles.calendarURLHolder}>
          <Typography variant="h3" className={classes.calendarURLHeader}>
            Calendar URL
          </Typography>

          <Typography variant="body1" className={classes.calendarURL}>
            calendar.showboat.live/{selectedCalendar.loginCode}
          </Typography>

          {loadingSpinner &&
            <CircularProgress className={classes.loadingSpinner} />
          }

          <IconButton 
            onClick={handleCopyClick} 
            className={classes.copyButton} 
            color="primary"
          >
            <img src="assets/images/CopyIcon.svg" alt="Copy icon" className={styles.copyIcon}></img>
          </IconButton>

          <IconButton 
            onClick={handleRegenerateClick} 
            disabled={loadingSpinner} 
            className={classes.regenerateButton} 
            color="primary"
          >
            <RefreshIcon 
              className={classes.refreshIcon}
            ></RefreshIcon>
          </IconButton>
        </div>

      </div>

      {editCalendarName &&
        <EditCalendarNamePopup open={true} handlePopupClose={handleEditCalendarPopupClose} />
      }

      {deleteCalendar &&
        <DeleteCalendarPopup open={true} handlePopupClose={handleDeleteCalendarClose} />
      }

      {/*  COPY LINK SNACKBAR */}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }} 
        autoHideDuration={1500}
        open={copyLink}
        className={classes.copySnackbar}
        onClose={handleCopyLinkClose}
        message="Calendar Link Copied to Clipboard"
      />

      {/*  REGENERATE LINK SNACKBAR */}
      <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }} 
          autoHideDuration={1500}
          className={classes.regenerateSnackbar}
          open={linkRegenerated}
          onClose={handleRegenerateLinkClose}
          message="Calendar URL Regenerated"
        />

    </React.Fragment>

  )
}
