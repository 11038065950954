import React, { ReactElement, useEffect, useState, useContext } from 'react';
import { orderBy } from 'natural-orderby';

import { AppContext, AppContextType } from '../../../context/AppContext';
import EmailSkinnableItem from '../EmaiSkinnableItem/EmailSkinnableItem';
import UploadImagePopup from '../UploadPopups/UploadImagePopup/UploadImagePopup';
import PreviewAssetPopup from '../PreviewAssetPopup/PreviewAssetPopup';
import UploadImageOrVideoPopup from '../UploadPopups/UploadImageOrVideoPopup/UploadImageOrVideoPopup';
import EditNumberPopup from '../UploadPopups/EditNumberPopup/EditNumberPopup';

interface PreviewObject {
  title: string,
  property: string,
  value: string,
  type: string,
  division: string
}

export default function BrandingEmail(props): ReactElement {

  const { selectedSpace, isSuperAdmin } : AppContextType = useContext(AppContext);
  
  const [ skinnableFields, setSkinnableFields ] = useState([]);

  const [ uploadProperty, setUploadProperty ] = useState("");
  const [ uploadType, setUploadType ] = useState("");
  const [ uploadTitle, setUploadTitle ] = useState("");
  const [ uploadDivision, setUploadDivision ] = useState("");
  const [ uploadDimensions, setUploadDimensions ] = useState({});
  const [ uploadAssetFamily, setUploadAssetFamily ] = useState("");
  const [ upload, toggleUpload ] = useState(false);

  const [ preview, togglePreview ] = useState(false);
  const [ previewObject, setPreviewObject ] = useState({
    title: "",
    property: "",
    value: "",
    type: "",
    division: ""
  } as PreviewObject);

  //Get skinnable email properties
  useEffect(() => {

    let emailSkinArrayOfObjects = [];

    //Add objects for each skin item to array
    for (const skinItem in selectedSpace.emailSkin) {

      let skinItemEntry = {
        property: skinItem,
        label: selectedSpace.emailSkin[skinItem].label,
        type: selectedSpace.emailSkin[skinItem].type,
        value: selectedSpace.emailSkin[skinItem].value,
        dimensions: selectedSpace.emailSkin[skinItem].dimensions,
        division: "emailSkin"
      }

      //Check if asset family is undefined
      if (selectedSpace.emailSkin[skinItem].assetFamily) {
        skinItemEntry['assetFamily'] = selectedSpace.emailSkin[skinItem].assetFamily;
      }

      //Check if hidden is undefined
      if (selectedSpace.emailSkin[skinItem].hidden !== undefined) {
        skinItemEntry['hidden'] = selectedSpace.emailSkin[skinItem].hidden;
      }

      emailSkinArrayOfObjects.push(skinItemEntry);

    };

    const sortedPropertyArray = orderBy(
      emailSkinArrayOfObjects,
      [s => s.label],
      ['asc']
    );

    setSkinnableFields(sortedPropertyArray);

  }, []);

  /*Preview */
  const handleShowPreviewPopup = (previewObj : PreviewObject) => {

    setPreviewObject(previewObj);

    togglePreview(true);
  }

  const handlePreviewClose = () => {

    //Reset preview type object
    setPreviewObject({
      title: "",
      property: "",
      value: "",
      type: "",
      division: ""
    });
    togglePreview(false);
  }
  /**/

  /* Upload handlers */
  const handleShowUploadPopup = (label: string, type: string, property: string, assetFamily: any, division: string, dimensions: {}) => {
    setUploadTitle(label);
    setUploadProperty(property);
    setUploadDivision(division);
    setUploadType(type);
    setUploadDimensions(dimensions);
    setUploadAssetFamily(assetFamily);
    toggleUpload(true);
  };

  const handleUploadClose = () => {
    toggleUpload(false);
    setUploadTitle("");
    setUploadProperty("");
    setUploadType("");
    setUploadDimensions({});
  }
  /**/

  //Handle image or video upload
  const handleImageOrVideoUpload = (imageOrVideo: string, property: string) => {
    let spaceSkinnableFields = skinnableFields;

    //Find corresponding skin object and change type on it
    spaceSkinnableFields.map(field => {
      if (field.property === property) {
        field.type = imageOrVideo;
      }
    });

    setSkinnableFields(spaceSkinnableFields);

  }
  
  return (
    <React.Fragment>
      {skinnableFields.map((field, i) => {

        if (field.hidden && !isSuperAdmin) {
          return;
        } else {
          return (
            <EmailSkinnableItem
              type={field.type}
              value={field.value}
              label={field.label}
              division={field.division}
              property={field.property}
              dimensions={field.dimensions}
              assetFamily={field.assetFamily}
              key={i}
              handleShowUploadPopup={handleShowUploadPopup}
              handleShowPreviewPopup={handleShowPreviewPopup}
            />
          )
        }
        })}

      {(upload && uploadAssetFamily !== undefined)
        ?
          {
            'Image': 
              <UploadImagePopup 
                uploadDimensions={uploadDimensions} 
                uploadDivision={uploadDivision} 
                uploadProperty={uploadProperty} 
                uploadTitle={uploadTitle} 
                uploadType={uploadType} 
                handlePopupClose={handleUploadClose} 
              />,
            "ImageOrVideo": 
              <UploadImageOrVideoPopup 
                uploadDimensions={uploadDimensions} 
                uploadDivision={uploadDivision} 
                uploadProperty={uploadProperty} 
                uploadTitle={uploadTitle} 
                uploadType={uploadType} 
                handlePopupClose={handleUploadClose} 
                handleImageOrVideoUpload={handleImageOrVideoUpload}
              />,
              "SingleNumber":
                <EditNumberPopup
                  uploadType={uploadType}
                  uploadTitle={uploadTitle}
                  uploadProperty={uploadProperty}
                  uploadDivision={uploadDivision}
                  handlePopupClose={handleUploadClose}
                />
          }[uploadAssetFamily]
        :
          {
            'Image': <UploadImagePopup uploadDimensions={uploadDimensions} uploadDivision={uploadDivision} uploadProperty={uploadProperty} uploadTitle={uploadTitle} uploadType={uploadType} handlePopupClose={handleUploadClose} />,
          }[uploadType]
      }
      
      {preview &&
        <PreviewAssetPopup 
          previewObject={previewObject}
          handlePopupClose={handlePreviewClose} 
        />
      }
      
    </React.Fragment>
  )
}
