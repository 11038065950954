import {
  Divider,
  Paper,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import React, { ReactElement, useContext, useState } from "react";
import AdminInterfaceWrapper from "../../../components/ui/AdminInterfaceWrapper/AdminInterfaceWrapper";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";

import styles from "./ProjectsAdminCreate.module.css";
import { ProjectsAdminCreateStyles } from "./ProjectsAdminCreateStyles";

interface Props {}

export default function ProjectsAdminCreate(props: Props): ReactElement {
  const { idToken }: AuthContextType = useContext(AuthContext);

  const [projectName, setProjectName] = useState("");

  const [projectNameError, setProjectNameError] = useState(false);

  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const [projectPostError, setProjectPostError] = useState(false);

  const [postComplete, setPostComplete] = useState(false);

  //Project name
  const handleProjectNameChange = (e) => {
    setProjectNameError(false);

    setProjectName(e.target.value);
  };

  //Submit handler
  const handleSubmit = () => {
    let formHasError = false;

    if (!projectName.trim().length) {
      setProjectNameError(true);
      formHasError = true;
    } else {
      setProjectNameError(false);
    }

    if (formHasError) {
      return;
    }

    setProjectPostError(false);
    setLoadingSpinner(true);

    axios
      .post("/project", JSON.stringify({ name: projectName }), {
        headers: {
          Authorization: idToken,
        },
      })
      .then(function (response) {
        setLoadingSpinner(false);
        setPostComplete(true);
      })
      .catch(function (error) {
        setProjectPostError(true);
      });
  };

  const classes = ProjectsAdminCreateStyles();

  return (
    <AdminInterfaceWrapper
      wrapperHeight={375}
      wrapperWidth={974}
    >
      <Paper className={classes.projectAdminWrapper}>
        <Typography variant="h1" className={classes.createProjectHeader}>
          Create a Project
        </Typography>
        <Divider className={classes.projectsDivider}></Divider>
        {postComplete ? (
          <div className={styles.projectFormHolder}>
            <Typography variant="body1" className={classes.success}>
              Success
            </Typography>
          </div>
        ) : (
          <div className={styles.projectFormHolder}>
            {projectPostError && (
              <Typography variant="body1" className={classes.errorMessage}>
                An error occurred creating this project
              </Typography>
            )}

            <Typography variant="h2" className={classes.projectNameHeader}>
              Project Name
            </Typography>

            <TextField
              variant="outlined"
              className={classes.projectTextField}
              value={projectName}
              onChange={handleProjectNameChange}
              error={projectNameError}
            ></TextField>

            {loadingSpinner && (
              <CircularProgress
                className={classes.loadingSpinner}
              ></CircularProgress>
            )}

            <Button
              disabled={loadingSpinner}
              variant="contained"
              color="primary"
              className={classes.submitButton}
              onClick={handleSubmit}
            >
              SUBMIT
            </Button>
          </div>
        )}
      </Paper>
    </AdminInterfaceWrapper>
  );
}
