import React, { ReactElement, useContext, useState, useEffect } from "react";
import {
  Dialog,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";

import { AppContext, AppContextType } from "../../../context/AppContext";
import AvatarColorItem from "./AvatarColorItem/AvatarColorItem";
import PopupHeader from "../../PopupHeader/PopupHeader";

import styles from "./ManageAvatarColorsPopup.module.css";
import { ManageAvatarColorsPopupStyles } from "./ManageAvatarColorsPopupStyles";
import { JWTHelper } from "../../../utilities/JWTHelper";
import SaveButton from "../../ui/SaveButton/SaveButton";
import CancelButton from "../../ui/CancelButton/CancelButton";
import SaveLoadingSpinner from "../../ui/SaveLoadingSpinner/SaveLoadingSpinner";

interface Props {
  handlePopupClose: () => any;
}

export default function ManageAvatarColorsPopup(props: Props): ReactElement {
  const {
    selectedSpace,
    setSelectedSpace,
    selectedSpaceID,
    producerID,
  }: AppContextType = useContext(AppContext);

  const [avatarColorSelections, setAvatarColorSelections] = useState(
    selectedSpace.avatarSkin.avatarColors.value
  );

  const [errorIndexes, setErrorIndexes] = useState([]);

  const [error, toggleError] = useState(false);

  const [disabledIndexes, setDisabledIndexes] = useState([]);

  const [isLoading, toggleIsLoading] = useState(false);

  const [cancelLoadingSpinner, toggleCancelLoadingSpinner] = useState(false);

  useEffect(() => {}, [disabledIndexes]);

  useEffect(() => {
    let disabledIndexesArray = [];
    let avatarSkinColors = selectedSpace.avatarSkin.avatarColors.value;

    avatarSkinColors.forEach((colorValue, i) => {
      if (colorValue.primary === "" && colorValue.secondary === "") {
        disabledIndexesArray.push(i);
      }
    });

    setDisabledIndexes(disabledIndexesArray);
  }, []);

  const handleSaveButtonClick = () => {
    toggleIsLoading(true);

    let errorIndexesClone = [];

    avatarColorSelections.forEach((colorSelection, index) => {
      if (
        (colorSelection.primary === "" || colorSelection.secondary === "") &&
        !disabledIndexes.includes(index)
      ) {
        errorIndexesClone.push(index);
      }
    });

    setErrorIndexes(errorIndexesClone);

    if (errorIndexesClone.length) {
      toggleIsLoading(false);
      return;
    } else {
      axios
        .put("/skin/avatar", {
          spaceID: selectedSpaceID,
          property: "avatarColors",
          value: avatarColorSelections,
        })
        .then(function (response) {
          let selectedSpaceClone = selectedSpace;

          selectedSpaceClone.avatarSkin.avatarColors.value =
            avatarColorSelections;

          setSelectedSpace(selectedSpaceClone);

          toggleIsLoading(false);
          props.handlePopupClose();
        })
        .catch(function (error) {
          toggleIsLoading(false);
          toggleError(true);
          console.log(error);
        });
    }
  };

  const handleCancelButtonClick = () => {
    if (isLoading) {
      return;
    }

    toggleCancelLoadingSpinner(true);

    setDisabledIndexes([]);

    axios
      .get("/space", {
        params: {
          spaceID: selectedSpaceID,
          producerID: producerID,
        },
      })
      .then(function (response) {
        setSelectedSpace(response.data.space);
        setAvatarColorSelections(
          response.data.space.avatarSkin.avatarColors.value
        );
        toggleCancelLoadingSpinner(false);
        props.handlePopupClose();
      })
      .catch(function (error) {
        console.log(error);
        toggleCancelLoadingSpinner(false);
        props.handlePopupClose();
      });
  };

  /*BODY COLOR CHANGE */
  const handleBodyColorChange = (color, index) => {
    let avatarColorsClone = [...avatarColorSelections];

    avatarColorsClone[index].primary = color;

    setAvatarColorSelections(avatarColorsClone);
  };
  /**/

  /*FACE COLOR CHANGE */
  const handleFaceColorChange = (color, index) => {
    let avatarColorsClone = [...avatarColorSelections];

    avatarColorsClone[index].secondary = color;

    setAvatarColorSelections(avatarColorsClone);
  };
  /**/

  /* DISABLE A COLOR COMBINATION */
  const handleDisableClick = (index) => {
    setDisabledIndexes([...disabledIndexes, index]);

    let avatarColorsClone = [...avatarColorSelections];

    avatarColorsClone[index].primary = "";
    avatarColorsClone[index].secondary = "";

    setAvatarColorSelections(avatarColorsClone);
  };

  const handleEnableClick = (index) => {
    setDisabledIndexes(
      disabledIndexes.filter((disabledIndex) => disabledIndex !== index)
    );
  };
  /**/

  const classes = ManageAvatarColorsPopupStyles();

  return (
    <Dialog open={true} classes={{ paper: classes.dialogPaper }}>
      <div className={classes.popup}>
        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={isLoading}
        />

        <Typography variant="h1" className={classes.header}>
          Avatar Colors
        </Typography>

        {error && (
          <Typography variant="body1" className={classes.error}>
            An error occurred updating avatar colors for this space
          </Typography>
        )}

        <Typography variant="body1" className={classes.avatarColorDescription}>
          The Avatar system supports up to 8 unique combinations. Avatar color
          choices will not show up in the intake workflow until both primary and
          secondary color choices are made.
        </Typography>

        <div className={styles.avatarColorsHolder}>
          {avatarColorSelections.map((avatarColor, i) => {
            return (
              <AvatarColorItem
                handleBodyColorChange={handleBodyColorChange}
                handleFaceColorChange={handleFaceColorChange}
                handleDisableClick={handleDisableClick}
                handleEnableClick={handleEnableClick}
                index={i}
                error={errorIndexes.includes(i)}
                primary={avatarColor.primary}
                key={i}
                avatarColorSelections={avatarColorSelections}
                secondary={avatarColor.secondary}
              />
            );
          })}
        </div>

        {isLoading && <SaveLoadingSpinner />}

        {cancelLoadingSpinner && (
          <CircularProgress className={classes.cancelLoadingSpinner} />
        )}

        <CancelButton
          variant="text"
          onClick={handleCancelButtonClick}
        />

        <SaveButton
          disableBoolean={isLoading}
          onClick={handleSaveButtonClick}
        />
        
      </div>
    </Dialog>
  );
}
