import React, { ReactElement, useContext, useState } from 'react';
import axios from 'axios';
import { Dialog, Typography, TextField, Button, CircularProgress } from '@material-ui/core';

import PopupHeader from '../../PopupHeader/PopupHeader';
import { AppContext, AppContextType } from '../../../context/AppContext';

import { AddPresentationPopupStyles } from './AddPresentationPopupStyles';
import SaveButton from '../../ui/SaveButton/SaveButton';
import CancelButton from '../../ui/CancelButton/CancelButton';
import SaveLoadingSpinner from '../../ui/SaveLoadingSpinner/SaveLoadingSpinner';

interface Props {
  open: boolean,
  handlePopupClose: () => any,
  handlePresentationAdd: (newPresentation) => any
}

export default function AddPresentationPopup(props: Props): ReactElement {
  
  const [ presentationName, setPresentationName ] = useState("");
  const [ formHasError, toggleFormHasError ] = useState(false);
  const [error, toggleError] = useState(false);
  const [ formHasBeenSubmitted, toggleFormHasBeenSubmitted ] = useState(false);
  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);

  const { 
    selectedSpaceID 
  } : AppContextType = useContext(AppContext);

  const handlePresentationNameChange = (e) => {
    
    if (formHasBeenSubmitted) {
      toggleFormHasError(false);
    }
    
    setPresentationName(e.target.value);
  }

  const handleSaveButtonClick = async () => {
    if (presentationName === "") {
      toggleFormHasError(true);
      toggleFormHasBeenSubmitted(true);
      return;
    }

    toggleError(false);

    toggleLoadingSpinner(true);

    try {
      let response = await axios.post("/presentation", {
        spaceID: selectedSpaceID,
        name: presentationName,
        slides: []
      });

      toggleFormHasBeenSubmitted(true);
      props.handlePresentationAdd(response.data);
    }
    catch {
      toggleFormHasBeenSubmitted(true);
      toggleLoadingSpinner(false);
      toggleError(true);
    }
    
  }

  const handleCancelButtonClick = () => {

    if (loadingSpinner) {
      return;
    }
    
    //Reset name input field
    setPresentationName("");
    
    props.handlePopupClose();
  }

  const classes = AddPresentationPopupStyles();
  
  return (
    <Dialog
      open={props.open}
      classes={{ paper: classes.dialogPaper}}  
    >
      <div className={classes.popup}>

        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={loadingSpinner}
        />

        <Typography variant="h1" className={classes.createPresentationHeader}>
          Create Presentation
        </Typography>

        {error &&
          <Typography variant="body1" className={classes.error}>
            An error occurred creating this presentation
          </Typography>
        }

        <TextField
          variant="outlined"
          value={presentationName}
          onChange={handlePresentationNameChange}
          helperText="Required"
          className={classes.presentationNameInput}
          placeholder="Presentation Name"
          error={formHasError}
        />

        {loadingSpinner && 
          <SaveLoadingSpinner />
        }

        <CancelButton 
          onClick={handleCancelButtonClick}
          variant="text"
        />
        
        <SaveButton 
          disableBoolean={loadingSpinner}
          onClick={handleSaveButtonClick}
        />
      </div>
    </Dialog>
  )
}
