import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Link,
  Paper,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import LinkIcon from "@material-ui/icons/Link";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import axios from "axios";
import React, { ReactElement, useEffect, useState, useContext } from "react";
import { AuthContext, AuthContextType } from "../../context/AuthContext";

import styles from "./SpaceWorldsAdmin.module.css";
import { SpaceWorldsAdminStyles } from "./SpaceWorldsAdminStyles";
import AdminInterfaceWrapper from "../../components/ui/AdminInterfaceWrapper/AdminInterfaceWrapper";
import { orderBy } from "natural-orderby";
import { JWTHelper } from "../../utilities/JWTHelper";

interface Props {}

export default function SpaceWorldsAdmin({}: Props): ReactElement {
  const history = useHistory();

  const { idToken, setIdToken }: AuthContextType = useContext(AuthContext);

  const [authenticated, toggleAuthenticated] = useState(false);

  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const [currentRepairSpaceID, setCurrentRepairSpaceID] = useState(null);

  const [spaceWorldsSet, setSpaceWorldsSet] = useState(null);

  const [error, setError] = useState(false);

  let id = 0;

  useEffect(() => {
    //First check if there is any producerID in sessionStorage
    if (localStorage.getItem("producerID") == undefined) {
      //Redirect to sign-in if no producerID in sessionStorage
      alert("You do not have privileges to access this page.");
      window.location.replace("/");
    } else {
      //Set up authentication to query producer object
      var poolData = {
        UserPoolId: "us-east-1_N15Q0NLkm",
        ClientId: "2332rbhi35f5016dglri2mojo",
      };

      const userPool = new CognitoUserPool(poolData);

      let currentUser = userPool.getCurrentUser();

      if (currentUser) {
        currentUser.getSession((err, session) => {
          if (err) {
            alert("Your session has expired. Please sign in again.");
            window.location.replace("/");
          } else {
            axios.defaults.headers.common["Authorization"] = session
              .getIdToken()
              .getJwtToken();

            setIdToken(session.getIdToken().getJwtToken());

            //Check producer privileges on load (check if SuperAdmin)
            //ProducerID is received from sessionStorage
            axios
              .get("/producer/role", {
                params: {
                  producerID: localStorage.getItem("producerID"),
                },
              })
              .then(function (response) {
                //Get the role on the producer object
                if (response.data.role === 0) {
                  toggleAuthenticated(true);
                } else {
                  console.log("ERROR");
                  alert("You do not have privileges to access this page.");
                  window.location.replace("/");
                }
              })
              .catch(function (error) {
                console.log("ERROR 2", error);
                alert("You do not have privileges to access this page.");
                window.location.replace("/");
              });
          }
        });
      } else {
        alert("Your session has expired. Please sign in again.");
        window.location.replace("/");
      }
    }
  }, []);

  const handleRunScriptClick = async () => {
    setLoadingSpinner(true);

    setSpaceWorldsSet(null);

    setError(false);

    try {
      let response = await axios.get("/space-worlds-admin");

      console.log("RESPONSE", response);

      setLoadingSpinner(false);

      //Order both arrays

      //Order invalid spaces array
      let spacesArray = response.data.invalidSpaces;
      let worldsArray = response.data.invalidWorlds;

      let spacesArrayOrdered = orderBy(
        spacesArray,
        [(s) => (s as any).spaceName],
        ["asc"]
      );

      //Order invalid worlds array
      let worldsArrayOrdered = orderBy(
        worldsArray,
        [(w) => (w as any).spaceName],
        ["asc"]
      );

      //Assign ID to each entry in each array
      for (let i = 0; i < worldsArrayOrdered.length; i++) {
        (worldsArrayOrdered[i] as any).id = id;
        id++;
      }
      for (let i = 0; i < spacesArrayOrdered.length; i++) {
        (spacesArrayOrdered[i] as any).id = id;
        id++;
      }

      let spacesWorldsSetObj = {
        invalidWorlds: worldsArrayOrdered,
        invalidSpaces: spacesArrayOrdered,
      };

      console.log("Space worlds set", spacesWorldsSetObj);

      setSpaceWorldsSet(spacesWorldsSetObj);
    } catch (error) {
      console.log("ERROR", error);
      setError(true);
      setLoadingSpinner(false);
    }
  };

  const handleSpaceIDCopyToClipboard = (e, spaceID) => {
    let dummy = document.createElement("textarea");

    document.body.appendChild(dummy);

    dummy.value = spaceID;
    dummy.select();

    document.execCommand("copy");
    document.body.removeChild(dummy);
  };

  const handleRepairClick = async (invalidWorld) => {
    setError(false);

    let worldID = invalidWorld.worldID;
    let spaceID = invalidWorld.spaceID;

    setCurrentRepairSpaceID(spaceID);

    try {
      //Retrieve the world via worldID, then assign the property to the space
      let getWorldsResponse = await axios.get("/worlds/all/admin");

      let worlds = getWorldsResponse.data;

      let matchingWorld;

      for (let i = 0; i < worlds.length; i++) {
        if (worlds[i].worldID === worldID) matchingWorld = worlds[i];
      }

      if (!matchingWorld) throw new Error("No world found");

      let propertyName = invalidWorld.propertyName;

      let missingValue = matchingWorld.worldSkin[`${propertyName}`];

      if (!missingValue) throw new Error("World is missing property");

      JWTHelper.createJWT(spaceID, "");

      let spaceWorldSkinUpdateResponse = await axios.put("/skin/world/repair", {
        spaceID: spaceID,
        property: propertyName,
        value: missingValue,
      });

      //Remove repaired entry from invalid list
      let spaceWorldsSetClone = spaceWorldsSet;

      for (let i = 0; i < spaceWorldsSetClone.invalidWorlds.length; i++) {
        if (spaceWorldsSetClone.invalidWorlds[i].id === invalidWorld.id) {
          spaceWorldsSetClone.invalidWorlds.splice(i, 1);
          break;
        }
      }

      setSpaceWorldsSet(spaceWorldsSetClone);

      setCurrentRepairSpaceID(null);
    } catch (error) {
      console.log("ERROR", error);
      setCurrentRepairSpaceID(null);
      setError(true);
    }
  };

  const classes = SpaceWorldsAdminStyles();

  if (authenticated) {
    return (
      <React.Fragment>
        <Button
          className={classes.adminMenuButton}
          variant="contained"
          color="primary"
          onClick={() => {
            history.push("/admin-menu");
          }}
        >
          ADMIN MENU
        </Button>

        <AdminInterfaceWrapper wrapperHeight={900} wrapperWidth={974}>
          <Paper className={classes.spaceWorldsAdminWrapper}>
            <Typography variant="h1" className={classes.spaceWorldsHeader}>
              Space and Worlds Script
            </Typography>

            <Divider className={classes.spaceWorldsDivider}></Divider>

            <Button
              className={classes.scriptButton}
              variant="contained"
              color="primary"
              onClick={handleRunScriptClick}
            >
              RUN SCRIPT
            </Button>

            {loadingSpinner && (
              <CircularProgress className={classes.loadingSpinner} />
            )}

            {error && (
              <Typography variant="body1" className={classes.error}>
                An error occurred
              </Typography>
            )}

            <div
              className={`${styles.invalidSpacesHolder} ${styles.invalidSpacesHolderLeft}`}
            >
              <Typography variant="h2" className={classes.invalidSpacesHeader}>
                Invalid Spaces (Space)
              </Typography>

              <Typography variant="body1" className={classes.invalidInfo}>
                These spaces have a property that is not on their matching world
              </Typography>

              {spaceWorldsSet !== null &&
                spaceWorldsSet.invalidSpaces.map((invalidSpace) => {
                  return (
                    <div
                      className={styles.invalidItem}
                      key={`${invalidSpace.spaceID} ${invalidSpace.propertyName}`}
                    >
                      <div className={styles.linkHolder}>
                        <Link
                          className={`${classes.invalidName} ${classes.invalidNameSpace}`}
                          href={`https://producer.showboat.live/space/${invalidSpace.spaceID}`}
                          target="_blank"
                          underline="always"
                        >
                          {invalidSpace.spaceName}
                        </Link>

                        <IconButton
                          onClick={(e) =>
                            handleSpaceIDCopyToClipboard(
                              e,
                              invalidSpace.spaceID
                            )
                          }
                          className={classes.copyButton}
                        >
                          <LinkIcon />
                        </IconButton>
                      </div>

                      <Typography
                        variant="h3"
                        className={`${classes.invalidName2} ${classes.invalidName2Space}`}
                      >
                        {invalidSpace.worldName}
                      </Typography>

                      <Typography
                        variant="h3"
                        className={classes.invalidProperty}
                      >
                        {invalidSpace.propertyName}
                      </Typography>
                    </div>
                  );
                })}
            </div>

            <div
              className={`${styles.invalidSpacesHolder} ${styles.invalidSpacesHolderRight}`}
            >
              <Typography variant="h2" className={classes.invalidWorldsHeader}>
                Invalid Spaces (World)
              </Typography>

              <Typography variant="body1" className={classes.invalidInfo}>
                These spaces are missing a property that is on their matching
                world
              </Typography>

              {spaceWorldsSet !== null &&
                spaceWorldsSet.invalidWorlds.map((invalidWorld) => {
                  return (
                    <div
                      className={`${styles.invalidItem} ${styles.invalidItemRight}`}
                      key={`${invalidWorld.spaceID} ${invalidWorld.propertyName}`}
                    >
                      <div className={styles.linkHolder}>
                        <Link
                          className={classes.invalidName}
                          href={`https://producer.showboat.live/space/${invalidWorld.spaceID}`}
                          target="_blank"
                          underline="always"
                        >
                          {invalidWorld.spaceName}
                        </Link>

                        <IconButton
                          onClick={(e) =>
                            handleSpaceIDCopyToClipboard(
                              e,
                              invalidWorld.spaceID
                            )
                          }
                          className={classes.copyButton}
                        >
                          <LinkIcon />
                        </IconButton>
                      </div>

                      <Typography
                        variant="body1"
                        className={classes.invalidName2}
                      >
                        {invalidWorld.worldName}
                      </Typography>

                      <Typography
                        variant="body1"
                        className={classes.invalidProperty}
                      >
                        {invalidWorld.propertyName}
                      </Typography>

                      <Button
                        onClick={() => handleRepairClick(invalidWorld)}
                        className={classes.repairButton}
                        variant="contained"
                      >
                        REPAIR
                      </Button>

                      {currentRepairSpaceID === invalidWorld.spaceID && (
                        <CircularProgress
                          className={classes.repairLoadingSpinner}
                        />
                      )}
                    </div>
                  );
                })}
            </div>
          </Paper>
        </AdminInterfaceWrapper>
      </React.Fragment>
    );
  } else {
    return <div></div>;
  }
}
