import { CircularProgress, Typography } from "@material-ui/core";
import axios from "axios";
import { orderBy } from "natural-orderby";
import React, {
  ReactElement,
  useState,
  useRef,
  useContext,
  useEffect,
} from "react";

import { AppContext, AppContextType } from "../../../context/AppContext";
import useGetAllSpaceCalendars from "../../../hooks/useGetAllSpaceCalendars";
import CalendarItem from "../CalendarItem/CalendarItem";

import styles from "./CalendarItemsDisplay.module.css";
import { CalendarItemsDisplayStyles } from "./CalendarItemsDisplayStyles";

interface Props {}

export default function CalendarItemsDisplay(props: Props): ReactElement {
  const { selectedSpaceID, setSpaceCalendars }: AppContextType =
    useContext(AppContext);

  const {
    getAllCalendars,
    loadingSpinner,
    toggleLoadingSpinner,
    noCalendarsError,
    toggleNoCalendarsError,
    error,
    toggleError,
  } = useGetAllSpaceCalendars();

  const [spaceCalendarsState, setSpaceCalendarsState] = useState([]);

  const firstRender = useRef(true);

  const getSpaceCalendars = async () => {
    toggleNoCalendarsError(false);
    toggleError(false);
    setSpaceCalendars([]);
    setSpaceCalendarsState([]);
    toggleLoadingSpinner(true);

    if (selectedSpaceID === null) return;
    
    //Call custom hook
    let spaceCalendarsResponse = await getAllCalendars(selectedSpaceID);

    toggleLoadingSpinner(false);

    if (spaceCalendarsResponse) {
      let spaceCalendarsOrdered = orderBy(
        spaceCalendarsResponse,
        [(c) => (c as any).calendarName],
        ["asc"]
      );

      setSpaceCalendarsState(spaceCalendarsOrdered);
      setSpaceCalendars(spaceCalendarsOrdered as any);
    }
  };

  useEffect(() => {
    toggleLoadingSpinner(true);

    toggleError(false);
    toggleNoCalendarsError(false);

    getSpaceCalendars();
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    //Get calendars again when spaceID changes
    getSpaceCalendars();
  }, [selectedSpaceID]);

  const classes = CalendarItemsDisplayStyles();

  return (
    <div
      className={styles.calendarsHolder}
      style={{
        minHeight: loadingSpinner || noCalendarsError ? "200px" : "0px",
      }}
    >
      {loadingSpinner && (
        <CircularProgress className={classes.loadingSpinner} />
      )}

      {!loadingSpinner && noCalendarsError && (
        <Typography variant="body1" className={classes.noCalendarsError}>
          No calendars found for this space.
        </Typography>
      )}

      {error && (
        <Typography variant="body1" className={classes.error}>
          An error occurred getting calendars for this space.
        </Typography>
      )}

      {!loadingSpinner &&
        spaceCalendarsState.map((calendar, i) => {
          return (
            <CalendarItem
              key={calendar.calendarID}
              calendarName={calendar.calendarName}
              calendarID={calendar.calendarID}
              index={i}
            />
          );
        })}
    </div>
  );
}
