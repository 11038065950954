import axios from "axios";
import { useState } from "react";

const useGetAllSpaceCalendars = () => {
  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);
  const [ noCalendarsError, toggleNoCalendarsError ] = useState(false);
  const [ error, toggleError ] = useState(false);
  
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const getAllCalendars = async (selectedSpaceID) => {
    try {
      let response = await axios.get("/calendars", {
        params: {
          spaceID: selectedSpaceID,
        },
        cancelToken: source.token,
      });

      toggleLoadingSpinner(false);
      return response.data;
    } catch (error) {
      toggleLoadingSpinner(false);

      if (error.response && error.response.status === 404) {
        toggleNoCalendarsError(true);
      } else {
        toggleError(true);
      }

      return false;
    }
  };

  return {
    getAllCalendars,
    loadingSpinner,
    toggleLoadingSpinner,
    noCalendarsError,
    toggleNoCalendarsError,
    error,
    toggleError
  };
};

export default useGetAllSpaceCalendars;
