import React, { ReactElement, useState, useContext, useEffect } from "react";
import {
  Typography,
  Button,
  Dialog,
  CircularProgress,
  ClickAwayListener,
} from "@material-ui/core";
import TimezoneSelect from "react-timezone-select";
import TimeIcon from "@material-ui/icons/AccessTime";
import moment from "moment-timezone";
import MomentUtils from "@date-io/moment";
import axios from "axios";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import { AppContext, AppContextType } from "../../../../context/AppContext";
import PopupHeader from "../../../PopupHeader/PopupHeader";

import styles from "./EditBookingStartTimePopup.module.css";
import { EditBookingStartTimePopupStyles } from "./EditBookingStartTimePopupStyles";
import SaveButton from "../../../ui/SaveButton/SaveButton";
import CancelButton from "../../../ui/CancelButton/CancelButton";
import SaveLoadingSpinner from "../../../ui/SaveLoadingSpinner/SaveLoadingSpinner";

interface Props {
  open: boolean;
  handlePopupClose: () => any;
  duplicateBooking: boolean;
  handleNextButtonClick?: (startTime: string) => any;
}

export default function EditBookingStartTimePopup(props: Props): ReactElement {
  const { selectedBooking, setSelectedBooking, selectedSpace }: AppContextType =
    useContext(AppContext);

  const [selectedDate, setSelectedDate] = useState(
    moment
      .tz(selectedBooking.start.time, selectedBooking.start.timezone)
      .format()
  );
  const [selectedTimezone, setSelectedTimezone] = useState({});
  const [selectedTimezoneValue, setSelectedTimezoneValue] = useState(
    selectedBooking.start.timezone
  );
  const [timezonePickerOpen, toggleTimezonePickerOpen] = useState(false);
  const [timezoneError, toggleTimezoneError] = useState(false);
  const [dateError, toggleDateError] = useState(false);
  const [spaceDateError, toggleSpaceDateError] = useState(false);
  const [loadingSpinner, toggleLoadingSpinner] = useState(false);

  useEffect(() => {
    moment.tz.setDefault(selectedBooking.start.timezone);

    //Set default timezone value
    setSelectedTimezone({
      value: 1212121,
      label: selectedTimezoneValue,
    });
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date.tz(selectedTimezoneValue, true).format());
  };

  const handleTimeChange = (time) => {
    setSelectedDate(time.tz(selectedTimezoneValue, true).format());
  };

  const handleTimezoneChange = (tz) => {
    toggleTimezonePickerOpen(false);

    moment.tz.setDefault(tz.value);

    toggleTimezoneError(false);

    let currentDate = moment(selectedDate);

    currentDate.tz(tz.value).format("ha z");

    setSelectedDate(currentDate.format());
    setSelectedTimezone(tz);
    setSelectedTimezoneValue(tz.value);
  };

  const handleSaveButtonClick = async () => {
    toggleLoadingSpinner(true);
    toggleDateError(false);
    toggleSpaceDateError(false);

    //Make sure not after booking end time
    if (
      moment.utc(selectedDate).valueOf() >
      moment.utc(selectedBooking.end.time).valueOf()
    ) {
      toggleLoadingSpinner(false);
      toggleDateError(true);
      return;
    }

    //Make sure not before space open time
    if (
      moment.utc(selectedDate).valueOf() <
      moment.utc(selectedSpace.startDate.date).valueOf()
    ) {
      toggleLoadingSpinner(false);
      toggleSpaceDateError(true);
      return;
    }

    //Make sure not after space close time
    if (
      moment.utc(selectedDate).valueOf() >
      moment.utc(selectedSpace.endDate.date).valueOf()
    ) {
      toggleLoadingSpinner(false);
      toggleSpaceDateError(true);
      return;
    }

    if (Object.keys(selectedTimezone).length === 0) {
      toggleTimezoneError(true);

      return;
    } else {
      //UPDATE BOOKING START TIME

      toggleTimezoneError(false);

      //Check if booking has doorsOpen/doorsClosed
      if (selectedBooking.doorsOpen && selectedBooking.doorsClosed) {
        let returnArray = handleChangeDoorsOpenWithStartTimeChange();

        (selectedBooking.doorsOpen as any) = returnArray[0];
        (selectedBooking.doorsClosed as any) = returnArray[1];
      }

      let selectedBookingClone = selectedBooking;

      let timeObj = {
        time: selectedDate,
        timezone: selectedTimezoneValue,
        timeValue: moment.utc(selectedDate).valueOf(),
      };

      selectedBookingClone.start = timeObj;

      //change booking endTime timezone
      let newEndTime = moment
        .tz(selectedBooking.end.time, selectedTimezoneValue)
        .format();

      let timeObjEnd = {
        time: newEndTime,
        timezone: selectedTimezoneValue,
        timeValue: moment.utc(newEndTime).valueOf(),
      };

      selectedBookingClone.end = timeObjEnd;

      try {
        await axios.put("/booking", JSON.stringify(selectedBookingClone));

        toggleLoadingSpinner(false);
        setSelectedBooking(selectedBookingClone);
        props.handlePopupClose();
      } catch {
        toggleLoadingSpinner(false);
      }
    }
  };

  const handleCancelButtonClick = () => {
    if (loadingSpinner) {
      return;
    }

    toggleDateError(false);
    setSelectedDate(selectedBooking.start.time as any);
    setSelectedTimezone({
      value: 1212121,
      label: selectedBooking.start.timezone,
    });
    props.handlePopupClose();
  };

  const handleTimezonePickerClick = () => {
    toggleTimezonePickerOpen(!timezonePickerOpen);
  };

  const handleTimezonePickerClose = () => {
    toggleTimezonePickerOpen(false);
  };

  //Duplicate booking next
  const handleDuplicateBookingNextClick = () => {
    toggleSpaceDateError(false);

    //Make sure selected start date is within space open/close
    if (
      moment.utc(selectedDate).valueOf() <
      moment.utc(selectedSpace.startDate.date).valueOf()
    ) {
      toggleLoadingSpinner(false);
      toggleSpaceDateError(true);
      return;
    }

    if (
      moment.utc(selectedDate).valueOf() >
      moment.utc(selectedSpace.endDate.date).valueOf()
    ) {
      toggleLoadingSpinner(false);
      toggleSpaceDateError(true);
      return;
    }

    props.handleNextButtonClick(selectedDate);
  };

  const handleChangeDoorsOpenWithStartTimeChange = () => {
    //Change timezone of doorsOpen/doorsClosed
    let newDoorsOpen = moment
      .tz(selectedBooking.doorsOpen, selectedTimezoneValue)
      .format();
    let newDoorsClosed = moment
      .tz(selectedBooking.doorsClosed, selectedTimezoneValue)
      .format();

    let oldStartTime = moment(selectedBooking.start.time);
    let oldDoorsOpen = moment(selectedBooking.doorsOpen);

    //Set new open time to be new selected date initially
    let newDoorsOpenFromStartTime = moment(selectedDate);

    //Calculate duration string
    let durationString = moment.duration(
      moment(oldStartTime).diff(oldDoorsOpen)
    );

    //Check if there is a days difference
    let days = durationString.days();
    if (days !== 0) {
      //There is a days difference, so change newDoorsOpen that amount
      let newDoorsOpen = moment(newDoorsOpenFromStartTime).subtract(
        days,
        "days"
      );
      newDoorsOpenFromStartTime = newDoorsOpen;
    }

    //Check if there is an hours difference
    let hours = durationString.hours();
    if (hours !== 0) {
      //There is an hours difference, so change newDoorsOpen that amount
      let newDoorsOpen = moment(newDoorsOpenFromStartTime).subtract(
        hours,
        "hours"
      );
      newDoorsOpenFromStartTime = newDoorsOpen;
    }

    //Check if there is a minutes difference
    let minutes = durationString.minutes();
    if (minutes !== 0) {
      let newDoorsOpen = moment(newDoorsOpenFromStartTime).subtract(
        minutes,
        "minutes"
      );
      newDoorsOpenFromStartTime = newDoorsOpen;
    }

    return [
      moment(newDoorsOpenFromStartTime).format(),
      moment(newDoorsClosed).format(),
    ];
  };

  const classes = EditBookingStartTimePopupStyles();

  return (
    <Dialog
      open={props.open}
      classes={{
        paper: timezonePickerOpen
          ? `${classes.dialogPaper} ${classes.dialogPaperTimezoneOpen} `
          : classes.dialogPaper,
      }}
    >
      <div
        className={
          timezonePickerOpen
            ? `${classes.popup} ${classes.popupTimezoneOpen}`
            : classes.popup
        }
      >
        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={loadingSpinner}
        />

        <div className={styles.contentWrapper}>
          <Typography variant="h1" className={classes.editTimeHeader}>
            Set Run Start Date / Time
          </Typography>
          <Typography variant="body1" className={classes.editTimeText}>
            The space will open up at this time
          </Typography>

          {timezoneError && (
            <Typography variant="body1" className={classes.errorText}>
              Please select a timezone
            </Typography>
          )}

          {dateError && (
            <Typography variant="body1" className={classes.dateError}>
              Start date/time must be before the event's end date/time
            </Typography>
          )}

          {spaceDateError && (
            <Typography variant="body1" className={classes.dateError}>
              Start date/time must be within the open/close time of this space
            </Typography>
          )}

          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              clearable
              label="Enter Date"
              inputVariant="outlined"
              value={selectedDate}
              onChange={(date) => handleDateChange(date)}
              okLabel={<span style={{ color: "green" }}>OK</span>}
              /* minDate={new Date()} */
              format="MM/DD/YYYY"
              className={classes.datePicker}
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardTimePicker
              keyboardIcon={<TimeIcon />}
              label="Enter Time"
              inputVariant="outlined"
              className={classes.timePicker}
              value={selectedDate}
              mask="__:__ _M"
              /* inputValue={moment.tz(selectedDate, selectedTimezoneValue).format("hh:mm a")} */
              onChange={(date) => handleTimeChange(date)}
              okLabel={<span style={{ color: "green" }}>OK</span>}
            />
          </MuiPickersUtilsProvider>

          <Typography variant="body1" className={classes.timezoneLabel}>
            Select Timezone
          </Typography>

          <ClickAwayListener onClickAway={handleTimezonePickerClose}>
            <div
              onClick={handleTimezonePickerClick}
              className={styles.timezonePicker}
            >
              <TimezoneSelect
                value={selectedTimezone}
                onChange={(tz) => handleTimezoneChange(tz)}
                className={styles.timezonePicker}
              />
            </div>
          </ClickAwayListener>
        </div>

        {loadingSpinner && (
          <SaveLoadingSpinner />
        )}

        <CancelButton 
          variant="contained"
          onClick={handleCancelButtonClick}
        />

        <SaveButton
          disableBoolean={loadingSpinner}
          onClick={
            props.duplicateBooking
              ? handleDuplicateBookingNextClick
              : handleSaveButtonClick
          }
        />

      </div>
    </Dialog>
  );
}
