import { CircularProgress, IconButton, Paper, Snackbar, TextField, Typography } from '@material-ui/core';
import React, { ReactElement, useState, useEffect, useContext, useRef } from 'react';
import DownloadIcon from '@material-ui/icons/GetApp';
import { CSVLink } from 'react-csv';
import RefreshIcon from '@material-ui/icons/Refresh';
import axios from 'axios';
import moment from 'moment-timezone';

import { AppContext, AppContextType } from '../../../context/AppContext';
import { orderBy } from 'natural-orderby';
import BookingCurrentAttendee from './BookingCurrentAttendee/BookingCurrentAttendee';

import styles from './BookingCurrentAttendees.module.css';
import { BookingCurrentAttendeesStyles } from './BookingCurrentAttendeesStyles';

interface Props {
  
}

export default function BookingCurrentAttendees(props: Props): ReactElement {

  const {
    producerID,
    selectedSpace,
    selectedBooking,
  } : AppContextType = useContext(AppContext);
  
  const [ bookingAttendeeRecords, setBookingAttendeeRecords ] = useState([]);
  const [ searchFieldName, setSearchFieldName ] = useState("");
  const [ error, setError ] = useState("");
  const [ downloadCSV, toggleDownloadCSV ] = useState(false); 
  const [ loadingSpinner, toggleLoadingSpinner ] = useState(true);
  const [ currentAttendeesState, setCurrentAttendeesState ] = useState(null);

  const [ isSuperAdmin, toggleIsSuperAdmin ] = useState(false);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    
    //Get attendee records for a booking
    getBookingAttendees();

    //Check role to see if we need to display socketID's for attendees
    const getProducerRole = async () => {
      
      try {
        let response = await axios.get("/producer/role", {
          params: {
            producerID: producerID
          }
        });

        if (response.data.role === 0) {
          toggleIsSuperAdmin(true);
        } else {
          toggleIsSuperAdmin(false);
        }
      }
      catch {
        toggleIsSuperAdmin(false);
      }
      
    }
    getProducerRole();
    
    
    return function cleanup() {
      source.cancel("Component was unmounted");
    }
  }, []);

  //Show snackbar after downloading attendee CSV
  const handleDownloadClick = () => {

    //Disable CSV download if loading current attendees
    if (loadingSpinner) {
      return false;
    }

    toggleDownloadCSV(true);
  }
  
  const handleDownloadClose = () => {
    toggleDownloadCSV(false);
  }
  
  const handleSearchFieldChange = (e) => {
    setSearchFieldName(e.target.value);
  }

  const getBookingAttendees = async () => {

    try {
      
      let response = await axios.get("/attendees/list", {
        params: {
          spaceID: selectedSpace.spaceID,
          bookingID: selectedBooking.bookingID
        },
        cancelToken: source.token
      });

      //Ensure no error is shown
      setError("");

      toggleLoadingSpinner(false);
      setBookingAttendeeRecords(response.data);

      //Get currentAttendees count
      getCurrentAttendeesCount(response.data);
    }
    catch (error) {
      if (axios.isCancel(error)) {
        return;
      }

      if (error.response && error.response.status === 404) {
        setCurrentAttendeesState(0);
        setError("The attendee list is empty for this booking");
      }

      toggleLoadingSpinner(false);
    }
  }

  //Refresh list
  const handleRefreshClick = () => {
    if (loadingSpinner) {
      return;
    }

    //Null out currentAttendees state count
    setCurrentAttendeesState(null);
    
    toggleLoadingSpinner(true);

    getBookingAttendees();
  
  }

  const getCurrentAttendeesCount = (currentAttendees) => {

    //Map over currentAttendees, counting any that do not have an end time
    let currentAttendeesCount = 0;

    for (var key in currentAttendees) {
      if (currentAttendees[key].endTime === "") {
        currentAttendeesCount++;
      }
    };
    
    setCurrentAttendeesState(currentAttendeesCount);
  }
  
  const classes = BookingCurrentAttendeesStyles();

  //Assemble current attendee items array from map
  let currentAttendeesArray = [];

  for (var key in bookingAttendeeRecords) {
    if ((`${bookingAttendeeRecords[key].intakeData.firstName} ${bookingAttendeeRecords[key].intakeData.lastName}`).toLocaleLowerCase()
    .includes(searchFieldName.toLowerCase())
    ) {
      currentAttendeesArray.push(bookingAttendeeRecords[key]);
    }
  }

  let currentAttendeesArrayOrdered = orderBy(
    currentAttendeesArray,
    [a => a.intakeData.lastName, a => a.intakeData.firstName],
    ['asc', 'asc']
  )

  let currentAttendeesComponentArray = [];

  //Assemble component array 
  for (let i = 0; i < currentAttendeesArrayOrdered.length; i++) {
    currentAttendeesComponentArray.push(
      <BookingCurrentAttendee 
        attendeeRecord={currentAttendeesArrayOrdered[i]} 
        key={currentAttendeesArrayOrdered[i].attendeesID}
        matchingRecords={bookingAttendeeRecords.filter(attendeeRecord => 
          attendeeRecord.registrationID === currentAttendeesArrayOrdered[i].registrationID)}
        isSuperAdmin={isSuperAdmin}
        index={i}
      />
    )
  }

  //Prepare headers for csv download
  let csvHeaders;

  if (selectedBooking.intakeFields[5].type==="international") {
    csvHeaders = [
      { label: "First Name", key: "firstName"},
      { label: "Last Name", key: "lastName"},
      { label: "Company Name", key: "company"},
      { label: "Email Address", key: "emailAddress"},
      { label: "Phone Number", key: "phoneNumber"},
      { label: "Address Line 1", key: "addressLine1"},
      { label: "Address Line 2", key: "addressLine2"},
      { label: "City", key: "city"},
      { label: "State/Province/Region", key: "stateProvinceRegion"},
      { label: "Zip/Postal Code", key: "zipPostalCode"},
      { label: "Country", key: "country"},
      { label: "Brand", key: "brand"},
      { label: "Start Time", key: "startTime"},
      { label: "End Time", key: "endTime"},
      { label: "Time in Room", key: "timeInRoom"},
      { label: "Login Code", key: "loginCode"},
      { label: "Registration ID", key: "registrationID"},
      { label: "Socket ID", key: "socketID"},
      { label: "Attendees ID", key: "attendeesID"}
    ]
    
  } else {
    csvHeaders = [
      { label: "First Name", key: "firstName"},
      { label: "Last Name", key: "lastName"},
      { label: "Company Name", key: "company"},
      { label: "Email Address", key: "emailAddress"},
      { label: "Phone Number", key: "phoneNumber"},
      { label: "Address Line 1", key: "addressLine1"},
      { label: "Address Line 2", key: "addressLine2"},
      { label: "City", key: "city"},
      { label: "State", key: "state"},
      { label: "Zip Code", key: "zipCode"},
      { label: "Brand", key: "brand"},
      { label: "Start Time", key: "startTime"},
      { label: "End Time", key: "endTime"},
      { label: "Time in Room", key: "timeInRoom"},
      { label: "Login Code", key: "loginCode"},
      { label: "Registration ID", key: "registrationID"},
      { label: "Socket ID", key: "socketID"},
      { label: "Attendees ID", key: "attendeesID"}
    ];
  }

  const getTimeInRoomForAttendee = (attendee) => {

    let timeHours;
    let timeMinutes;

    if (attendee.endTime !== "") {

      let endTime = new Date(attendee.endTime).toUTCString();

      let difference = new Date(endTime).getTime() - new Date(attendee.startTime).getTime();

      let differenceSeconds = Math.round(difference/1000);

      let timeFormatted = new Date(differenceSeconds * 1000).toISOString().substr(11, 8);

      let hours = timeFormatted.substring(0, 2);

      let hoursFormatted = parseInt(hours, 10);

      let minutes = timeFormatted.substring(3, 5);

      timeHours = hoursFormatted;
      timeMinutes = minutes;
    } 
    //End time does not exist, so calculate difference between now and start time
    else {
      let currentTime = new Date().toUTCString();

      let difference = new Date(currentTime).getTime() - new Date(attendee.startTime).getTime();

      let differenceSeconds = Math.round(difference / 1000);

      let timeFormatted = new Date(differenceSeconds * 1000).toISOString().substr(11,8);

      let hours = timeFormatted.substring(0, 2);
      let hoursFormatted = parseInt(hours, 10);

      let minutes = timeFormatted.substring(3, 5);

      timeHours = hoursFormatted;
      timeMinutes = minutes;
    }

    return `${timeHours}h:${timeMinutes}m`;
  }
  
  const csvData = [];

  if (selectedBooking.intakeFields[5].type === "international") {
    bookingAttendeeRecords.forEach(attendee => {

      let brand;

      if (attendee.brand) {
        brand = "true";
      } else {
        brand = "false";
      }

      csvData.push({
        firstName: attendee.intakeData.firstName,
        lastName: attendee.intakeData.lastName,
        company: attendee.intakeData.company,
        emailAddress: attendee.intakeData.emailAddress,
        phoneNumber: attendee.intakeData.phone,
        addressLine1: attendee.intakeData.address.addressLine1,
        addressLine2: attendee.intakeData.address.addressLine2,
        city: attendee.intakeData.address.city,
        stateProvinceRegion: attendee.intakeData.address.stateProvinceRegion,
        zipPostalCode: attendee.intakeData.address.zipPostalCode,
        country: attendee.intakeData.address.country,
        brand: brand,
        startTime: `${moment.tz(attendee.startTime, selectedBooking.start.timezone).format("MM/DD/YYYY")} ${moment.tz(attendee.startTime, selectedBooking.start.timezone).format("h:mm A z")}`,
        endTime: attendee.endTime !== "" ? `${moment.tz(attendee.endTime, selectedBooking.start.timezone).format("MM/DD/YYYY")} ${moment.tz(attendee.endTime, selectedBooking.start.timezone).format("h:mm A z")}` : "",
        timeInRoom: getTimeInRoomForAttendee(attendee),
        loginCode: attendee.loginCode,
        registrationID: attendee.registrationID,
        socketID: attendee.socketID,
        attendeesID: attendee.attendeesID
      })
    })

  } else {
    bookingAttendeeRecords.forEach(attendee => {

      let brand;

      if (attendee.brand) {
        brand = "true";
      } else {
        brand = "false";
      }

      csvData.push({
        firstName: attendee.intakeData.firstName,
        lastName: attendee.intakeData.lastName,
        company: attendee.intakeData.company,
        emailAddress: attendee.intakeData.emailAddress,
        phoneNumber: attendee.intakeData.phone,
        addressLine1: attendee.intakeData.address.addressLine1,
        addressLine2: attendee.intakeData.address.addressLine2,
        city: attendee.intakeData.address.city,
        state: attendee.intakeData.address.state,
        zipCode: attendee.intakeData.address.zipCode,
        brand: brand,
        startTime: `${moment.tz(attendee.startTime, selectedBooking.start.timezone).format("MM/DD/YYYY")} ${moment.tz(attendee.startTime, selectedBooking.start.timezone).format("h:mm A z")}`,
        endTime: attendee.endTime !== "" ? `${moment.tz(attendee.endTime, selectedBooking.start.timezone).format("MM/DD/YYYY")} ${moment.tz(attendee.endTime, selectedBooking.start.timezone).format("h:mm A z")}` : "",
        timeInRoom: getTimeInRoomForAttendee(attendee),
        loginCode: attendee.loginCode,
        registrationID: attendee.registrationID,
        socketID: attendee.socketID,
        attendeesID: attendee.attendeesID
      })
    })
  }
  
  return (
    <React.Fragment>
      <div className={styles.searchBarHolder}>
        <TextField
          value={searchFieldName}
          onChange={handleSearchFieldChange}
          variant="outlined"
          label={"Search Attendees"}
          size="small"
          className={classes.attendeeSearch}
        />

        <Typography variant="body1" className={classes.attendeesCount}>
          Current In-Event Count 
        </Typography>

        <div className={styles.countWrapper}>
          <Typography variant="body1" className={classes.attendeesCountCount}>
            {currentAttendeesState !== null ? ` ${currentAttendeesState}` : ""}
          </Typography>
        </div>
        
        <div className={styles.iconHolder}>
          <div>
            <IconButton
              style={{
                padding: "4px"
              }}
              onClick={handleRefreshClick}
              className={classes.refreshIcon}
            >
              <RefreshIcon 
                style={{
                  fontSize: "20px"
                }}
              />
            </IconButton>
          </div>
          
          <CSVLink
            data={csvData}
            headers={csvHeaders}
            className="downloadLink"
            filename={`${selectedBooking.name}-attendees.csv`}
            onClick={handleDownloadClick}
          >

            <IconButton
              className={loadingSpinner ? `${classes.downloadIcon} ${classes.downloadIconDisabled}` : classes.downloadIcon}
              disabled={loadingSpinner}
              style={{
                padding: "4px"
              }}
            >
              <DownloadIcon 
                style={{
                  fontSize: "20px"
                }}
              />
            </IconButton>

          </CSVLink>
        </div>

      </div>
      <Paper square elevation={3} className={classes.currentAttendeesHeaderHolder}>
        <Typography variant="h1" className={classes.nameHeader}>
          Name
        </Typography>

        <Typography variant="h1" className={classes.brandHeader}>
          Brand
        </Typography>

        <Typography variant="h1" className={classes.presenterHeader}>
          Presenter
        </Typography>

        <Typography variant="h1" className={classes.inRoomHeader}>
          In-Room
        </Typography>

        <Typography variant="h1" className={classes.timeHeader}>
          Time
        </Typography>

      </Paper>

      <div 
        className={styles.currentAttendeesHolder}
        style={{
          minHeight: (loadingSpinner || (error !== "")) ? "170px" : "48px"
        }}
      >

        {loadingSpinner 
        ? (
          <CircularProgress className={classes.loadingSpinner} />
        )
        : (
          <React.Fragment>
            {error !== "" &&
              <Typography variant="body1" className={classes.error}>
                {error}
              </Typography>
            }

            {
              [currentAttendeesComponentArray].map(item => {
                return (item);
              })
            }
          </React.Fragment>
        )}
        
      </div>

       {/*  ATTENDEE LIST DOWNLOAD SNACKBAR */}
       <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          autoHideDuration={1500}
          className={classes.downloadSnackbar}
          open={downloadCSV}
          onClose={handleDownloadClose}
          message="Attendee List (.csv) has downloaded"
        />
    </React.Fragment>
  )
}
