import React, { ReactElement, useState, useContext, useEffect } from "react";
import {
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  Link,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import RefreshIcon from "@material-ui/icons/Refresh";
import axios from "axios";
import { CSVLink } from "react-csv";
import DownloadIcon from "@material-ui/icons/GetApp";

import EditBookingCapacityPopup from "../../BookingEdit/EditBookingCapacityPopup/EditBookingCapacityPopup";
import { AppContext, AppContextType } from "../../../../context/AppContext";
import moment from "moment";
import CalendarAssignPopup from "../CalendarAssignPopup/CalendarAssignPopup";

import styles from "./MiscellaneousDisplay.module.css";
import { MiscellaneousDisplayStyles } from "./MiscellaneousDisplayStyles";
import useGetBookingUsage from "../../../../hooks/useGetBookingUsage";
import useGetAllSpaceCalendars from "../../../../hooks/useGetAllSpaceCalendars";

interface Props {
  privateCloud: boolean;
  selectedBookingStartTime: any;
}

const liveswitchServerArray = [
  {
    url: "https://cloud.liveswitch.io/",
    appID: "dc68fa69-6578-4b42-a0ae-a567b850a1d7",
    value: "Public Cloud",
  },
  {
    url: "https://sync.showboat-useast2.liveswitch.cloud",
    appID: "0471b1ff-a1c9-4d90-80a9-903489301dd4",
    value: "US Private Cloud",
  },
  {
    url: "https://sync.fm-ps-eunorth1.liveswitch.cloud",
    appID: "0471b1ff-a1c9-4d90-80a9-903489301dd4",
    value: "Europe Private Cloud",
  },
];

const gameServerArray = [
  {
    url: "engine.showboat.live",
    port: 443,
    value: "Public Server",
  },
  {
    url: "engine.showboat.live",
    port: 3777,
    value: "Development Server",
  },
  {
    url: "northeurope_engine1.showboat.live",
    port: 443,
    value: "Europe Server",
  },
];

export default function MiscellaneousDisplay(props: Props): ReactElement {
  const {
    selectedBooking,
    setSelectedBooking,
    selectedBookingUsage,
    setSelectedBookingUsage,
    isSuperAdmin,
    spaceCalendars,
    toggleBookingHasCalendar,
    selectedSpaceID,
    setSpaceCalendars,
  }: AppContextType = useContext(AppContext);

  //Custom hooks
  const { usageLoadingSpinner, getBookingUsage } = useGetBookingUsage();
  const { getAllCalendars } = useGetAllSpaceCalendars();

  const [editBookingCapacity, toggleEditBookingCapacity] = useState(false);

  const [qaLoadingSpinner, setQaLoadingSpinner] = useState(false);
  const [hasQAItems, setHasQAItems] = useState(false);

  const [bookingCalendarNameState, setBookingCalendarNameState] = useState("");

  const [liveswitchServer, setLiveswitchServer] = useState("");
  const [gameServer, setGameServer] = useState("");

  const [disableEditCapacity, toggleDisableEditCapacity] = useState(false);

  const [qaItems, setQAItems] = useState({});

  const [stateCounter, setStateCounter] = useState(0);

  const [sendEntryNotifications, setSendEntryNotifications] = useState(
    selectedBooking.sendEntryNotifications !== undefined
      ? selectedBooking.sendEntryNotifications
      : false
  );

  const [showInRoomHelpButton, setShowInRoomHelpButton] = useState(
    selectedBooking.showInRoomHelpButton !== undefined
      ? selectedBooking.showInRoomHelpButton
      : false
  );

  const [notificationLoadingSpinner, setNotificationLoadingSpinner] =
    useState(false);

  const [helpButtonLoadingSpinner, setHelpButtonLoadingSpinner] = useState(false);
    
  const [calendarEntry, toggleCalendarEntry] = useState(
    selectedBooking.calendarID !== undefined &&
      selectedBooking.calendarID !== ""
      ? true
      : false
  );
  const [calendarAssign, toggleCalendarAssign] = useState(false);

  const [privateCloud, togglePrivateCloud] = useState(
    selectedBooking.privateCloud !== undefined
      ? selectedBooking.privateCloud
      : false
  );

  const [enableQA, setEnableQA] = useState(
    selectedBooking.enableQA !== undefined ? selectedBooking.enableQA : false
  );

  const [privateCloudLoadingSpinner, togglePrivateCloudLoadingSpinner] =
    useState(false);
  const [calendarEntryLoadingSpinner, toggleCalendarEntryLoadingSpinner] =
    useState(false);

  const [qaDisplayCopied, setQADisplayCopied] = useState(false);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    //Get initial values of gameServer and LS server on mount
    for (let i = 0; i < liveswitchServerArray.length; i++) {
      if (selectedBooking.videoServer === liveswitchServerArray[i].url)
        setLiveswitchServer(liveswitchServerArray[i].value);
    }

    for (let i = 0; i < gameServerArray.length; i++) {
      if (
        selectedBooking.gameServer === gameServerArray[i].url &&
        selectedBooking.gamePort === gameServerArray[i].port
      )
        setGameServer(gameServerArray[i].value);
    }

    //Get qa items on mount
    const getQAItems = async () => {
      try {
        let qaItemsResponse = await axios.get("/booking/questions", {
          params: {
            bookingID: selectedBooking.bookingID,
          },
        });

        setQAItems(qaItemsResponse.data);
        setHasQAItems(true);
      } catch (error) {
        console.log("ERROR", error);
      }
    };
    getQAItems();

    //Get booking usage on mount
    const getBookingUsageOnMount = async () => {
      let bookingUsage = await getBookingUsage(selectedBooking);
      setSelectedBookingUsage(bookingUsage);
    };
    getBookingUsageOnMount();

    //Ensure that we have all space calendars on mount
    const getSpaceCalendarsOnMount = async () => {
      let spaceCalendarsReturnValue = await getAllCalendars(selectedSpaceID);
      setSpaceCalendars(spaceCalendarsReturnValue);
    };
    getSpaceCalendarsOnMount();

    return function cleanup() {
      source.cancel("Component was unmounted");

      setSelectedBookingUsage(null);
    };
  }, []);

  useEffect(() => {
    //Double check for calendar entry toggle
    if (
      selectedBooking.calendarID == "" ||
      selectedBooking.calendarID === undefined
    ) {
      toggleCalendarEntry(false);
    }
  }, [selectedBooking]);

  useEffect(() => {
    //Check if we need to disable capacity edit (privateCloud and <24hrs before event)
    if (props.privateCloud) {
      let timeDif = moment
        .utc(props.selectedBookingStartTime)
        .diff(moment.utc(), "h");

      if (timeDif < 24) {
        toggleDisableEditCapacity(true);
      } else {
        toggleDisableEditCapacity(false);
      }
    } else {
      toggleDisableEditCapacity(false);
    }
  }, [props.privateCloud, props.selectedBookingStartTime]);

  /* Refresh booking usage */
  const handleRefreshBookingUsageClick = async () => {
    setSelectedBookingUsage(null);

    let bookingUsage = await getBookingUsage(selectedBooking);
    setSelectedBookingUsage(bookingUsage);
  };
  /**/

  /* Edit booking capacity popup */
  const handleEditBookingCapacityClick = () => {
    toggleEditBookingCapacity(true);
  };

  const handleEditBookingCapacityClose = () => {
    toggleEditBookingCapacity(false);
  };
  /**/

  const handleEnableQAToggle = async () => {
    try {
      //Show loading spinner
      setQaLoadingSpinner(true);

      let selectedBookingClone = selectedBooking;

      let toggleState = !enableQA;

      selectedBookingClone.enableQA = toggleState;

      await axios.put("/booking", JSON.stringify(selectedBookingClone));

      setQaLoadingSpinner(false);

      setSelectedBooking(selectedBookingClone);

      setEnableQA(toggleState);
    } catch (error) {
      setQaLoadingSpinner(false);
      console.log(error);
    }
  };

  //LS Private Cloud toggle handler
  const handlePrivateCloudToggle = async () => {
    //Ensure we are SuperAdmin
    if (isSuperAdmin) {
      try {
        //Show loader
        togglePrivateCloudLoadingSpinner(true);

        let selectedBookingClone = selectedBooking;

        //Assemble object to send to Axios
        selectedBookingClone.privateCloud = !privateCloud;

        let response = await axios.put(
          "/booking",
          JSON.stringify(selectedBookingClone)
        );

        togglePrivateCloudLoadingSpinner(false);

        //Set selected booking to what is returned in response
        setSelectedBooking(response.data.Attributes);

        togglePrivateCloud(!privateCloud);
      } catch (error) {
        togglePrivateCloudLoadingSpinner(false);
        console.log(error);
      }
    }
  };

  /* Calendar Logic*/
  const handleCalendarEntryToggle = async () => {
    //Show assign popup if toggling on calendar entry
    if (!calendarEntry) {
      toggleCalendarAssign(true);
    }
    //Edit booking to empty string for calendar otherwise
    else {
      toggleCalendarEntryLoadingSpinner(true);

      toggleBookingHasCalendar(false);

      let selectedBookingClone = selectedBooking;

      selectedBookingClone.calendarID = "";

      try {
        await axios.put("/booking", JSON.stringify(selectedBookingClone));

        setSelectedBooking(selectedBookingClone);
        toggleCalendarEntryLoadingSpinner(false);
        toggleCalendarEntry(false);
      } catch {
        toggleCalendarEntryLoadingSpinner(false);
        alert("An error occurred.");
      }
    }
  };

  const handleSendEntryNotificationsToggle = async () => {
    try {
      //Show loading spinner
      setNotificationLoadingSpinner(true);

      let selectedBookingClone = selectedBooking;
      let toggleState = !sendEntryNotifications;
      selectedBookingClone.sendEntryNotifications = toggleState;

      await axios.put("/booking", JSON.stringify(selectedBookingClone));

      setNotificationLoadingSpinner(false);

      setSelectedBooking(selectedBookingClone);

      setSendEntryNotifications(toggleState);
    } catch (error) {
      setNotificationLoadingSpinner(false);
      console.log(error);
    }
  }

  const handleShowHelpButtonChange = async () => {
    try {
      setHelpButtonLoadingSpinner(true);

      let selectedBookingClone = selectedBooking;
      let toggleState = !showInRoomHelpButton;
      selectedBookingClone.showInRoomHelpButton = toggleState;
      
      await axios.put("/booking", JSON.stringify(selectedBookingClone));

      setHelpButtonLoadingSpinner(false);

      setSelectedBooking(selectedBookingClone);

      setShowInRoomHelpButton(toggleState);
    } catch (error) {
      console.log(error);
      setHelpButtonLoadingSpinner(false);
    }
  }

  const handleCalendarAssignClose = (calendarID: string) => {
    setStateCounter((stateCounter) => stateCounter + 1);

    //Check if there is still no calendar ID assigned, if so, toggle calendar entry to false
    if (calendarID === "" || calendarID === undefined) {
      toggleBookingHasCalendar(false);
      toggleCalendarEntry(false);
    } else {
      //Otherwise, toggle calendar entry to true
      toggleBookingHasCalendar(true);
      toggleCalendarEntry(true);
    }

    //Close popup
    toggleCalendarAssign(false);
  };

  //Edit assigned calendar
  const handleEditCalendarNameClick = () => {
    toggleCalendarAssign(true);
  };

  //Edit LiveSwitch Server
  const handleLiveswitchServerChange = async (e) => {
    //Retrieve info from LiveSwitch/server array

    togglePrivateCloudLoadingSpinner(true);

    let matchingServer, matchingAppID;

    for (let i = 0; i < liveswitchServerArray.length; i++) {
      if (liveswitchServerArray[i].value === e.target.value) {
        matchingServer = liveswitchServerArray[i].url;
        matchingAppID = liveswitchServerArray[i].appID;
      }
    }

    console.log("matching server", matchingServer);
    console.log("matching appID", matchingAppID);

    if (matchingServer === undefined || matchingAppID === undefined) return;

    //Add extra updated LS server to send up
    let selectedBookingClone = selectedBooking;

    selectedBookingClone.videoServer = matchingServer;
    selectedBookingClone.videoServerAppID = matchingAppID;

    setLiveswitchServer(e.target.value);

    let requestBody = {
      ...selectedBookingClone,
      updatedVideoServer: {
        url: matchingServer,
        appID: matchingAppID,
      },
    };

    try {
      await axios.put("/booking", JSON.stringify(requestBody));

      setSelectedBooking(selectedBookingClone);
      togglePrivateCloudLoadingSpinner(false);
    } catch (error) {
      togglePrivateCloudLoadingSpinner(false);

      console.log("ERROR", error);
    }
  };

  //Edit Game Server
  const handleGameServerChange = async (e) => {
    togglePrivateCloudLoadingSpinner(true);

    let matchingServer, matchingPort;

    for (let i = 0; i < gameServerArray.length; i++) {
      if (gameServerArray[i].value === e.target.value) {
        matchingServer = gameServerArray[i].url;
        matchingPort = gameServerArray[i].port;
      }
    }

    console.log("matching server", matchingServer);
    console.log("matching port", matchingPort);

    if (matchingServer === undefined || matchingPort === undefined) {
      togglePrivateCloudLoadingSpinner(false);
      return;
    }

    setGameServer(e.target.value);

    let selectedBookingClone = selectedBooking;

    selectedBookingClone.gameServer = matchingServer;
    selectedBookingClone.gamePort = matchingPort;

    let requestBody = {
      ...selectedBookingClone,
      updatedGameServer: {
        url: matchingServer,
        port: matchingPort,
      },
    };

    try {
      await axios.put("/booking", JSON.stringify(requestBody));

      setSelectedBooking(selectedBookingClone);
      togglePrivateCloudLoadingSpinner(false);
    } catch (error) {
      togglePrivateCloudLoadingSpinner(false);

      console.log("ERROR", error);
    }
  };

  const handleCopyQADisplay = () => {
    let dummy = document.createElement("textarea");

    document.body.appendChild(dummy);

    dummy.value = document.getElementById("qaDisplayLink").innerText;

    dummy.select();

    document.execCommand("copy");
    document.body.removeChild(dummy);

    setQADisplayCopied(true);
  };

  /**/

  const classes = MiscellaneousDisplayStyles();

  useEffect(() => {
    if (
      selectedBooking.calendarID !== undefined &&
      selectedBooking.calendarID !== "" &&
      calendarEntry
    ) {
      for (let i = 0; i < spaceCalendars.length; i++) {
        if (spaceCalendars[i].calendarID === selectedBooking.calendarID) {
          setBookingCalendarNameState(spaceCalendars[i].calendarName);
          break;
        }
      }
    }
  }, [selectedBooking, spaceCalendars, calendarEntry]);

  let csvHeaders = [
    { label: "Question Text", key: "questionText" },
    { label: "Asker Name", key: "askerName" },
    { label: "Votes", key: "votes" },
    { label: "Answer Text", key: "answerText" },
    { label: "Answerer Name", key: "answererName" },
    { label: "Status", key: "status" },
    { label: "Timestamp", key: "timestamp" },
  ];

  const csvData = [];

  for (const bookingID in qaItems) {
    let item = qaItems[bookingID];

    csvData.push({
      questionText: item.questionText !== undefined ? item.questionText : "",
      askerName: item.askerName !== undefined ? item.askerName : "",
      votes: item.votes !== undefined ? item.votes : 0,
      answerText: item.answerText !== undefined ? item.answerText : "",
      answererName: item.answererName !== undefined ? item.answererName : "",
      status: item.status !== undefined ? item.status : "",
      timestamp:
        item.lastUpdateTime !== undefined
          ? moment
              .tz(parseInt(item.lastUpdateTime), selectedBooking.start.timezone)
              .format("MMMM Do YYYY, h:mm:ss a")
          : "",
    });
  }

  return (
    <React.Fragment>
      <Typography variant="h2" classes={{ root: classes.miscellaneousHeader }}>
        Miscellaneous
      </Typography>

      <div className={styles.itemHolder}>
        <Typography variant="body1" classes={{ root: classes.capacityHeader }}>
          Event Capacity
        </Typography>

        <Typography variant="body1" classes={{ root: classes.capacity }}>
          {selectedBooking.capacity}
        </Typography>

        <IconButton
          className={`${classes.editButton} ${classes.editButtonCapacity}`}
          onClick={handleEditBookingCapacityClick}
          disabled={disableEditCapacity}
        >
          <EditIcon className={classes.editIcon} />
        </IconButton>

        <Divider className={classes.divider}></Divider>
      </div>

      <div className={styles.itemHolder}>
        <Typography variant="h2" classes={{ root: classes.userHoursHeader }}>
          Q&A
        </Typography>

        <Switch
          disabled={qaLoadingSpinner}
          className={classes.qaSwitch}
          checked={enableQA}
          onChange={handleEnableQAToggle}
        />

        {qaLoadingSpinner && (
          <CircularProgress className={classes.qaLoadingSpinner} />
        )}

        <CSVLink
          data={csvData}
          headers={csvHeaders}
          className="downloadLinkQA"
          filename={`${selectedBooking.name}-attendees.csv`}
        >
          <IconButton
            className={classes.downloadIcon}
            disabled={!enableQA || !hasQAItems}
            style={{
              padding: "4px",
            }}
          >
            <DownloadIcon
              style={{
                fontSize: "20px",
              }}
            />
          </IconButton>
        </CSVLink>

        <Divider className={classes.divider}></Divider>
      </div>

      {enableQA && (
        <div className={styles.itemHolder}>
          <Typography
            variant="h2"
            classes={{
              root: `${classes.userHoursHeader} ${classes.userHoursHeaderQADisplay}`,
            }}
          >
            Q&A Display
          </Typography>

          <Link
            id="qaDisplayLink"
            variant="body1"
            classes={{
              root: classes.qaDisplayLink,
            }}
            target="_blank"
            href={`https://display.showboat.live/${selectedBooking.publicAppLoginCode}`}
          >
            {`https://display.showboat.live/${selectedBooking.publicAppLoginCode}`}
          </Link>

          <IconButton
            onClick={handleCopyQADisplay}
            color="primary"
            classes={{ root: classes.qaDisplayCopy }}
          >
            <img
              className={styles.copyImage}
              alt="Copy icon"
              src="assets/images/CopyIcon.svg"
            />
          </IconButton>

          <Divider className={classes.divider}></Divider>
        </div>
      )}

      <div className={styles.itemHolder}>
        {usageLoadingSpinner && (
          <CircularProgress className={classes.usageLoadingSpinner} />
        )}

        <Typography variant="h2" classes={{ root: classes.userHoursHeader }}>
          User Hours
        </Typography>
        <Typography variant="body1" classes={{ root: classes.userHours }}>
          {selectedBookingUsage !== null &&
            `${(selectedBookingUsage / 3600).toFixed(2)} hours`}

          <IconButton
            className={
              usageLoadingSpinner
                ? `${classes.refreshIcon} ${classes.refreshIconLoading}`
                : classes.refreshIcon
            }
            onClick={handleRefreshBookingUsageClick}
          >
            <RefreshIcon className={classes.refreshButtonIcon} />
          </IconButton>
        </Typography>

        <Divider className={classes.divider}></Divider>
      </div>

      <div className={styles.itemHolder}>
        <Typography variant="body1" classes={{ root: classes.capacityHeader }}>
          Add to Calendar
        </Typography>

        {calendarEntry && !calendarEntryLoadingSpinner && (
          <Typography variant="body1" className={classes.calendarName}>
            {bookingCalendarNameState}

            <IconButton
              className={classes.editButton}
              onClick={handleEditCalendarNameClick}
            >
              <EditIcon className={classes.editIcon} />
            </IconButton>
          </Typography>
        )}

        <Switch
          className={classes.addToCalendarSwitch}
          disabled={calendarEntryLoadingSpinner}
          checked={calendarEntry}
          onChange={handleCalendarEntryToggle}
        />

        {calendarEntryLoadingSpinner && (
          <CircularProgress className={classes.calendarEntryLoadingSpinner} />
        )}

        {isSuperAdmin && <Divider className={classes.divider}></Divider>}
      </div>

      {isSuperAdmin && (
        <React.Fragment>
          <div className={styles.itemHolder}>
            <Typography
              variant="body1"
              classes={{ root: classes.capacityHeader }}
            >
              Send Entry Notifications
            </Typography>

            <Switch
              className={classes.addToCalendarSwitch}
              disabled={notificationLoadingSpinner}
              checked={sendEntryNotifications}
              onChange={handleSendEntryNotificationsToggle}
            />

            {notificationLoadingSpinner && (
              <CircularProgress
                className={classes.calendarEntryLoadingSpinner}
              />
            )}

            <Divider className={classes.divider}></Divider>
          </div>
          <div className={styles.itemHolder}>
            <Typography
              variant="body1"
              classes={{ root: classes.capacityHeader }}
            >
              Show In-Room Help Button
            </Typography>

            <Switch
              className={classes.addToCalendarSwitch}
              disabled={helpButtonLoadingSpinner}
              checked={showInRoomHelpButton}
              onChange={handleShowHelpButtonChange}
            />

            {helpButtonLoadingSpinner && (
              <CircularProgress
                className={classes.calendarEntryLoadingSpinner}
              />
            )}

            <Divider className={classes.divider}></Divider>
          </div>
          <div className={styles.privateCloudHolder}>
            <Typography
              variant="body1"
              classes={{
                root: `${classes.capacityHeader} ${classes.capacityHeaderPrivateCloud}`,
              }}
            >
              LiveSwitch Server
            </Typography>

            {privateCloudLoadingSpinner && (
              <CircularProgress
                className={classes.privateCloudLoadingSpinner}
              ></CircularProgress>
            )}

            <FormControl variant="outlined" size="small">
              <Select
                value={liveswitchServer}
                onChange={handleLiveswitchServerChange}
                className={classes.liveswitchServerSelect}
                disabled={privateCloudLoadingSpinner}
              >
                <MenuItem className={classes.menuItem} value={"Public Cloud"}>
                  Public Cloud
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  value={"US Private Cloud"}
                >
                  US Private Cloud
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  value={"Europe Private Cloud"}
                >
                  Europe Private Cloud
                </MenuItem>
              </Select>
            </FormControl>

            <Typography
              variant="body1"
              classes={{
                root: `${classes.capacityHeader} ${classes.capacityHeaderPrivateCloud} ${classes.capacityHeaderGameServer}`,
              }}
            >
              Game Server
            </Typography>

            <FormControl variant="outlined" size="small">
              <Select
                value={gameServer}
                onChange={handleGameServerChange}
                className={classes.gameServerSelect}
                disabled={privateCloudLoadingSpinner}
              >
                <MenuItem className={classes.menuItem} value={"Public Server"}>
                  Public Server
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  value={"Development Server"}
                >
                  Development Server
                </MenuItem>
                <MenuItem className={classes.menuItem} value={"Europe Server"}>
                  Europe Server
                </MenuItem>
              </Select>
            </FormControl>

            {/* <Switch
            className={`${classes.addToCalendarSwitch} ${classes.privateCloudSwitch}`}
            disabled={privateCloudLoadingSpinner}
            checked={privateCloud}
            onChange={handlePrivateCloudToggle}
          /> */}

            <Divider
              className={`${classes.divider} ${classes.dividerPrivateCloud}`}
            ></Divider>
          </div>
        </React.Fragment>
      )}

      {editBookingCapacity && (
        <EditBookingCapacityPopup
          open={editBookingCapacity}
          handlePopupClose={handleEditBookingCapacityClose}
        />
      )}

      {calendarAssign && (
        <CalendarAssignPopup
          open={true}
          handlePopupClose={handleCalendarAssignClose}
        />
      )}

      {/* PHOTO COPY TO CLIPBOARD SNACKBAR */}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={1500}
        className={classes.snackbar}
        open={qaDisplayCopied}
        onClose={() => setQADisplayCopied(false)}
        message="Q&A Display URL Copied to Clipboard"
      />
    </React.Fragment>
  );
}
