import React, { ReactElement, useState, useContext, useEffect } from "react";
import {
  Typography,
  Button,
  Dialog,
  CircularProgress,
  ClickAwayListener,
} from "@material-ui/core";
import TimezoneSelect from "react-timezone-select";
import TimeIcon from "@material-ui/icons/AccessTime";
import moment from "moment-timezone";
import MomentUtils from "@date-io/moment";
import axios from "axios";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import { AppContext, AppContextType } from "../../../../context/AppContext";
import PopupHeader from "../../../PopupHeader/PopupHeader";
import SaveButton from "../../../ui/SaveButton/SaveButton";

import styles from "./EditDoorsOpenClosed.module.css";
import { EditDoorsOpenClosedStyles } from "./EditDoorsOpenClosedStyles";
import CancelButton from "../../../ui/CancelButton/CancelButton";
import SaveLoadingSpinner from "../../../ui/SaveLoadingSpinner/SaveLoadingSpinner";



interface Props {
  open: boolean;
  handlePopupClose: () => any;
  duplicateBooking: boolean;
  handleNextButtonClick?: (startTime: string) => any;
  doorsClosedTime: boolean;
}

export default function EditDoorsOpenClosed(props: Props): ReactElement {
  const { selectedBooking, setSelectedBooking, selectedSpace }: AppContextType =
    useContext(AppContext);

  const [selectedDate, setSelectedDate] = useState(
    props.doorsClosedTime
      ? moment
          .tz(selectedBooking.doorsClosed, selectedBooking.start.timezone)
          .format()
      : moment
          .tz(selectedBooking.doorsOpen, selectedBooking.start.timezone)
          .format()
  );
  const [selectedTimezone, setSelectedTimezone] = useState({});
  const [selectedTimezoneValue, setSelectedTimezoneValue] = useState(
    selectedBooking.start.timezone
  );
  const [timezonePickerOpen, toggleTimezonePickerOpen] = useState(false);
  const [timezoneError, toggleTimezoneError] = useState(false);
  const [dateError, toggleDateError] = useState(false);
  const [spaceDateError, toggleSpaceDateError] = useState(false);
  const [loadingSpinner, toggleLoadingSpinner] = useState(false);

  useEffect(() => {
    moment.tz.setDefault(selectedBooking.start.timezone);

    //Set default timezone value
    setSelectedTimezone({
      value: 1212121,
      label: selectedTimezoneValue,
    });
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date.tz(selectedTimezoneValue, true).format());
  };

  const handleTimeChange = (time) => {
    setSelectedDate(time.tz(selectedTimezoneValue, true).format());
  };

  const handleTimezoneChange = (tz) => {
    toggleTimezonePickerOpen(false);

    moment.tz.setDefault(tz.value);

    toggleTimezoneError(false);

    let currentDate = moment(selectedDate);

    currentDate.tz(tz.value).format("ha z");

    setSelectedDate(currentDate.format());
    setSelectedTimezone(tz);
    setSelectedTimezoneValue(tz.value);
  };

  const handleSaveButtonClick = async () => {
    toggleLoadingSpinner(true);
    toggleDateError(false);
    toggleSpaceDateError(false);

    if (props.doorsClosedTime) {
      //If we are editing doors closed, ensure it is not before event end time
      if (
        moment.utc(selectedDate).valueOf() <
        moment.utc(selectedBooking.end.time).valueOf()
      ) {
        toggleLoadingSpinner(false);
        toggleDateError(true);
        return;
      }
    } else {
      //If we are editing doors open, ensure it is not after event
      if (
        moment.utc(selectedDate).valueOf() >
        moment.utc(selectedBooking.start.time).valueOf()
      ) {
        toggleLoadingSpinner(false);
        toggleDateError(true);
        return;
      }
    }

    //Make sure not before space open time
    /*     if (moment.utc(selectedDate).valueOf() < moment.utc(selectedSpace.startDate.date).valueOf()) {
      toggleLoadingSpinner(false);
      toggleSpaceDateError(true);
      return;
    }

    //Make sure not after space close time
    if (moment.utc(selectedDate).valueOf() > moment.utc(selectedSpace.endDate.date).valueOf()) {
      toggleLoadingSpinner(false);
      toggleSpaceDateError(true);
      return;
    } */

    if (Object.keys(selectedTimezone).length === 0) {
      toggleTimezoneError(true);

      return;
    } else {
      //UPDATE BOOKING DOORS OPEN/CLOSED TIME

      toggleTimezoneError(false);

      let selectedBookingClone = selectedBooking;

      if (props.doorsClosedTime) {
        selectedBookingClone.doorsClosed = selectedDate;
      } else {
        selectedBookingClone.doorsOpen = selectedDate;
      }

      try {
        await axios.put("/booking", JSON.stringify(selectedBookingClone));

        toggleLoadingSpinner(false);
        setSelectedBooking(selectedBookingClone);
        props.handlePopupClose();
      } catch {
        toggleLoadingSpinner(false);
      }
    }
  };

  const handleCancelButtonClick = () => {
    if (loadingSpinner) {
      return;
    }

    toggleDateError(false);
    setSelectedDate(selectedBooking.start.time as any);
    setSelectedTimezone({
      value: 1212121,
      label: selectedBooking.start.timezone,
    });
    props.handlePopupClose();
  };

  const handleTimezonePickerClick = () => {
    toggleTimezonePickerOpen(!timezonePickerOpen);
  };

  const handleTimezonePickerClose = () => {
    toggleTimezonePickerOpen(false);
  };

  const classes = EditDoorsOpenClosedStyles();

  return (
    <Dialog
      open={props.open}
      classes={{
        paper: timezonePickerOpen
          ? `${classes.dialogPaper} ${classes.dialogPaperTimezoneOpen} `
          : classes.dialogPaper,
      }}
    >
      <div
        className={
          timezonePickerOpen
            ? `${classes.popup} ${classes.popupTimezoneOpen}`
            : classes.popup
        }
      >
        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={loadingSpinner}
        />

        <div className={styles.contentWrapper}>
          <Typography variant="h1" className={classes.editTimeHeader}>
            Set Doors {props.doorsClosedTime ? "Closed" : "Open"} Time
          </Typography>
          <Typography variant="body1" className={classes.editTimeText}>
            The booking will {props.doorsClosedTime ? "close" : "open"} at this
            time
          </Typography>

          {timezoneError && (
            <Typography variant="body1" className={classes.errorText}>
              Please select a timezone
            </Typography>
          )}

          {dateError && (
            <Typography variant="body1" className={classes.dateError}>
              {props.doorsClosedTime
                ? "Selected doors closed time must be after event end time"
                : "Selected doors open time must be before event start time"}
            </Typography>
          )}

          {spaceDateError && (
            <Typography variant="body1" className={classes.dateError}>
              Start date/time must be within the open/close time of this space
            </Typography>
          )}

          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              clearable
              label="Enter Date"
              inputVariant="outlined"
              value={selectedDate}
              onChange={(date) => handleDateChange(date)}
              format="MM/DD/YYYY"
              className={classes.datePicker}
              okLabel={<span style={{ color: "green" }}>OK</span>}
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardTimePicker
              keyboardIcon={<TimeIcon />}
              label="Enter Time"
              inputVariant="outlined"
              className={classes.timePicker}
              value={selectedDate}
              mask="__:__ _M"
              /* inputValue={moment.tz(selectedDate, selectedTimezoneValue).format("hh:mm a")} */
              onChange={(date) => handleTimeChange(date)}
              okLabel={<span style={{ color: "green" }}>OK</span>}
            />
          </MuiPickersUtilsProvider>

          <Typography variant="body1" className={classes.timezoneLabel}>
            Select Timezone
          </Typography>

          <ClickAwayListener onClickAway={handleTimezonePickerClose}>
            <div className={styles.timezonePicker}>
              <TimezoneSelect
                value={selectedTimezone}
                onChange={(tz) => handleTimezoneChange(tz)}
                className={styles.timezonePicker}
                style={{
                  opacity: "0.5",
                }}
              />
            </div>
          </ClickAwayListener>
        </div>

        {loadingSpinner && (
          <SaveLoadingSpinner />
        )}

        <CancelButton 
          variant="contained"
          onClick={handleCancelButtonClick}
        />

        <SaveButton
          disableBoolean={loadingSpinner}
          onClick={handleSaveButtonClick}
        />
        
      </div>
    </Dialog>
  );
}
