import React, { ReactElement, useState, useEffect, useContext } from "react";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import { orderBy } from "natural-orderby";

import SendEmailPresenter from "../SendEmailPresenter/SendEmailPresenter";
import { AppContext, AppContextType } from "../../../../../context/AppContext";
import {
  EmailContext,
  EmailContextType,
} from "../../../../../context/EmailContext";

import styles from "./SendEmailPresenters.module.css";
import { SendEmailPresentersStyles } from "./SendEmailPresentersStyles";
import useGetAllAttendeesOrPresenters from "../../../../../hooks/useGetAllAttendeesOrPresenters";

interface Props {
  currentTab: number;
}

export default function SendEmailPresenters(props: Props): ReactElement {
  const { selectedBookingID }: AppContextType = useContext(AppContext);

  const {
    selectedPresenters,
    setSelectedPresenters,
    selectedEmailTemplate,
  }: EmailContextType = useContext(EmailContext);

  const {
    isLoading,
    error,
    noPresenters,
    getAllAttendeesOrPresenters,
    bookingPresenters,
  } = useGetAllAttendeesOrPresenters(selectedBookingID, true);

  const [selectAll, toggleSelectAll] = useState(false);
  const [selectAllBrand, toggleSelectAllBrand] = useState(false);
  const [selectAllSent, toggleSelectAllSent] = useState(false);
  const [selectAllUnsent, toggleSelectAllUnsent] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  //GET BOOKING PRESENTERS
  useEffect(() => {
    setSelectedPresenters([]);

    getAllAttendeesOrPresenters();

    return function cleanup() {
      source.cancel("Component was unmounted");
    };
  }, []);

  const classes = SendEmailPresentersStyles();

  /* SEARCH FIELD CHANGE */
  const handleSearchFieldChange = (e) => {
    setSearchValue(e.target.value);
  };
  /**/

  /* SELECT ALL ATTENDEES*/
  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedPresenters([]);
    } else {
      setSelectedPresenters(bookingPresenters);
    }

    toggleSelectAll(!selectAll);
  };
  /**/

  /* SELECT ALL BRAND ATTENDEES */
  const handleSelectAllBrandChange = () => {
    if (selectAllBrand) {
      setSelectedPresenters(
        selectedPresenters.filter((presenter) => !presenter.brand)
      );
    } else {
      bookingPresenters.forEach((presenter) => {
        if (presenter.brand) {
          setSelectedPresenters((selectedPresenters) => [
            ...selectedPresenters,
            presenter,
          ]);
        }
      });
    }
    toggleSelectAllBrand(!selectAllBrand);
  };
  /**/

  /* SELECT ALL PREVIOUSLY SENT */
  const handleSelectAllPreviousChange = () => {
    let emailTemplateID = selectedEmailTemplate.emailTemplateID;

    /* TODO: TODO: TODO: TODO: */

    if (selectAllSent) {
      //remove all sent previous
      setSelectedPresenters(
        selectedPresenters.filter(
          (presenter) => !presenter.emails.includes(emailTemplateID)
        )
      );
    } else {
      bookingPresenters.forEach((presenter) => {
        if (presenter.emails.includes(emailTemplateID)) {
          setSelectedPresenters((selectedPresenters) => [
            ...selectedPresenters,
            presenter,
          ]);
        }
      });
    }
    toggleSelectAllSent(!selectAllSent);
  };
  /**/

  /* SELECT ALL UNSENT */
  const handleSelectAllUnsentChange = () => {
    let emailTemplateID = selectedEmailTemplate.emailTemplateID;

    if (selectAllUnsent) {
      //add all sent previous
      setSelectedPresenters(
        selectedPresenters.filter((presenter) =>
          presenter.emails.includes(emailTemplateID)
        )
      );
    } else {
      //add all unsent previous
      bookingPresenters.forEach((presenter) => {
        if (!presenter.emails.includes(emailTemplateID)) {
          setSelectedPresenters((selectedPresenters) => [
            ...selectedPresenters,
            presenter,
          ]);
        }
      });
    }

    toggleSelectAllUnsent(!selectAllUnsent);
  };
  /**/

  //BOOKING PRESENTERS
  let presentersArray = [];
  for (var key in bookingPresenters) {
    if (
      `${bookingPresenters[key].registrationData.firstName} ${bookingPresenters[key].registrationData.lastName}`
        .toLocaleLowerCase()
        .includes(searchValue.toLowerCase())
    ) {
      presentersArray.push(bookingPresenters[key]);
    }
  }

  let presentersArrayOrdered = orderBy(
    presentersArray,
    [(p) => p.registrationData.lastName, (p) => p.registrationData.firstName],
    ["asc", "asc"]
  );

  let presentersComponentArray = [];

  for (let i = 0; i < presentersArrayOrdered.length; i++) {
    presentersComponentArray.push(
      <SendEmailPresenter
        registration={presentersArrayOrdered[i]}
        presenters={true}
        selectAll={selectAll}
        selectAllBrand={selectAllBrand}
        selectAllSentPrevious={selectAllSent}
        key={presentersArrayOrdered[i].registrationID}
      />
    );
  }

  return (
    <React.Fragment>
      <div
        className={styles.searchHolder}
        style={{ display: props.currentTab === 1 ? "inline-block" : "none" }}
      >
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              checked={selectAll}
              onChange={handleSelectAllChange}
              name="Select All"
              color="primary"
            />
          }
          label="Select All"
        />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              checked={selectAllBrand}
              onChange={handleSelectAllBrandChange}
              name="Select All Brand"
              color="primary"
            />
          }
          label="Select All Brand"
        />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              checked={selectAllSent}
              onChange={handleSelectAllPreviousChange}
              name="Select All Sent"
              color="primary"
            />
          }
          label="Select All Sent"
        />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              checked={selectAllUnsent}
              onChange={handleSelectAllUnsentChange}
              name="Select All Unsent"
              color="primary"
            />
          }
          label="Select All Unsent"
        />

        <TextField
          variant="outlined"
          label={"Presenter Search"}
          className={classes.attendeeSearch}
          value={searchValue}
          onChange={handleSearchFieldChange}
          InputProps={{
            className: classes.searchInput,
          }}
        />
      </div>
      <div
        className={styles.sendEmailPresentersHolder}
        style={{ display: props.currentTab === 1 ? "inline-block" : "none" }}
      >
        <div className={styles.presenterListHeader}>
          <Typography variant="body1" className={classes.nameHeader}>
            Name
          </Typography>
          <Typography variant="body1" className={classes.brandHeader}>
            Brand
          </Typography>
          <Typography variant="body1" className={classes.sentPreviousHeader}>
            Sent Previous
          </Typography>
        </div>

        <div className={styles.presenterListHolder}>
          {error !== "" && (
            <Typography variant="body1" className={classes.error}>
              An error occurred getting presenters for this booking
            </Typography>
          )}
          {noPresenters && (
            <Typography variant="body1" className={classes.noPresentersError}>
              No presenters found for the selected booking
            </Typography>
          )}

          {isLoading && <CircularProgress className={classes.loadingSpinner} />}

          {[presentersComponentArray].map((item) => {
            return item;
          })}

          {/* {
            [presentersArray].filter(item => 
            `${(item as any).registrationData.firstName} ${(item as any).registrationData.lastName}`
            .toLocaleLowerCase().includes(searchValue.toLowerCase()))
            .map(item => {
              return (item);
            })
          } */}
        </div>
      </div>
    </React.Fragment>
  );
}
