import { makeStyles, createStyles } from '@material-ui/core/styles';

const BookingOverviewStyles = makeStyles((theme: any) =>
  createStyles({
    dividerIntake: {
      position: "relative",
      top: 34,
      left: 31,
      width: "92%",
    },
    dividerPhotoAlbum: {
      position: "relative",
      top: 55,
      left: 31,
      width: "92%",
    },
    copyButton: {
      position: "relative",
      bottom: 8,
      padding: 4,
    },
    regenerateButton: {
      marginLeft: 20,
      position: "relative",
      padding: 4,
      bottom: 8,
      right: 12,
      width: 28,
      height: 28,
    },
    qrCodeButton: {
      marginLeft: 10,
    },
    intakeUrlHeader: {
      fontSize: 14,
      fontWeight: "bold",
      position: "absolute",
      left: 30,
    },
    intakeURLHeaderPhotoAlbum: {
      top: 9,
    },
    intakeUrl: {
      fontSize: 14,
      position: "absolute",
      fontFamily: "Helvetica",
      left: 130.5,
      color: "black !important",
      "&:hover": {
        cursor: "pointer",
      },
    },
    mobileHybridUrl: {
      left: 214,
    },
    photoAlbumUrl: {
      fontSize: 14,
      position: "absolute",
      fontFamily: "Helvetica",
      left: 160.5,
      display: "inline-block",
      wordWrap: "break-word",
      maxWidth: 455,
      color: "black !important",
      "&:hover": {
        cursor: "pointer",
      },
    },
    dividerOne: {
      position: "relative",
      top: 162,
      left: 25,
      width: "93%",
    },
    dividerOneCalendarSelected: {
      top: 202,
    },
    dividerTwo: {
      left: 25,
      width: "93%",
      position: "relative",
      top: 350,
    },
    dividerTwoCalendarSelected: {
      top: 390,
    },
    snackbar: {
      width: 360,
    },
    calendarEntryHeader: {
      fontSize: 16,
      position: "absolute",
      left: 27,
    },
    calenadarEntryToggle: {
      position: "absolute",
      left: 175,
      top: -7,
    },
    calendarNameHeader: {
      fontSize: 16,
      fontWeight: 400,
      position: "absolute",
      left: 27,
    },
    calendarName: {
      fontSize: 16,
      position: "relative",
      display: "inline-block",
      left: 185,
      top: 0,
      maxWidth: 350,
      minWidth: 100,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      fontWeight: 400,
    },
    editIcon: {
      fontSize: 20,
      "&:hover": {
        cursor: "pointer",
      },
    },
    calendarEntryLoadingSpinner: {
      width: "20px !important",
      height: "20px !important",
      position: "absolute",
      right: 450,
      top: 2,
    },
    privateCloudHeader: {
      fontSize: 16,
      position: "absolute",
      left: 300,
    },
    privateCloudToggle: {
      position: "absolute",
      left: 445,
      top: -7,
    },
    linksHeader: {
      fontSize: 16,
      fontWeight: 400,
      position: "relative",
      top: 0,
      left: 30,
    },
  })
);

export { BookingOverviewStyles };