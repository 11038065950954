import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const LoginCodeMenuStyles = makeStyles((theme: any) =>
  createStyles({
    loginCodeAdminWrapper: {
      position: "absolute",
      display: "inline-block",
      width: 714,
      height: 550,
      top: 40,
      left: 0,
      right: 0,
      bottom: 0,
      margin: "auto",
      border: "1px solid #dedede",
      borderRadius: 0,
      textAlign: "center",
      [theme.breakpoints.down("md")]: {},
      [theme.breakpoints.down("sm")]: {},
    },
    loginCodeHeader: {
      fontSize: 30,
      position: "relative",
      display: "inline-block",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      top: 20,
    },
    loginCodeDivider: {
      width: "90%",
      position: "relative",
      top: 25,
      display: "inline-block",
    },
    adminMenuButton: {
      position: "absolute",
      top: 15,
      left: 15,
      zIndex: 900,
    },
    loginTextField: {
      width: 300,
    },
    loginCodeEntryHeader: {
      position: "relative",
      fontSize: 18,
      float: "left",
      fontWeight: 400,
    },
    submitButton: {
      position: "relative",
    },
    loadingSpinner: {
      color: "grey",
      position: "absolute",
      width: 20,
      height: 20,
      left: 150,
      top: 216,
    },
    loginCodeLinkHeader: {
      fontSize: 18,
      fontWeight: 400,
    },
    bookingLink: {
      fontSize: 18,
      fontWeight: 400,
      "&:hover": {
        cursor: "pointer"
      }
    },
  })
);

export { LoginCodeMenuStyles };