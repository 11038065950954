import { Link, Paper, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import React, { ReactElement, useState, useEffect } from 'react'

import styles from './ErrorPage.module.css';
import { ErrorPageStyles } from './ErrorPageStyles'
import { JWTHelper } from '../../utilities/JWTHelper';

interface Props {
  handleLinkClick?: () => any
  errorText: string,
}

export default function ErrorPage(props: Props): ReactElement {

  const [ authenticated, toggleAuthenticated ] = useState(false);
  
  useEffect(() => {
    toggleAuthenticated(checkAuthentication());
  }, [])
  
  const history = useHistory();
  
  const checkAuthentication = () => {
    //If either producerID or idToken are null, we are not authenticated
    if (localStorage.getItem("producerID") == null) {
      return false;
    }

    if (localStorage.getItem("idToken") == null) {
      return false;
    } 

    return true;
  }
  
  const handleLinkClick = () => {
    //Check authentication, then determine where to navigate
    if (checkAuthentication()) {
      //We are authenticated, so navigate home
      history.push("/home")
    } else {
      //We are not authenticated, so navigate to sign in && refresh
      window.location.replace("/");
    }

    if (props.handleLinkClick) props.handleLinkClick();
  }
  
  const classes = ErrorPageStyles();
  
  return (
    <div className={styles.errorPageWrapper}>
      <Paper className={classes.errorMessageHolder}>

        <img className={styles.showboatLogo} alt="showboat logo" src="assets/images/sb-logo.png" />

        <Typography 
          variant="body1" 
          className={!authenticated ? `${classes.errorMessage} ${classes.errorMessageUnauth}` : classes.errorMessage}
        >
          {props.errorText}
          {!authenticated && " Please return to sign in form to ensure you are authenticated."}
          <Link 
            className={classes.link}
            onClick={handleLinkClick}
          >
            {` Return to ${authenticated ? "Producer Portal" : "Sign In" }`}.
          </Link>
        </Typography>

      </Paper>
    </div>
  )
}
