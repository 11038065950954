import React, { ReactElement, useContext, useEffect } from 'react';
import { Dialog, Typography, Button } from '@material-ui/core';

import AvatarColorPreview from '../AvatarColorPreview/AvatarColorPreview';
import { AppContext, AppContextType } from '../../../context/AppContext';
import PopupHeader from '../../PopupHeader/PopupHeader';

import { PreviewAssetPopupStyles } from './PreviewAssetPopupStyles';
import styles from './PreviewAssetPopup.module.css';

interface Props {
  handlePopupClose: () => any,
  previewObject: PreviewObject
}

interface PreviewObject {
  title: string,
  property: string,
  value: string,
  type: string,
  division: string
}

export default function PreviewAssetPopup(props: Props): ReactElement {

  const { selectedSpace } : AppContextType = useContext(AppContext);

  const classes = PreviewAssetPopupStyles();

  let imageClass;

  if (props.previewObject.title === "Header Image") {
    imageClass = `${styles.previewImage} ${styles.previewImageHeader}`
  } else if (props.previewObject.title === "Header Logo") {
    imageClass = `${styles.previewImage} ${styles.previewImageLogo}`
  } else {
    imageClass = styles.previewImage;
  }

  return (
    <Dialog 
      open={true}
      classes={{ 
        paper: props.previewObject.type === "Audio" 
          ? `${classes.dialogPaper} ${classes.dialogPaperAudio}`
          : 
            props.previewObject.type === "ColorPairList"
             ? `${classes.dialogPaper} ${classes.dialogPaperAvatar}`
              : props.previewObject.title === "Header Image"
                ? `${classes.dialogPaper} ${classes.dialogPaperHeaderImage}`
                : classes.dialogPaper
      }}
    >
      <div 
        className={
          props.previewObject.type === "Audio" 
          ? `${classes.popup} ${classes.popupAudio}` 
          : 
            props.previewObject.type === "ColorPairList"
              ? `${classes.popup} ${classes.popupAvatar}`
              : props.previewObject.title === "Header Image"
                ? `${classes.popup} ${classes.popupHeaderImage}`
                : classes.popup
        }
      >

        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={false}
        />
        
        <Typography variant="h1" className={classes.previewHeader}>
          {props.previewObject.title}
        </Typography>
      
        {
          {
            "Image": 
              <div className={styles.imageHolder}>
                <img 
                  className={imageClass} 
                  src={selectedSpace[props.previewObject.division][props.previewObject.property].value} 
                  alt={`${props.previewObject.type} preview`}
                />
              </div>,

            "Video": 
              <div className={styles.videoHolder}>
                <video 
                  className={styles.previewVideo} 
                  src={selectedSpace[props.previewObject.division][props.previewObject.property].value} 
                  controls 
                />
              </div>,
            "ColorPairList": 
              <AvatarColorPreview colors={selectedSpace.avatarSkin.avatarColors.value} />,
            "Audio": 
              <audio className={styles.audioPreview} controls>
                <source src={selectedSpace[props.previewObject.division][props.previewObject.property].value}></source>
                  Your browser does not support this audio element.
              </audio>
            
          }[props.previewObject.type]
        }
      </div>

    </Dialog>
  )
}
