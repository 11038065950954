export class StringHelper {

  public static PresenterPermissions = {
    canPresent: "canPresent",
    canUseLaser: "canUseLaser",
    canControlAvatarAudio: "canControlAvatarAudio",
    canTeleport: "canTeleport",
    canBreakout: "canBreakout",
    canShareWebcam: "canShareWebcam",
    canScreenshare: "canScreenshare",
    canShareSlides: "canShareSlides",
    canShareLocalVideo: "canShareLocalVideo",
    canShareLivestream: "canShareLivestream",
    canShareDefault: "canShareDefault"
  }

  public static PermissionsHelpText = {
    canPresent: ["Turns on/off Present Mode access.", "Present Mode allows users to be heard and seen from every room in an event."],
    canUseLaser: ["Turns on/off laser pointer access."],
    canControlAvatarAudio: ["Turns on/off ability to control avatars.", "Avatar controls consist of silencing the audience, forcing 2D mode, etc."],
    canTeleport: ["Turns on/off access to teleportation."],
    canBreakout: ["Turns on/off access to breakout feature.", "This feature allows presenters to force-move attendees to a new room."],
    canShareWebcam: ["Turns on/off access to webcam sharing.", "This feature allows the presenter to share their own webcam feed to the jumbotron.", "Requires present mode access."],
    canScreenshare: ["Turns on/off access to screen sharing.", "This tool allows the presenter to share their screen to the jumbotron."],
    canShareSlides: ["Turns on/off access to slide sharing.", "This feature allows the presenter to share pre-uploaded slides to the jumbotron."],
    canShareLocalVideo: ["Turns on/off access to local video sharing.", "This feature allows the presenter to share a video file to the jumbotron."],
    canShareLivestream: ["Turns on/off ability to share a livestream.", "This allows the presenter to share a livestream URL to the jumbotron."],
    canShareDefault: ["Turns on/off ability to set jumbotron back to the default screen."]
  }
}