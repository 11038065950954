import React, { ReactElement } from "react";

import styles from './AdminInterfaceWrapper.module.css';

interface Props {
  wrapperHeight: number;
  wrapperWidth: number;
  children: any;
}

export default function AdminInterfaceWrapper(props: Props): ReactElement {
  return (
    <div
      className={styles.adminInterfaceWrapper}
      style={{
        height: props.wrapperHeight,
        width: props.wrapperWidth
      }}
    >
      {props.children}
    </div>
  );
}
