import { makeStyles, createStyles } from '@material-ui/core/styles';

const RegistrationEmailEditStyles = makeStyles((theme: any) => createStyles({
  dialogPaper: {
    width: 492,
    height: 320,
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "auto",
  },
  popup: {
    width: "100%",
    height: 320,
    position: "absolute",
  },
  editErrorMessageHeader: {
    fontSize: 24,
    position: "relative",
    left: 30,
    top: 58,
  },
  editErrorMessageSubheader: {
    fontSize: 14,
    width: "89%",
    display: "inline-block",
  },
  errorMessageTextField: {
    width: 432,
    position: "absolute",
    left: 30,
    top: 142
  },
  errorMessageInput: {
    backgroundColor: "#F7F7F7"
  },
  error: {
    color: "red",
    position: "absolute",
    left: 20,
    top: 200,
    fontSize: 14,
  },
  loadingSpinner: {
    color: "grey",
    position: "absolute",
    right: 64,
    bottom: 82,
    width: "25px !important",
    height: "25px !important",
  },
})
)

export { RegistrationEmailEditStyles };