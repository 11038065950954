import React, { ReactElement, useContext, useState, useEffect } from "react";
import {
  Card,
  Typography,
  Button,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import EditIcon from "@material-ui/icons/Edit";

import { AppContext, AppContextType } from "../../../context/AppContext";

import { BookingItemStyles } from "./BookingItemStyles";
import styles from "./BookingItem.module.css";

interface Props {
  name: string;
  start: any;
  end: any;
  bookingID: string;
  onEditClick: (booking: any) => any;
  registrationCount: number;
  booking: any;
  index: number;
}

export default function BookingItem(props: Props): ReactElement {
  const history = useHistory();

  const { setSelectedBookingID, selectedBookingID }: AppContextType =
    useContext(AppContext);

  const handleEditBookingItemClick = () => {
    if (window.location.pathname !== `/booking/${props.bookingID}`) {
      window.history.pushState(null, "Title", `/booking/${props.bookingID}`);
    }

    setSelectedBookingID(props.bookingID);
    props.onEditClick(props.booking);
  };

  //material UI classes
  const classes = BookingItemStyles();

  return (
    <Card
      square
      elevation={1}
      classes={{ root: classes.bookingItem }}
      style={{
        backgroundColor:
          (props.index + 1) % 2 === 0 ? "rgb(0, 0, 0, 0.02)" : "white",
      }}
    >
      <Typography variant="body1" classes={{ root: classes.bookingName }}>
        {props.name}
      </Typography>

      <div className={styles.startDateWrapper}>
        <Typography
          variant="body1"
          classes={{ root: classes.bookingStartDate }}
        >
          {moment.tz(props.start.time, props.start.timezone).format("L")}
        </Typography>
        <Typography
          variant="body1"
          classes={{ root: classes.bookingStartTime }}
        >
          {moment.tz(props.start.time, props.start.timezone).format("LT z")}
        </Typography>
      </div>

      <div className={styles.endDateWrapper}>
        <Typography variant="body1" classes={{ root: classes.bookingEndDate }}>
          {moment.tz(props.end.time, props.end.timezone).format("L")}
        </Typography>
        <Typography variant="body1" classes={{ root: classes.bookingEndTime }}>
          {moment.tz(props.end.time, props.end.timezone).format("LT z")}
        </Typography>
      </div>

      <div className={styles.registrationCountWrapper}>
        <Typography
          variant="body1"
          classes={{ root: classes.bookingStartDate }}
        >
          {props.registrationCount}
        </Typography>
      </div>

      <IconButton
        onClick={handleEditBookingItemClick}
        color="primary"
        classes={{ root: classes.bookingEditButton }}
      >
        <EditIcon className={classes.editIcon}></EditIcon>
      </IconButton>
    </Card>
  );
}
