import axios from 'axios';
import { CognitoRefreshToken, CognitoUserPool } from 'amazon-cognito-identity-js';
import { AuthHelper } from './AuthHelper';

const jwt = require("jsonwebtoken");

export class JWTHelper {

  public static refreshTimer = null;

  public static createJWT = (spaceID, bookingID) => {

    let payload = {
      spaceID,
      bookingID,
    }

    let jwtToken = jwt.sign(payload, process.env.REACT_APP_JWT_SECRET, {
      algorithm: "HS256",
      expiresIn: process.env.REACT_APP_JWT_EXPIRATION
    })

    //Assign JWT as default header so it is passed with every request
    axios.defaults.headers.common['producerauth'] = `Basic ${jwtToken}`;
    
    localStorage.setItem("producerJWT", jwtToken);

    return jwtToken;
  }

  public static storeJWT = (jwt) => {
    axios.defaults.headers.common['producerauth'] = `Basic ${jwt}`;

    localStorage.setItem("producerJWT", jwt);
  }

  public static checkJWTExpiration = (idToken) => {

    //Ensure refresh only occurs every 1 minute

    try {
      let jwtDecoded = jwt.decode(idToken)
      
      if (jwtDecoded) {
        if (!jwtDecoded.exp) return false;

        //Check JWT expiration time
        if (Date.now() >= jwtDecoded.exp * 1000) {
          return false;
        }

        return true;
      } else {
        return false;
      }
    }
    catch {
      return false;
    }
  }

  public static refreshCognitoToken = async () => {

    return new Promise(async (resolve) => {

      //If there is a timer going, do nothing. Otherwise restart it
      if (JWTHelper.refreshTimer) {
        resolve(true);
        return;
      } else {
        JWTHelper.refreshTimer = setTimeout(function() {
          JWTHelper.refreshTimer = null;
        }, 60000)
      }

      let refreshToken = localStorage.getItem("refreshToken");

      //Get the current user
      if (refreshToken) {

        let token = new CognitoRefreshToken({ RefreshToken: refreshToken });

        var poolData = {
          UserPoolId: 'us-east-1_N15Q0NLkm',
          ClientId: '2332rbhi35f5016dglri2mojo'
        };

        const userPool = new CognitoUserPool(poolData);

        let currentUser = userPool.getCurrentUser();

        //Refresh token
        if (currentUser) {
          await currentUser.refreshSession(token, (err, session) => {
            if (err) {
              resolve(false);
            } else {
              let idToken = session.getIdToken().getJwtToken();
              localStorage.setItem("idToken", idToken);
              axios.defaults.headers.common["Authorization"] = idToken;
              resolve(true);
            }
          })
        } else {
          resolve(false);
          alert("Your session has expired. Please sign in again.");
          AuthHelper.signOutUser();
          window.location.replace("/")
        }
      } else {
        resolve(false);
        alert("Your session has expired. Please sign in again.");
        AuthHelper.signOutUser();
        window.location.replace("/")
      }
    })
  }

  public static addAxiosInterceptor = () => {
    axios.interceptors.request.use(async function (config) {
      //Refresh Cognito token
      let refresh = await JWTHelper.refreshCognitoToken();
      if (!refresh) {
        throw new axios.Cancel("Unable to refresh token");
      } else {
        return config;
      }
    })
  }
}