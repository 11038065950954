import React, { ReactElement, useState, useEffect, useContext } from 'react';
import { Typography, Button, TextField, Dialog, FormControlLabel, Switch, CircularProgress, InputLabel, FormControl, Select, MenuItem, IconButton } from '@material-ui/core';
import axios from 'axios';
import DeleteIcon from '@material-ui/icons/Delete';
import $ from 'jquery';

import { AppContext, AppContextType } from '../../../../context/AppContext';
import { EmailContext, EmailContextType } from '../../../../context/EmailContext';
import DefaultBody from '../../../../emails/DefaultAttendeeLink';
import DefaultAttendeeLink from '../../../../emails/DefaultAttendeeLink';
import DefaultPresenterLink from '../../../../emails/DefaultPresenterLink';
import DefaultRegistrationConfirmation from '../../../../emails/DefaultRegistrationConfirmation';
import DeleteEmailTemplatePopup from '../DeleteEmailTemplatePopup/DeleteEmailTemplatePopup';
import PopupHeader from '../../../PopupHeader/PopupHeader';

import styles from './ManageTemplatePopup.module.css';
import { ManageTemplatePopupStyles } from './ManageTemplatePopupStyles';

interface Props {
  handlePopupClose: () => any,
  templateName: string,
  templateID: string,
  HTML: boolean,
  system: boolean,
  handleEmailTemplateDelete: (emailTemplateID: string) => any,
}

export default function ManageTemplatePopup(props: Props): ReactElement {

  const { 
    selectedBookingID, 
    selectedBooking, 
    setSelectedBooking 
  } : AppContextType = useContext(AppContext);
  
  const { selectedEmailTemplate } : EmailContextType = useContext(EmailContext);

  const [ templateBody, setTemplateBody ] = useState("");

  const [ templateSubject, setTemplateSubject ] = useState(selectedEmailTemplate.subject);
  const [ templateFrom, setTemplateFrom ] = useState(selectedEmailTemplate.from);
  const [ templateReplyTo, setTemplateReplyTo ] = useState(selectedEmailTemplate.replyTo);

  const [ subjectError, toggleSubjectError ] = useState(false);
  const [ replyToError, toggleReplyToError ] = useState(false);
  const [ fromError, toggleFromError ] = useState(false);

  const [ dynamicData, setDynamicData ] = useState("");

  const [ deleteEmailTemplate, toggleDeleteEmailTemplate ] = useState(false);
  
  const [ isHTML, toggleIsHTML ] = useState(selectedEmailTemplate.HTML);

  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);
  const [ error, toggleError ] = useState(false);
  
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  
  useEffect(() => {
    
    //GET EMAIL TEMPLATE DETAILS
    const getEmailTemplate = async () => {
      try {

        let response = await  axios.get("/email-template", {
          params: {
            emailTemplateID: props.templateID
          },
          cancelToken: source.token,
        });

        setTemplateBody(response.data.body);
        setTemplateSubject(response.data.subject);
        toggleIsHTML(response.data.HTML);
      }
      catch (error) {
        if (axios.isCancel(error)) {
          return;
        } 
        
        console.log(error);
      }
    };
    getEmailTemplate();

    return function cleanup() {
      $('textarea').off("click");
      $('textarea').css({'cursor': 'text'});

      source.cancel("Component was unmounted");
      
    }
  }, []);

  /* HTML TOGGLE */
  const handleToggleHTML = () => {

    toggleIsHTML(!isHTML);

    //CLEAR TEMPLATE BODY AND SUBJECT
    if (!isHTML) {
      setTemplateBody("");
    } else {
      //RESET EMAIL TEMPLATE DETAILS
      
      if (props.system) {
        if (props.templateName === "Attendee Invite Link") {
          setTemplateBody(DefaultAttendeeLink);
        } else if (props.templateName === "Presenter Invite Link") {
          setTemplateBody(DefaultPresenterLink);
        }
        else if (props.templateName === "Registration Confirmation") {
          setTemplateBody(DefaultRegistrationConfirmation);
        } else {
          setTemplateBody(DefaultBody);
        }
      } else {
        setTemplateBody(DefaultBody);
      }
      
    }
  }
  /**/

  /* TEMPLATE ELEMENT */

  const handleTemplateElementChange = (e) => {
    setDynamicData(e.target.value);
  }

  //insert at cursor function
  const insertAtCursor = (myField, myValue) => {
    //MOZILLA and others
    if (myField.selectionStart || myField.selectionStart === '0') {
        var startPos = myField.selectionStart;
        var endPos = myField.selectionEnd;
        myField.value = myField.value.substring(0, startPos)
            + myValue
            + myField.value.substring(endPos, myField.value.length);
        myField.selectionStart = startPos + myValue.length;
        myField.selectionEnd = startPos + myValue.length;
    } else {
        myField.value += myValue;
    }

    setTemplateBody(myField.value);

    //remove jquery event listener
    $('textarea').off("click");
    $('textarea').css({'cursor': 'text'});
    setDynamicData("");
  }

  const handleSectionHeaderClick = () => {

    $('textarea').click(function() {
      insertAtCursor(this, "\r\n<~~~SECTION HEADER~~~>Lorem Ipsum<~~~END SECTION HEADER~~~>\r\n")
    });

    //change cursor to pointer
    $('textarea').css({'cursor': 'pointer'});
  }

  const handleCalendarLinkClick = (e) => {

    let toInsert;

    if (e.target.getAttribute("data-value") === "Calendar Link Raw") {
      toInsert = "{~~~ADD TO CALENDAR LINK~~~}"
    } else {
      toInsert = "\r\n<~~~ADD TO CALENDAR LINK~~~>\r\n"
    }

    $('textarea').click(function() {
      insertAtCursor(this, toInsert)
    });
    //change cursor to pointer
    $('textarea').css({'cursor': 'pointer'});
  }

  const handleEventDetailsClick = () => {
    $('textarea').click(function() {
      insertAtCursor(this, "\r\n<~~~EVENT DETAILS~~~>\r\n")
    });
    //change cursor to pointer
    $('textarea').css({'cursor': 'pointer'});
  }

  const handleWelcomeHeaderClick = () => {
    $('textarea').click(function() {
      insertAtCursor(this, "\r\n<~~~MAIN HEADER~~~>Lorem Ipsum<~~~END MAIN HEADER~~~>\r\n")
    });

    //change cursor to pointer
    $('textarea').css({'cursor': 'pointer'});
  }

  const handleFirstNameClick = (e) => {

    $('textarea').click(function() {
      insertAtCursor(this, "{~~~FIRSTNAME~~~}")
    });
    //change cursor to pointer
    $('textarea').css({'cursor': 'pointer'});
  }

  const handleIntakeLinkClick = () => {
    $('textarea').click(function() {
      insertAtCursor(this, "{~~~INTAKE LINK~~~}")
    });
    //change cursor to pointer
    $('textarea').css({'cursor': 'pointer'});
  };

  const handleRegistrationLinkClick = () => {
    $('textarea').click(function() {
      insertAtCursor(this, "{~~~REGISTRATION LINK~~~}")
    });
    //change cursor to pointer
    $('textarea').css({'cursor': 'pointer'});
  }

  /**/

  const handleEmailTemplateBodyChange = (e) => {
    setTemplateBody(e.target.value);
  }

  const handleTemplateFromChange = (e) => {
    toggleFromError(false);
    setTemplateFrom(e.target.value);
  }

  const handleTemplateReplyToChange = (e) => {
    toggleReplyToError(false);
    setTemplateReplyTo(e.target.value);
  }

  const handleTemplateSubjectChange = (e) => {
    toggleSubjectError(false);
    setTemplateSubject(e.target.value);
  }
  
  const handleCancelButtonClick = () => {

    if (loadingSpinner) {
      return;
    }
    
    toggleError(false);
    props.handlePopupClose();
  }
  
  const handleSaveButtonClick = () => {

    if (templateSubject === "") {
      toggleSubjectError(true);
      return;
    }
    if (templateFrom === "") {
      toggleFromError(true);
      return;
    }
    if (templateReplyTo === "") {
      toggleReplyToError(true);
      return;
    }

    toggleLoadingSpinner(true);

    //UPDATE THE TEMPLATE
    axios.put("/email-template", {
      bookingID: selectedBookingID,
      emailTemplateID: props.templateID,
      subject: templateSubject,
      from: templateFrom,
      replyTo: templateReplyTo,
      body: templateBody,
      system: props.system,
      html: isHTML
    })
    .then(function(response) {

      let selectedBookingClone = selectedBooking;

      selectedBookingClone.emailTemplates[props.templateID].HTML = isHTML;

      setSelectedBooking(selectedBookingClone);
      
      toggleLoadingSpinner(false);
      props.handlePopupClose();
    })
    .catch(function(error) {
      toggleError(true);
      toggleLoadingSpinner(false);
      console.log(error);
    })
  }

  //EMAIL TEMPLATE DELETE
  const handleEmailTemplateDeleteClick = () => {
    toggleDeleteEmailTemplate(true);
  }

  const handleEmailTemplateDeleteClose = () => {
    toggleDeleteEmailTemplate(false);
    props.handlePopupClose();
  }

  const handleEmailTemplateDelete = (emailTemplateID : string) => {
    props.handleEmailTemplateDelete(emailTemplateID)
  }
  
  const classes = ManageTemplatePopupStyles();

  return (
    <React.Fragment>
      <Dialog
        open={true}
        classes={{ paper: classes.dialogPaper}}
      >
        <div className={classes.popup}>

          <PopupHeader
            closeFunction={props.handlePopupClose}
            disableBoolean={loadingSpinner}
          />

          <Typography variant="h1" className={classes.manageTemplateHeader}>
            Manage Template
          </Typography>
          <Typography variant="h2" className={classes.manageTemplateSubheader}>
            {props.templateName}

            {!props.system &&
              <IconButton className={classes.deleteIconButton} onClick={handleEmailTemplateDeleteClick}>
                <DeleteIcon  />
              </IconButton>
            }
          </Typography>

          {isHTML
            ? (
              <FormControl
                variant="outlined"
                className={classes.dynamicDataForm}
                key={dynamicData}
              >
                <InputLabel className={classes.selectLabel}>Insert Template Element</InputLabel>
                <Select
                  value={dynamicData}
                  onChange={handleTemplateElementChange}
                  label="Template Element"
                >
                  <MenuItem onClick={handleFirstNameClick} value={"First Name Raw"}>Insert First Name</MenuItem>
                  <MenuItem onClick={handleCalendarLinkClick} value={"Calendar Link Raw"}>Insert Add to Calendar Link</MenuItem>
                  <MenuItem onClick={handleIntakeLinkClick} value={"Intake Link Raw"}>Insert Intake Link</MenuItem>
                  <MenuItem onClick={handleRegistrationLinkClick} value={"Registration Link Raw"}>Insert Registration Link</MenuItem>
                </Select>
              </FormControl>
            )
            : (
              <FormControl 
                variant="outlined" 
                className={classes.dynamicDataForm}
                key={dynamicData}
              >
                <InputLabel className={classes.selectLabel}>Insert Template Element</InputLabel>
                <Select
                  value={dynamicData}
                  onChange={handleTemplateElementChange}
                  label="Template Element"
                >
                  <MenuItem onClick={handleFirstNameClick} value={"First Name"}>Insert First Name</MenuItem>
                  <MenuItem onClick={handleCalendarLinkClick} value={"Calendar Link"}>Insert Add to Calendar Link</MenuItem>
                  <MenuItem onClick={handleEventDetailsClick} value={"Event Details"}>Insert Event Details</MenuItem>
                  <MenuItem onClick={handleWelcomeHeaderClick} value={"Main Header"}>Insert Main Header</MenuItem>
                  <MenuItem onClick={handleSectionHeaderClick} value={"Sub Header"}>Insert Section Header</MenuItem>
                </Select>
              </FormControl>
            )
          }

          <div className={styles.emailOptionsHolder}>
            <TextField
              value={templateFrom}
              error={fromError}
              onChange={handleTemplateFromChange}
              fullWidth
              helperText="Required"
              variant="outlined"
              label="From Name"
              className={classes.emailOptionsInput}
            />
            <TextField
              value={templateReplyTo}
              error={replyToError}
              onChange={handleTemplateReplyToChange}
              fullWidth
              helperText="Required"
              variant="outlined"
              label="Reply To Email Address"
              className={classes.emailOptionsInput}
            />
            <TextField
              value={templateSubject}
              error={subjectError}
              onChange={handleTemplateSubjectChange}
              fullWidth
              helperText="Required"
              variant="outlined"
              label="Email Subject"
              className={classes.emailOptionsInput}
            />

          </div>

          <TextField
            value={templateBody}
            onChange={handleEmailTemplateBodyChange}
            fullWidth
            multiline
            rows={28}
            variant="outlined"
            id="emailTemplateBodyInput"
            InputProps={{
              className: classes.templateInput,
            }}
            className={classes.templateTextField}
          />

          {error &&
            <Typography variant="body1" className={classes.error}>
              An error occurred updating this email template
            </Typography>
          }

          <FormControlLabel
            className={classes.htmlToggle}
            control={<Switch color="secondary" checked={isHTML} onChange={handleToggleHTML} name="HTML" />}
            label="HTML"
          />  

          {loadingSpinner &&
            <CircularProgress className={classes.loadingSpinner} />
          }

          <Button onClick={handleCancelButtonClick} variant="contained" color="primary" className={classes.cancelButton}>
            CANCEL
          </Button>
          <Button disabled={loadingSpinner} onClick={handleSaveButtonClick} variant="contained" color="primary" className={classes.saveButton}>
            SAVE
          </Button>
        </div>
      </Dialog>

      {deleteEmailTemplate &&
        <DeleteEmailTemplatePopup 
          open={deleteEmailTemplate} 
          handlePopupClose={handleEmailTemplateDeleteClose} 
          handleEmailTemplateDelete={handleEmailTemplateDelete}
        />
      }
    </React.Fragment>

  )
  
}
