import { CognitoRefreshToken, CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import axios from 'axios';

export class AuthHelper {

  public static signOutUser = () => {
    //Clear any existing users 
    var poolData = {
      UserPoolId: 'us-east-1_N15Q0NLkm',
      ClientId: '2332rbhi35f5016dglri2mojo'
    };

    const userPool = new CognitoUserPool(poolData);

    let currentUser = userPool.getCurrentUser();

    if (currentUser) {
      currentUser.signOut();
    }

    if (localStorage.getItem("producerID")) {
      localStorage.removeItem("producerID");
    }
    if (localStorage.getItem("idToken")) {
      localStorage.removeItem("idToken");
    }
    if (localStorage.getItem("refreshToken")) {
      localStorage.removeItem("refreshToken");
    }
  }

 
  
}