import { makeStyles, createStyles } from "@material-ui/core/styles";

const SaveButtonStyles = makeStyles((theme: any) =>
  createStyles({
    saveButton: {
      height: 44,
      width: 95,
      position: "absolute",
      right: 30,
      bottom: 30,
      backgroundColor: "green",
      "&:hover": {
        backgroundColor: "#00B6FF",
      },
    },
  })
);

export { SaveButtonStyles };
