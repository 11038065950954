import React, {
  ReactElement,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import {
  Typography,
  Button,
  Divider,
  Snackbar,
  Switch,
  CircularProgress,
  IconButton,
  Link,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import QRCode from "qrcode";
import axios from "axios";
import RunDisplay from "./RunDisplay/RunDisplay";
import MiscellaneousDisplay from "./MiscellaneousDisplay/MiscellaneousDisplay";
import { AppContext, AppContextType } from "../../../context/AppContext";
import ConfirmLinkRegeneratePopup from "./ConfirmLinkRegeneratePopup/ConfirmLinkRegeneratePopup";
import CalendarAssignPopup from "./CalendarAssignPopup/CalendarAssignPopup";

import styles from "./BookingOverview.module.css";
import { BookingOverviewStyles } from "./BookingOverviewStyles";

interface Props {}

export default function BookingOverview(props: Props): ReactElement {
  const {
    selectedBooking,
    setSelectedBooking,
    toggleOnMainBookingWindow,
    toggleBookingHasCalendar,
    isSuperAdmin,
    spaceCalendars,
  }: AppContextType = useContext(AppContext);

  const [copyIntakeToClipboard, toggleIntakeCopyToClipboard] = useState(false);
  const [copyMobileHybridToClipboard, setCopyMobileHybridToClipboard] =
    useState(false);
  const [copyPhotoToClipboard, togglePhotoCopyToClipboard] = useState(false);

  const [regenerateIntake, toggleRegenerateIntake] = useState(false);
  const [regenerateMobileHybrid, setRegenerateMobileHybrid] = useState(false);
  const [confirmLinkRegeneratePopup, toggleConfirmLinkRegeneratePopup] =
    useState(false);

  const [isRegeneratingPublicCode, toggleIsRegeneratingPublicCode] =
    useState(false);

  const [intakeRegenerating, toggleIntakeRegenerating] = useState(false);
  const [mobileHybridRegenerating, setMobileHybridRegenerating] =
    useState(false);

  const [calendarEntryLoadingSpinner, toggleCalendarEntryLoadingSpinner] =
    useState(false);

  const [calendarEntry, toggleCalendarEntry] = useState(
    selectedBooking.calendarID !== undefined &&
      selectedBooking.calendarID !== ""
      ? true
      : false
  );

  const [hasMobileHybridCode, setHasMobileHybridCode] = useState(selectedBooking.mobileHybridLoginCode !== undefined ? true : false);

  const [regenerateType, setRegenerateType] = useState("");

  const [calendarAssign, toggleCalendarAssign] = useState(false);

  const [stateCounter, setStateCounter] = useState(0);

  const [privateCloud, togglePrivateCloud] = useState(
    selectedBooking.privateCloud !== undefined
      ? selectedBooking.privateCloud
      : false
  );
  const [privateCloudLoadingSpinner, togglePrivateCloudLoadingSpinner] =
    useState(false);

  const [bookingStartTime, setBookingStartTime] = useState(
    selectedBooking.start.time
  );

  const firstRender = useRef(true);

  //Toggle context variable to increase height when on main booking window
  useEffect(() => {
    toggleOnMainBookingWindow(true);

    //Check if selected booking has calendar to increase height further
    if (
      selectedBooking.calendarID !== "" &&
      selectedBooking.calendarID !== undefined
    ) {
      toggleBookingHasCalendar(true);
    } else {
      toggleBookingHasCalendar(false);
    }

    //If selected booking does not have a mobile hybrid code, create one
    if (!selectedBooking.mobileHybridLoginCode) {
      const createMobileHybridCode = async () => {
        try {
          let response = await axios.put("/booking/mobile-hybrid/regenerate", {
            bookingID: selectedBooking.bookingID,
            create: true
          });

          let selectedBookingClone = selectedBooking;
          selectedBookingClone.mobileHybridLoginCode = response.data;
          setSelectedBooking(selectedBookingClone);
          setHasMobileHybridCode(true);

        } catch (error) {
          console.log("Error creating mobile hybrid login:", error);
        }
      };
      createMobileHybridCode();
    }

    return function cleanup() {
      toggleOnMainBookingWindow(false);
    };
  }, []);

  //Show calendar assign popup upon toggling "Calendar Entry" on
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
  }, [calendarEntry]);

  useEffect(() => {
    //Double check for calendar entry toggle
    if (
      selectedBooking.calendarID == "" ||
      selectedBooking.calendarID === undefined
    ) {
      toggleCalendarEntry(false);
    }
  }, [selectedBooking]);

  //typeof type : "signup" or "intake" or "mobile-hybrid" or "photo"
  const handleCopyToClipboardClick = (type: string) => {
    let dummy = document.createElement("textarea");

    document.body.appendChild(dummy);

    if (type === "signup") {
      dummy.value = document.getElementById("signUpLink").innerText;
    } else if (type === "intake") {
      dummy.value = document.getElementById("intakeLink").innerText;
    } else if (type === "photo") {
      dummy.value = document.getElementById("photoLink").innerText;
    } else if (type === "mobile-hybrid") {
      dummy.value = document.getElementById("mobileHybridLink").innerText;
    }

    dummy.select();

    document.execCommand("copy");
    document.body.removeChild(dummy);

    if (type === "intake") {
      toggleIntakeCopyToClipboard(true);
    } else if (type === "photo") {
      togglePhotoCopyToClipboard(true);
    } else if (type === "mobile-hybrid") {
      setCopyMobileHybridToClipboard(true);
    }
  };

  /*Regenerate intake handlers */
  const handleRegerateIntakeClick = () => {
    setRegenerateType("public");

    toggleIsRegeneratingPublicCode(true);

    toggleConfirmLinkRegeneratePopup(true);
  };

  const handlePublicLinkRegenerated = () => {
    toggleRegenerateIntake(true);
  };

  const handleConfirmLinkRegeneratePopupClose = () => {
    toggleConfirmLinkRegeneratePopup(false);
  };
  /**/

  /**Regenerate mobile hybrid handlers */
  const handleRegenerateMobileHybridClick = () => {
    setRegenerateType("mobile-hybrid");

    setMobileHybridRegenerating(true);

    toggleConfirmLinkRegeneratePopup(true);
  };

  const handleMobileHybridLinkRegenerated = () => {
    setRegenerateMobileHybrid(true);
  };
  /**/

  const handleCalendarAssignClose = (calendarID: string) => {
    setStateCounter((stateCounter) => stateCounter + 1);

    //Check if there is still no calendar ID assigned, if so, toggle calendar entry to false
    if (calendarID === "" || calendarID === undefined) {
      toggleBookingHasCalendar(false);
      toggleCalendarEntry(false);
    } else {
      //Otherwise, toggle calendar entry to true
      toggleBookingHasCalendar(true);
      toggleCalendarEntry(true);
    }

    //Close popup
    toggleCalendarAssign(false);
  };

  //Edit assigned calendar
  const handleEditCalendarNameClick = () => {
    toggleCalendarAssign(true);
  };

  const handleStartTimeChange = (startTime) => {
    setBookingStartTime(startTime);
  };

  const handleQRCodeDownloadClick = async () => {
    //Generate QR code and download it
    let qrCode = await QRCode.toDataURL(
      `https://go.showboat.live/${selectedBooking.mobileHybridLoginCode}`
    );

    downloadQRCode(qrCode, `QRCode-${selectedBooking.name}.png`);
  };

  function downloadQRCode(dataurl, filename) {
    const link = document.createElement("a");
    link.href = dataurl;
    link.download = filename;
    link.click();
  }

  const classes = BookingOverviewStyles();

  //Find matching calendar name if calendarID !== ""
  let bookingCalendarName;

  if (
    selectedBooking.calendarID !== undefined &&
    selectedBooking.calendarID !== ""
  ) {
    for (let i = 0; i < spaceCalendars.length; i++) {
      if (spaceCalendars[i].calendarID === selectedBooking.calendarID) {
        bookingCalendarName = spaceCalendars[i].calendarName;
        break;
      }
    }
  }

  return (
    <div className={styles.bookingOverviewWrapper}>
      <Typography variant="h2" className={classes.linksHeader}>
        General Access Link
      </Typography>

      <div className={styles.intakeUrlHolder}>
        <Typography variant="body1" classes={{ root: classes.intakeUrlHeader }}>
          Intake URL:
        </Typography>
        <Link
          id="intakeLink"
          variant="body1"
          classes={{ root: classes.intakeUrl }}
          target="_blank"
          href={`https://go.showboat.live/${selectedBooking.publicAppLoginCode}`}
        >
          https://go.showboat.live/{selectedBooking.publicAppLoginCode}
        </Link>

        <div className={styles.intakeButtonHolder}>
          <IconButton
            onClick={() => handleCopyToClipboardClick("intake")}
            disabled={intakeRegenerating}
            color="primary"
            classes={{ root: classes.copyButton }}
          >
            <img
              className={styles.copyImage}
              alt="Copy icon"
              src="assets/images/CopyIcon.svg"
            />
          </IconButton>

          <IconButton
            color="primary"
            disabled={intakeRegenerating}
            onClick={handleRegerateIntakeClick}
            classes={{ root: classes.regenerateButton }}
          >
            <RefreshIcon
              style={{ color: "black", fontSize: "20px" }}
            ></RefreshIcon>
          </IconButton>
        </div>
        <Divider className={classes.dividerIntake}></Divider>
      </div>

      { hasMobileHybridCode && (
        <div
          className={`${styles.intakeUrlHolder} ${styles.mobileHybridUrlHolder}`}
        >
          <Typography
            variant="body1"
            classes={{ root: classes.intakeUrlHeader }}
          >
            Mobile Hybrid URL (beta):
          </Typography>
          <Link
            id="mobileHybridLink"
            variant="body1"
            classes={{
              root: `${classes.intakeUrl} ${classes.mobileHybridUrl}`,
            }}
            target="_blank"
            href={`https://go.showboat.live/${selectedBooking.mobileHybridLoginCode}`}
          >
            https://go.showboat.live/{selectedBooking.mobileHybridLoginCode}
          </Link>

          <div className={styles.mobileHybridButtonHolder}>
            <IconButton
              onClick={() => handleCopyToClipboardClick("mobile-hybrid")}
              disabled={intakeRegenerating}
              color="primary"
              classes={{ root: classes.copyButton }}
            >
              <img
                className={styles.copyImage}
                alt="Copy icon"
                src="assets/images/CopyIcon.svg"
              />
            </IconButton>

            <IconButton
              color="primary"
              disabled={intakeRegenerating}
              onClick={handleRegenerateMobileHybridClick}
              classes={{ root: classes.regenerateButton }}
            >
              <RefreshIcon
                style={{ color: "black", fontSize: "20px" }}
              ></RefreshIcon>
            </IconButton>

            <IconButton
              color="primary"
              onClick={handleQRCodeDownloadClick}
              classes={{
                root: `${classes.regenerateButton} ${classes.qrCodeButton}`,
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                enable-background="new 0 0 24 24"
                height="24"
                viewBox="0 0 24 24"
                width="24"
              >
                <g>
                  <rect fill="none" height="24" width="24" />
                </g>
                <path d="M15,21h-2v-2h2V21z M13,14h-2v5h2V14z M21,12h-2v4h2V12z M19,10h-2v2h2V10z M7,12H5v2h2V12z M5,10H3v2h2V10z M12,5h2V3h-2V5 z M4.5,4.5v3h3v-3H4.5z M9,9H3V3h6V9z M4.5,16.5v3h3v-3H4.5z M9,21H3v-6h6V21z M16.5,4.5v3h3v-3H16.5z M21,9h-6V3h6V9z M19,19v-3 l-4,0v2h2v3h4v-2H19z M17,12l-4,0v2h4V12z M13,10H7v2h2v2h2v-2h2V10z M14,9V7h-2V5h-2v4L14,9z M6.75,5.25h-1.5v1.5h1.5V5.25z M6.75,17.25h-1.5v1.5h1.5V17.25z M18.75,5.25h-1.5v1.5h1.5V5.25z" />
              </svg>
            </IconButton>

            <IconButton></IconButton>
          </div>

          <Divider className={classes.dividerIntake}></Divider>
        </div>
      )}

      <div
        className={
          selectedBooking.mobileHybridLoginCode !== undefined
            ? `${styles.intakeUrlHolder} ${styles.intakeURLHolderPhotoAlbum}`
            : `${styles.intakeUrlHolder} ${styles.intakeURLHolderPhotoAlbum} ${styles.intakeURLHolderPhotoAlbumNoMobileHybrid}`
        }
      >
        <Typography
          variant="body1"
          classes={{
            root: `${classes.intakeUrlHeader} ${classes.intakeURLHeaderPhotoAlbum}`,
          }}
        >
          Photo Album URL:
        </Typography>
        <Link
          id="photoLink"
          variant="body1"
          classes={{ root: classes.photoAlbumUrl }}
          target="_blank"
          href={`https://albums.showboat.live/albums/${selectedBooking.bookingID}`}
        >
          https://albums.showboat.live/albums/{selectedBooking.bookingID}
        </Link>

        <div
          className={`${styles.intakeButtonHolder} ${styles.photoAlbumButtonHolder}`}
        >
          <IconButton
            onClick={() => handleCopyToClipboardClick("photo")}
            disabled={intakeRegenerating}
            color="primary"
            classes={{ root: classes.copyButton }}
          >
            <img
              className={styles.copyImage}
              alt="Copy icon"
              src="assets/images/CopyIcon.svg"
            />
          </IconButton>
        </div>

        <Divider className={classes.dividerPhotoAlbum}></Divider>
      </div>

      <div
        className={
          selectedBooking.mobileHybridLoginCode === undefined
            ? `${styles.runHolder} ${styles.runHolderNoMobileHybrid}`
            : styles.runHolder
        }
      >
        <RunDisplay handleStartTimeChange={handleStartTimeChange} />
      </div>

      <div className={styles.limitHolder}>
        <MiscellaneousDisplay
          privateCloud={privateCloud}
          selectedBookingStartTime={bookingStartTime}
        />
      </div>

      {/* LINK REGENERATE ERROR MESSAGE POPUP */}
      {confirmLinkRegeneratePopup && (
        <ConfirmLinkRegeneratePopup
          regenerateType={regenerateType}
          open={confirmLinkRegeneratePopup}
          handlePopupClose={handleConfirmLinkRegeneratePopupClose}
          handlePublicLinkRegenerated={handlePublicLinkRegenerated}
          handleMobileHybridLinkRegenerated={handleMobileHybridLinkRegenerated}
        />
      )}

      {/* INTAKE COPY TO CLIPBOARD SNACKBAR */}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={1500}
        className={classes.snackbar}
        open={copyIntakeToClipboard}
        onClose={() => toggleIntakeCopyToClipboard(false)}
        message="Intake URL Copied to Clipboard"
      />

      {/* MOBILE HYBRID COPY TO CLIPBOARD SNACKBAR */}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={1500}
        className={classes.snackbar}
        open={copyMobileHybridToClipboard}
        onClose={() => setCopyMobileHybridToClipboard(false)}
        message="Mobile Hybrid URL Copied to Clipboard"
      />

      {/* PHOTO COPY TO CLIPBOARD SNACKBAR */}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={1500}
        className={classes.snackbar}
        open={copyPhotoToClipboard}
        onClose={() => togglePhotoCopyToClipboard(false)}
        message="Photo Album URL Copied to Clipboard"
      />

      {/* INTAKE REGENERATED SNACKBAR */}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={1500}
        className={classes.snackbar}
        open={regenerateIntake}
        onClose={() => toggleRegenerateIntake(false)}
        message="Intake URL Regenerated"
      />

      {/* MOBILE HYBRID REGENERATED SNACKBAR */}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={1500}
        className={classes.snackbar}
        open={regenerateMobileHybrid}
        onClose={() => setRegenerateMobileHybrid(false)}
        message="Mobile Hybrid URL Regenerated"
      />

      {calendarAssign && (
        <CalendarAssignPopup
          open={true}
          handlePopupClose={handleCalendarAssignClose}
        />
      )}
    </div>
  );
}
