import React, { ReactElement, useContext, useState } from 'react';
import { TextField, Button } from '@material-ui/core';
import InputMask from 'react-input-mask';
import isEmail from 'validator/lib/isEmail';

import { intakeStore } from '../../../../intakeStore';
import { AppContext, AppContextType } from '../../../../context/AppContext';

import { AttendeeFormStyles } from './AttendeeFormStyles';
import styles from './AttendeeForm.module.css';

interface Props {
  handleAttendeeSubmit: (newAttendeeObject: any) => any;
  handlePopupClose: (cancelled: boolean) => any;
  isAddingAttendee: boolean;
  isLoading: boolean;
  emailError: boolean;
  tab?: number;
  presenter?: boolean
}

interface IntakeField {
  name: string;
  visible: boolean;
  mandatory: boolean;
  type?: string,
}

export default function AttendeeForm(props: Props): ReactElement {

  const {
    selectedBooking,
    selectedRegistration,
    setSelectedRegistration,
    selectedBookingID,
    
  } : AppContextType = useContext(AppContext);

  const intakeFieldMap : Map<string, IntakeField> = new Map<string, IntakeField>();
  const initialErrorMap : Map<string, boolean> = new Map<string, boolean>();

  let usAddressFields = [
    {name: "Address Line 1", visible: true, mandatory: false},
    {name: "Address Line 2", visible: true, mandatory: false},
    {name: "City", visible: true, mandatory: false},
    {name: "State", visible: true, mandatory: false},
    {name: "Zip Code", visible: true, mandatory: false},
  ]

  let internationalAddressFields = [
    {name: "Address Line 1", visible: true, mandatory: false},
    {name: "Address Line 2", visible: true, mandatory: false},
    {name: "City", visible: true, mandatory: false},
    {name: "State/Province/Region", visible: true, mandatory: false},
    {name: "Zip/Postal Code", visible: true, mandatory: false},
    { name: "Country", visible: true, mandatory: false}
  ]

  //Set up maps for intakeFields 
  intakeStore.intakeFields.forEach((intakeField:IntakeField)=>{

    //If we are going over address, check type of address on booking object
    if (intakeField.name === "Address") {
      
      if (selectedBooking.intakeFields[5].type === "US") {

        usAddressFields.forEach((intakeField: IntakeField) => {
          intakeFieldMap.set(intakeField.name, intakeField);
          initialErrorMap.set(intakeField.name, false);
        });

      } else if (selectedBooking.intakeFields[5].type === "international") {

        internationalAddressFields.forEach((intakeField : IntakeField) => {
          intakeFieldMap.set(intakeField.name, intakeField);
          initialErrorMap.set(intakeField.name, false);
        })

      } 
    } else {
      intakeFieldMap.set(intakeField.name, intakeField);
      initialErrorMap.set(intakeField.name, false);
    }
  });

  //State
  const [ firstName, setFirstName ] = useState(selectedRegistration !== null 
    ? selectedRegistration.registrationData.firstName
    : "")

  const [lastName, setLastName] = useState(selectedRegistration !== null
    ? selectedRegistration.registrationData.lastName
    : "");

  const [company, setCompany] = useState(selectedRegistration !== null
    ? selectedRegistration.registrationData.company
    : "");
  
  const [emailAddress, setEmailAddress] = useState(selectedRegistration !== null
    ? selectedRegistration.registrationData.emailAddress
    : "");

  const [phoneNumber, setPhoneNumber] = useState(selectedRegistration !== null
    ? selectedRegistration.registrationData.phone
    : "");

  const [addressLine1, setAddressLine1] = useState((selectedRegistration !== null &&
    selectedRegistration.registrationData.address.addressLine1 !== undefined)
    ? selectedRegistration.registrationData.address.addressLine1
    : "");

  const [ addressLine2, setAddressLine2 ] = useState((selectedRegistration !== null &&
    selectedRegistration.registrationData.address.addressLine2 !== undefined)
    ? selectedRegistration.registrationData.address.addressLine2
    : "");

  const [ city, setCity ] = useState((selectedRegistration !== null &&
    selectedRegistration.registrationData.address.city !== undefined)
    ? selectedRegistration.registrationData.address.city
    : "");

  /* US ADDRESSES */
  const [ state, setState ] = useState((selectedRegistration !== null &&
    selectedRegistration.registrationData.address.state !== undefined)
    ? selectedRegistration.registrationData.address.state
    : "");

  const [ zipCode, setZipCode ] = useState((selectedRegistration !== null &&
    selectedRegistration.registrationData.address.zipCode !== undefined)
    ? selectedRegistration.registrationData.address.zipCode
    : "");
  /**/

  /* INTERNATIONAL ADDRESSES */
  const [ stateProvinceRegion, setStateProvinceRegion ] = useState((selectedRegistration !== null &&
    selectedRegistration.registrationData.address.stateProvinceRegion !== undefined)
    ? selectedRegistration.registrationData.address.stateProvinceRegion
    : ""
  )

  const [ zipPostalCode, setZipPostalCode ] = useState((selectedRegistration !== null &&
    selectedRegistration.registrationData.address.zipPostalCode !== undefined)
    ? selectedRegistration.registrationData.address.zipPostalCode
    : ""
  )

  const [ country, setCountry ] = useState((selectedRegistration !== null &&
    selectedRegistration.registrationData.address.country !== undefined)
    ? selectedRegistration.registrationData.address.country
    : ""
  )
  /**/

  const [errorMap, setErrorMap] = useState(initialErrorMap);

  const [ emailInvalidError, toggleEmailInvalidError ] = useState(false);
  
  const [ emailHelperText, setEmailHelperText ] = useState("Optional");

  const [ disableSubmit, toggleDisableSubmit ] = useState(false);
  
  const [ formHasBeenSubmitted, setFormHasBeenSubmitted ] = useState(false);

  /**/

  const handleCancelButtonClick = () => {

    if (props.isLoading) return;
    
    props.handlePopupClose(true);
  }

  /* Input change handlers */
  const handleFirstNameChange = (e) => {
    errorMap.set("First Name", false);

    setFirstName(e.target.value);
  }

  const handleLastNameChange = (e) => {
    errorMap.set("Last Name", false);
    
    setLastName(e.target.value);
  }

  const handleCompanyChange = (e) => {  
    errorMap.set("Company", false);

    setCompany(e.target.value);
  }

  const handleEmailAddressChange = (e) => {
    errorMap.set("Email", false);

    if (emailInvalidError && isEmail(e.target.value)) {
      setEmailHelperText("Optional");
      toggleEmailInvalidError(false);
    }

    //If we are editing a registration, update the email directly
    if (!props.isAddingAttendee) {

      let selectedRegistrationClone = selectedRegistration;

      selectedRegistrationClone.registrationData.emailAddress = e.target.value;

      setSelectedRegistration(selectedRegistrationClone);
    }
    
    setEmailAddress(e.target.value);    
  }

  const handlePhoneChange = (e) => {
    errorMap.set("Phone", false);

    setPhoneNumber(e.target.value);
  }

  const handleAddressLine1Change = (e) => {
    errorMap.set("Address Line 1", false);

    setAddressLine1(e.target.value);
  }

  const handleAddressLine2Change = (e) => {
    errorMap.set("Address Line 2", false);

    setAddressLine2(e.target.value);
  }

  const handleCityChange = (e) => {
    errorMap.set("City", false);

    setCity(e.target.value);
  }

  const handleStateChange = (e) => {
    errorMap.set("State", false);

    setState(e.target.value);
  }

  const handleZipCodeChange = (e) => {
    errorMap.set("Zip Code", false);

    setZipCode(e.target.value);
  }

  const handleStateProvinceRegionChange = (e) => {
    errorMap.set("State/Province/Region", false);

    setStateProvinceRegion(e.target.value);
  };

  const handleZipPostalCodeChange = (e) => {
    errorMap.set("Zip/Postal Code", false);

    setZipPostalCode(e.target.value);
  }

  const handleCountryChange = (e) => {
    errorMap.set("Country", false);

    setCountry(e.target.value);
  }
  /**/

  const handleAttendeeEditSubmit = () => {
    let formHasError : boolean = false;

    toggleDisableSubmit(true);

    let intakeField : IntakeField;

    //Check if any input fields have errors. Show errors and disallow form submission if so
    intakeField = intakeFieldMap.get("First Name");
    if(intakeField && intakeField.visible && intakeField.mandatory){
      let error : boolean = (!firstName.trim().length);
      errorMap.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //Last name
    intakeField = intakeFieldMap.get("Last Name");
    if(intakeField && intakeField.visible && intakeField.mandatory){
      let error : boolean = (!lastName.trim().length);
      errorMap.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //Company
    intakeField = intakeFieldMap.get("Company");
    if (intakeField && intakeField.visible && intakeField.mandatory){
      let error : boolean = (!company.trim().length);
      errorMap.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //Email
    intakeField = intakeFieldMap.get("Email");
    if (intakeField && intakeField.visible && intakeField.mandatory){
      let error : boolean = ((!emailAddress.trim().length) || (!isEmail(emailAddress)));
      errorMap.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //Phone number
    intakeField = intakeFieldMap.get("Phone");
    if (intakeField && intakeField.visible && intakeField.mandatory) {
      let error : boolean = (!phoneNumber.trim().length);
      errorMap.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //Address line 1
    intakeField = intakeFieldMap.get("Address Line 1");
    if (intakeField && intakeField.visible && intakeField.mandatory) {
      let error : boolean = (!addressLine1.trim().length);
      errorMap.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //Address line 2
    intakeField = intakeFieldMap.get("Address Line 2");
    if (intakeField && intakeField.visible && intakeField.mandatory) {
      let error : boolean = (!addressLine2.trim().length);
      errorMap.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //City
    intakeField = intakeFieldMap.get("City");
    if (intakeField && intakeField.visible && intakeField.mandatory) {
      let error : boolean = (!city.trim().length);
      errorMap.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //State
    intakeField = intakeFieldMap.get("State");
    if (intakeField && intakeField.visible && intakeField.mandatory) {
      let error : boolean = (!state.trim().length);
      errorMap.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    //Zip code
    intakeField = intakeFieldMap.get("Zip Code");
    if (intakeField && intakeField.visible && intakeField.mandatory) {
      let error : boolean = (!zipCode.trim().length);
      errorMap.set(intakeField.name, error);
      formHasError = formHasError || error;
    }

    /* International-specific addresses */
      //state/province/region
      intakeField = intakeFieldMap.get("State/Province/Region");
      if (intakeField && intakeField.visible && intakeField.mandatory) {
        let error : boolean = (!stateProvinceRegion.trim().length);
        errorMap.set(intakeField.name, error);
        formHasError = formHasError || error;
      }

      //zip/postal code
      intakeField = intakeFieldMap.get("Zip/Postal Code");
      if (intakeField && intakeField.visible && intakeField.mandatory) {
        let error : boolean = (!zipPostalCode.trim().length);
        errorMap.set(intakeField.name, error);
        formHasError = formHasError || error;
      }

      //country/region
      intakeField = intakeFieldMap.get("Country");
      if (intakeField && intakeField.visible && intakeField.mandatory) {
        let error : boolean = (!country.trim().length);
        errorMap.set(intakeField.name, error);
        formHasError = formHasError || error;
      }
    /**/

    //If email address is not blank and is not a valid email, show error and disallow submission
    if (emailAddress !== "" && !isEmail(emailAddress)) {
      formHasError = true;
      setEmailHelperText("Email must be a valid email address")
      toggleEmailInvalidError(true);
      toggleDisableSubmit(false);
      setFormHasBeenSubmitted(true);
      return;
    }

    //If any errors are present, return
    if (formHasError) {
      setFormHasBeenSubmitted(true);
      toggleDisableSubmit(false);
      setErrorMap(errorMap);
    } else {

      toggleEmailInvalidError(false);
      
      setFormHasBeenSubmitted(true);

      let addressObj;

        if (selectedBooking.intakeFields[5].type === "international") {
          addressObj = {
            addressLine1,
            addressLine2,
            city,
            stateProvinceRegion,
            zipPostalCode,
            country
          }
        } else {
          addressObj = {
            addressLine1,
            addressLine2,
            city,
            state,
            zipCode
          }
        }

      if (props.isAddingAttendee) {

        let newAttendee = {
          bookingID: selectedBookingID,
          first: firstName,
          last: lastName,
          email: emailAddress,
          company: company,
          phone: phoneNumber,
          address: addressObj,
        }

        props.handleAttendeeSubmit(newAttendee);
        
      } else {
        let selectedRegistrationClone = selectedRegistration;

        selectedRegistrationClone.registrationData.firstName=firstName;
        selectedRegistrationClone.registrationData.lastName=lastName;
        selectedRegistrationClone.registrationData.company=company;
        selectedRegistrationClone.registrationData.emailAddress=emailAddress;
        selectedRegistrationClone.registrationData.phone=phoneNumber;
        selectedRegistrationClone.registrationData.address=addressObj;

        props.handleAttendeeSubmit(selectedRegistrationClone);
      }

    }
  }

  //material ui classes
  const classes = AttendeeFormStyles();

  let cancelButtonClasses : string;
  let saveButtonClasses : string;
  
  if (props.isAddingAttendee) {

    if (selectedBooking.intakeFields[5].type === "international") {
      cancelButtonClasses = `${classes.cancelButton} ${classes.cancelButtonAddAttendee} ${classes.cancelButtonAddInternational}`
      saveButtonClasses = `${classes.saveButton} ${classes.saveButtonAddAttendee} ${classes.saveButtonAddInternational}`;
    } else {
      cancelButtonClasses = `${classes.cancelButton} ${classes.cancelButtonAddAttendee}`
      saveButtonClasses = `${classes.saveButton} ${classes.saveButtonAddAttendee}`
    }
    
  } else {

    if (selectedBooking.intakeFields[5].type === "international") {
      cancelButtonClasses = `${classes.cancelButton} ${classes.cancelButtonInternational}`
      saveButtonClasses = `${classes.saveButton} ${classes.saveButtonInternational}`
    } else {  
      cancelButtonClasses = `${classes.cancelButton}`
      saveButtonClasses = `${classes.saveButton}`
    }
    
  }

  let formFieldsColumn1 : ReactElement[] = [];
  let formFieldsColumn2 : ReactElement[] = [];
  let intakeField : IntakeField;
  let fieldName : string;

  //Loop through intake field map, displaying ones that have visible set to true

  //First Name field
  fieldName = "First Name";
  intakeField = intakeFieldMap.get(fieldName);
  if(intakeField && intakeField.visible){
    formFieldsColumn1.push(
      <TextField 
        id={fieldName}
        inputProps={{maxLength: 50}}
        helperText={intakeField.mandatory ? "Required" : "Optional"}
        label={intakeField.name}
        variant="outlined"
        classes={intakeField.mandatory ? { root: classes.attendeeEditInput } : { root: classes.attendeeEditInputNotRequired }}
        key="First Name"
        onChange={handleFirstNameChange}
        value={firstName}
        error={errorMap.get(fieldName)} 
      />
    );
  }

  //Last Name field
  fieldName = "Last Name";
  intakeField = intakeFieldMap.get(fieldName);
  if(intakeField && intakeField.visible){
    formFieldsColumn1.push(
      <TextField 
        helperText={intakeField.mandatory ? "Required" : "Optional"}
        label={intakeField.name}
        inputProps={{maxLength: 50}}
        variant="outlined"
        classes={intakeField.mandatory ? { root: classes.attendeeEditInput } : { root: classes.attendeeEditInputNotRequired }}
        key="Last Name"
        onChange={handleLastNameChange}
        value={lastName}
        error={ errorMap.get(fieldName) }
      />
    );
  }

  //Company field
  fieldName = "Company";
  intakeField = intakeFieldMap.get(fieldName);
  if (intakeField && intakeField.visible) {
    formFieldsColumn1.push(
      <TextField
        helperText={intakeField.mandatory ? "Required" : "Optional"}
        label={intakeField.name}
        inputProps={{maxLength: 50}}
        variant="outlined"
        classes={intakeField.mandatory ? { root: classes.attendeeEditInput } : { root: classes.attendeeEditInputNotRequired }}
        onChange={handleCompanyChange}
        key="Company"
        value={company}
        error={ errorMap.get(fieldName)}
      />
    )
  }

  //Email Address field
  fieldName = "Email";
  intakeField = intakeFieldMap.get(fieldName);
  if (intakeField && intakeField.visible) {
    formFieldsColumn1.push(
      <TextField
        helperText={emailHelperText}
        error={emailInvalidError || props.emailError}
        label={intakeField.name}
        inputProps={{maxLength: 50}}
        variant="outlined"
        classes={intakeField.mandatory ? { root: classes.attendeeEditInput } : { root: classes.attendeeEditInputNotRequired }}
        onChange={handleEmailAddressChange}
        key="Email"
        value={emailAddress}
      />
    )
  }

  //telephone number field
  fieldName = "Phone";
  intakeField = intakeFieldMap.get(fieldName);
  if (intakeField && intakeField.visible) {
    formFieldsColumn1.push(
      <InputMask
        mask="(999) 999-9999"
        value={phoneNumber}
        disabled={false}
        onChange={handlePhoneChange}
        maskChar=" "
        key="input mask"
      >
        {(intakeField) => <TextField 
            helperText={intakeField.mandatory ? "Required" : "Optional"}
            label={"Phone"}
            variant="outlined"
            classes={intakeField.mandatory ? { root: classes.attendeeEditInput } : { root: classes.attendeeEditInputNotRequired }}
            key="Phone"
            error={ errorMap.get(fieldName)}/>
        }
      </InputMask>
    )
  }

  //address line 1 field
  fieldName = "Address Line 1";
  intakeField = intakeFieldMap.get(fieldName);
  if (intakeField && intakeField.visible) {
    formFieldsColumn2.push(
      <TextField
        helperText={intakeField.mandatory ? "Required" : "Optional"}
        label={intakeField.name}
        inputProps={{ maxLength: 250 }}
        variant="outlined"
        classes={intakeField.mandatory ? { root: classes.attendeeEditInput } : { root: classes.attendeeEditInputNotRequired }}
        onChange={handleAddressLine1Change}
        key="Address Line 1"
        value={addressLine1}
        error={ errorMap.get(fieldName)}
      />
    )
  }

  //address line 2 field
  fieldName = "Address Line 2";
  intakeField = intakeFieldMap.get(fieldName);
  if (intakeField && intakeField.visible) {
    formFieldsColumn2.push(
      <TextField
        helperText={intakeField.mandatory ? "Required" : "Optional"}
        label={intakeField.name}
        inputProps={{ maxLength: 250 }}
        variant="outlined"
        classes={intakeField.mandatory ? { root: classes.attendeeEditInput } : { root: classes.attendeeEditInputNotRequired }}
        onChange={handleAddressLine2Change}
        key="Address Line 2"
        value={addressLine2}
        error={ errorMap.get(fieldName)}
      />
    )
  }

  //city field
  fieldName = "City";
  intakeField = intakeFieldMap.get(fieldName);
  if (intakeField && intakeField.visible) {
    formFieldsColumn2.push(
      <TextField
        helperText={intakeField.mandatory ? "Required" : "Optional"}
        label={intakeField.name}
        inputProps={{ maxLength: 50 }}
        variant="outlined"
        classes={intakeField.mandatory ? { root: classes.attendeeEditInput } : { root: classes.attendeeEditInputNotRequired }}
        onChange={handleCityChange}
        key="City"
        value={city}
        error={ errorMap.get(fieldName)}
      />
    )
  }

  //If this booking is using US addresses, display those input fields
  if(selectedBooking.intakeFields[5].type === "US") {
    //state field
    fieldName = "State";
    intakeField = intakeFieldMap.get(fieldName);
    if (intakeField && intakeField.visible) {
      formFieldsColumn2.push(
        <TextField
          helperText={intakeField.mandatory ? "Required" : "Optional"}
          label={intakeField.name}
          inputProps={{ maxLength: 50 }}
          variant="outlined"
          classes={intakeField.mandatory ? { root: classes.attendeeEditInput } : { root: classes.attendeeEditInputNotRequired }}
          onChange={handleStateChange}
          key="State"
          value={state}
          error={ errorMap.get(fieldName)}
        />
      )
    }

    //zip code
    fieldName = "Zip Code";
    intakeField = intakeFieldMap.get(fieldName);
    if (intakeField && intakeField.visible) {
      formFieldsColumn2.push(
        <TextField
          helperText={intakeField.mandatory ? "Required" : "Optional"}
          label={intakeField.name}
          inputProps={{ 
            type: "number",
            maxLength: 5
          }}
          variant="outlined"
          classes={intakeField.mandatory ? { root: classes.attendeeEditInput } : { root: classes.attendeeEditInputNotRequired }}
          onChange={handleZipCodeChange}
          key="Zip Code"
          value={zipCode}
          error={ errorMap.get(fieldName)}
        />
      )
    }
  } 
  //If this booking is set to use international addresses, display those input fields
  else {
    fieldName = "State/Province/Region";
    intakeField = intakeFieldMap.get(fieldName);
    if (intakeField && intakeField.visible) {
      formFieldsColumn2.push(
        <TextField
          helperText={intakeField.mandatory ? "Required" : "Optional"}
          label="State/Province/Region"
          inputProps={{
            maxLength: 100,
          }}
          variant="outlined"
          classes={intakeField.mandatory ? { root: classes.attendeeEditInput } : { root: classes.attendeeEditInputNotRequired }}
          onChange={handleStateProvinceRegionChange}
          key="State/Province/Region"
          value={stateProvinceRegion}
          error={ errorMap.get(fieldName)}
        />
      )
    }

    fieldName = "Zip/Postal Code";
    intakeField = intakeFieldMap.get(fieldName);
    if (intakeField && intakeField.visible) {
      formFieldsColumn2.push(
        <TextField
          helperText={intakeField.mandatory ? "Required" : "Optional"}
          label="Zip/Postal Code"
          inputProps={{
            maxLength: 10,
          }}
          variant="outlined"
          classes={intakeField.mandatory ? { root: classes.attendeeEditInput } : { root: classes.attendeeEditInputNotRequired }}
          onChange={handleZipPostalCodeChange}
          key="Zip/Postal Code"
          value={zipPostalCode}
          error={ errorMap.get(fieldName)}
        />
      )
    }

    fieldName = "Country";
    intakeField = intakeFieldMap.get(fieldName);
    if (intakeField && intakeField.visible) {
      formFieldsColumn2.push(
        <TextField
          helperText={intakeField.mandatory ? "Required" : "Optional"}
          label="Country"
          inputProps={{
            maxLength: 100,
          }}
          variant="outlined"
          classes={intakeField.mandatory ? { root: classes.attendeeEditInput } : { root: classes.attendeeEditInputNotRequired }}
          onChange={handleCountryChange}
          key="Country"
          value={country}
          error={ errorMap.get(fieldName)}
        />
      )
    }
  }
    
  return (

    <React.Fragment>

    <form className={styles.attendeeForm} style={{ visibility: props.tab === 0 || props.isAddingAttendee ? "visible" : "hidden"}}>
      <div className={styles.column1}>
        {formFieldsColumn1}
      </div>
      <div className={styles.column2}>
        {formFieldsColumn2}
      </div>
    </form>

    <Button 
      onClick={handleCancelButtonClick} 
      className={cancelButtonClasses} 
      variant="contained"
      color="primary"
    >
      CANCEL
    </Button>
    <Button 
      disabled={props.isLoading} 
      onClick={handleAttendeeEditSubmit} 
      className={saveButtonClasses} 
      variant="contained" 
      color="primary"
    >
      SAVE
    </Button>

    </React.Fragment>

  )
}
