import { Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import React, { ReactElement, useEffect, useState, useContext } from 'react'
import axios from 'axios';

import SignInForm from '../../components/SignInForm/SignInForm';
import { AppContext, AppContextType } from '../../context/AppContext';
import { AuthContext, AuthContextType } from '../../context/AuthContext';
import { AuthHelper } from '../../utilities/AuthHelper';

import styles from './Login.module.css';
import { LoginStyles } from './LoginStyles';

interface Props {
  fromBookingLink: boolean,
  fromSpaceLink: boolean,
  toggleBookingLink: () => any,
  toggleSpaceLink: () => any,
  handleAuthenticate: () => any,
}

export default function Login(props : Props): ReactElement {
  
  const history = useHistory();
  
  const {
    setProducerID
  } : AppContextType = useContext(AppContext);

  const {
    setIdToken
  } : AuthContextType = useContext(AuthContext);
  
  const [ authenticated, toggleAuthenticated ] = useState(false);

  const [ render, toggleRender ] = useState(false);
  
  useEffect(() => {

    //remove min-height class from app
    document.getElementById("app").classList.remove("appMain");

    toggleRender(true);

  }, []);

  //Check for authentication
  const checkAuthentication = () => {
    if (localStorage.getItem("producerID") == null) {
      return false;
    }

    if (localStorage.getItem("idToken") == null) {
      return false;
    }

    return true;
  }

  
  const classes = LoginStyles();


  if (render) {
    return (
      <Paper
        elevation={0}
        className={classes.loginContentHolder}
      >

        <div className={styles.showboatLogoHolder}>
          <img
            alt="Showboat logo"
            src="assets/images/ShowboatLogo.svg"
            className={styles.showboatLogo}
          />
        </div>

        <SignInForm
          handleAuthenticate={props.handleAuthenticate}
          fromBookingLink={props.fromBookingLink}
          fromSpaceLink={props.fromSpaceLink}
        />

      </Paper>
    )
  } else {
    return null;
  }
  
}
