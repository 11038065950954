import { IconButton, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import CloseIcon from '@material-ui/icons/Close';

import styles from './PopupHeader.module.css';
import { PopupHeaderStyles } from './PopupHeaderStyles';

interface Props {
  closeFunction: any,
  disableBoolean: boolean,
  text?: string,
  icon?: ReactElement,
  hideClose?: boolean
}

export default function PopupHeader(props: Props): ReactElement {
  
  const handleCloseIconClick = () => {
    props.closeFunction();
  }
  
  const classes = PopupHeaderStyles();
  
  return (
    <div
      className={styles.popupHeader}
      style={{
        height: props.text ? "auto" : "37px",
      }}
    >
      {props.icon && <div className={styles.iconHolder}>{props.icon}</div>}
      {props.text && (
        <Typography className={classes.headerTitle} variant="h2">
          {props.text}
        </Typography>
      )}

      {!props.hideClose && (
        <IconButton
          className={classes.closeIconButton}
          onClick={handleCloseIconClick}
          disabled={props.disableBoolean}
        >
          <CloseIcon />
        </IconButton>
      )}
    </div>
  );
}
