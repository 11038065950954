import { Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import React, { ReactElement, useContext } from 'react';

import PopupHeader from '../../PopupHeader/PopupHeader';
import { AppContext } from '../../../context/AppContext';

import styles from './PreviewSingleColorPopup.module.css';
import { PreviewSingleColorPopupStyles } from './PreviewSingleColorPopupStyles';

interface Props {
  previewObject: any,
  handlePopupClose: () => void
}

export default function PreviewSingleColorPopup(props: Props): ReactElement {

  const {
    selectedSpace
  } = useContext(AppContext);
  
  const classes = PreviewSingleColorPopupStyles();
  
  return (
    <Dialog
      open={true}
      classes={{
        paper: classes.dialogPaper
      }}
    >
      <div 
        className={classes.popup}
      >

        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={false}
        />
        
        <Typography variant="h1" className={classes.previewHeader}>
          {props.previewObject.title}
        </Typography>

        <div className={styles.previewContentHolder}>
          <Typography variant="body1" className={classes.colorHeader}>
            {props.previewObject.title}
          </Typography>

          <Typography variant="body1" className={classes.colorLabel}>
            {selectedSpace[props.previewObject.division][props.previewObject.property].value}
          </Typography>

          <div 
            style={
              { 
                backgroundColor: selectedSpace[props.previewObject.division][props.previewObject.property].value
              }
            } 
            className={styles.colorHolder}
          >

          </div>
        </div>

        <Button variant="contained" color="primary" onClick={props.handlePopupClose} className={classes.closeButton}>
          CLOSE
        </Button>
      </div>
    </Dialog>
  )
}
