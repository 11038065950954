import { makeStyles, createStyles } from '@material-ui/core/styles';

const SaveLoadingSpinnerStyles = makeStyles((theme: any) =>
  createStyles({
    loadingSpinner: {
      color: "grey",
      position: "absolute",
      right: 62,
      bottom: 81,
      width: "30px !important",
      height: "30px !important",
    },
  })
);

export { SaveLoadingSpinnerStyles };