import React, {
  ReactElement,
  useCallback,
  useState,
  useContext,
  useEffect,
} from "react";
import {
  Dialog,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import AWS from "aws-sdk";
import axios from "axios";
import FailIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";

import { AppContext, AppContextType } from "../../../context/AppContext";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import PopupHeader from "../../PopupHeader/PopupHeader";

import styles from "./UploadSlidesPopup.module.css";
import { UploadSlidesPopupStyles } from "./UploadSlidesPopupStyles";
import SaveButton from "../../ui/SaveButton/SaveButton";
import CancelButton from "../../ui/CancelButton/CancelButton";

interface Props {
  handlePopupClose: () => any;
  presentationID: string;
}

export default function UploadSlidesPopup(props: Props): ReactElement {
  const { selectedPresentation, setSelectedPresentation }: AppContextType =
    useContext(AppContext);

  const { idToken }: AuthContextType = useContext(AuthContext);

  const [loadingSpinner, toggleLoadingSpinner] = useState(false);
  const [correctDimensions, toggleCorrectDimensions] = useState(false);
  const [dimensionsError, toggleDimensionsError] = useState(false);
const [error, toggleError] = useState(false);
  const [fileTypeError, toggleFileTypeError] = useState(false);

  const [dimensionsErrorIndexes, setDimensionsErrorIndexes] = useState([]);

  async function getSlideDimensions(file, index) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function (e) {
        var image = new Image();

        (image as HTMLImageElement).src = e.target.result as string;

        image.onload = function () {
          var height = image.height;
          var width = image.width;

          if (width === 960 && height === 540) {
            resolve(true);
          } else {
            setDimensionsErrorIndexes((dimensionsErrorIndexes) => [
              ...dimensionsErrorIndexes,
              index,
            ]);
            resolve(false);
          }
        };
      };
    });
  }

  /*File drop code */
  const onDrop = useCallback(async (acceptedFiles) => {
    setDimensionsErrorIndexes([]);

    toggleDimensionsError(false);

    //Check each uploaded slide for correct dimensions
    let promises = [];

    for (let i = 0; i < acceptedFiles.length; i++) {
      promises.push(getSlideDimensions(acceptedFiles[i], i));
    }

    let slideDimensionResult = await Promise.all(promises);

    let errorResult = false;

    if (slideDimensionResult.includes(false)) {
      errorResult = true;
    }

    if (errorResult) {
      toggleCorrectDimensions(false);
      toggleDimensionsError(true);
    } else {
      toggleCorrectDimensions(true);
      toggleDimensionsError(false);
    }
  }, []);

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      onDrop: onDrop,
      accept: "image/*",
    });

  const files = acceptedFiles.map((file) => (
    <li key={file.name} className={styles.fileName}>
      {file.name} - {file.size} bytes
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.name} className={styles.fileName}>
      {file.name} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  const handleSaveButtonClick = () => {
    toggleError(false);

    toggleLoadingSpinner(true);

    if (!acceptedFiles.length) {
      return alert("Please choose a file to upload first.");
    }

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: "us-east-1",
      signatureVersion: "v4",
    });

    //GET SIGNED URLS FROM S3
    let filesToSend = [];

    acceptedFiles.forEach((file) => {
      filesToSend.push({
        name: file.name,
        type: file.type,
      });
    });

    axios
      .post("/slides/upload-request", {
        files: filesToSend,
      })
      .then(function (response) {
        let preSignedURLResponse = response.data;

        let promises = [];

        //delete authorization header to allow for PUT to pre-signed URL's

        delete axios.defaults.headers.common["Authorization"];

        for (let i = 0; i < preSignedURLResponse.length; i++) {
          promises.push(
            axios
              .put(preSignedURLResponse[i].url, acceptedFiles[i])
              .then((response) => {
                return preSignedURLResponse[i].fileID + ".png";
              })
              .catch((error) => {})
          );
        }

        Promise.all(promises)
          .then((response) => {
            //add back authorization header
            axios.defaults.headers.common["Authorization"] = idToken;

            //UPDATE SLIDES ARRAY
            let presentationSlides = selectedPresentation.slides;

            let newPresentationSlidesArray = [
              ...presentationSlides,
              ...response,
            ];

            axios
              .put("/presentation", {
                spaceID: selectedPresentation.spaceID,
                presentationID: selectedPresentation.presentationID,
                name: selectedPresentation.name,
                slides: newPresentationSlidesArray,
              })
              .then(function (response) {
                toggleLoadingSpinner(false);
                setSelectedPresentation(null);
                props.handlePopupClose();
              })
              .catch(function (error) {
                toggleLoadingSpinner(false);
                toggleError(true);
                console.log(error);
              });
          })
          .catch(function (error) {
            //add back authorization header
            axios.defaults.headers.common["Authorization"] = idToken;
            toggleError(true);
          });
      })
      .catch(function (error) {
        console.log(error);
        toggleLoadingSpinner(false);
        toggleError(true);
      });
  };

  const handleCancelButtonClick = () => {
    if (loadingSpinner) {
      return;
    }

    toggleError(false);
    toggleDimensionsError(false);
    props.handlePopupClose();
  };

  useEffect(() => {
    if (acceptedFiles.length) {
      acceptedFiles.forEach((file) => {
        if (file.type !== "image/png") {
          toggleFileTypeError(true);
        } else {
          toggleFileTypeError(false);
        }
      });
    }
  }, [acceptedFiles]);

  const classes = UploadSlidesPopupStyles();

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} open={true}>
      <div className={styles.popup}>
        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={loadingSpinner}
        />

        <Typography variant="h1" className={classes.uploadSlidesHeader}>
          Upload Slides
        </Typography>
        <Typography variant="h2" className={classes.uploadSlidesSubHeader}>
          PNG: 960px x 540px
        </Typography>

        {error && (
          <Typography variant="body1" className={classes.error}>
            An error occurred uploading slides
          </Typography>
        )}

        <div
          className={styles.fileUploadHolder}
          style={{
            marginBottom:
              fileRejections.length || dimensionsError
                ? "160px"
                : files.length
                ? "139px"
                : "0px",
          }}
        >
          <div {...getRootProps({ className: `${styles.dropzone}` })}>
            <input id="fileDrop" {...getInputProps()} />
            <Typography variant="body1" className={classes.dropzoneText}>
              Drag 'n' drop some files here, or click to select files
            </Typography>
          </div>

          <div className={styles.fileResultHolder}>
            {acceptedFiles.length !== 0 && (
              <React.Fragment>
                {acceptedFiles.map((file, i) => {
                  if (dimensionsErrorIndexes.includes(i)) {
                    return (
                      <div className={styles.fileFailureHolder}>
                        <Typography
                          variant="body1"
                          className={classes.fileName}
                        >
                          {file.name}
                        </Typography>

                        {dimensionsError && (
                          <Typography
                            variant="body1"
                            className={classes.fileError}
                          >
                            Dimensions of image must be 960px x 540px
                          </Typography>
                        )}

                        <FailIcon className={styles.failIcon} />
                      </div>
                    );
                  } else if (file.type !== "image/png") {
                    return (
                      <div className={styles.fileFailureHolder}>
                        <Typography
                          variant="body1"
                          className={classes.fileName}
                        >
                          {file.name}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.fileError}
                        >
                          File must be of type PNG
                        </Typography>
                        <FailIcon className={styles.failIcon} />
                      </div>
                    );
                  } else {
                    return (
                      <div className={styles.fileSuccessHolder}>
                        <Typography
                          variant="body1"
                          className={classes.fileName}
                        >
                          {file.name}
                        </Typography>

                        <CheckIcon className={styles.checkIcon} />
                      </div>
                    );
                  }
                })}
              </React.Fragment>
            )}

            {/* {(fileRejectionItems.length !== 0 || dimensionsError) &&
              <div className={styles.fileFailureHolder}>
                <Typography variant="body1" className={classes.fileName}>
                  {
                    dimensionsError
                      ?
                      acceptedFiles[0]
                        ? (acceptedFiles[0] as any).name
                        : ""
                      :
                      fileRejections[0]
                        ?
                        (fileRejections[0] as any).file.name
                        : ""
                  }
                </Typography>

                {dimensionsError &&
                  <Typography variant="body1" className={classes.fileError}>
                    Dimensions of image must be 960px x 540px
                  </Typography>
                }

                {fileTypeError &&
                  <Typography variant="body1" className={classes.fileError}>
                    File must be of type PNG
                  </Typography>
                }

                <FailIcon className={styles.failIcon} />
              </div>
            } */}
          </div>
        </div>

        {loadingSpinner && (
          <CircularProgress className={classes.loadingSpinner} />
        )}

        <CancelButton onClick={handleCancelButtonClick} variant="text" />

        <SaveButton
          disableBoolean={
            !correctDimensions ||
            loadingSpinner ||
            fileRejectionItems.length !== 0 ||
            fileTypeError
          }
          onClick={handleSaveButtonClick}
        />
      </div>
    </Dialog>
  );
}
