import { makeStyles, createStyles } from '@material-ui/core/styles';

const ErrorMessageEditPopupStyles = makeStyles((theme: any) => createStyles({
  dialogPaper: {
    width: 462,
    height: 298,
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "auto",
  },
  popup: {
    width: "100%",
    height: 298,
    position: "absolute",
  },
  editErrorMessageHeader: {
    fontSize: 24,
    position: "relative",
    left: 30,
    top: 58,
  },
  errorMessageTextField: {
    width: 403,
    position: "absolute",
    left: 30,
    top: 120
  },
  errorMessageInput: {
    backgroundColor: "#F7F7F7"
  },
  error: {
    color: "red",
    position: "absolute",
    left: 30,
    top: 89,
  },
  loadingSpinner: {
    color: "grey",
    position: "absolute",
    right: 57,
    bottom: 77
  },
  
})
)

export { ErrorMessageEditPopupStyles };