import { CognitoUserPool } from "amazon-cognito-identity-js";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { AuthContext, AuthContextType } from "../../context/AuthContext";
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Paper,
  Typography,
} from "@material-ui/core";
import { SpaceUpdateAdminStyles } from "./SpaceUpdateAdminStyles";
import AdminInterfaceWrapper from "../../components/ui/AdminInterfaceWrapper/AdminInterfaceWrapper";
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import styles from "./SpaceUpdateAdmin.module.css";
import { orderBy } from "natural-orderby";

interface Props {}

export default function SpaceUpdateAdmin({}: Props): ReactElement {
  const history = useHistory();

  const { setIdToken, idToken }: AuthContextType = useContext(AuthContext);

  const [authenticated, setAuthenticated] = useState(false);

  const [spacesLoadingSpinner, setSpacesLoadingSpinner] = useState(true);

  const [spaces, setSpaces] = useState([]);

  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const [disableDatePicker, setDisableDatePicker] = useState(true);

  const [selectedSpaceIDs, setSelectedSpaceIDs] = useState([]);

  const [submitLoadingSpinner, setSubmitLoadingSpinner] = useState(false);

  const [error, setError] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    //Initial authentication check
    //First check if there is any producerID in sessionStorage
    if (localStorage.getItem("producerID") == undefined) {
      //Redirect to sign-in if no producerID in sessionStorage
      alert("You do not have privileges to access this page.");
      window.location.replace("/");
    } else {
      //Set up authentication to query producer object
      var poolData = {
        UserPoolId: "us-east-1_N15Q0NLkm",
        ClientId: "2332rbhi35f5016dglri2mojo",
      };

      const userPool = new CognitoUserPool(poolData);

      let currentUser = userPool.getCurrentUser();

      if (currentUser) {
        currentUser.getSession(async (err, session) => {
          if (err) {
            alert("Your session has expired. Please sign in again.");
            window.location.replace("/");
          } else {
            axios.defaults.headers.common["Authorization"] = session
              .getIdToken()
              .getJwtToken();

            setIdToken(session.getIdToken().getJwtToken());

            //Check producer privileges on load (check if SuperAdmin)
            //ProducerID is received from sessionStorage

            try {
              let roleResponse = await axios.get("/producer/role", {
                params: {
                  producerID: localStorage.getItem("producerID"),
                },
              });

              //Get the role on the producer object
              if (roleResponse.data.role === 0) {
                setAuthenticated(true);

                //Get all spaces
                try {
                  const response = await axios.get("/spaces/all/admin", {
                    headers: {
                      Authorization: session.getIdToken().getJwtToken(),
                    },
                  });

                  console.log("SPACES:", response.data);

                  setSpacesLoadingSpinner(false);

                  //Sort state array
                  const stateOrdered = orderBy(
                    response.data,
                    [(s) => (s as any).spaceName],
                    "asc"
                  );

                  setSpaces(stateOrdered);

                  setDisableDatePicker(false);
                } catch (error) {
                  setSpacesLoadingSpinner(false);
                  console.error("Error getting spaces:", error);
                }
              } else {
                alert("You do not have privileges to access this page.");
                window.location.replace("/");
              }
            } catch {
              alert("You do not have privileges to access this page.");
              window.location.replace("/");
            }
          }
        });
      } else {
        alert("Your session has expired. Please sign in again.");
        window.location.replace("/");
      }
    }
  }, []);

  const handleEndDateChange = (date) => {
    setSelectedEndDate(moment.tz(date, "America/Chicago").format());
  };

  const handleSpaceCheck = (e) => {
    const spaceID = e.target.value;

    if (selectedSpaceIDs.includes(spaceID)) {
      setSelectedSpaceIDs((selectedSpaceIDs) =>
        selectedSpaceIDs.filter((s) => s !== spaceID)
      );
    } else {
      setSelectedSpaceIDs((selectedSpaceIDs) => [...selectedSpaceIDs, spaceID]);
    }
  };

  const handleSelectAllSpacesClick = () => {
    let spaceIDArr = [];
    for (let i = 0; i < spaces.length; i++) {
      spaceIDArr.push(spaces[i].spaceID);
    }

    setSelectedSpaceIDs(spaceIDArr);
  };

  const handleSubmitClick = async () => {
    setError(false);

    setSubmitLoadingSpinner(true);

    if (selectedEndDate === null) {
      alert("Please select an end date");
      return;
    }

    //Format end time to be 11:59pm of day selected
    const endTimeToSend = moment
      .tz(selectedEndDate, "America/Chicago")
      .set("hour", 23)
      .set("minutes", 59)
      .set("seconds", 59)
      .format();

    try {
      //Execute put request for each space
      let response = await axios.put(
        "/spaces-endtime-update-admin",
        JSON.stringify({
          spaceIDArr: selectedSpaceIDs,
          endTime: endTimeToSend,
        }),
        {
          headers: {
            Authorization: idToken,
          },
        }
      );

      setSubmitted(true);
    } catch (error) {
      setSubmitLoadingSpinner(false);
    }
  };

  const classes = SpaceUpdateAdminStyles();

  if (authenticated) {
    return (
      <React.Fragment>
        <Button
          className={classes.adminMenuButton}
          variant="contained"
          color="primary"
          onClick={() => {
            history.push("/admin-menu");
          }}
        >
          ADMIN MENU
        </Button>

        <AdminInterfaceWrapper wrapperHeight={900} wrapperWidth={974}>
          <Paper className={classes.spaceUpdateWrapper}>
            <Typography variant="h1" className={classes.spaceUpdateHeader}>
              Space End Time Update
            </Typography>

            <Divider className={classes.spaceAdminDivider}></Divider>

            {submitted ? (
              <React.Fragment>
                <Typography className={classes.success}>Success</Typography>
              </React.Fragment>
            ) : (
              <div style={{ textAlign: "left" }}>
                <Typography className={classes.spaceEndTimeHeader}>
                  Select end time (in CST)
                </Typography>

                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    value={selectedEndDate}
                    onChange={handleEndDateChange}
                    showTodayButton
                    disabled={disableDatePicker}
                    className={classes.dateTimePicker}
                    variant="inline"
                    format="MM/DD/yyyy"
                  />
                </MuiPickersUtilsProvider>

                {spacesLoadingSpinner && (
                  <CircularProgress className={classes.loadingSpinner} />
                )}

                {spaces.length !== 0 && (
                  <React.Fragment>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSelectAllSpacesClick}
                      className={classes.selectAllButton}
                    >
                      CHECK ALL
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setSelectedSpaceIDs([])}
                      className={`${classes.selectAllButton} ${classes.deselectButton}`}
                    >
                      UNCHECK ALL
                    </Button>

                    <Button
                      className={classes.submitButton}
                      variant="contained"
                      color="primary"
                      onClick={handleSubmitClick}
                      disabled={submitLoadingSpinner}
                    >
                      SUBMIT
                    </Button>

                    {submitLoadingSpinner && (
                      <CircularProgress
                        className={classes.submitLoadingSpinner}
                      />
                    )}
                  </React.Fragment>
                )}

                <div className={styles.spacesHolder}>
                  {error && (
                    <Typography className={classes.error}>
                      An error occurred updating the selected spaces
                    </Typography>
                  )}

                  {spaces.map((space) => {
                    return (
                      <React.Fragment key={space.spaceID}>
                        <FormControlLabel
                          value={space.spaceID}
                          control={
                            <Checkbox
                              color="primary"
                              onChange={handleSpaceCheck}
                              checked={selectedSpaceIDs.includes(space.spaceID)}
                            />
                          }
                          label={space.spaceName}
                          labelPlacement="start"
                          className={classes.spaceItem}
                        />

                        <Typography className={classes.spaceEndTime}>
                          {moment(space.endDate.date).format("L")}
                        </Typography>

                        <Divider className={classes.spaceDivider}></Divider>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            )}
          </Paper>
        </AdminInterfaceWrapper>
      </React.Fragment>
    );
  } else {
    return <div></div>;
  }
}
