import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const BookingsAdminItemStyles = makeStyles((theme: any) => createStyles({
  bookingName: {
    fontSize: 14,
    position: "absolute",
    display: "inline-block",
    left: 20,
    bottom: 5,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: 205,
    textAlign: "left",
    "&:hover": {
      cursor: "pointer"
    }
  },
  spaceName: {
    fontSize: 14,
    width: 215,
    left: 240,
    bottom: 5,
    textAlign: "left",
    position: "absolute",
    display: "inline-block",
    wordWrap: "break-word",
  },
  bookingStart: {
    fontSize: 14,
    position: "absolute",
    display: "inline-block",
    wordWrap: "break-word",
    width: 120,
    textAlign: "left",
    right: 125,
    bottom: 20,
  },
  bookingStartTime: {
    fontSize: 14,
    position: "absolute",
    display: "inline-block",
    wordWrap: "break-word",
    width: 120,
    textAlign: "left",
    right: 125,
    bottom: 0,
  },
  bookingEnd: {
    fontSize: 14,
    position: "absolute",
    display: "inline-block",
    wordWrap: "break-word",
    width: 120,
    textAlign: "left",
    right: 20,
    bottom: 20,
  },
  bookingEndTime: {
    fontSize: 14,
    position: "absolute",
    display: "inline-block",
    wordWrap: "break-word",
    width: 120,
    textAlign: "left",
    right: 20,
    bottom: 0,
  },
  bookingRegistrationCount: {
    position: "absolute",
    fontSize: 14,
    right: 80,
    bottom: 5,
  }
})
)

export { BookingsAdminItemStyles };