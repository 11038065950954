import { makeStyles, createStyles } from '@material-ui/core/styles';

const BookingPresenterPermissionsStyles = makeStyles((theme: any) =>
  createStyles({
    permissionsHeader: {
      fontSize: 19,
      fontWeight: 400,
      position: "absolute",
      top: 0,
      left: 0,
    },
    permissionsSubheader: {
      fontSize: 17,
      fontWeight: "bold",
      position: "absolute",
      top: 0,
      bottom: 0,
    },
    permissionsDivider: {
      width: "100%",
      position: "absolute",
      bottom: 0,
      left: 0,
    },
    permissionName: {
      fontSize: 16,
      fontWeight: 400,
    },
    permissionsToggle: {},
    helpIcon: {
      padding: 4,
      position: "relative",
      left: 20,
      color: "black",
      bottom: 1
    }
  })
);

export { BookingPresenterPermissionsStyles };