import React, { ReactElement, useContext, useState, useEffect } from 'react';
import { orderBy } from 'natural-orderby';

import UploadImagePopup from '../UploadPopups/UploadImagePopup/UploadImagePopup';
import { AppContext, AppContextType } from '../../../context/AppContext';
import WebTemplateSkinnableItem from '../WebTemplateSkinnableItem/WebTemplateSkinnableItem';
import PreviewAssetPopup from '../PreviewAssetPopup/PreviewAssetPopup';
import UploadImageOrVideoPopup from '../UploadPopups/UploadImageOrVideoPopup/UploadImageOrVideoPopup';

interface Props {
  
}

interface PreviewObject {
  title: string,
  property: string,
  value: string,
  type: string,
  division: string
}

export default function BrandingWebTemplate(props: Props): ReactElement {
  
  const { 
    selectedSpace, isSuperAdmin
  } : AppContextType = useContext(AppContext);

  const [ skinnableFields, setSkinnableFields ] = useState([]);

  const [ preview, togglePreview ] = useState(false);
  const [ previewObject, setPreviewObject ] = useState({
    title: "",
    property: "",
    value: "",
    type: "",
    division: ""
  } as PreviewObject);

  const [ uploadType, setUploadType ] = useState("");
  const [ uploadTitle, setUploadTitle ] = useState("");
  const [ uploadProperty, setUploadProperty ] = useState("");
  const [ uploadDimensions, setUploadDimensions ] = useState({});
  const [ uploadAssetFamily, setUploadAssetFamily ] = useState("");
  const [ upload, toggleUpload ] = useState(false);

  //GET UI SKIN SKINNABLE ASSETS
  useEffect(() => {
    let uiSkinArrayOfObjects = [];

    for (const skinItem in selectedSpace.uiSkin) {

      let skinItemEntry;

      if (selectedSpace.uiSkin[skinItem].dimensions) {
        skinItemEntry = {
          property: skinItem,
          label: selectedSpace.uiSkin[skinItem].label,
          type: selectedSpace.uiSkin[skinItem].type,
          value: selectedSpace.uiSkin[skinItem].value,
          dimensions: selectedSpace.uiSkin[skinItem].dimensions,
          division: "uiSkin"
        };

      } else {
        skinItemEntry = {
          property: skinItem,
          label: selectedSpace.uiSkin[skinItem].label,
          type: selectedSpace.uiSkin[skinItem].type,
          value: selectedSpace.uiSkin[skinItem].value,
          division: "uiSkin"
        }

      }

      //Check if assetFamily is undefined
      if (selectedSpace.uiSkin[skinItem].assetFamily) {
        skinItemEntry.assetFamily = selectedSpace.uiSkin[skinItem].assetFamily;
      }

      //Check if hidden is undefined
      if (selectedSpace.uiSkin[skinItem].hidden !== undefined) {
        skinItemEntry.hidden = selectedSpace.uiSkin[skinItem].hidden;
      }
      
      uiSkinArrayOfObjects.push(skinItemEntry);
    };

    let sortedPropertyArray = orderBy(
      uiSkinArrayOfObjects,
      [s => s.label],
      ['asc']
    );

    setSkinnableFields(sortedPropertyArray);

  }, [])

  //Upload/edit
  const handleShowUploadPopup = (label: string, type: string, property: string, assetFamily: string, dimensions: {}) => {
    setUploadTitle(label);
    setUploadProperty(property);
    setUploadDimensions(dimensions);
    setUploadType(type);
    setUploadAssetFamily(assetFamily);
    toggleUpload(true);
  };

  const handleUploadClose = () => {
    toggleUpload(false);
    setUploadTitle("");
    setUploadProperty("");
    setUploadAssetFamily("");
    setUploadType("");
  }

  //Preview
  const handleShowPreviewPopup = (previewObj : PreviewObject) => {

    setPreviewObject(previewObj);

    togglePreview(true);
  }
  const handlePreviewClose = () => {

    setPreviewObject({
      title: "",
      property: "",
      division: "",
      value: "",
      type: "",
    });

    togglePreview(false);
  }

  //Handle image or video upload
  const handleImageOrVideoUpload = (imageOrVideo: string, property: string) => {
    let spaceSkinnableFields = skinnableFields;

    //Find corresponding skin object and change type on it
    spaceSkinnableFields.map(field => {
      if (field.property === property) {
        field.type = imageOrVideo;
      }
    });

    setSkinnableFields(spaceSkinnableFields);

  }
  
  let uploadPopup;
  
  if (uploadProperty === "landingPageAsset") {
    uploadPopup = (
      <UploadImageOrVideoPopup 
        uploadDivision="uiSkin" 
        handlePopupClose={handleUploadClose} 
        uploadType={uploadType} 
        uploadTitle={uploadTitle} 
        uploadDimensions={uploadDimensions}
        uploadProperty={uploadProperty}
        handleImageOrVideoUpload={handleImageOrVideoUpload}
      />
    )
  } else {
    uploadPopup = (
      <UploadImagePopup 
        uploadDivision="uiSkin" 
        handlePopupClose={handleUploadClose} 
        uploadType={uploadType} 
        uploadTitle={uploadTitle} 
        uploadDimensions={uploadDimensions}
        uploadProperty={uploadProperty}
      />
    )
  }
  
  return (
    <React.Fragment>

      {skinnableFields.map((field, i) => {

        if (field.hidden && !isSuperAdmin) {
          return null;
        } else if (field.property === "theme") {
          return null;
        } else {
          return (
            <WebTemplateSkinnableItem
              type={field.type}
              value={field.value}
              label={field.label}
              division={field.division}
              property={field.property}
              dimensions={field.dimensions}
              assetFamily={field.assetFamily}
              key={i}
              handleShowUploadPopup={handleShowUploadPopup}
              handleShowPreviewPopup={handleShowPreviewPopup}
              index={i}
            />
          )
        }
        
      })}

      {upload &&
        uploadPopup
      }

      {preview &&
        <PreviewAssetPopup 
          previewObject={previewObject}
          handlePopupClose={handlePreviewClose} 
        />
      }

    </React.Fragment>
  )
}
