import { CognitoUserPool } from "amazon-cognito-identity-js";
import React, {
  ReactElement,
  useEffect,
  useContext,
  useState,
  useRef,
} from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { AuthContext, AuthContextType } from "../../context/AuthContext";
import {
  Button,
  CircularProgress,
  Divider,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { UsageAdminStyles } from "./UsageAdminStyles";
import styles from "./UsageAdmin.module.css";
import AttendeeItem from "./AttendeeItem/AttendeeItem";
import { AppContextType, AppContext } from "../../context/AppContext";
import AdminInterfaceWrapper from "../../components/ui/AdminInterfaceWrapper/AdminInterfaceWrapper";
import { JWTHelper } from "../../utilities/JWTHelper";

interface Props {}

export default function UsageAdmin(props: Props): ReactElement {
  const history = useHistory();

  const { idToken, setIdToken }: AuthContextType = useContext(AuthContext);

  const { producerID }: AppContextType = useContext(AppContext);

  const [authenticated, toggleAuthenticated] = useState(false);

  const [bookingID, setBookingID] = useState("");
  const [bookingIDError, setBookingIDError] = useState(false);

  const [bookingTimezone, setBookingTimezone] = useState("");

  const [bookingAttendees, setBookingAttendees] = useState([]);

  const [usageRepairCompleted, toggleUsageRepairCompleted] = useState(false);

  const [bookingError, toggleBookingError] = useState(false);

  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const [bookingLoadingSpinner, toggleBookingLoadingSpinner] = useState(false);

  const [overallLoadingSpinner, toggleOverallLoadingSpinner] = useState(false);

  const [disableDatePicker, toggleDisableDatePicker] = useState(true);

  const [usageError, toggleUsageError] = useState(false);

  const selectedAttendees = useRef([]);

  useEffect(() => {
    //First check if there is any producerID in sessionStorage
    if (localStorage.getItem("producerID") == undefined) {
      //Redirect to sign-in if no producerID in sessionStorage
      alert("You do not have privileges to access this page.");
      window.location.replace("/");
    } else {
      //Set up authentication to query producer object
      var poolData = {
        UserPoolId: "us-east-1_N15Q0NLkm",
        ClientId: "2332rbhi35f5016dglri2mojo",
      };

      const userPool = new CognitoUserPool(poolData);

      let currentUser = userPool.getCurrentUser();

      if (currentUser) {
        currentUser.getSession((err, session) => {
          if (err) {
            alert("Your session has expired. Please sign in again.");
            window.location.replace("/");
          } else {
            axios.defaults.headers.common["Authorization"] = session
              .getIdToken()
              .getJwtToken();

            setIdToken(session.getIdToken().getJwtToken());

            //Check producer privileges on load (check if SuperAdmin)
            //ProducerID is received from sessionStorage
            axios
              .get("/producer/role", {
                params: {
                  producerID: localStorage.getItem("producerID"),
                },
              })
              .then(function (response) {
                //Get the role on the producer object
                if (response.data.role === 0) {
                  toggleAuthenticated(true);
                } else {
                  alert("You do not have privileges to access this page.");
                  window.location.replace("/");
                }
              })
              .catch(function (error) {
                alert("You do not have privileges to access this page.");
                window.location.replace("/");
              });
          }
        });
      } else {
        alert("Your session has expired. Please sign in again.");
        window.location.replace("/");
      }
    }
  }, []);

  //Booking ID Change
  const handleBookingIDChange = (e) => {
    setBookingIDError(false);

    setBookingID(e.target.value);
  };

  //Submit
  const handleBookingSubmitClick = () => {
    //Make sure bookingID is not empty
    if (!bookingID.trim().length) {
      setBookingIDError(true);
      return;
    }

    setBookingIDError(false);

    //Show loading spinner
    toggleBookingLoadingSpinner(true);

    toggleBookingError(false);

    let producerToken = JWTHelper.createJWT("", bookingID);

    //Get booking for spaceID, then get attendees for booking
    axios
      .get("/booking", {
        params: {
          bookingID: bookingID,
          producerID: producerID,
        },
        headers: {
          Authorization: idToken,
          producerauth: producerToken
        },
      })
      .then(function (response) {
        //Set booking timezone in state, and set as moment default
        setBookingTimezone(response.data.booking.start.timezone);
        moment.tz.setDefault(response.data.booking.start.timezone); 

        //Set initial end date to booking's end time
        setSelectedEndDate(
          moment
            .tz(
                response.data.booking.end.time,
                response.data.booking.end.timezone
            )
            .format()
        );

        let producerToken2 = JWTHelper.createJWT(response.data.booking.spaceID, bookingID);

        axios
          .get("/attendees/list", {
            params: {
              bookingID: bookingID,
              spaceID: response.data.booking.spaceID,
            },
            headers: {
              Authorization: idToken,
              producerauth: producerToken2
            },
          })
          .then(function (response) {
            toggleBookingLoadingSpinner(false);

            //Set attendees in state
            setBookingAttendees(response.data);
          })
          .catch(function (error) {
            toggleBookingLoadingSpinner(false);
            toggleBookingError(true);
          });
      })
      .catch(function (error) {
        toggleBookingLoadingSpinner(false);
        toggleBookingError(true);
      });
  };

  //Select attendee
  const handleAttendeeSelect = (attendeeID) => {
    let selectedAttendeesClone = selectedAttendees.current;
    //If selectedAttendees ref contains the passed ID, remove it
    if (selectedAttendees.current.includes(attendeeID)) {
      selectedAttendeesClone = selectedAttendeesClone.filter(
        (id) => id !== attendeeID
      );
      selectedAttendees.current = selectedAttendeesClone;
    }
    //Otherwise, add it
    else {
      selectedAttendeesClone.push(attendeeID);
      selectedAttendees.current = selectedAttendeesClone;
    }

    //Check if we should un-disable date picker
    if (selectedAttendees.current.length) {
      toggleDisableDatePicker(false);
    } else {
      toggleDisableDatePicker(true);
    }
  };

  //End date change
  const handleEndDateChange = (date) => {
    setSelectedEndDate(moment.tz(date, bookingTimezone).format());
  };

  //Repair submit
  const handleRepairSubmit = () => {
    toggleOverallLoadingSpinner(true);

    toggleUsageError(false);

    //Assemble attendees object
    let attendeeIDArray = selectedAttendees.current;

    let attendeesToSend = [];

    attendeeIDArray.forEach((attendeeID) => {
      //Loop through booking attendees, finding corresponding object
      for (let i = 0; i < bookingAttendees.length; i++) {
        if (bookingAttendees[i].attendeesID === attendeeID) {
          attendeesToSend.push(bookingAttendees[i]);
        }
      }
    });

    //Call repair endpoint
    axios
      .put("/booking/usage/repair", {
        attendees: attendeesToSend,
        endTime: selectedEndDate,
        bookingID: bookingID,
      })
      .then(function (response) {
        toggleOverallLoadingSpinner(false);
        toggleUsageRepairCompleted(true);
      })
      .catch(function (error) {
        toggleOverallLoadingSpinner(false);
        toggleUsageError(true);
      });
  };

  //Back
  const handleBackClick = () => {
    window.location.reload();
  };

  const classes = UsageAdminStyles();

  if (authenticated) {
    return (
      <React.Fragment>
        <Button
          className={classes.adminMenuButton}
          variant="contained"
          color="primary"
          onClick={() => {
            history.push("/admin-menu");
          }}
        >
          ADMIN MENU
        </Button>

        <AdminInterfaceWrapper
          wrapperHeight={900}
          wrapperWidth={974}
        >
          <Paper className={classes.usageAdminWrapper}>
            <Typography variant="h1" className={classes.usageRepairHeader}>
              Repair a Booking's Usage Record
            </Typography>

            <Divider className={classes.usageDivider}></Divider>

            {usageRepairCompleted ? (
              <React.Fragment>
                <Typography variant="body1" className={classes.success}>
                  Success
                </Typography>

                <Button
                  className={classes.backButton}
                  variant="contained"
                  color="primary"
                  onClick={handleBackClick}
                >
                  BACK
                </Button>
              </React.Fragment>
            ) : (
              <div className={styles.usageRepairHolder}>
                {bookingError && (
                  <Typography variant="body1" className={classes.error}>
                    An error occurred getting this booking
                  </Typography>
                )}

                {usageError && (
                  <Typography variant="body1" className={classes.error}>
                    An error occurred updating the selected attendees
                  </Typography>
                )}

                <Typography variant="h2" className={classes.bookingHeader}>
                  Input Booking ID
                </Typography>

                <TextField
                  variant="outlined"
                  value={bookingID}
                  onChange={handleBookingIDChange}
                  className={classes.bookingIDTextField}
                  error={bookingIDError}
                />

                {bookingLoadingSpinner && (
                  <CircularProgress className={classes.loadingSpinner} />
                )}

                <Button
                  className={classes.submitButton}
                  variant="contained"
                  color="primary"
                  onClick={handleBookingSubmitClick}
                  disabled={bookingLoadingSpinner}
                >
                  SUBMIT
                </Button>

                <Typography variant="h2" className={classes.attendeeHeader}>
                  Select Attendees
                </Typography>

                <Paper className={classes.attendeeSelectHeaderHolder}>
                  <Typography
                    variant="body1"
                    className={classes.attendeeNameHeader}
                  >
                    Name
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.attendeeEnterHeader}
                  >
                    Enter
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.attendeeExitHeader}
                  >
                    Exit
                  </Typography>
                </Paper>
                <div className={styles.attendeeSelectHolder}>
                  {bookingAttendees
                    .filter((attendee) => attendee.endTime == "")
                    .map((attendee) => {
                      return (
                        <AttendeeItem
                          key={attendee.attendeesID}
                          start={attendee.startTime}
                          end={attendee.endTime}
                          firstName={attendee.intakeData.firstName}
                          lastName={attendee.intakeData.lastName}
                          attendeeID={attendee.attendeesID}
                          timezone={bookingTimezone}
                          handleAttendeeSelect={handleAttendeeSelect}
                        />
                      );
                    })}
                </div>

                <Typography variant="h2" className={classes.dateHeader}>
                  Select End Time to Mark (in event's timezone)
                </Typography>

                <Typography variant="h2" className={classes.dateSubheader}>
                  Defaults to event's end time
                </Typography>

                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    value={selectedEndDate}
                    onChange={handleEndDateChange}
                    showTodayButton
                    disabled={disableDatePicker}
                    className={classes.dateTimePicker}
                    variant="inline"
                  />
                </MuiPickersUtilsProvider>
              </div>
            )}

            {overallLoadingSpinner && (
              <CircularProgress className={classes.overallLoadingSpinner} />
            )}

            {!usageRepairCompleted && (
              <Button
                className={classes.overallSubmitButton}
                variant="contained"
                color="primary"
                disabled={overallLoadingSpinner || disableDatePicker}
                onClick={handleRepairSubmit}
              >
                Submit
              </Button>
            )}
          </Paper>
        </AdminInterfaceWrapper>
      </React.Fragment>
    );
  } else {
    return <div></div>;
  }
}
