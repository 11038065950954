import { useState } from "react";
import axios from 'axios';

const useGetBookingUsage = () => {
  const [usageLoadingSpinner, toggleUsageLoadingSpinner] = useState(false);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  
  const getBookingUsage = async (selectedBooking) => {
    toggleUsageLoadingSpinner(true);

    try {
      let response = await axios.get("/booking/usage", {
        params: {
          bookingID: selectedBooking.bookingID,
        },
        cancelToken: source.token,
      });

      toggleUsageLoadingSpinner(false);
      return response.data.usageSeconds;
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }

      toggleUsageLoadingSpinner(false);

      return 0;
    }
  };

  return {
    usageLoadingSpinner,
    getBookingUsage
  }
};

export default useGetBookingUsage;
