import { makeStyles, createStyles } from '@material-ui/core/styles';

const EditPresentationPopupStyles = makeStyles((theme: any) => createStyles({
  dialogPaper: {
    width: 940,
    minHeight: 433,
    height: "auto",
    display: "inline-block",
    margin: 0,
    overflowX: "hidden",
    overflowY: "auto",
    [theme.breakpoints.down('sm')]: {
      width: 700,
    }
  },
  popup: {
    minHeight: 433,
    height: "auto",
    width: "100%",
    position: "relative",
  },
  editPresentationHeader: {
    fontSize: 24,
    fontWeight: 400,
    position: "relative",
    left: 30,
    top: 58,
  },
  presentationNameInput: {
    position: "absolute",
    width: 449,
    left: 30,
    top: 109,
  },
  deleteIcon: {
    position: "absolute",
    right: 56,
    top: 47,
    "&:hover": {
      cursor: "pointer"
    }
  },
  slidesHeader: {
    fontSize: 24,
    position: "absolute",
    fontWeight: 400,
    left: 30,
    top: 209,
    width: 150,
  },
  dragHeader: {
    position: "absolute",
    fontWeight: 400,
    fontSize: "1rem",
    top: 215,
    left: 120,
    width: 150,
  },
  divider: {
    width: 878,
    position: "absolute",
    left: 30,
    top: 260,
    [theme.breakpoints.down('sm')]: {
      width: 628
    }
  },
  deleteSelectedButton: {
    width: 135,
    height: 29,
    marginRight: 17,
    padding: 0,
    color: "#A3A3A3",
    fontSize: 14
  },
  deleteAllSlidesButton: {
    width: 135,
    height: 29,
    padding: 0,
    fontSize: 14
  },
  loadingSpinner: {
    color: "grey",
    position: "absolute",
    bottom: 87,
    right: 63,
    width: "25px !important",
    height: "25px !important",
  },
  error: {
    color: "red",
    position: "relative",
    left: 63,
    top: 50,
    [theme.breakpoints.down('sm')]: {
      top: 55,
      left: 31
    }
  },
  trashIconButton: {
    marginRight: 10
  },
  buttonLabel: {
    fontSize: 14,
  }
})
)

export { EditPresentationPopupStyles };