import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const AdminMenuStyles = makeStyles((theme: any) =>
  createStyles({
    menuButton: {},
    menuButtonUsage: {
      width: 145,
      right: 25,
    },
    spacePickerButton: {
      position: "absolute",
      top: 15,
      left: 15,
    },
    menuButtonLogin: {
      width: 200,
      right: 55,
    },
    menuButtonSpaceUpdate: {
      width: 215,
      right: 62,
    },
    spaceAdminDivider: {
      width: "90%",
      position: "relative",
      top: 25,
      display: "inline-block",
    },
  })
);

export { AdminMenuStyles };