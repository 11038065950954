import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

const SpaceUpdateAdminStyles = makeStyles((theme: any) =>
  createStyles({
    adminMenuButton: {
      position: "absolute",
      top: 15,
      left: 15,
      zIndex: 900,
    },
    spaceUpdateWrapper: {
      position: "relative",
      display: "inline-block",
      width: 714,
      height: "auto",
      top: 50,
      left: 130,
      minHeight: 450,
      margin: "auto",
      border: "1px solid #dedede",
      borderRadius: 0,
      textAlign: "center",
      padding: 40,
    },
    spaceUpdateHeader: {
      fontSize: 30,
      position: "relative",
      display: "inline-block",
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      top: 20,
    },
    spaceAdminDivider: {
      width: "100%",
      position: "relative",
      top: 25,
      display: "inline-block",
    },
    spaceEndTimeHeader: {
      fontSize: 18,
      position: "relative",
      top: 40,
      textAlign: "left",
    },
    dateTimePicker: {
      position: "relative",
      top: 40,
    },
    spaceItem: {
      display: "block",
      position: "relative",
      right: 28,
    },
    spaceEndTime: {
      fontSize: 16,
      position: "relative",
      bottom: 33,
      right: "41%",
      float: "right",
    },
    spaceDivider: {},
    selectAllButton: {
      top: 100,
      right: 169,
    },
    deselectButton: {
      right: 140,
    },
    loadingSpinner: {
      position: "relative",
      width: 40,
      height: 40,
      top: 120,
      right: 163,
    },
    submitButton: {
      top: 100,
    },
    submitLoadingSpinner: {
      width: "30px !important",
      height: "30px !important",
      position: "relative",
      top: 110,
      left: 15,
    },
    error: {
      color: "red",
      fontSize: 16,
    },
    success: {
      textAlign: "center",
      fontSize: 16,
      top: 100,
      position: "relative"
    }
  })
);

export { SpaceUpdateAdminStyles };