import React, { ReactElement, useState, useEffect } from 'react';
import { Button, IconButton, Typography } from '@material-ui/core';
import DetailsIcon from '@material-ui/icons/Launch';
import PersonIcon from '@material-ui/icons/PersonOutlineOutlined';

import AttendeeDetailsPopup from '../AttendeeDetailsPopup/AttendeeDetailsPopup';
import { AttendeeUsageHelper } from '../../../../utilities/AttendeeUsageHelper';
import AttendeeInformationPopup from '../AttendeeInformationPopup/AttendeeInformationPopup';

import styles from './BookingCurrentAttendee.module.css';
import { BookingCurrentAttendeeStyles } from './BookingCurrentAttendeeStyles';

interface Props {
  attendeeRecord: any,
  matchingRecords: any,
  isSuperAdmin: boolean,
  index: number,
}

export default function BookingCurrentAttendee(props: Props): ReactElement {

  const [ showDetailsPopup, toggleShowDetailsPopup ] = useState(false);

  const [ timeHours, setTimeHours ] = useState(0);
  const [ timeMinutes, setTimeMinutes ] = useState("");

  const [ showAttendeeInfo, toggleShowAttendeeInfo ] = useState(false);
  
  //Calculate current attendee's time usage
  useEffect(() => {
    
    let endTime;

    //If endtime is empty string, set endTime to current time. Otherwise, set endTime to record's end time
    if (props.attendeeRecord.endTime !== "") {
      endTime = new Date(props.attendeeRecord.endTime).toUTCString();
    } else {
      endTime = new Date().toUTCString();
    }

    let attendeeUsage = AttendeeUsageHelper.calculateUsage(props.attendeeRecord.startTime, endTime);

    //Attendee usage returned in form [ hours, minutes ]
    setTimeHours(parseInt(attendeeUsage[0]));
    setTimeMinutes(attendeeUsage[1]);

  }, [])
  
  const handleAttendeeDetailsClick = () => {
    toggleShowDetailsPopup(true);
  };

  const handleShowDetailsPopupClose = () => {
    toggleShowDetailsPopup(false);
  }

  //TODO: TODO: TODO: TODO: TODO:
  const handleViewAttendeeInfoClick = () => {
    toggleShowAttendeeInfo(true);
  }

  const handleInfoPopupClose=() => {
    toggleShowAttendeeInfo(false);
  }

  const classes = BookingCurrentAttendeeStyles();
  
  return (
    <React.Fragment>
      <div 
        className={styles.currentAttendeeHolder}
        style={{
          backgroundColor:
            (props.index + 1) % 2 === 0 ? "rgb(0, 0, 0, 0.02)" : "white"
        }}
      >
        <div className={styles.nameHolder}>
          <Typography variant="body1" className={classes.attendeeName}>
            {props.attendeeRecord.intakeData.lastName.slice().length 
              ? `${props.attendeeRecord.intakeData.lastName}, ${props.attendeeRecord.intakeData.firstName}`
              : `${props.attendeeRecord.intakeData.firstName}`
            }
          </Typography>
        </div>

        {/* BRAND */}
        {props.attendeeRecord.brand &&
          <div className={`${styles.badge} ${styles.brandBadge}`}>
          </div>
        }
        

        {/* PRESENTER */}
        {props.attendeeRecord.role === 2 && 
          <div className={`${styles.badge} ${styles.presenterBadge}`}>
          </div>
        }

        {/* IN-ROOM */}

        {props.attendeeRecord.endTime === ""  &&
          <div className={`${styles.badge} ${styles.inRoomBadge}`}>
          </div>
        }

        <Typography variant="body1" className={classes.currentAttendeeTime}>
          {`${timeHours}h:${timeMinutes}m`}
        </Typography>

        <IconButton
          color="primary"
          onClick={handleViewAttendeeInfoClick}
          classes={{ 
            root: classes.personButton, label: classes.detailsButtonLabel }}
        >
          <PersonIcon
            style={{
              fontSize: "20px"
            }}
          ></PersonIcon>
        </IconButton>

        <IconButton 
          color="primary" 
          onClick={handleAttendeeDetailsClick}  
          classes={{ root: classes.detailsButton, label: classes.detailsButtonLabel }}
        >
          <DetailsIcon
            style={{
              fontSize: "20px"
            }}
          ></DetailsIcon>
        </IconButton>
      </div>

      {showDetailsPopup &&
        <AttendeeDetailsPopup 
          attendeeRecord={props.attendeeRecord} 
          open={showDetailsPopup}
          handlePopupClose={handleShowDetailsPopupClose}
          matchingRecords={props.matchingRecords}
          isSuperAdmin={props.isSuperAdmin}
        />
      }

      {showAttendeeInfo &&
        <AttendeeInformationPopup
          attendeeRecord={props.attendeeRecord}
          handlePopupClose={handleInfoPopupClose}
          open={true}
        />
      }
    </React.Fragment>
  )
}
