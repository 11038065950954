import { Divider, IconButton, Switch, Typography } from "@material-ui/core";
import React, { ReactElement, useEffect, useState } from "react";
import HelpIcon from "@material-ui/icons/InfoOutlined";

import { useContext } from "react";
import { AppContext } from "../../../../context/AppContext";

import { StringHelper } from "../../../../utilities/StringHelper";

import styles from "./BookingPresenterPermissions.module.css";
import { BookingPresenterPermissionsStyles } from "./BookingPresenterPermissionsStyles";
import PermissionsHelpPopup from "./PermissionsHelpPopup/PermissionsHelpPopup";

interface Props {}

const defaultPermissions = {
  canPresent: true,
  canUseLaser: true,
  canControlAvatarAudio: true,
  canTeleport: true,
  canBreakout: true,
  canShareWebcam: true,
  canScreenshare: true,
  canShareSlides: true,
  canShareLocalVideo: true,
  canShareLivestream: true,
  canShareDefault: true,
};

const generalPermissions = [
  StringHelper.PresenterPermissions.canPresent,
  StringHelper.PresenterPermissions.canUseLaser,
  StringHelper.PresenterPermissions.canControlAvatarAudio,
  StringHelper.PresenterPermissions.canTeleport,
  StringHelper.PresenterPermissions.canBreakout,
];

const generalPermissionsReadableMap = {
  [StringHelper.PresenterPermissions.canPresent]: "Present",
  [StringHelper.PresenterPermissions.canUseLaser]: "Laser Pointer",
  [StringHelper.PresenterPermissions.canControlAvatarAudio]: "Avatar Control",
  [StringHelper.PresenterPermissions.canTeleport]: "Teleport",
  [StringHelper.PresenterPermissions.canBreakout]: "Breakout",
};

const displayPermissions = [
  StringHelper.PresenterPermissions.canShareWebcam,
  StringHelper.PresenterPermissions.canScreenshare,
  StringHelper.PresenterPermissions.canShareSlides,
  StringHelper.PresenterPermissions.canShareLocalVideo,
  StringHelper.PresenterPermissions.canShareLivestream,
  StringHelper.PresenterPermissions.canShareDefault,
];

const displayPermissionsReadableMap = {
  [StringHelper.PresenterPermissions.canShareWebcam]: "Webcam",
  [StringHelper.PresenterPermissions.canScreenshare]: "Screenshare",
  [StringHelper.PresenterPermissions.canShareSlides]: "Slides",
  [StringHelper.PresenterPermissions.canShareLocalVideo]: "Local Video",
  [StringHelper.PresenterPermissions.canShareLivestream]: "Livestream",
  [StringHelper.PresenterPermissions.canShareDefault]: "Default Screen",
};

export default function BookingPresenterPermissions({}: Props): ReactElement {
  const { selectedRegistration, setSelectedRegistration } =
    useContext(AppContext);

  const [currentPermissions, setCurrentPermissions] = useState(
    selectedRegistration.permissions
      ? selectedRegistration.permissions
      : defaultPermissions
  );

  const [showHelpPopup, setShowHelpPopup] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState("");

  const handlePermissionsToggle = (e) => {
    const item = e.target.value;

    //Toggle the boolean in permissions object
    let currentPermissionsClone = Object.assign({}, currentPermissions);

    currentPermissionsClone[item] = !currentPermissionsClone[item];

    //If permissions for presenting are turned off, deactivate and turn off webcam permissions
    if (
      item ===
        generalPermissions[0] &&
      currentPermissionsClone[StringHelper.PresenterPermissions.canPresent] ===
        false
    ) {
      currentPermissionsClone[
        StringHelper.PresenterPermissions.canShareWebcam
      ] = false;
    }

    setCurrentPermissions(currentPermissionsClone);

    //Set permissions on current registration
    let selectedRegistrationClone = selectedRegistration;

    selectedRegistrationClone.permissions = currentPermissionsClone;

    setSelectedRegistration(selectedRegistrationClone);

    /* if (currentPermissions.includes(toggled)) {
      setCurrentPermissions((currentPermissions) =>
        currentPermissions.filter((p) => p !== toggled)
      );
    } else {
      setCurrentPermissions((currentPermissions) => [
        ...currentPermissions,
        toggled,
      ]);
    } */
  };

  const handlePermissionHelpClick = (e, permission) => {
    setSelectedPermission(permission);
    setShowHelpPopup(true);
  };

  const classes = BookingPresenterPermissionsStyles();

  return (
    <React.Fragment>
      <div className={styles.permissionsHolder}>
        <Typography variant="h2" className={classes.permissionsHeader}>
          Customize Controls
        </Typography>

        <div className={styles.generalPermissions}>
          <Typography variant="h3" className={classes.permissionsSubheader}>
            General Controls
          </Typography>

          {generalPermissions.map((item) => {
            return (
              <div className={styles.permissionsItem} key={item}>
                <Typography variant="h4" className={classes.permissionName}>
                  {`${generalPermissionsReadableMap[item]}`}

                  <IconButton
                    className={classes.helpIcon}
                    onClick={(e) => handlePermissionHelpClick(e, item)}
                  >
                    <HelpIcon />
                  </IconButton>
                </Typography>

                <Switch
                  value={item}
                  checked={currentPermissions[item]}
                  onChange={handlePermissionsToggle}
                  color="secondary"
                  className={classes.permissionsToggle}
                  
                />

                <Divider className={classes.permissionsDivider} />
              </div>
            );
          })}
        </div>

        <div className={styles.displayPermissions}>
          <Typography variant="h3" className={classes.permissionsSubheader}>
            Display Controls
          </Typography>

          {displayPermissions.map((item) => {
            return (
              <div className={styles.permissionsItem} key={item}>
                <Typography variant="h4" className={classes.permissionName}>
                  {`${displayPermissionsReadableMap[item]}`}

                  <IconButton
                    className={classes.helpIcon}
                    onClick={(e) => handlePermissionHelpClick(e, item)}
                  >
                    <HelpIcon />
                  </IconButton>
                </Typography>

                <Switch
                  value={item}
                  checked={currentPermissions[item]}
                  onChange={handlePermissionsToggle}
                  color="secondary"
                  className={classes.permissionsToggle}
                  disabled={
                    item === StringHelper.PresenterPermissions.canShareWebcam &&
                    currentPermissions[
                      StringHelper.PresenterPermissions.canPresent
                    ] === false
                  }
                />

                <Divider className={classes.permissionsDivider} />
              </div>
            );
          })}
        </div>
      </div>

      {showHelpPopup && (
        <PermissionsHelpPopup
          value={selectedPermission}
          title={
            generalPermissionsReadableMap[selectedPermission] !== undefined
              ? generalPermissionsReadableMap[selectedPermission]
              : displayPermissionsReadableMap[selectedPermission]
          }
          handlePopupClose={() => setShowHelpPopup(false)}
        />
      )}
    </React.Fragment>
  );
}
