import React, {
  ReactElement,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
  Radio,
  IconButton,
  Divider,
  Switch,
  Link,
  OutlinedInput,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import RefreshIcon from "@material-ui/icons/Refresh";

import { AppContext, AppContextType } from "../../../context/AppContext";

import styles from "./BookingRegistration.module.css";
import { BookingRegistrationStyles } from "./BookingRegistrationStyles";
import ConfirmLinkRegeneratePopup from "../BookingOverview/ConfirmLinkRegeneratePopup/ConfirmLinkRegeneratePopup";

interface Props {
  doneClick: boolean;
  currentTab: string;
  handleOverviewTabSelect: () => void;
}

export default function BookingRegistration(props: Props): ReactElement {
  const {
    selectedBooking,
    setSelectedBooking,
    setSelectedBookingID,
    toggleIsRegistrationTabSelected,
    wasDoneClicked,
  }: AppContextType = useContext(AppContext);

  const [firstName, toggleFirstName] = useState(
    selectedBooking.intakeFields[0].visible
  );
  const [firstNameRequired, toggleFirstNameRequired] = useState(
    selectedBooking.intakeFields[0].mandatory
  );

  const [lastName, toggleLastName] = useState(
    selectedBooking.intakeFields[1].visible
  );
  const [lastNameRequired, toggleLastNameRequired] = useState(
    selectedBooking.intakeFields[1].mandatory
  );

  const [company, toggleCompany] = useState(
    selectedBooking.intakeFields[2].visible
  );
  const [companyRequired, toggleCompanyRequired] = useState(
    selectedBooking.intakeFields[2].mandatory
  );

  const [email, toggleEmail] = useState(
    selectedBooking.intakeFields[3].visible
  );
  const [emailRequired, toggleEmailRequired] = useState(
    selectedBooking.intakeFields[3].mandatory
  );

  const [phoneNumber, togglePhoneNumber] = useState(
    selectedBooking.intakeFields[4].visible
  );
  const [phoneNumberRequired, togglePhoneNumberRequired] = useState(
    selectedBooking.intakeFields[4].mandatory
  );

  const [address, toggleAddress] = useState(
    selectedBooking.intakeFields[5].visible
  );
  const [addressRequired, toggleAddressRequired] = useState(
    selectedBooking.intakeFields[5].mandatory
  );

  const [addressType, setAddressType] = useState(
    selectedBooking.intakeFields[5].type
  );

  const [registrationCapacity, setRegistrationCapacity] = useState(
    selectedBooking.useRegistrationCapacity === true
      ? selectedBooking.registrationCapacity
      : selectedBooking.capacity
  );

  const [specifyRegistrationCapacity, toggleSpecifyRegistrationCapacity] =
    useState(selectedBooking.useRegistrationCapacity === true ? true : false);

  const [signUpRegenerating, toggleSignUpRegenerating] = useState(false);
  const [confirmLinkRegeneratePopup, toggleConfirmLinkRegeneratePopup] =
    useState(false);
  const [copySignUpToClipboard, toggleSignUpCopyToClipboard] = useState(false);
  const [regenerateSignUp, toggleRegenerateSignUp] = useState(false);

  const [loadingBooking, setLoadingBooking] = useState(true);

  /* REFS */
  const firstNameRef = useRef(selectedBooking.intakeFields[0].visible);
  const firstNameRequiredRef = useRef(
    selectedBooking.intakeFields[0].mandatory
  );

  const lastNameRef = useRef(selectedBooking.intakeFields[1].visible);
  const lastNameRequiredRef = useRef(selectedBooking.intakeFields[1].mandatory);

  const companyRef = useRef(selectedBooking.intakeFields[2].visible);
  const companyRequiredRef = useRef(selectedBooking.intakeFields[2].mandatory);

  const emailRef = useRef(selectedBooking.intakeFields[3].visible);
  const emailRequiredRef = useRef(selectedBooking.intakeFields[3].mandatory);

  const phoneNumberRef = useRef(selectedBooking.intakeFields[4].visible);
  const phoneNumberRequiredRef = useRef(
    selectedBooking.intakeFields[4].mandatory
  );

  const addressRef = useRef(selectedBooking.intakeFields[5].visible);
  const addressRequiredRef = useRef(selectedBooking.intakeFields[5].mandatory);
  const addressTypeRef = useRef(selectedBooking.intakeFields[5].type);
  const doneClickRef = useRef(false);
  const apiCallCount = useRef(0);
  /**/

  useEffect(() => {
    //On mount, fetch booking one more time
    const getBookingOnMount = async () => {
      try {
        let getBookingResponse = await axios.get("/booking", {
          params: {
            bookingID: selectedBooking.bookingID,
          },
        });

        let booking = getBookingResponse.data.booking;

        setSelectedBooking(booking);

        //Set state and ref values
        toggleFirstName(booking.intakeFields[0].visible);
        toggleFirstNameRequired(booking.intakeFields[0].mandatory);
        firstNameRef.current = booking.intakeFields[0].visible;
        firstNameRequiredRef.current = booking.intakeFields[0].mandatory;

        toggleLastName(booking.intakeFields[1].visible);
        toggleLastNameRequired(booking.intakeFields[1].mandatory);
        lastNameRef.current = booking.intakeFields[1].visible;
        lastNameRequiredRef.current = booking.intakeFields[1].mandatory;

        toggleCompany(booking.intakeFields[2].visible);
        toggleCompanyRequired(booking.intakeFields[2].mandatory);
        companyRef.current = booking.intakeFields[2].visible;
        companyRequiredRef.current = booking.intakeFields[2].mandatory;

        toggleEmail(booking.intakeFields[3].visible);
        toggleEmailRequired(booking.intakeFields[3].mandatory);
        emailRef.current = booking.intakeFields[3].visible;
        emailRequiredRef.current = booking.intakeFields[3].mandatory;

        togglePhoneNumber(booking.intakeFields[4].visible);
        togglePhoneNumberRequired(booking.intakeFields[4].mandatory);
        phoneNumberRef.current = booking.intakeFields[4].visible;
        phoneNumberRequiredRef.current = booking.intakeFields[4].mandatory;

        toggleAddress(booking.intakeFields[5].visible);
        toggleAddressRequired(booking.intakeFields[5].mandatory);
        addressRef.current = booking.intakeFields[5].visible;
        addressRequiredRef.current = booking.intakeFields[5].mandatory;

        setRegistrationCapacity(
          booking.useRegistrationCapacity === true
            ? booking.registrationCapacity
            : booking.capacity
        );

        toggleSpecifyRegistrationCapacity(
          booking.useRegistrationCapacity === true ? true : false
        );

        setLoadingBooking(false);
      } catch (error) {
        console.log("Get booking error", error);
        setLoadingBooking(false);
      }
    };
    getBookingOnMount();
  }, []);

  useEffect(() => {
    toggleIsRegistrationTabSelected(true);

    if (props.doneClick) {
      doneClickRef.current = true;
    }
  }, [props.doneClick]);

  //Send over updated fields on dismount
  useEffect(() => {
    return async function cleanup() {
      toggleIsRegistrationTabSelected(false);

      let newIntakeFields = [
        {
          name: "First Name",
          visible: firstNameRef.current,
          mandatory: firstNameRequiredRef.current,
        },
        {
          name: "Last Name",
          visible: lastNameRef.current,
          mandatory: lastNameRequiredRef.current,
        },
        {
          name: "Company",
          visible: companyRef.current,
          mandatory: companyRequiredRef.current,
        },
        {
          name: "Email",
          visible: emailRef.current,
          mandatory: emailRequiredRef.current,
        },
        {
          name: "Phone",
          visible: phoneNumberRef.current,
          mandatory: phoneNumberRequiredRef.current,
        },
        {
          name: "Address",
          visible: addressRef.current,
          mandatory: addressRequiredRef.current,
          type: addressTypeRef.current,
        },
      ];

      let selectedBookingClone = selectedBooking;

      selectedBookingClone.intakeFields = newIntakeFields;

      if (!wasDoneClicked) {
        setSelectedBooking(selectedBookingClone);
        setSelectedBookingID(selectedBookingClone.bookingID);
      }

      if (apiCallCount.current < 1) {
        apiCallCount.current = apiCallCount.current + 1;
        axios
          .put("/booking", selectedBookingClone)
          .then(function (response) {})
          .catch(function (error) {
            console.log(error);
          });
      }
    };
  }, [wasDoneClicked]);

  /*Visibility handlers */
  const handleFirstNameChange = () => {
    toggleFirstName(!firstName);
    firstNameRef.current = !firstNameRef.current;

    if (firstName) {
      toggleFirstNameRequired(false);
      firstNameRequiredRef.current = false;
    }
  };
  const handleLastNameChange = () => {
    toggleLastName(!lastName);
    lastNameRef.current = !lastNameRef.current;

    if (lastName) {
      toggleLastNameRequired(false);
      lastNameRequiredRef.current = false;
    }
  };
  const handleEmailChange = () => {
    toggleEmail(!email);
    emailRef.current = !emailRef.current;

    if (email) {
      toggleEmailRequired(false);
      emailRequiredRef.current = false;
    }
  };
  const handleCompanyChange = () => {
    toggleCompany(!company);
    companyRef.current = !companyRef.current;

    if (company) {
      toggleCompanyRequired(false);
      companyRequiredRef.current = false;
    }
  };
  const handlePhoneNumberChange = () => {
    togglePhoneNumber(!phoneNumber);
    phoneNumberRef.current = !phoneNumberRef.current;

    if (phoneNumber) {
      togglePhoneNumberRequired(false);
      phoneNumberRequiredRef.current = false;
    }
  };

  const handleAddressChange = () => {
    toggleAddress(!address);
    addressRef.current = !addressRef.current;

    if (address) {
      toggleAddressRequired(false);
      addressRequiredRef.current = false;
    }
  };

  const handleAddressTypeChange = (e) => {
    let newSelection = e.target.value;

    addressTypeRef.current = newSelection;
    setAddressType(newSelection);
  };
  /**/

  /*Required handlers */
  const handleFirstNameRequiredChange = () => {
    toggleFirstNameRequired(!firstNameRequired);
    firstNameRequiredRef.current = !firstNameRequiredRef.current;
  };

  const handleLastNameRequiredChange = () => {
    toggleLastNameRequired(!lastNameRequired);
    lastNameRequiredRef.current = !lastNameRequiredRef.current;
  };

  const handleEmailRequiredChange = () => {
    toggleEmailRequired(!emailRequired);
    emailRequiredRef.current = !emailRequiredRef.current;
  };

  const handleCompanyRequiredChange = () => {
    toggleCompanyRequired(!companyRequired);
    companyRequiredRef.current = !companyRequiredRef.current;
  };

  const handlePhoneNumberRequiredChange = () => {
    togglePhoneNumberRequired(!phoneNumberRequired);
    phoneNumberRequiredRef.current = !phoneNumberRequiredRef.current;
  };

  const handleAddressRequiredChange = () => {
    toggleAddressRequired(!addressRequired);
    addressRequiredRef.current = !addressRequiredRef.current;
  };
  /**/

  /* Registration capacity */
  const handleSpecifyRegistrationCapacityToggle = async () => {
    toggleSpecifyRegistrationCapacity(!specifyRegistrationCapacity);

    let selectedBookingClone = selectedBooking;

    //If we are toggling off specifying, set registration cap to 0
    if (specifyRegistrationCapacity) {
      setRegistrationCapacity(selectedBooking.capacity);

      selectedBookingClone.registrationCapacity = parseInt(
        selectedBooking.capacity
      );
      selectedBookingClone.useRegistrationCapacity = false;

      await axios.put("/booking", selectedBookingClone);

      setSelectedBooking(selectedBookingClone);
    } else {
      selectedBookingClone.useRegistrationCapacity = true;

      await axios.put("/booking", selectedBookingClone);
    }
  };

  /**/

  const handleOverviewLinkClick = () => {
    props.handleOverviewTabSelect();
  };

  const handleSendRegistrationCapacity = async () => {
    //Dont send an empty value
    if (!registrationCapacity.toString().trim().length) return;

    let selectedBookingClone = selectedBooking;

    selectedBookingClone.registrationCapacity = parseInt(registrationCapacity);

    try {
      await axios.put("/booking", selectedBookingClone);

      setSelectedBooking(selectedBookingClone);
    } catch (error) {}
  };

  const handleRegistrationCapacityChange = (e) => {
    setRegistrationCapacity(e.target.value);
  };

  //Sign up link regeneration
  const handleRegenerateSignUpClick = () => {
    toggleConfirmLinkRegeneratePopup(true);
  };

  //Sign up link copy
  const handleCopyToClipboardClick = (type: string) => {
    let dummy = document.createElement("textarea");

    document.body.appendChild(dummy);

    dummy.value = document.getElementById("signUpLink").innerText;
    dummy.select();

    document.execCommand("copy");
    document.body.removeChild(dummy);

    toggleSignUpCopyToClipboard(true);
  };

  const handleConfirmLinkRegeneratePopupClose = () => {
    toggleConfirmLinkRegeneratePopup(false);
  };

  const handleSignUpLinkRegenerated = () => {
    //Show snackbar notification
    toggleRegenerateSignUp(true);
  };

  const classes = BookingRegistrationStyles();

  return (
    <React.Fragment>
      <div className={styles.signUpUrlHolder}>
        <Typography variant="body1" classes={{ root: classes.signUpUrlHeader }}>
          Sign-up URL:
        </Typography>
        <Typography
          variant="body1"
          id="signUpLink"
          classes={{ root: classes.signUpUrl }}
        >
          https://registration.showboat.live/{selectedBooking.signUpLoginCode}
        </Typography>

        <div className={styles.signUpButtonHolder}>
          <IconButton
            onClick={() => handleCopyToClipboardClick("signup")}
            disabled={signUpRegenerating}
            color="primary"
            classes={{ root: classes.copyButton }}
          >
            <img
              className={styles.copyImage}
              alt="Copy icon"
              src="assets/images/CopyIcon.svg"
            />
          </IconButton>

          <IconButton
            disabled={signUpRegenerating}
            onClick={handleRegenerateSignUpClick}
            color="primary"
            classes={{ root: classes.regenerateButton }}
          >
            <RefreshIcon
              style={{ color: "black", fontSize: "20px" }}
            ></RefreshIcon>
          </IconButton>
        </div>
      </div>

      <Divider className={classes.dividerSignUp}></Divider>

      <div className={styles.registrationFieldsHolder}>
        <Typography variant="h2" className={classes.registrationFieldsHeader}>
          Registration Fields
        </Typography>

        <Typography variant="h1" className={classes.fieldsHeader}>
          Fields
        </Typography>

        <Typography variant="h1" className={classes.requiredHeader}>
          Required
        </Typography>

        {loadingBooking && (
          <CircularProgress className={classes.loadingSpinner} />
        )}

        {!loadingBooking && (
          <>
            <div className={styles.basicHolder}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={firstName}
                    onChange={handleFirstNameChange}
                    name="gilad"
                  />
                }
                label="First Name"
              />

              <Divider
                className={`${classes.divider} ${classes.divider1}`}
              ></Divider>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={lastName}
                    onChange={handleLastNameChange}
                    name="gilad"
                  />
                }
                label="Last Name"
              />

              <Divider
                className={`${classes.divider} ${classes.divider2}`}
              ></Divider>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={email}
                    onChange={handleEmailChange}
                    name="gilad"
                  />
                }
                label="Email"
              />

              <Divider
                className={`${classes.divider} ${classes.divider3}`}
              ></Divider>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={company}
                    onChange={handleCompanyChange}
                    name="gilad"
                  />
                }
                label="Company"
              />

              <Divider
                className={`${classes.divider} ${classes.divider4}`}
              ></Divider>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    name="gilad"
                  />
                }
                label="Phone Number"
              />

              <Divider
                className={`${classes.divider} ${classes.divider5}`}
              ></Divider>
            </div>

            <div className={styles.requiredHolderBasic}>
              <div>
                <Checkbox
                  checked={firstNameRequired}
                  disabled={!firstName}
                  onChange={handleFirstNameRequiredChange}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </div>

              <div>
                <Checkbox
                  checked={lastNameRequired}
                  disabled={!lastName}
                  onChange={handleLastNameRequiredChange}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </div>

              <div>
                <Checkbox
                  checked={emailRequired}
                  disabled={!email}
                  onChange={handleEmailRequiredChange}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </div>

              <div>
                <Checkbox
                  checked={companyRequired}
                  disabled={!company}
                  onChange={handleCompanyRequiredChange}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </div>

              <div>
                <Checkbox
                  checked={phoneNumberRequired}
                  disabled={!phoneNumber}
                  onChange={handlePhoneNumberRequiredChange}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </div>
            </div>

            <Typography variant="h1" className={classes.addressHeader}>
              Address
            </Typography>

            <div className={styles.addressHolder}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={address}
                    onChange={handleAddressChange}
                    name="Mailing Address"
                  />
                }
                label="Mailing Address"
              />

              <FormControl
                disabled={!address}
                component="fieldset"
                style={{
                  position: "relative",
                  bottom: "5px",
                }}
              >
                <RadioGroup
                  aria-label="address type"
                  name="address type"
                  value={addressType}
                  onChange={handleAddressTypeChange}
                >
                  <FormControlLabel
                    value="US"
                    control={<Radio color="secondary" />}
                    label="US Address"
                  />
                  <FormControlLabel
                    value="international"
                    control={<Radio color="secondary" />}
                    label="Generic International Address"
                  />
                </RadioGroup>
              </FormControl>

              <Divider
                className={`${classes.divider} ${classes.divider6}`}
              ></Divider>

              <Divider
                className={`${classes.divider} ${classes.divider7}`}
              ></Divider>

              <Divider
                className={`${classes.divider} ${classes.divider8}`}
              ></Divider>

              <div className={styles.addressRequiredCheckboxHolder}>
                <div>
                  <Checkbox
                    checked={addressRequired}
                    disabled={!address}
                    onChange={handleAddressRequiredChange}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        <Typography
          variant="h2"
          className={`${classes.registrationFieldsHeader} ${classes.registrationFieldsHeaderCapacity}`}
        >
          Registration Capacity
        </Typography>

        <div className={styles.registrationCapacityHolder}>
          <FormControlLabel
            control={
              <Switch
                value={specifyRegistrationCapacity}
                checked={specifyRegistrationCapacity}
                color="secondary"
                onChange={handleSpecifyRegistrationCapacityToggle}
              />
            }
            label="Specify Registration Number"
            labelPlacement="start"
            classes={{
              root: classes.specifyToggle,
              label: classes.specifyToggleLabel,
            }}
          />

          <Typography variant="body1" className={classes.capacityInfoText}>
            If off, the registration capacity will be the same as the event
            capacity set in the
            <Link
              onClick={handleOverviewLinkClick}
              className={classes.overviewLink}
            >
              Overview
            </Link>
            tab.
          </Typography>

          <Typography
            style={{
              color: specifyRegistrationCapacity ? "black" : "#BABABA",
            }}
            variant="body1"
            className={classes.registrationCapText}
          >
            Total Registration Allowed
          </Typography>

          <OutlinedInput
            classes={{
              root: classes.registrationCountInput,
              input: classes.registrationCountInputInner,
            }}
            disabled={!specifyRegistrationCapacity}
            style={{
              borderColor: specifyRegistrationCapacity ? "black" : "#C6C6C6",
            }}
            inputProps={{
              maxLength: 4,
              onBlur: handleSendRegistrationCapacity,
            }}
            onChange={handleRegistrationCapacityChange}
            value={registrationCapacity}
          />
        </div>
      </div>

      {/* SIGN UP REGENERATED SNACKBAR */}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={1500}
        open={regenerateSignUp}
        className={classes.snackbar}
        onClose={() => toggleRegenerateSignUp(false)}
        message="Sign-up URL Regenerated"
      />

      {/*  SIGN UP COPY TO CLIPBOARD SNACKBAR */}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={1500}
        className={classes.snackbar}
        open={copySignUpToClipboard}
        onClose={() => toggleSignUpCopyToClipboard(false)}
        message="Sign-up URL Copied to Clipboard"
      />

      {/* LINK REGENERATE ERROR MESSAGE POPUP */}
      {confirmLinkRegeneratePopup && (
        <ConfirmLinkRegeneratePopup
          regenerateType="signup"
          open={confirmLinkRegeneratePopup}
          handlePopupClose={handleConfirmLinkRegeneratePopupClose}
          handleSignUpLinkRegenerated={handleSignUpLinkRegenerated}
        />
      )}
    </React.Fragment>
  );
}
