import { Button } from '@material-ui/core'
import React, { ReactElement } from 'react'
import { SaveButtonStyles } from './SaveButtonStyles'

interface Props {
  onClick: () => any,
  disableBoolean: boolean,
}

export default function SaveButton(props: Props): ReactElement {
  
  const classes = SaveButtonStyles();
  
  return (
    <Button
      className={classes.saveButton}
      onClick={props.onClick}
      variant="contained"
      color="primary"
      disabled={props.disableBoolean}
    >
      SAVE
    </Button>
  )
}
