import React, {
  ReactElement,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  Typography,
  Button,
  Dialog,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@material-ui/icons/Close";
import { CSVLink } from "react-csv";
import axios from "axios";

import { AppContext, AppContextType } from "../../../../context/AppContext";

import { UploadAttendeesPopupStyles } from "./UploadAttendeesPopupStyles";
import styles from "./UploadAttendeesPopup.module.css";
import SaveButton from "../../../ui/SaveButton/SaveButton";
import CancelButton from "../../../ui/CancelButton/CancelButton";
import SaveLoadingSpinner from "../../../ui/SaveLoadingSpinner/SaveLoadingSpinner";

const papa = require("papaparse");

interface Props {
  open: boolean;
  handlePopupClose: () => any;
  handleUploadAttendees: (attendees) => any;
}

export default function UploadAttendeesPopup(props: Props): ReactElement {
  const { selectedBooking }: AppContextType = useContext(AppContext);

  const [parsedAttendees, setParsedAttendees] = useState([]);
  const [isParsing, toggleIsParsing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [axiosError, toggleAxiosError] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [parsingMessage, setParsingMessage] = useState("");
  const [loadingSpinner, toggleLoadingSpinner] = useState(false);

  const parsingErrorRef = useRef(false);

  //Handle file drop
  const handleFileDrop = (files) => {
    parsingErrorRef.current = false;

    setParsedAttendees([]);

    toggleIsParsing(true);

    setErrorMessage("");

    setSuccessMessage("");

    setParsingMessage("Parsing...");

    papa.parse(files[0], {
      worker: true, // Don't bog down the main thread if its a big file,
      skipEmptyLines: true,
      header: true,
      step: function (result, parser) {
        if (result.errors.length) {
          parser.abort();
          return;
        }

        const first = result.data["First Name"];
        const last = result.data["Last Name"];
        const company = result.data["Company Name"];
        const email = result.data["Email Address"];
        const phone = result.data["Phone Number"];
        const addressLine1 = result.data["Address Line 1"];
        const addressLine2 = result.data["Address Line 2"];
        let city;
        let state;
        let zipCode;
        let stateProvinceRegion;
        let zipPostalCode;
        let country;

        if (selectedBooking.intakeFields[5].type === "international") {
          city = result.data["City"];
          stateProvinceRegion = result.data["State/Province/Region"];
          zipPostalCode = result.data["Zip/Postal Code"];
          country = result.data["Country"];
        } else {
          city = result.data["City"];
          state = result.data["State"];
          zipCode = result.data["Zip Code"];
        }

        //Allow for both "Brand (true/false)" and "Brand" column names
        let brandColumn;

        if (result.data["Brand (true/false)"] !== undefined) {
          brandColumn = result.data["Brand (true/false)"];
        } else if (result.data["Brand"] !== undefined) {
          brandColumn = result.data["Brand"];
        }

        if (selectedBooking.intakeFields[5].type === "international") {
          const requiredInternationalColumns = [
            first,
            last,
            company,
            email,
            phone,
            addressLine1,
            addressLine2,
            city,
            stateProvinceRegion,
            zipPostalCode,
            country,
            brandColumn,
          ];

          requiredInternationalColumns.forEach((item, i) => {
            if (item === undefined) {
              toggleIsParsing(false);
              setErrorMessage(
                "There was an error parsing the uploaded CSV file. Please make sure the upload follows the CSV template and corresponds to the selected address type for this booking."
              );
              toggleLoadingSpinner(false);
              toggleIsParsing(false);
              setParsedAttendees([]);
              setParsingMessage("");
              parsingErrorRef.current = true;
              return false;
            }
          });
        } else {
          const requiredUSColumns = [
            first,
            last,
            company,
            email,
            phone,
            addressLine1,
            addressLine2,
            city,
            state,
            zipCode,
            brandColumn,
          ];

          requiredUSColumns.forEach((item, i) => {
            if (item === undefined) {
              toggleIsParsing(false);
              setErrorMessage(
                "There was an error parsing the uploaded CSV file. Please make sure the upload follows the CSV template and corresponds to the selected address type for this booking."
              );
              toggleLoadingSpinner(false);
              toggleIsParsing(false);
              setParsedAttendees([]);
              setParsingMessage("");
              parsingErrorRef.current = true;
              return false;
            }
          });
        }

        let brand: boolean;

        //Get all possible affirmatives for brand column
        if (
          brandColumn === "true" ||
          brandColumn === "yes" ||
          brandColumn === "True" ||
          brandColumn === "Yes" ||
          brandColumn === "TRUE"
        ) {
          brand = true;
        } else {
          brand = false;
        }

        let newAttendee;

        if (selectedBooking.intakeFields[5].type === "international") {
          newAttendee = {
            bookingID: selectedBooking.bookingID,
            first,
            last,
            email,
            company,
            phone,
            address: {
              addressLine1,
              addressLine2,
              city,
              stateProvinceRegion,
              zipPostalCode,
              country,
            },
            brand,
          };
        } else {
          newAttendee = {
            bookingID: selectedBooking.bookingID,
            first,
            last,
            email,
            company,
            phone,
            address: {
              addressLine1,
              addressLine2,
              city,
              state,
              zipCode,
            },
            brand,
          };
        }

        setParsedAttendees((parsedAttendees) => [
          ...parsedAttendees,
          newAttendee,
        ]);
      },
      complete: function (result) {
        if (parsingErrorRef.current) {
          setParsedAttendees([]);
          return;
        } else {
          setParsingMessage("");
          setSuccessMessage("Parsing successful!");
          toggleIsParsing(false);
        }
      },
    });
  };

  const handleUploadAttendeesSave = () => {
    toggleLoadingSpinner(true);

    let promises: any = [];

    for (let i = 0; i < parsedAttendees.length; i++) {
      promises.push(
        axios
          .post("/registration", parsedAttendees[i])
          .then(function (response) {
            return response.data;
          })
      );
    }

    Promise.all(promises)
      .then((response) => {
        toggleLoadingSpinner(false);
        props.handleUploadAttendees(response);
        props.handlePopupClose();
      })
      .catch((err) => {
        toggleLoadingSpinner(false);
        toggleAxiosError(true);
      });
  };

  const handleCancelButtonClick = () => {
    //If we are currently parsing or loading, do nothing
    if (isParsing || loadingSpinner) return;

    parsingErrorRef.current = false;
    setErrorMessage("");
    setSuccessMessage("");
    setParsingMessage("");
    setParsedAttendees([]);
    toggleAxiosError(false);
    props.handlePopupClose();
  };

  //Prepare configuration for dropzone component
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ".csv",
    onDrop: handleFileDrop,
    multiple: false,
  });

  //Prepare file list to display accepted files
  const files = acceptedFiles.map((file) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  //material ui classes
  const classes = UploadAttendeesPopupStyles();

  //Prepare column headers for CSV upload
  //Use the correct version if booking utilizes international or US addresses
  let csvHeaders;

  if (selectedBooking.intakeFields[5].type === "international") {
    csvHeaders = [
      { label: "First Name", key: "" },
      { label: "Last Name", key: "" },
      { label: "Company Name", key: "" },
      { label: "Email Address", key: "" },
      { label: "Phone Number", key: "" },
      { label: "Address Line 1", key: "" },
      { label: "Address Line 2", key: "" },
      { label: "City", key: "" },
      { label: "State/Province/Region", key: "" },
      { label: "Zip/Postal Code", key: "" },
      { label: "Country", key: "" },
      { label: "Brand (true/false)", key: "" },
    ];
  } else {
    csvHeaders = [
      { label: "First Name", key: "" },
      { label: "Last Name", key: "" },
      { label: "Company Name", key: "" },
      { label: "Email Address", key: "" },
      { label: "Phone Number", key: "" },
      { label: "Address Line 1", key: "" },
      { label: "Address Line 2", key: "" },
      { label: "City", key: "" },
      { label: "State", key: "" },
      { label: "Zip Code", key: "" },
      { label: "Brand (true/false)", key: "" },
    ];
  }

  const csvData = [];

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} open={props.open}>
      <div className={classes.popup}>
        <div className={styles.popupHeader}>
          <IconButton
            onClick={handleCancelButtonClick}
            className={classes.closeButton}
            disabled={isParsing || loadingSpinner}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <Typography variant="h1" className={classes.uploadAttendeeHeader}>
          Upload Attendee List
        </Typography>

        {axiosError && (
          <Typography variant="body1" className={classes.axiosError}>
            An error occurred uploading the parsed attendees
          </Typography>
        )}

        <Typography variant="body1" className={classes.uploadAttendeeText}>
          Attendee list must be in CSV format and follow the template
        </Typography>

        <CSVLink
          data={csvData}
          headers={csvHeaders}
          filename={"AttendeeUploadTemplate.csv"}
        >
          <Typography variant="body1" className={classes.downloadAttendeeText}>
            Download Attendee Template (.csv)
            {selectedBooking.intakeFields[5].type === "international"
              ? " (international addresses)"
              : " (US addresses)"}
          </Typography>
        </CSVLink>

        <div className={styles.fileUploadHolder}>
          <div {...getRootProps({ className: `${styles.dropzone}` })}>
            <input {...getInputProps()} />
            <Typography variant="body1" className={classes.dropzoneText}>
              Drag 'n' drop some files here, or click to select files
            </Typography>
          </div>
          <aside>
            {errorMessage !== "" && (
              <Typography variant="body1" className={classes.error}>
                {errorMessage}
              </Typography>
            )}

            {successMessage !== "" && (
              <Typography variant="body1" className={classes.success}>
                {successMessage}
              </Typography>
            )}

            {parsingMessage !== "" && (
              <Typography variant="body1" className={classes.parsing}>
                {parsingMessage}
              </Typography>
            )}
          </aside>
        </div>

        {loadingSpinner && (
          <SaveLoadingSpinner />
        )}

        <CancelButton
          disableBoolean={isParsing || loadingSpinner}
          onClick={handleCancelButtonClick}
          variant="text"
        />

        <SaveButton
          disableBoolean={
            isParsing || loadingSpinner || !parsedAttendees.length
          }
          onClick={handleUploadAttendeesSave}
        />
      </div>
    </Dialog>
  );
}
