import {
  Checkbox,
  Dialog,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import React, { ReactElement, useContext } from "react";

import axios from "axios";
import { useState } from "react";

import PopupHeader from "../../../PopupHeader/PopupHeader";
import { AppContext } from "../../../../context/AppContext";

import styles from "./EditBooleanPopup.module.css";
import { EditBooleanPopupStyles } from "./EditBooleanPopupStyles";
import SaveButton from "../../../ui/SaveButton/SaveButton";
import CancelButton from "../../../ui/CancelButton/CancelButton";
import SaveLoadingSpinner from "../../../ui/SaveLoadingSpinner/SaveLoadingSpinner";

interface Props {
  uploadTitle: string;
  uploadProperty: string;
  uploadDivision: string;
  uploadType: string;
  handlePopupClose: () => any;
}

export default function EditBooleanPopup(props: Props): ReactElement {
  const { selectedSpace, setSelectedSpace } = useContext(AppContext);

  const [checkboxBoolean, setCheckboxBoolean] = useState(
    selectedSpace[props.uploadDivision][props.uploadProperty].value !==
      undefined
      ? selectedSpace[props.uploadDivision][props.uploadProperty].value
      : false
  );

  const [loadingSpinner, toggleLoadingSpinner] = useState(false);

  const [error, toggleError] = useState(false);

  const handleCheckboxChange = (e) => {
    setCheckboxBoolean(!checkboxBoolean);
  };

  const handleCancelClick = () => {
    if (loadingSpinner) {
      return;
    }

    toggleError(false);
    props.handlePopupClose();
  };

  const handleSaveClick = () => {
    toggleLoadingSpinner(true);

    let selectedSpaceClone = selectedSpace;

    selectedSpaceClone[props.uploadDivision][props.uploadProperty].value =
      checkboxBoolean

    //Assemble correct axios endpoint based on which skin we are editing
    let axiosBaseURL;
    switch (props.uploadDivision) {
      case "worldSkin":
        axiosBaseURL = "/skin/world";
        break;
      case "avatarSkin":
        axiosBaseURL = "/skin/avatar";
        break;
      case "emailSkin":
        axiosBaseURL = "/skin/email";
        break;
      case "uiSkin":
        axiosBaseURL = "/skin/ui";
        break;
    }

    axios
      .put(axiosBaseURL, {
        spaceID: selectedSpace.spaceID,
        property: props.uploadProperty,
        value: checkboxBoolean,
      })
      .then((response) => {
        toggleLoadingSpinner(false);
        setSelectedSpace(selectedSpaceClone);
        props.handlePopupClose();
      })
      .catch((err) => {
        toggleLoadingSpinner(false);
        console.log(err);
      });
  };

  const classes = EditBooleanPopupStyles();

  return (
    <Dialog open={true} classes={{ paper: classes.dialogPaper }}>
      <div className={classes.popup}>
        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={loadingSpinner}
        />

        <Typography variant="h1" className={classes.editHeader}>
          Edit {props.uploadTitle}
        </Typography>

        {error && (
          <Typography variant="body1" className={classes.error}>
            An error occurred editing this checkbox
          </Typography>
        )}

        <div className={styles.editHolder}>
          <FormControlLabel
            value={checkboxBoolean}
            control={<Checkbox color="primary" checked={checkboxBoolean} />}
            label={props.uploadTitle}
            labelPlacement="start"
            className={classes.checkbox}
            onChange={handleCheckboxChange}
          />
        </div>

        {loadingSpinner && <SaveLoadingSpinner />}

        <CancelButton variant="text" onClick={handleCancelClick} />

        <SaveButton disableBoolean={loadingSpinner} onClick={handleSaveClick} />
      </div>
    </Dialog>
  );
}
