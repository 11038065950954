import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { Dialog, Typography, Button, CircularProgress } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import AWS from 'aws-sdk';
import FailIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import { AppContext, AppContextType } from '../../../../context/AppContext';
import { AuthContext, AuthContextType } from '../../../../context/AuthContext';
import PopupHeader from '../../../PopupHeader/PopupHeader';

import styles from './UploadImagePopup.module.css';
import { UploadImagePopupStyles} from './UploadImagePopupStyles';
import SaveButton from '../../../ui/SaveButton/SaveButton';
import CancelButton from '../../../ui/CancelButton/CancelButton';
import UploadLoadingSpinner from '../../../ui/UploadLoadingSpinner/UploadLoadingSpinner';

interface Props {
  handlePopupClose: () => any,
  uploadType: string,
  uploadTitle: string,
  uploadProperty: string,
  uploadDivision: string,
  uploadDimensions?: any
};

export default function UploadImagePopup(props: Props): ReactElement {

  const { 
    selectedSpaceID, 
    selectedSpace, 
    setSelectedSpace 
  } : AppContextType = useContext(AppContext);

  const { idToken } : AuthContextType = useContext(AuthContext);

  const [ loadingSpinner, toggleLoadingSpinner ] = useState(false);
  const [ correctDimensions, toggleCorrectDimensions ] = useState(false);
  const [ dimensionsError, toggleDimensionsError ] = useState(false);
  const [ fileTypeError, toggleFileTypeError ] = useState(false);
  const [ error, toggleError ] = useState(false);

  /*File drop code */
  const onDrop = useCallback(acceptedFiles => {

    toggleDimensionsError(false);

    //Make sure we have accepted files before doing checks
    if (!acceptedFiles.length) {
      return;
    }

    //Make sure we have dimensions to check before checking
    if (props.uploadDimensions === undefined) {
      toggleCorrectDimensions(true);
      return; 
    } else {

      var reader = new FileReader();

      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = function (e) {
        var image = new Image();
  
        (image as HTMLImageElement).src = e.target.result as string;
  
        image.onload = function() {
          var height = image.height;
          var width = image.width;
  
          if (height === props.uploadDimensions.height && width === props.uploadDimensions.width) {
  
            toggleCorrectDimensions(true);
            toggleDimensionsError(false);
  
          } else {
  
            toggleCorrectDimensions(false);
            toggleDimensionsError(true);
            toggleFileTypeError(false);
  
          }
        }
      }
    
    }


  }, [])
  
  const {acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: "image/png",
    onDrop: onDrop,
    multiple: false
  });

  //Assemble accepted files list
  const files = acceptedFiles.map(file => (
    <li key={file.name} className={styles.fileName}>
      {file.name} - {file.size} bytes
    </li>
  ));

  //Assemble rejected files list
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li className={styles.rejectedItem} key={file.name}>
      {file.name} - {file.size} bytes
      <ul>
        {errors.map(e => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));
  /**/
  
  const handleCancelClick = () => {

    if (loadingSpinner) {
      return;
    }
    
    toggleError(false);
    toggleDimensionsError(false);
    props.handlePopupClose();
  }
  
  const handleSaveClick = () => {
    
    toggleLoadingSpinner(true);

    if (!acceptedFiles.length) {
      toggleLoadingSpinner(false);
      return alert("Please choose a file to upload first.");
    }

    

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: "us-east-1",
      signatureVersion: "v4",
    });

    //Get signed urls from s3
    let filesToSend = [];

    acceptedFiles.forEach(file => {
      filesToSend.push({
        name: file.name,
        type: file.type
      })
    });

    axios
      .post("/assets/upload-request", {
        files: filesToSend,
        folderName: props.uploadProperty,
      })
      .then(function (response) {
        let preSignedURLResponse = response.data;

        let baseUrl = "https://go.showboat.live/assets/app/";

        //Remove authorization header to allow for upload to pre-signed URL
        delete axios.defaults.headers.common["Authorization"];

        //Upload the file to s3
        axios
          .put(preSignedURLResponse[0].url, acceptedFiles[0])
          .then((response) => {
            //Add back authorization header
            axios.defaults.headers.common["Authorization"] = idToken;

            //Update ui skin with uploaded item's url
            if (props.uploadDivision === "uiSkin") {
              let selectedSpaceClone = selectedSpace;

              let uploadProperty = props.uploadProperty;

              selectedSpaceClone.uiSkin[uploadProperty].value =
                baseUrl +
                `${props.uploadProperty}/` +
                preSignedURLResponse[0].fileID +
                ".png";

              axios
                .put("/skin/ui", {
                  spaceID: selectedSpaceID,
                  property: props.uploadProperty,
                  value:
                    baseUrl +
                    `${props.uploadProperty}/` +
                    preSignedURLResponse[0].fileID +
                    ".png",
                })
                .then((response) => {
                  toggleLoadingSpinner(false);
                  console.log(response);
                  props.handlePopupClose();
                  setSelectedSpace(selectedSpaceClone);
                })
                .catch((err) => {
                  toggleLoadingSpinner(false);
                  console.log(err);
                  toggleError(true);
                });
            }
            //Update world skin
            else if (props.uploadDivision === "worldSkin") {
              let selectedSpaceClone = selectedSpace;

              let uploadProperty = props.uploadProperty;

              selectedSpaceClone.worldSkin[uploadProperty].value =
                baseUrl +
                `${props.uploadProperty}/` +
                preSignedURLResponse[0].fileID +
                ".png";

              axios
                .put("/skin/world", {
                  spaceID: selectedSpaceID,
                  property: props.uploadProperty,
                  value:
                    baseUrl +
                    `${props.uploadProperty}/` +
                    preSignedURLResponse[0].fileID +
                    ".png",
                })
                .then((response) => {
                  toggleLoadingSpinner(false);
                  props.handlePopupClose();
                  setSelectedSpace(selectedSpaceClone);
                })
                .catch((err) => {
                  toggleLoadingSpinner(false);
                  console.log(err);
                  toggleError(true);
                });
            } else if (props.uploadDivision === "avatarSkin") {
              let selectedSpaceClone = selectedSpace;

              let uploadProperty = props.uploadProperty;

              selectedSpaceClone.avatarSkin[uploadProperty].value =
                baseUrl +
                `${props.uploadProperty}/` +
                preSignedURLResponse[0].fileID +
                ".png";

              axios
                .put("/skin/avatar", {
                  spaceID: selectedSpaceID,
                  property: props.uploadProperty,
                  value:
                    baseUrl +
                    `${props.uploadProperty}/` +
                    preSignedURLResponse[0].fileID +
                    ".png",
                })
                .then((response) => {
                  toggleLoadingSpinner(false);
                  console.log(response);
                  props.handlePopupClose();
                  setSelectedSpace(selectedSpaceClone);
                })
                .catch((err) => {
                  toggleLoadingSpinner(false);
                  console.log(err);
                  toggleError(true);
                });
            }
            //Update email skin
            else if (props.uploadDivision === "emailSkin") {
              let selectedSpaceClone = selectedSpace;

              let uploadProperty = props.uploadProperty;

              selectedSpaceClone.emailSkin[uploadProperty].value =
                baseUrl +
                `${props.uploadProperty}/` +
                preSignedURLResponse[0].fileID +
                ".png";

              axios
                .put("/skin/email", {
                  spaceID: selectedSpaceID,
                  property: props.uploadProperty,
                  value:
                    baseUrl +
                    `${props.uploadProperty}/` +
                    preSignedURLResponse[0].fileID +
                    ".png",
                })
                .then((response) => {
                  toggleLoadingSpinner(false);
                  console.log(response);
                  props.handlePopupClose();
                  setSelectedSpace(selectedSpaceClone);
                })
                .catch((err) => {
                  toggleLoadingSpinner(false);
                  console.log(err);
                  toggleError(true);
                });
            }
          })
          .catch((err) => {
            //Add back authorization header
            axios.defaults.headers.common["Authorization"] = idToken;
            console.log(err);
            toggleLoadingSpinner(false);
            toggleError(true);
          });
      })
      .catch(function (err) {
        toggleLoadingSpinner(false);
        console.log("ERROR", err);
        toggleError(true);
      });
  }

  useEffect(() => {
    //Check if we have a file type error
    if (fileRejections && fileRejections[0] && fileRejections[0].errors) {
      if (fileRejections[0].errors[0].code === "file-invalid-type") {
        toggleFileTypeError(true);
      } else {
        toggleFileTypeError(false);
      }
    } else {
      toggleFileTypeError(false);
    }
  }, [fileRejections, acceptedFiles, dimensionsError])

  const classes = UploadImagePopupStyles();

  return (
    <Dialog open={true} classes={{ paper: classes.dialogPaper }}>
      <div className={classes.popup}>
        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={loadingSpinner}
        />

        <Typography variant="h1" className={classes.uploadHeader}>
          {props.uploadTitle}
        </Typography>

        {props.uploadTitle === "Header Logo" ? (
          <Typography variant="body1" className={classes.uploadSizeHeader}>
            PNG: 145px x 145px
          </Typography>
        ) : (
          props.uploadDimensions !== undefined && (
            <Typography variant="body1" className={classes.uploadSizeHeader}>
              PNG: {props.uploadDimensions.width}px x{" "}
              {props.uploadDimensions.height}px
            </Typography>
          )
        )}

        {error && (
          <Typography variant="body1" className={classes.error}>
            An error occurred uploading the image
          </Typography>
        )}

        <div
          className={styles.fileUploadHolder}
          style={{
            marginBottom:
              fileRejections.length || dimensionsError
                ? "160px"
                : files.length
                ? "139px"
                : "0px",
          }}
        >
          <div {...getRootProps({ className: `${styles.dropzone}` })}>
            <input type="file" {...getInputProps()} id="fileUpload" />
            <Typography variant="body1" className={classes.dropzoneText}>
              Drag 'n' drop a file here, or click to select a file
            </Typography>
            <Typography variant="body1" className={classes.dropzoneText}>
              Only .png image files will be accepted
            </Typography>
          </div>

          <div className={styles.fileResultHolder}>
            {files.length !== 0 && !dimensionsError && (
              <div className={styles.fileSuccessHolder}>
                <Typography variant="body1" className={classes.fileName}>
                  {acceptedFiles[0] ? (acceptedFiles[0] as any).name : ""}
                </Typography>

                <CheckIcon className={styles.checkIcon} />
              </div>
            )}

            {(fileRejectionItems.length !== 0 || dimensionsError) && (
              <div className={styles.fileFailureHolder}>
                <Typography variant="body1" className={classes.fileName}>
                  {dimensionsError
                    ? acceptedFiles[0]
                      ? (acceptedFiles[0] as any).name
                      : ""
                    : fileRejections[0]
                    ? (fileRejections[0] as any).file.name
                    : ""}
                </Typography>

                {dimensionsError && (
                  <Typography variant="body1" className={classes.fileError}>
                    Dimensions of image must be {props.uploadDimensions.width}px
                    x {props.uploadDimensions.height}px
                  </Typography>
                )}

                {fileTypeError && (
                  <Typography variant="body1" className={classes.fileError}>
                    File must be of type PNG
                  </Typography>
                )}

                <FailIcon className={styles.failIcon} />
              </div>
            )}
          </div>
        </div>

        {loadingSpinner && (
          <UploadLoadingSpinner />
        )}

        <CancelButton
          onClick={handleCancelClick}
          variant="text"
        />

        <SaveButton
          disableBoolean={!correctDimensions || fileTypeError || loadingSpinner}
          onClick={handleSaveClick}
        />
      </div>
    </Dialog>
  );
}
