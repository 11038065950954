import { makeStyles, createStyles } from "@material-ui/core/styles";

const AttendeeEditPopupStyles = makeStyles((theme: any) =>
  createStyles({
    dialogPaper: {
      width: 1003,
      height: 644,
      display: "inline-block",
      margin: 0,
      overflowX: "hidden",
      overflowY: "auto",
      [theme.breakpoints.down("sm")]: {
        width: 700,
        height: 700,
      },
    },
    dialogPaperInternational: {
      height: 800,
      [theme.breakpoints.down("sm")]: {
        height: 870,
      },
    },
    dialogPaperPresenter: {
      height: 700,
    },
    dialogPaperInternationalPresenter: {
      height: 866,
    },
    popup: {
      width: "100%",
      height: 644,
      position: "absolute",
      [theme.breakpoints.down("sm")]: {
        height: 700,
      },
    },
    popupInternational: {
      [theme.breakpoints.down("sm")]: {
        height: 870,
      },
    },
    editAttendeeHeader: {
      fontSize: "1.5rem",
      fontWeight: 400,
      position: "absolute",
      left: 30,
      top: 58,
    },
    editAttendeeLink: {
      fontSize: 16,
      position: "relative",
      fontWeight: 400,
    },
    editAttendeeLinkBridge: {
      position: "absolute",
      fontSize: 16,
      fontWeight: 400,
      top: 33,
      left: 15,
    },
    copyLinkButton: {
      padding: 4,
      position: "relative",
      bottom: 8,
      color: "black",
      zIndex: 9999,
    },
    copyLinkButtonBridge: {
      position: "absolute",
      bottom: -37,
      right: 20,
    },
    resendInviteButton: {
      padding: 4,
      color: "black",
      position: "relative",
      bottom: 8,
      left: 3,
    },
    regenerateButton: {
      padding: 4,
      color: "black",
      position: "relative",
      bottom: 8,
    },
    trashIcon: {},
    trashIconButton: {
      padding: 4,
      color: "black",
      position: "relative",
      bottom: 8,
    },
    brandToggle: {
      position: "relative",
      left: 105,
    },
    loadingSpinner: {
      width: "20px !important",
      height: "20px !important",
      right: 71,
      bottom: 84,
      color: "grey",
      position: "absolute",
      zIndex: 999,
      [theme.breakpoints.down("sm")]: {
        right: 57,
        bottom: 93,
      },
    },
    loadingSpinnerPresenter: {
      bottom: 24,
    },
    loadingSpinnerInternational: {
      bottom: -30,
    },
    loadingSpinnerInternationalPresenter: {
      bottom: -90
    },
    copySnackbar: {
      width: 360,
    },
    regenerateSnackbar: {
      width: 360,
    },
    error: {
      color: "red",
      position: "absolute",
      right: 692,
      top: 86,
      [theme.breakpoints.down("sm")]: {
        left: 31,
        top: 82,
        right: 0,
      },
    },
    errorPresenter: {
      top: 62,
      right: 477,
      [theme.breakpoints.down("sm")]: {
        left: 255,
        width: 300,
      }
    },
    emailError: {
      color: "red",
      position: "absolute",
      right: 125,
      top: 130,
      [theme.breakpoints.down("sm")]: {
        top: 175,
        right: 115,
      },
    },
    regenerateLoadingSpinner: {
      width: "20px !important",
      height: "20px !important",
      color: "grey",
      position: "absolute",
      right: 115,
      bottom: 35,
    },
    resendInviteLoadingSpinner: {
      width: "20px !important",
      height: "20px !important",
      color: "grey",
      position: "absolute",
      right: 275,
      bottom: 35,
    },
    allowEarlyAccessToggle: {
      position: "relative",
      left: 200,
    },
    closeIconButton: {
      position: "absolute",
      top: 3,
      color: "white",
      padding: 4,
      right: 5,
    },
    tooltip: {
      opacity: 1,
      fontSize: 12,
      padding: "8px 12px",
      backgroundColor: "#757575 !important",
    },
    tooltipPopper: {
      opacity: 1,
    },
    toggleLabel: {
      fontSize: 14,
    },
    tabs: {},
    tab: {
      padding: "6px 17px",
    },
    trashIconButtonPresenter: {
      top: 56,
      right: 24,
      position: "absolute",
      width: 32,
      height: 32,
    },
    linkHeader: {
      fontSize: 18,
      fontWeight: "bold",
    },
    presenterLink: {
      fontSize: 18,
      position: "relative",
      fontWeight: 400,
      top: 12,
    },
    bridgeLink: {},
    presenterEmailError: {
      color: "red",
      fontSize: 16,
      position: "relative",
    },
    cancelButton: {
      width: 95,
      height: 44,
      position: "absolute",
      bottom: -129,
      right: 115,
      [theme.breakpoints.down("sm")]: {
        bottom: -143,
      },
    },
    cancelButtonInternational: {
      bottom: -250,
      [theme.breakpoints.down("sm")]: {
        bottom: -145,
      },
    },
    saveButton: {
      width: 95,
      height: 44,
      position: "absolute",
      bottom: -129,
      right: -10,
      backgroundColor: "#009617",
      [theme.breakpoints.down("sm")]: {
        bottom: -143,
      },
      "&:hover": {
        backgroundColor: "#00B6FF",
      },
    },
    saveButtonInternational: {
      bottom: -250,
      [theme.breakpoints.down("sm")]: {
        bottom: -145,
      },
    },
    saveButtonAddAttendee: {
      bottom: -43,
    },
    saveButtonAddInternational: {
      bottom: -168,
    },
    cancelButtonAddAttendee: {
      bottom: -43,
    },
    cancelButtonAddInternational: {
      bottom: -168,
    },
    bridgeToggle: {
      position: "absolute",
      bottom: 70,
      left: 135
    },
    accessDivider: {
      position: "relative",
      top: 27,
      width: "99.7%"
    }
  })
);

export { AttendeeEditPopupStyles };
