import React, { ReactElement, useContext, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";

import { AppContext, AppContextType } from "../../../context/AppContext";
import PopupHeader from "../../PopupHeader/PopupHeader";

import { AddCalendarPopupStyles } from "./AddCalendarPopupStyles";
import SaveButton from "../../ui/SaveButton/SaveButton";
import CancelButton from "../../ui/CancelButton/CancelButton";
import SaveLoadingSpinner from "../../ui/SaveLoadingSpinner/SaveLoadingSpinner";

interface Props {
  open: boolean;
  handlePopupClose: () => any;
  handleCancelClick: () => any;
}

export default function AddCalendarPopup(props: Props): ReactElement {
  const {
    selectedSpaceID,
    selectedCalendar,
    setSelectedCalendar,
  }: AppContextType = useContext(AppContext);

  const [calendarName, setCalendarName] = useState("");
  const [error, toggleError] = useState(false);
  const [loadingSpinner, toggleLoadingSpinner] = useState(false);
  const [formHasBeenSubmitted, toggleFormHasBeenSubmitted] = useState(false);
  const [nameRequiredError, toggleNameRequiredError] = useState(false);

  const handleCalendarNameChange = (e) => {
    toggleNameRequiredError(false);

    setCalendarName(e.target.value);
  };

  const handleSaveButtonClick = async () => {
    toggleFormHasBeenSubmitted(true);

    if (!calendarName.trim().length) {
      toggleNameRequiredError(true);
      return;
    }

    toggleLoadingSpinner(true);

    toggleNameRequiredError(false);

    toggleError(false);

    try {
      let response = await axios.post("/calendar", {
        calendarName: calendarName,
        spaceID: selectedSpaceID,
      });

      toggleLoadingSpinner(false);
      setSelectedCalendar(response.data);
      props.handlePopupClose();
    } catch {
      toggleLoadingSpinner(false);
      toggleError(true);
    }
  };

  const handleCancelButtonClick = () => {
    if (loadingSpinner) return;

    props.handleCancelClick();
  };

  const classes = AddCalendarPopupStyles();

  return (
    <Dialog open={props.open} classes={{ paper: classes.dialogPaper }}>
      <div className={classes.popup}>
        <PopupHeader
          closeFunction={props.handlePopupClose}
          disableBoolean={loadingSpinner}
        />

        <Typography variant="h1" className={classes.createCalendarHeader}>
          Name Calendar
        </Typography>

        {error && (
          <Typography variant="body1" className={classes.error}>
            An error occurred creating this calendar
          </Typography>
        )}

        <TextField
          variant="outlined"
          value={calendarName}
          onChange={handleCalendarNameChange}
          helperText="Required"
          className={classes.calendarNameInput}
          placeholder="Calendar Name"
          error={formHasBeenSubmitted && nameRequiredError}
        />

        {loadingSpinner && (
          <SaveLoadingSpinner />
        )}


        <CancelButton onClick={handleCancelButtonClick} variant="text" />

        <SaveButton
          disableBoolean={loadingSpinner}
          onClick={handleSaveButtonClick}
        />
      </div>
    </Dialog>
  );
}
